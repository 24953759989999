/**
 * @React
 */
import React, {
  createContext,
  useContext,
  useState
} from 'react';
/**
 * @Types
 */
import {
  COMPONENTS,
  CURRENCY,
  IMonitoringAgreementModel,
  ProgramComponent
} from '../../types';
interface FormDataContextType<T> {
  formData: T;
  setFormData: React.Dispatch<React.SetStateAction<T>>;
}


export const FormDataContext = createContext<FormDataContextType<Partial<IMonitoringAgreementModel>> | undefined>(undefined);
export const MonitoringFormDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<Partial<IMonitoringAgreementModel>>({});

  return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = (): FormDataContextType<any> => {
  const context = useContext(FormDataContext);
  if (context === undefined) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};
