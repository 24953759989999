import _ from "lodash";
import {
  ICountry,
  IEditField,
  IEditFieldGroup,
  IFieldGroup,
  IInputField,
  IInputFieldOption,
  IMonitoringAgreementModel,
  IMonitoringFields,
  INaceActivity,
  InputObject,
  MonitoringFieldsOptionsData,
  MonitoringFieldsOptionType,
  MyType,
  PropertyMappings,
  TransformedData,
  TransformedObject,
} from "../../../../types";
import { monitoring_initial_data } from "../init";
import { IFieldValues, IFieldOptions } from "../monitoringDeps";
const getFirstErrorMessage = (errors: any): string | undefined => {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key];
      if (error?.message) {
        return error.message;
      } else if (typeof error === "object") {
        const nestedErrorMessage = getFirstErrorMessage(error);
        if (nestedErrorMessage) {
          return nestedErrorMessage;
        }
      }
    }
  }
  return undefined;
};

const getNestedError = (errors: any, fieldName: string) => {
  return fieldName.split(".").reduce((acc, part) => acc && acc[part], errors);
};
const transformDataStructure = <T extends Record<string, InputObject[]>>(
  data: T,
  propertyMappings: PropertyMappings
): { options: TransformedData; values: any } => {
  const result: { options: TransformedData; values: any } = {
    options: {},
    values: {},
  };
  for (const [key, objectsArray] of Object.entries(data)) {
    result.options[key] = {};
    result.values[key] = {};
    objectsArray.forEach((item) => {
      const { _id } = item;
      const label = propertyMappings[key]
        .map((prop) => {
          return item[prop];
        })
        .join(" ");
      result.options[key][_id] = {
        value: _id,
        label: label,
      };
      result.values[key][_id] = item;
    });
  }

  return result;
};

const generateOptions = <T, U>(data: T[], a: keyof T, b: keyof T, c?: keyof T): MyType<U> => {
  let options: MyType<U> = {} as MyType<U>;
  data.forEach((item: T) => {
    if (item && item[a] && item[b]) {
      let value = item[a] as unknown as U[keyof U] | undefined;
      let label = `${item[b]} ${item[a]}` as unknown as U[keyof U] | undefined;
      options[item[a] as unknown as keyof MyType<U>] = { value, label } as unknown as U[keyof U] | undefined;
    }
  });
  return options;
};
const selectKeys = <T>(data: T | null, initialData: { [key: string]: any }): T => {
  let result = _.cloneDeep(initialData) as T & object;
  let init = _.cloneDeep(initialData) as T & object;
  if (!data) return result as T;
  Object.keys(result).forEach((key) => {
    result[key as keyof T] = assignValueToField(data, key as string, init);
  });
  return result;
};

const getUniqueObjectsByProps = <T>(arr: T[], a: keyof T, b: keyof T): MyType<T> => {
  const uniqueSet = new Set<string>();
  let options: MyType<T> = {} as MyType<T>;
  arr.forEach((item: T) => {
    const key = `${item[a]}-${item[b]}`;
    if (!uniqueSet.has(key)) {
      uniqueSet.add(key);
      if (item && item[a] && item[b]) {
        options[item[a] as unknown as keyof MyType<T>] = item as any | undefined;
      }
    }
  });
  return options;
};
const getCompaniesData = async <T, U>(
  data: T[],
  setCompaniesData: React.Dispatch<T[] | null>,
  getItem: (item: T) => U,
  setCompanies: React.Dispatch<MyType<{ value: string; label: string }> | null>,
  setCompaniesLoad: React.Dispatch<boolean>
) => {
  setCompaniesLoad(true);
  setCompaniesData(data);
  const tempCompanies: U[] = data
    ?.map(getItem)
    // .slice(0, 200)
    .filter((b) => !!b);
  // .sort((a:U, b:U) => Number(b.value) - Number(a.value)) as U;

  const uniqueCompanies = getUniqueObjectsByProps<U>(tempCompanies as U[], "value" as keyof U, "label" as keyof U) as MyType<U>;
  setCompanies(uniqueCompanies as any);
  setCompaniesLoad(false);
};
const getUniqueCompaniesData = async <T>(
  data: T[],
  setCompaniesData: React.Dispatch<T[] | null>,
  getItem: (item: T) => string | undefined,
  setCompanies: React.Dispatch<string[] | null>,
  setCompaniesLoad: React.Dispatch<boolean>
) => {
  setCompaniesLoad(true);
  setCompaniesData(data);
  const tempCompanies: string[] = data
    ?.map(getItem)
    .filter((b) => !!b)
    .sort((a, b) => Number(b) - Number(a)) as string[];
  const uniqueCompanies: string[] = Array.from(new Set(tempCompanies));
  setCompanies(uniqueCompanies);
  setCompaniesLoad(false);
};

type ObjectWithId<T extends Record<string, any>> = T & { _id: string };

const transformArray = <T extends Record<string, any>>(arr: ObjectWithId<T>[]): Record<string, T> =>
  arr.reduce((acc, item) => {
    const { _id, ...rest } = item;
    acc[_id] = { _id, ...rest } as unknown as T;
    return acc;
  }, {} as Record<string, T>);

const addOptions = (
  optionsData: TransformedData,
  group: IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[]
): IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[] => {
  let updatedGroup = _.cloneDeep(group);
  updatedGroup.forEach((group) => {
    if (group.groupName === "exporter_company") {
      for (let field of group.fields) {
        if (field.name === "country_export") {
          if (field.fieldTemplate?.country_export) {
            field.fieldTemplate.country_export.autocompleteOptions = optionsData.countries;
            break;
          }
        }
      }
    }
    if (group.groupName === "fixed_assets_capital_expenditures_funds_turnover") {
      for (let field of group.fields) {
        if (field.name === "project_activities") {
          if (field.fieldTemplate?.project_activities) {
            field.fieldTemplate.project_activities.autocompleteOptions = optionsData.nace_activities;
            break;
          }
        }
      }
    }
    if (group.groupName === "bank_credentials") {
      for (let field of group.fields) {
        if (field.name === "bank") {
          field.autocompleteOptions = optionsData.banks;
          break;
        }
      }
    }
    if (group.groupName === "actual_municipality_address_cadastral_code_is_building_rented") {
      for (let field of group.fields) {
        if (field.name === "actual_municipality") {
          field.autocompleteOptions = optionsData.municipalities;
          break;
        }
      }
    }
  });
  return updatedGroup;
};

const getNestedValue = (obj: any, path: string) => {
  const keys = path.split(".");
  let value = obj;
  for (const key of keys) {
    if (value[key] !== undefined && value[key] !== null) {
      if (!Array.isArray(value[key]) || value[key].length !== 0) {
        value = value[key];
      } else {
        value = obj[key];
      }
    } else {
      value = obj[key];
    }
  }
  return value;
};

const assignValueToField = (object: any, fieldName: string, initialData: { [key: string]: any }) => {
  const keys = fieldName.split(".");
  let init: { [key: string]: any } = _.cloneDeep(initialData);
  let value = object;
  for (const key of keys) {
    if (value[key] !== undefined && value[key] !== null) {
      if (!Array.isArray(value[key]) || value[key].length !== 0) {
        value = value[key];
      } else {
        value = init[key];
      }
    } else {
      value = init[key];
    }
  }
  return value;
};

type FieldType<T> = IInputField<T>;
type GroupType<T> = IFieldGroup<T>;

const updateMonitoringFormFields = <T, K extends keyof T>(
  groups: GroupType<T>[],
  initValues: T,
  dependedOnFields?: { [key in K]: string },
  dependendedFieldValues?: IFieldValues,
  valueDependencies?: { [key: string]: (a: any) => boolean },
  optionsValueDependencies?: IFieldOptions
) => {
  if (!initValues) return null;
  let updatedFields: GroupType<T>[] = Object.assign([], groups);
  // let updatedFields: GroupType<T>[] = _.cloneDeep(groups) as GroupType<T>[];
  let updatedInitValues: Partial<T> = Object.assign({}, initValues);
  // let updatedInitValues: Partial<T> = _.cloneDeep(initValues) as Partial<T>;
  let dependencies: any = {
    ...(dependedOnFields && { keyToWatch: [...Object.keys(dependedOnFields)], options: {} }),
  };
  groups.forEach((group: GroupType<T>) => {
    group.fields.forEach((field: FieldType<T>, index: number) => {
      if (field.sub_fields && Array.isArray(field.sub_fields)) {
        const key = field.name as K;
        const arr = assignValueToField(updatedInitValues, field.name, _.cloneDeep(monitoring_initial_data));
        let template = _.cloneDeep(field.fieldTemplate) as FieldType<T>;
        let sub_fields = [] as FieldType<T>[];
        if (Array.isArray(arr) && field.fieldTemplate) {
          if (arr.length > 0) {
            arr.forEach((item: string, i: number) => {
              if (key in template) {
                let temp_field = _.cloneDeep(template[key as keyof FieldType<T>]) as FieldType<T>;
                temp_field.value = item;
                temp_field.name = `${key as keyof FieldType<T>}[${i}]`;
                sub_fields.push(temp_field as FieldType<T>);
              }
            });
          } else {
            let temp_field = _.cloneDeep(template[key as keyof FieldType<T>]) as FieldType<T>;
            temp_field.value = "";
            temp_field.name = `${key as keyof FieldType<T>}[0]`;
            sub_fields.push(temp_field as FieldType<T>);
          }

          field.sub_fields = sub_fields;
        }
        if (dependedOnFields && dependendedFieldValues) {
          if (key in dependedOnFields) {
            let k = dependedOnFields[key] as keyof IFieldValues;
            for (let element of k) {
              if (valueDependencies && element in valueDependencies) {
                dependencies[element] = valueDependencies[element as string](field.value);
              } else {
                dependencies[element] = field.value;
              }
              if (optionsValueDependencies && field.type === "select" && element in optionsValueDependencies) {
                dependencies[element] = optionsValueDependencies[element as keyof IFieldOptions](field.value);
              }
            }
          }
        }
      } else if (field.subGroupFields && Array.isArray(field.subGroupFields)) {
        field.subGroupFields.forEach((subField: FieldType<T>, i: number) => {
          let key = subField.name as K;
          subField.value = assignValueToField(updatedInitValues, subField.name, monitoring_initial_data);
          if (dependedOnFields && dependendedFieldValues) {
            if (key in dependedOnFields) {
              let k = dependedOnFields[key] as keyof IFieldValues;
              for (let element of k) {
                if (valueDependencies && element in valueDependencies) {
                  dependencies[element] = valueDependencies[element as string](subField.value);
                } else {
                  dependencies[element] = subField.value;
                }
                if (optionsValueDependencies && subField.type === "select" && element in optionsValueDependencies) {
                  dependencies[element] = optionsValueDependencies[element as keyof IFieldOptions](subField.value);
                }
              }
            }
          }
        });
      } else {
        const key = field.name as K;
        field.value = assignValueToField(updatedInitValues, field.name, monitoring_initial_data);
        if (dependedOnFields && dependendedFieldValues) {
          if (key in dependedOnFields) {
            let k = dependedOnFields[key] as keyof IFieldValues;
            for (let element of k) {
              if (valueDependencies && element in valueDependencies) {
                dependencies[element] = valueDependencies[element as string](field.value);
              } else {
                dependencies[element] = field.value;
              }
              if (optionsValueDependencies && field.type === "select" && element in optionsValueDependencies) {
                dependencies.options[element] = optionsValueDependencies[element as keyof IFieldOptions](field.value);
              }
            }
          }
        }
      }
    });
  });
  return {
    updatedFields,
    dependencies: dependedOnFields && dependendedFieldValues ? dependencies : null,
  };
};
export const propertyMappings: PropertyMappings = {
  country_export: ["name"],
  project_activities: ["activity_code", "activity_description"],
  // actual_municipality: [`region`, `municipality`],
  "actual_municipality.region": ["region"],
  "actual_municipality.municipality": ["municipality"],
  bank: ["name"],
};
export const mappingPropertyToOtherProperty = {
  country_export: "countries",
  project_activities: "nace_activities",
  "actual_municipality.region": "municipalities",
  "actual_municipality.municipality": "municipalities",
  bank: "banks",
};

// const getProperData = ()
type EditFieldType<T> = IEditField<T>;
type EditGroupType<T> = IEditFieldGroup<T>;
const generateDetailsFields = <T, K extends keyof T>(
  groups: EditGroupType<T>[],
  initValues: T,
  generalInfo?: { [key: string]: any } | null,
  dependedOnFields?: { [key in K]: string },
  dependendedFieldValues?: IFieldValues,
  valueDependencies?: { [key: string]: (a: any) => boolean }
) => {
  if (!initValues) return null;
  let updatedFields: EditGroupType<T>[] = Object.assign([], groups);
  let updatedInitValues: Partial<T> = Object.assign({}, initValues);
  let dependencies: any = {
    ...(dependedOnFields && { keyToWatch: [...Object.keys(dependedOnFields)], options: {} }),
  };
  groups.forEach((group: EditGroupType<T>) => {
    group.fields.forEach((field: EditFieldType<T>, index: number) => {
      if (field.sub_fields && Array.isArray(field.sub_fields)) {
        const key = field.name as K;
        const arr = assignValueToField(updatedInitValues, field.name, _.cloneDeep(monitoring_initial_data));
        let sub_fields = [] as IEditField<T>[];
        if (Array.isArray(arr)) {
          arr.forEach((item: string, i: number) => {
            let mappedKey = mappingPropertyToOtherProperty[key as keyof typeof mappingPropertyToOtherProperty];
            if (generalInfo && generalInfo[mappedKey as keyof typeof generalInfo]) {
              let itemObject = generalInfo[mappedKey as keyof typeof generalInfo][item];
              if (field.generateDetailsFields) {
                sub_fields.push({
                  name: key as string,
                  value: field.generateDetailsFields(
                    ...propertyMappings[key as keyof typeof propertyMappings].map((a) => {
                      return `${itemObject[a]}`;
                    })
                  ),
                  type: "text",
                  label: field.label,
                });
              }
            }
          });
          field.sub_fields = sub_fields;
        }
        if (dependedOnFields && dependendedFieldValues) {
          if (key in dependedOnFields) {
            let k = dependedOnFields[key] as keyof IFieldValues;
            for (let element of k) {
              if (valueDependencies && element in valueDependencies) {
                dependencies[element] = valueDependencies[element as string](field.value);
              } else {
                dependencies[element] = field.value;
              }
            }
          }
        }
      } else if (field.subGroupFields && Array.isArray(field.subGroupFields)) {
        field.subGroupFields.forEach((subField: EditFieldType<T>, i: number) => {
          let key = subField.name as K;
          subField.value = assignValueToField(updatedInitValues, subField.name, monitoring_initial_data);
          if (dependedOnFields && dependendedFieldValues) {
            if (key in dependedOnFields) {
              let k = dependedOnFields[key] as keyof IFieldValues;
              for (let element of k) {
                if (valueDependencies && element in valueDependencies) {
                  dependencies[element] = valueDependencies[element as string](subField.value);
                } else {
                  dependencies[element] = subField.value;
                }
              }
            }
          }
        });
      } else {
        const key = field.name as K;
        let temp = assignValueToField(updatedInitValues, field.name, monitoring_initial_data);
        if (mappingPropertyToOtherProperty[key as keyof typeof mappingPropertyToOtherProperty]) {
          let mappedKey = mappingPropertyToOtherProperty[key as keyof typeof mappingPropertyToOtherProperty];
          if (generalInfo && generalInfo[mappedKey as keyof typeof generalInfo]) {
            temp = assignValueToField(updatedInitValues, field.name.toString().split(".")[0], monitoring_initial_data);
            let items = generalInfo[mappedKey as keyof typeof generalInfo];
            let val = temp ? items[temp as keyof typeof items][propertyMappings[key as keyof typeof propertyMappings][0]] : "";
            // let val =  items[temp as keyof typeof items][propertyMappings[key as keyof typeof propertyMappings][0]];
            field.value = val;
          } else {
            field.value = temp;
          }
        } else {
          field.value = temp;
        }
        if (dependedOnFields && dependendedFieldValues) {
          if (key in dependedOnFields) {
            let k = dependedOnFields[key] as keyof IFieldValues;
            for (let element of k) {
              if (valueDependencies && element in valueDependencies) {
                dependencies[element] = valueDependencies[element as string](field.value);
              } else {
                dependencies[element] = field.value;
              }
              // if (optionsValueDependencies && field.type === "select" && element in optionsValueDependencies) {
              //   dependencies.options[element] = optionsValueDependencies[element as keyof IFieldOptions](field.value);
              // }
            }
          }
        }
      }
    });
  });
  return {
    updatedFields,
    dependencies: dependedOnFields && dependendedFieldValues ? dependencies : null,
  };
};
interface IDataType {
  name: string;
  type: "text" | "number" | "select" | "checkbox" | "date" | "textarea" | "autocomplete";
}
const resetFieldValue = (data: IDataType[]): { [key: string]: any } => {
  let result: { [key: string]: any } = {};

  data.forEach((item: IDataType) => {
    const [arrayName, arrayIndex, fieldName] = item.name.split(/[\[\].]+/g);
    switch (item.type) {
      case "text":
      case "textarea":
      case "autocomplete":
        result[fieldName] = "";
        break;
      case "number":
        result[fieldName] = 0;
        break;
      case "date":
        result[fieldName] = null;
        break;
      default:
        result[fieldName] = false;
    }
  });
  return result;
};
export {
  generateDetailsFields,
  selectKeys,
  getCompaniesData,
  getUniqueCompaniesData,
  updateMonitoringFormFields,
  assignValueToField,
  getNestedValue,
  addOptions,
  transformArray,
  getUniqueObjectsByProps,
  generateOptions,
  transformDataStructure,
  resetFieldValue,
  getNestedError,
  getFirstErrorMessage,
};
