import React, { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
interface CardFieldSetProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}
const CardFieldSet: FC<CardFieldSetProps> = ({ title, children, className = '' }) => {
  return (
    <Box
      className={className}
      sx={{
        display: "inline-block",
        width: "100%",
        fontFamily: "none",
        color: "rgb(44, 91, 148)",
        fontSize: { xs: "10px", sm: "12px", md: `14px` },
        padding: "10px",
      }}
    >
      <fieldset
        style={{
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
          padding: "30px",
          fontSize: "14px",
        }}
      >
        <legend
          style={{
            padding: "0 10px",
          }}
        >
          <b>{title}</b>
        </legend>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "15px",
          }}
        >
          {children}
        </Box>
      </fieldset>
    </Box>
  );
};

export default CardFieldSet;
