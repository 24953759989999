const downloader = (pdfBuffer) => {
  const handleDownload = async (pdfBuffer) => {
    const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'application.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    console.log('PDF generated and downloaded successfully!');
  };
  handleDownload(pdfBuffer);
};

export default downloader;