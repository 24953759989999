import { makeStyles } from "@material-ui/core/styles";

const DetailedStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "98%",
    fontSize: "0.9rem",
  },
  innerCardContent: {
    display: "flex",
    flexWrap: "wrap",
  },
  component: {
    minWidth: "48%",
    margin: "0 15px 15px 0",
  },
  cardSummary: {
    fontSize: "1.1rem",
    margin: "0 0 8px 0",
    borderBottom: "1px #979797 solid",
  },
  table: {
    borderCollapse: "collapse",
    minWidth: "100%",
    width: "100%",
    margin: "0 0 15px 0",
  },
  row: {
    width: "100%",
    borderBottom: "1px #e3e3e3 solid",
  },
  title: {
    textAlign: "left",
  },
  detail: {
    fontWeight: "bold",
    textAlign: "right",
    verticalAlign: "middle",
    maxWidth: "calc(100%)",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    gap: "10px",
    margin: "15px 0 0 0",
  },
  button: {
    margin: "30px 30px 30px 0",
  },
});

export default DetailedStyles;
