/**
 * @React
 */
import React, {
    FC,
    ReactNode,
    useEffect,
    useState
} from "react";
/**
 * @Router
 */
import { useHistory, useLocation, useParams } from 'react-router-dom';
/**
 * @Router
 */
import {
    FormProvider,
    set,
    useForm,
    useFormContext,
    UseFormReturn
} from "react-hook-form";
/**
 * @MUI
 */
import {
    Box,
    Skeleton,
    CardContent,
    Card,
    Alert
} from "@mui/material";
/**
 * @Context
 */
import {
    useGeneralInfoContext,
    useMonitoringFormContext,
} from "../../../context";

/**
 * @Components
 */
import { MonitoringForm } from "../../../components/FormComponent";
import AutoComplete from "../../../components/CustomComponents/AutoComplete";
import SelectCategory from "../../../components/Selection";
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
    IMonitoringFields,
    IInputField,
    IMonitoringAgreementModel,
    IFieldGroup,
    IGroup,
    INaceActivity,
    ICountry,
    MonitoringFieldsOptionType,
    MyType,
    TransformedData,
    MonitoringFieldsOptionsData
} from "../../../types";
/**
 * @Utilities
 */
import {
    addOptions,
    generateDetailsFields,
    updateMonitoringFormFields
} from "../../../utilities/Fields/monitoringFields/utils";
import {
    monitoringGroupFieldsInitValues,
} from "../../../utilities/Fields/monitoringFields/fields/forms";
import {
    dependencies,
    fieldValues,
    optionsValueDependencies,
    valueDependencies
} from "../../../utilities/Fields/monitoringFields/monitoringDeps";
import CardFieldSet from "../../../components/CustomComponents/CardFieldSet";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../../hooks";
import MonitoringLayout from "../../../components/MonitoringLayout";
import { moitoringDetailsFields } from "../../../utilities/Fields/monitoringFields/fields/detailFields";
import {
    MonitoringDetails,
    MonitoringLogsDetails
} from "../../../components/detailedApplication/MonitoringApplication";

interface ConnectFormProps {
    children: (methods: UseFormReturn) => ReactNode;
}

const MonitoringAgreementDetailPage: FC = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [monitoringGroupFieldsInit, setMonitoringGroupFieldsInit] = useState<any[] | null>(null);
    const [fields, setFields] = useState<{ updatedFields: any[]; dependencies: any | null } | null>(null);
    const { data, load: loading, error: detailsError } = useFetchData<IMonitoringFields & Partial<IMonitoringAgreementModel> | null>(generateUrl(config.client, 'getAgreementById', { id: id }, null));
    const [bool, setBool] = useState<boolean>(false);
    let {
        options: GeneralInfo,
        setOptions: setGeneralInfoOptions,
        generatedValues: GeneralInfoValues,
        setGeneratedValues: setGeneralInfoValues,
        data: GeneralInfoOptions,
        load: GeneralInfoLoad,
        error: GeneralInfoError,
    } = useGeneralInfoContext();

    useEffect(() => {
        if (data && moitoringDetailsFields && GeneralInfoValues) {
            setBool(true);
            let updated = generateDetailsFields<any, keyof IMonitoringFields & Partial<IMonitoringAgreementModel>>(
                moitoringDetailsFields as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[],
                data as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                GeneralInfoValues,
                dependencies,
                fieldValues,
                valueDependencies
            );
            setFields(updated);
            setBool(false);
        }
    }, [data, moitoringDetailsFields, GeneralInfoValues]);
    return (
        <>
            {bool || GeneralInfoLoad || loading ? <Loader />
                :
                <Card sx={{ width: "100%", margin: "15px auto 10px auto" }}>
                    <CardContent>
                        <h4 style={{ textAlign: 'center' }}>სააგენტოში დადასტურების ეტაპზე არსებული დეტალური ინფორმაცია</h4>
                        {loading && <><Loader /></>}
                        {
                            detailsError && <Alert severity="error">დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს</Alert>
                        }
                        {fields && data && <MonitoringDetails details={fields.updatedFields} dependencies={fields.dependencies} data={data} />}
                    </CardContent>
                </Card>}
        </>
    )
}

export default MonitoringAgreementDetailPage;
