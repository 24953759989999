import React, { useCallback, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import axios, { AxiosResponse, AxiosError } from "axios";

interface FileUploadDropboxProps {
  uploadUrl: string;
  onUploadSuccess?: (data: any) => void;
  onUploadError?: (error: any) => void;
  fieldName?: string; // The key for the file in FormData
  accept?: Accept;
  multiple?: boolean;
  promptMessage?: string;
  onFilesSelected?: (files: File[]) => void; // Callback for when files are selected
}

const FileUploadDropbox: React.FC<FileUploadDropboxProps> = ({
  uploadUrl,
  onUploadSuccess,
  onUploadError,
  fieldName = "file",
  accept,
  multiple = false,
  promptMessage = "გსურთ ფაილის ატვირთვა?",
  onFilesSelected,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onFilesSelected) {
        onFilesSelected(acceptedFiles);
      }

      if (window.confirm(promptMessage)) {
        setLoading(true);
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append(fieldName, file);
        });
        axios
          .post(uploadUrl, formData)
          .then((res: AxiosResponse<any>) => {
            setLoading(false);
            if (onUploadSuccess) {
              onUploadSuccess(res.data);
            }
          })
          .catch((err: AxiosError<any>) => {
            setLoading(false);
            if (onUploadError) {
              onUploadError(err);
            } else {
              alert("File upload failed");
            }
          });
      }
    },
    [uploadUrl, onUploadSuccess, onUploadError, fieldName, promptMessage, onFilesSelected]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <div {...getRootProps({ className: "dropbox" })}>
      <input {...getInputProps()} />
      {loading ? <p>იტვირთება...</p> : isDragActive ? <p>ჩააგდეთ ფაილები...</p> : <p>ჩააგდეთ ფაილები ან დააჭირეთ ფაილების ასარჩევად.</p>}
    </div>
  );
};

export default FileUploadDropbox;
