import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Button, Icon } from "@material-ui/core";
import dateFormat from "dateformat";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ClearIcon from "@material-ui/icons/Clear";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import axios from "axios";

const columns = [
  {
    field: "bank",
    headerName: "ბანკი",
    flex: 1,
    valueGetter: (params) => {
      return params.row.loan_information.bank.name;
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "createdAt",
    headerName: "ატვირთვის თარიღი",
    type: "date",
    flex: 1,
    valueGetter: (params) => {
      return dateFormat(params.row.createdAt, "yyyy-mm-dd");
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastTrancheDate",
    headerName: "ბოლო ტრანშის თარიღი",
    valueGetter: (params) => {
      let trancheRows = params.row.rows.filter((row) => row.bank_data.tranche_amount > 0);
      if (trancheRows.length === 0) return "ტრანში ვერ მოიძებნა";
      let lastTrancheRow = trancheRows[trancheRows.length - 1];
      return dateFormat(lastTrancheRow.bank_data.payment_date, "yyyy-mm-dd", true);
    },
    type: "date",
    flex: 2,
    headerAlign: "center",
    align: "center",
  },
  //Create column for requested dates (row.loan_information.purpose_dates)
  {
    field: "requested_dates",
    headerName: "მოთხოვნილი თარიღები",
    description: "მოთხოვნილი თარიღები.",
    sortable: false,
    flex: 3,
    renderCell: (params) => {
      let purposeDates = params.row.loan_information.purpose_dates;
      let purposeDatesString = "";
      if (purposeDates) {
        purposeDates.forEach((date) => {
          purposeDatesString += dateFormat(date, "yyyy-mm-dd", true) + ", ";
        });
        purposeDatesString = purposeDatesString.slice(0, -2);
      }
      return purposeDatesString;
    },
  },
  {
    field: "file_path",
    headerName: "გრაფიკის ბმული",
    description: "ბმული.",
    sortable: false,
    flex: 2,
    renderCell: (params) => (
      <a
        variant="contained"
        color="primary"
        size="small"
        style={{
          marginLeft: 16,
          display: "flex",
          alignItems: "center",
          maxWidth: 250,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        href="/"
        onClick={(event) => onCellClick(event, params.row._id)}
      >
        <ListAltIcon />
        {params.row.file_path.split("/")[1]}
      </a>
    ),
    headerAlign: "center",
    align: "center",
  },
  JSON.parse(localStorage.getItem("permissions"))?.includes("MODIFY_SCHEDULING") &&
    localStorage.getItem("role") === "ROLE_AGENCY" && {
      field: "delete_button",
      headerName: (
        <Button onClick={(event) => deleteAllSchedules(event)} startIcon={<ClearAllIcon />} color="secondary" variant="outlined" size="small">
          ყველა გრაფიკის წაშლა
        </Button>
      ),
      description: "ყველა გრაფიკის წაშლა.",
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 16, display: "flex", alignItems: "center" }}
          href="/"
          onClick={(event) => deleteSchedule(event, params.row._id)}
        >
          <ClearIcon />
        </IconButton>
      ),
      headerAlign: "center",
      align: "center",
    },
];

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  columns.unshift({ field: "_id", headerName: "ID", flex: 1, headerAlign: "center", align: "center" });
}

function deleteSchedule(event, schedule_id) {
  event.preventDefault();
  if (!window.confirm("გსურთ გრაფიკის წაშლა?")) return;

  let component = window.location.href.split("/")[3];
  axios
    .delete(`/agency/delete_schedule/${component}/${schedule_id}`)
    .then((res) => {
      alert(res.data.message);
      window.location.reload();
    })
    .catch((err) => {
      alert(err.response.data.message || "შეცდომა");
    });
}

function deleteAllSchedules(event) {
  event.preventDefault();
  if (!window.confirm("გსურთ ყველა გრაფიკის წაშლა?")) return;

  let component = window.location.href.split("/")[3];
  let loanID = window.location.href.split("/")[4];
  // same endpoint, however, /agency/delete_schedule/:component/:schedule_id/:application_id
  axios
    .delete(`/agency/delete_schedule/${component}/all/${loanID}`)
    .then((res) => {
      alert(res.data.message);
      window.location.reload();
    })
    .catch((err) => {
      alert(err.response.data.message || "შეცდომა");
    });
}

function onCellClick(e, schedule_id) {
  let application_type = window.location.href.split("/")[3];
  e.preventDefault();
  axios
    .post("/view_schedule", { schedule_id, application_type })
    .then((res) => {
      window.location.assign(`${process.env.REACT_APP_BASE_URL}public/schedules/${res.data.scheduleFilePath}`);
    })
    .catch((err) => {
      if (err.response) return alert(err.response.data.message);
      alert("შეცდომა");
    });
}

export default function ScheduleTable({ rows, canModifyScheduling }) {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={rows}
        canModifyScheduling={canModifyScheduling}
        columns={columns}
        pageSize={100}
        pagination
        rowsPerPageOptions={[5]}
        disableColumnMenu
      />
    </div>
  );
}
