import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Card, CardContent, Typography, Box } from "@material-ui/core";



export default function SameApplicantTable({ rows, classes, component }) {
  const columns = [
    { field: "loan_id", headerName: "სესხის ID", flex: 1, headerAlign: "center", align: "center", valueGetter: (params) => params.row.loan_information.loan_id },
    {
      field: "_id",
      headerName: "განაცხადი",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row._id,
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            startIcon={<ListAltIcon />}
            href={`${component === "universal" && !params.row.industrial ? "/universal-application" : params.row.industrial ? "/industrial-application" : "/mortgage-application"}/${params.value}`}
          >
            განაცხადი
          </Button>
        );
      },
    },
    {
      field: component === "universal" ? "loan_information.conditions.approved_amount" : "loan_information.amount",
      headerName: "თანხა",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => component === "universal" ? params.row.loan_information.conditions.approved_amount : params.row.loan_information.amount,
    },
    {
      field: "approved",
      headerName: "სტატუსი",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params.row.approved === true) return "დადასტურებული";
        if (params.row.approved === false) return "უარყოფილი";
        return "განსახილველი";
      },
    },
    {
      field: "suspended",
      headerName: "გაუქმებული",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => (params.row.suspended ? "კი" : "არა"),      
    },
  ];

  return (
    <Card className={classes.component} variant="outlined">
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <Typography className={classes.cardSummary} style={{ marginBottom: "16px" }}>
          აპლიკანტისთვის დარეგისტრირებული სხვა განაცხადები
        </Typography>
        <DataGrid
          row
          autoHeight
          getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          disableColumnMenu
          components={{
            Footer: () => (
              <Box
                sx={{
                  float: "right",
                  padding: "1em",
                }}
              >
                <Typography>სულ თანხა: {rows.reduce((acc, curr) => acc + (component === "universal" ? curr.loan_information.conditions.approved_amount : curr.loan_information.amount), 0)}</Typography>
              </Box>
            ),
          }}
        />
      </CardContent>
    </Card>
  );
}
