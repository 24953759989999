export default function GetAgencyPosition(positionId) {
  switch (positionId) {
    case -2:
      return "სოციალური რისკების სპეციალისტი";
    case -1:
      return "გარემოსდაცვითი სპეციალისტი";
    case 1:
      return "დეპ. თანამშრომელი";
    case 2:
      return "დეპ. უფროსი";
    case 3:
      return "სააგენტოს დირექტორი";
    default:
      return "";
  }
}
