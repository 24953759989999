import React, { useState, useEffect } from "react";
import { Button, Typography, Modal, Box, TextField, FormControl, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import ListAltIcon from "@material-ui/icons/ListAlt";
import dateFormat from "dateformat";
import FileUploadDropbox from "../dialogs/FileUploadDropbox";

function AddChildModal({ open, handleOpen, handleClose, currentChildrenAmount, applicationID }) {
  const [initialChildrenAmount, setInitialChildrenAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [childrenData, setChildrenData] = useState({
    amount: 0,
    children: [],
    attachments: [],
  });

  useEffect(() => {
    setChildrenData({ ...childrenData, amount: currentChildrenAmount });
    setInitialChildrenAmount(currentChildrenAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChildrenAmount]);

  // Handle successful upload
  const handleUploadSuccess = (data) => {
    setChildrenData((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments, ...data.children_birth_certificate],
    }));
  };

  // Handle upload error
  const handleUploadError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      alert(err.response.data.message);
    } else {
      alert("File upload failed");
    }
  };

  function handleSubmit() {
    axios
      .post(`/bank/mortgage/${applicationID}/add_children`, childrenData)
      .then((res) => {
        alert(res.data.message);
        handleClose();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  }

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          {childrenData.amount ? (
            <>
              <Typography
                variant="h6"
                id="modal-modal-title"
                component="h2"
                style={{
                  borderBottom: "1px solid #E0E0E0",
                  marginBottom: "20px",
                }}
              >
                შვილების დამატება
              </Typography>

              <Typography
                variant="body2"
                id="modal-modal-description"
                style={{
                  marginBottom: "20px",
                  fontStyle: "italic",
                }}
              >
                *მოთხოვნის გაგზავნისას, სეხსი სააგენტოს მხრიდან განსახილველი გახდება.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" id="modal-modal-description">
                  შვილების რაოდენობა: {childrenData.amount}
                </Typography>
                <Box>
                  {childrenData.amount > initialChildrenAmount && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        childrenData.amount > initialChildrenAmount
                          ? setChildrenData((prevState) => ({
                              ...prevState,
                              amount: prevState.amount - 1,
                              children: prevState.children.slice(0, -1),
                            }))
                          : null
                      }
                    >
                      -
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      setChildrenData((prevState) => ({
                        ...prevState,
                        amount: prevState.amount + 1,
                        children: [
                          ...prevState.children,
                          {
                            name: "",
                            private_number: "",
                            birth_date: "",
                            is_adopted: false,
                          },
                        ],
                      }))
                    }
                  >
                    +
                  </Button>
                </Box>
              </Box>

              {Array(childrenData.amount - initialChildrenAmount)
                .fill()
                .map((_, i) => (
                  <FormControl
                    key={i}
                    style={{
                      borderTop: "1px solid #E0E0E0",
                      marginTop: "20px",
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <TextField
                      required
                      label="სრული სახელი"
                      variant="outlined"
                      value={childrenData.children[i].name}
                      onChange={(e) => {
                        const newChildrenData = { ...childrenData };
                        newChildrenData.children[i].name = e.target.value;
                        setChildrenData(newChildrenData);
                      }}
                    />
                    <TextField
                      required
                      label="პირადი ნომერი"
                      variant="outlined"
                      value={childrenData.children[i].private_number}
                      onChange={(e) => {
                        const newChildrenData = { ...childrenData };
                        newChildrenData.children[i].private_number = e.target.value;
                        setChildrenData(newChildrenData);
                      }}
                    />
                    <TextField
                      required
                      InputLabelProps={{ shrink: true }}
                      label="დაბ. თარიღი"
                      type="date"
                      variant="outlined"
                      value={childrenData.children[i].birth_date}
                      onChange={(e) => {
                        const newChildrenData = { ...childrenData };
                        newChildrenData.children[i].birth_date = e.target.value;
                        setChildrenData(newChildrenData);
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={childrenData.children[i].is_adopted}
                          onChange={(e) => {
                            const newChildrenData = { ...childrenData };
                            newChildrenData.children[i].is_adopted = e.target.checked;
                            setChildrenData(newChildrenData);
                          }}
                        />
                      }
                      label="შვილად აყვანილი"
                    />
                  </FormControl>
                ))}

              <Box
                sx={{
                  marginTop: "40px",
                }}
              >
                <Typography
                  variant="body2"
                  id="modal-modal-description"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  დოკუმენტაცია:
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  {childrenData.attachments.map((attachment, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ListAltIcon />
                      <Typography variant="body2" id="modal-modal-description">
                        {attachment.toString().split("\\").pop()}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <FileUploadDropbox
                  uploadUrl="/bank/application/upload_attachments"
                  onUploadSuccess={handleUploadSuccess}
                  onUploadError={handleUploadError}
                  fieldName="children_birth_certificate"
                  promptMessage="გსურთ აიტვირთოთ ფაილი?"
                />
              </Box>
            </>
          ) : (
            <p>Loading...</p>
          )}
          <Button
            style={{
              marginTop: "40px",
            }}
            disabled={childrenData.amount - initialChildrenAmount === 0 || loading}
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            color="primary"
          >
            მოთხოვნის გაგზავნა
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

function MortgageChildren({ applicationInformation, classes }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (!applicationInformation) return null;
  return (
    <>
      <AddChildModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentChildrenAmount={applicationInformation?.children_information?.length}
        applicationID={applicationInformation?._id}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography className={classes.cardSummary}>შვილების შესახებ ინფორმაცია</Typography>
        <IconButton
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <table className={classes.table}>
        <tbody>
          {applicationInformation?.children_information?.map((child) => (
            <>
              <tr className={classes.row}>
                <td className={classes.title}>სახელი:</td>
                <td className={classes.detail}>{child.name}</td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>პირადი ნომერი:</td>
                <td className={classes.detail}>{child.private_number}</td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>დაბადების თარიღი:</td>
                <td className={classes.detail}>{dateFormat(child.birth_date, "yyyy-mm-dd", "T")}</td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>ასაკი:</td>
                <td className={classes.detail}>{child.age}</td>
              </tr>

              {child.is_adopted ? (
                <tr className={classes.row}>
                  <td className={classes.title}>აყვანილი შვილი:</td>
                  <td className={classes.detail}>მონიშნულია</td>
                </tr>
              ) : null}
              <br />
            </>
          ))}
          {applicationInformation.children_history?.length > 0
            ? applicationInformation.children_history.map((history) => (
                <>
                  <tr className={classes.row}>
                    <td className={classes.title}>შვილები:</td>
                    <td className={classes.detail}>{history.children}</td>
                  </tr>
                  <tr className={classes.row}>
                    <td className={classes.title}>თარიღი:</td>
                    <td className={classes.detail}>{dateFormat(history.date, "yyyy-mm-dd", "T")}</td>
                  </tr>
                </>
              ))
            : null}
        </tbody>
      </table>
    </>
  );
}

export default MortgageChildren;
