import { Modal as MuiModal, Card, CardContent, CardHeader, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { PostData } from "../../utilities/PostData";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function Modal({ modalOpen, setModalOpen }) {
  const classes = useStyles();
  const [captcha, setCaptcha] = useState("");
  const captchaRef = useRef(null);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.captcha = captcha;
      resetForm();
      PostData("auth/forgot_password", values)
        .then((res) => {
          alert(res.message);
        })
        .catch((err) => {
          alert("დაფიქსირდა შეცდომა");
        });
    },
  });
  return (
    <MuiModal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <Card className={classes.card}>
        <CardHeader
          subheader={
            <div
              style={{
                color: "#000",
                fontSize: "1.1rem",
                textAlign: "center",
                borderBottom: "1px solid #ccc",
              }}
            >
              პაროლის აღსადგენად გთხოვთ შეიყვანოთ ელ-ფოსტა.
            </div>
          }
        />

        <CardContent className={classes.CardContent}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await captchaRef.current.execute({ async: true });
              formik.handleSubmit();
            }}
            className={classes.form}
          >
            <TextField
              fullWidth
              id="email"
              name="email"
              variant="outlined"
              //Give margin below the text field
              style={{ marginBottom: "2rem" }}
              label="ელ-ფოსტა"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <HCaptcha
              sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
              size="invisible"
              onVerify={(token) => {
                setCaptcha(token);
              }}
              ref={captchaRef}
            />
            <Button color="primary" variant="contained" fullWidth type="submit">
              აღდგენა
            </Button>
          </form>
        </CardContent>
      </Card>
    </MuiModal>
  );
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    margin: "8% 0 0 0",
    justifyContent: "center",
  },
  card: {
    width: "30%",
    height: "40vh",
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "2%",
  },
  form: {
    width: "90%",
  },
});

const validationSchema = yup.object({
  email: yup.string("შეიყვანეთ ფოსტა").email("შეიყვანეთ ვალიდური ფოსტა").required("ფოსტის შეყვანა აუცილებელია"),
});

export default Modal;
