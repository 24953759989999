import React, { useState } from 'react';
import { 
    styled, 
    Tooltip, 
    TooltipProps, 
    Paper } from '@mui/material';

interface CustomTooltipProps extends TooltipProps {
    backgroundColor?: string;
    textColor?: string;
    fontSize?: string;
    placement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined;
}

const CustomTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#f9f9f9',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.50)',
        fontSize: '10px',
        padding: '10px',
        borderRadius: '2px',
    },
    '& .MuiTooltip-arrow': {
        position: 'absolute',
        zIndex: 1,
        width: '20px',
        height: '15px', 
        bottom: '50',
        color: '#f9f9f9',
        backgroundColor: 'transparent', 
    },
    '&[data-placement*="top"] .MuiTooltip-arrow': {
        bottom: '0px',
        left: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
        borderBottom: 'none',
        borderRight: 'none',
        borderWidth: '50px'
    },
    '&[data-placement*="bottom"] .MuiTooltip-arrow': {
        top: '-10px',
        left: '50%',
        transform: 'translateX(-50%) rotate(-45deg)',
        borderTop: 'none',
        borderLeft: 'none',
        borderWidth: '50px'
    },
}));


export default CustomTooltip;
