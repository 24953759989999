import { PostData } from "../../utilities/PostData";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { TextField, Button, Card, CardContent, CardActions } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const ResetPassword = (props) => {
  let history = useHistory();

  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      PostData("auth/reset_password", { ...values, resetToken: props.match.params.token })
        .then((res) => {
          alert(res.message);
          history.push("/");
          window.location.reload();
        })
        .catch((err) => {
          alert("დაფიქსირდა შეცდომა");
        });
    },
  });

  return (
    <>
      <div className="login_container">
        <img src="/logo.png" alt="logo" />
        <Card className="login_card">
          <CardContent style={{ width: "90%" }}>
            <form onSubmit={formik.handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
              <TextField style={{ marginTop: 5 }} fullWidth id="password" name="password" label="ახალი პაროლი" type="password" value={formik?.values?.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password} />
              <Button style={{ marginTop: 25 }} color="primary" variant="contained" fullWidth type="submit" startIcon={<ExitToAppIcon />}>
                პაროლის ცვლილება
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

const validationSchema = yup.object({
  password: yup.string("შეიყვანეთ პაროლი").required("პაროლის შეყვანა აუცილებელია").min(5, "პაროლი უნდა შედგებოდეს მინიმუმ 5 სიმბოლოსაგან."),
});

export default ResetPassword;
