function RowStyle(approval_history, status, isBank) {
  if (isBank) return null;
  let user_ids = [];
  approval_history.forEach((element) => {
    if (!user_ids.includes(element.user_id)) {
      user_ids.push(element.user_id);
    }
  });
  if (status === "დადასტურებული") return { fontWeight: "bold" };
  if (approval_history.some((element) => element.agency_level === 2)) return { backgroundColor: "#FFA500" };
  approval_history = user_ids;
  if (approval_history.length === 1) {
    return {
      backgroundColor: "#FCDF92",
    };
  }
  if (approval_history.length === 2) {
    return {
      backgroundColor: "#7EB77F",
    };
  }
  if (approval_history.length > 0) {
    return {
      // fontWeight: "bold",
    };
  }
}

export default RowStyle;
