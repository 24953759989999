import axios from "axios";

const SetDetailedApplicationData = ({
  setIsBank,
  setIsBankHead,
  setCanModifyScheduling,
  setAgencyLevel,
  setApplicationInformation,
  id,
  setLoading,
  applicationType,
}) => {
  if (localStorage.getItem("role") === "ROLE_BANK") {
    setIsBank(true);
  }
  setAgencyLevel(parseInt(localStorage.getItem("agency-level")));
  if (JSON.parse(localStorage.getItem("permissions")).includes("ADD_USER") && localStorage.getItem("role") === "ROLE_BANK") {
    setIsBankHead(true);
  }
  if (JSON.parse(localStorage.getItem("permissions")).includes("MODIFY_SCHEDULING") && localStorage.getItem("role") === "ROLE_AGENCY") {
    setCanModifyScheduling(true);
  }

  axios
    .get(`/application/${applicationType}/${id}`)
    .then((response) => {
      setApplicationInformation(response.data);
      setLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        alert(error.response.data.message);
      } else {
        console.log(error);
        alert("შეცდომა");
      }
    });
};

export default SetDetailedApplicationData;
