import "./Industrial.css";
import { useState, useEffect } from "react";
import { GetData } from "../../../utilities/GetData";
import { PostData } from "../../../utilities/PostData";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, Checkbox, FormControlLabel, TextField, IconButton, Button, Card, CardContent, CardActions } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import WorldBank from "../../../components/application/WorldBank";

function Industrial() {
  const [agencyPercentage, setAgencyPercentage] = useState(0.03);
  const [userBank, setUserBank] = useState();
  const [generalInfo, setGeneralInfo] = useState();
  const [foundersCounter, setFoundersCounter] = useState(1);
  const [economicActivitiesCounter, setEconomicActivitiesCounter] = useState(1);
  const [signersCounter, setSignersCounter] = useState(1);
  const [applicantMunicipalities, setApplicantMunicipalities] = useState([]);
  const [projectMunicipalities, setProjectMunicipalities] = useState([]);
  const [localCheckboxes, setLocalCheckboxes] = useState({ applicantRegionSelected: false, projectRegionSelected: false, poa_person: [] });
  const [uploadedAttachments, setUploadedAttachments] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedPurposeType, setSelectedPurposeType] = useState();
  const [selectedEnterpriseCategory, setSelectedEnterpriseCategory] = useState();
  const [enterpriseCategories, setEnterpriseCategories] = useState(["I კატეგორია", "II კატეგორია", "III კატეგორიის საწარმო", "IV კატეგორიის საწარმო"]);
  const [attachmentButtons, setAttachmentButtons] = useState([]);
  const [founderLegalForms, setFounderLegalForms] = useState([]);
  const [mixedPurposeTypeUnderLimit, setMixedPurposeTypeUnderLimit] = useState(false);
  const [balneologicalMunicipality, setBalneologicalMunicipality] = useState();
  const [attachmentFields, setAttachmentFields] = useState([
    { title: "დანართი", field: "poa_proof" },
    { title: "სასტუმროს შემთხვევაში ლიფტის არ-არსებობის მიზეზის დამადასტურებელი შესაბამისი დოკუმენტი", field: "elevator_absence_reason" },
    { title: "სასტუმროს/ბალნეოლოგიური კურორტის მშენებლობის ნებართვა", field: "building_permit" },
  ]);

  const [mixedLoanInfo, setMixedLoanInfo] = useState();
  const [selectorData, setSelectorData] = useState();
  const [programType, setProgramType] = useState({});
  const [applicationType, setApplicationType] = useState({});
  const [projectTypes, setProjectTypes] = useState(["ახალი", "გაფართოება/გადაიარაღება"]);
  const [usageTypes, setUsageTypes] = useState(["საკუთრება", "დროებით სარგებლობა"]);
  const [selectedEconomicActivity, setSelectedEconomicActivity] = useState();
  const [balneologicalVillages, setBalneologicalVillages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [NACEActivities, setNACEActivities] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const classes = useStyles();
  useEffect(() => {
    GetData("general_info").then((res) => {
      setGeneralInfo(res);
    });
    setUserBank(JSON.parse(localStorage.getItem("bank")));
    if (JSON.parse(localStorage.getItem("bank")).leasing) {
      setAgencyPercentage(0.05);
    }
  }, []);
  useEffect(() => {
    PostData("bank/fetch_nace_activities", { program_type: programType._id }).then(async (res) => {
      setNACEActivities(res.activities);
    });
  }, [programType]);

  function balneologicalMunicipalityChangeHandler(municipality) {
    PostData("fetch_balneological_areas", { municipality: municipality }).then(async (res) => {
      setBalneologicalVillages(res);
    });
  }

  function regionChangeHandler(region, type) {
    if (region === "") {
      setLocalCheckboxes({ ...localCheckboxes, [type]: false });
      return;
    }
    PostData("fetch_areas", { region: region }).then(async (res) => {
      if (type === "applicantRegionSelected") {
        setApplicantMunicipalities(res);
      } else {
        setProjectMunicipalities(res);
      }
    });

    setLocalCheckboxes({ ...localCheckboxes, [type]: true });
  }

  function founderAddHandler() {
    setFoundersCounter(foundersCounter + 1);
  }

  function activityAddHandler() {
    setEconomicActivitiesCounter(economicActivitiesCounter + 1);
  }

  function signerAddHandler() {
    setSignersCounter(signersCounter + 1);
  }

  function founderRemoveHandler() {
    setFoundersCounter(foundersCounter - 1);
  }

  function activityRemoveHandler() {
    setEconomicActivitiesCounter(economicActivitiesCounter - 1);
  }

  function signerRemoveHandler() {
    setSignersCounter(signersCounter - 1);
  }

  function poaCheckboxHandler(event, index) {
    let poaPersons = [...localCheckboxes.poa_person];
    poaPersons[index] = event.target.checked;
    setLocalCheckboxes({ ...localCheckboxes, poa_person: poaPersons });
  }

  function checkTotalInvestment() {
    if (formik.values.project_information.total_investment < formik.values.loan_information.conditions.approved_amount) {
      setErrorMessages((prevState) => {
        return { ...prevState, total_investment: "ჯამური ინვესტიციის მოცულობა ვერ იქნება დამტკიცებული სესხის მოცულობაზე ნაკლები." };
      });
    } else {
      setErrorMessages((prevState) => {
        return { ...prevState, total_investment: undefined };
      });
    }
  }

  function checkApprovedAmount() {
    let minAmount = 50000;
    let maxAmount = 10000000;

    setErrorMessages((prevState) => {
      return { ...prevState, approved_amount: "დამტკიცებული თანხა უნდა იყოს 50,000-დან 10მლნ ლარამდე." };
    });
    if (programType?.name === "რესტორნები") {
      minAmount = 10000;
      maxAmount = 50000;
      setErrorMessages((prevState) => {
        return { ...prevState, approved_amount: "დამტკიცებული თანხა უნდა იყოს 10,000-დან 50,000 ლარამდე." };
      });
    }
    let amountCheck =
      formik.values.loan_information.conditions.approved_amount < minAmount || formik.values.loan_information.conditions.approved_amount > maxAmount;
    if (!amountCheck) {
      setErrorMessages((prevState) => {
        return { ...prevState, approved_amount: undefined };
      });
    }
    return amountCheck;
  }

  function isInvalidForm() {
    return checkApprovedAmount() && checkTotalInvestment();
  }

  function fileChangeHandler(event, index) {
    let newAttachmentFields = [...attachmentFields];
    let newAttachmentField = { ...newAttachmentFields[index] };
    newAttachmentField.file = event.target.files[0];
    newAttachmentFields[index] = newAttachmentField;
    setAttachmentFields(newAttachmentFields);
  }
  function fileSubmitHandler(fieldName, index) {
    if (!attachmentFields[index].file) {
      return;
    }
    if (parseFloat((Math.round(+attachmentFields[index].file.size / 1024) / 1000).toFixed(2)) > 20) {
      alert("ფაილის ზომა აღემატება 20MB-ს.");
      return;
    }
    setIsUploading(true);
    const fileData = new FormData();
    fileData.append(fieldName, attachmentFields[index].file, attachmentFields[index].file.name);
    const newAttachments = uploadedAttachments;
    PostData("bank/application/upload_attachments", fileData, true)
      .then((res) => {
        if (newAttachments[fieldName]) {
          newAttachments[fieldName] = [...newAttachments[fieldName], res[fieldName][0]];
        } else {
          newAttachments[fieldName] = res[fieldName];
        }
        setUploadedAttachments(newAttachments);
        setIsUploading(false);
      })
      .catch((err) => {
        alert("ფაილის ატვირთვისას დაფიქსირდა შეცდომა");
        setIsUploading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      world_bank: {
        risk_category: "moderate",
        eia_status: "eia_abscence",
      },
      applicant_information: { legal_address: {} },
      founders: [],
      loan_information: { conditions: { purpose: {} } },
      project_information: {},
    },
    onSubmit: async (values) => {
      if (values.founders[0]?.legal_form === "") {
        values.founders[0].legal_form = undefined;
      }
      if (isInvalidForm()) {
        alert("გთხოვთ ჩაასწოროთ გამოყოფილი ველები.");
      } else {
        PostData("bank/application/submit_industrial", values).then((res) => {
          alert(res.message);
        });
      }
    },
  });

  useEffect(() => {
    if (selectedPurposeType?.name?.includes("შერეული")) {
      formik.setFieldValue(
        "loan_information.conditions.purpose.general.percentage",
        ((formik.values.loan_information?.conditions?.purpose?.general?.amount / formik.values.loan_information?.conditions?.approved_amount) * 100).toFixed(2)
      );
      formik.setFieldValue(
        "loan_information.conditions.purpose.mixed.amount",
        formik.values.loan_information?.conditions?.approved_amount - formik.values.loan_information?.conditions?.purpose?.general?.amount
      );
      formik.setFieldValue(
        "loan_information.conditions.purpose.mixed.percentage",
        (
          ((formik.values.loan_information.conditions?.approved_amount - formik.values.loan_information?.conditions?.purpose?.general?.amount) /
            formik.values.loan_information.conditions?.approved_amount) *
          100
        ).toFixed(2)
      );
    } else {
      formik.setFieldValue("loan_information.conditions.purpose.general.percentage", undefined);
      formik.setFieldValue("loan_information.conditions.purpose.mixed.amount", undefined);
      formik.setFieldValue("loan_information.conditions.purpose.mixed.percentage", undefined);
    }
  }, [
    formik?.values.loan_information?.conditions?.purpose?.general?.amount,
    formik?.values.loan_information?.conditions?.approved_amount,
    selectedPurposeType,
  ]);

  return (
    <div className="industrial_container">
      <form onSubmit={formik.handleSubmit} className="industrial_form">
        <b>განაცხადის ტიპი</b>
        <FormControl variant="outlined" className={classes.formControl}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel required className={classes.inputLabel} htmlFor="program_type">
              პროგრამის კომპონენტი
            </InputLabel>
            <Select
              required
              className={classes.field}
              native
              onChange={(event) => {
                formik.handleChange(event);
                setProgramType(generalInfo?.program_types.find((type) => type._id === event.target.value));
              }}
              inputProps={{
                name: "program_type",
              }}
            >
              <option aria-label="None" value="" />
              {generalInfo?.program_types.map((programType) => (
                <option key={programType._id} value={programType._id} program={programType.name}>
                  {programType.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl required variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.inputLabel} htmlFor="application_type">
              განაცხადის ტიპი
            </InputLabel>
            <Select
              className={classes.field}
              native
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.value === "") {
                  setApplicationType({});
                  return;
                }
                setApplicationType(generalInfo?.universal_application_types.find((type) => type._id === e.target.value));
              }}
              inputProps={{
                name: "application_type",
              }}
            >
              <option aria-label="None" value="" />
              {generalInfo?.universal_application_types.map((universal_application_type) => (
                <option
                  key={universal_application_type._id}
                  value={universal_application_type._id}
                  disabled={["რეფინანსირება", "სესხი", "ლიზინგი", "სალიზინგო პროექტი"].indexOf(universal_application_type.name) === -1}
                >
                  {universal_application_type.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </FormControl>
        {/* <WorldBank formik={formik} classes={classes} /> */}
        {programType?.name === "ბალნეოლოგიური კურორტები" || programType?.name === "სასტუმრო" ? (
          <>
            <b>საერთაშორისო ბრენდი</b>
            <FormControlLabel
              variant="outlined"
              Label
              control={
                <Checkbox
                  checked={checkboxes.international_brand}
                  onChange={(event) => {
                    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                  }}
                  name="international_brand"
                />
              }
              label="საერთაშორისო ბრენდი"
            />
            {checkboxes.international_brand ? (
              <FormControl variant="outlined" className={classes.FormControl} style={{ flexDirection: "row" }}>
                <TextField
                  variant="outlined"
                  className={classes.field}
                  name="international_brand.agreement_number"
                  label="სააგენტოსთან გაფორმებული ხელშეკრულების ნომერი"
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
                <TextField
                  variant="outlined"
                  className={classes.field}
                  name="international_brand.agreement_date"
                  label="სააგენტოსთან გაფორმებული ხელშეკრულების თარიღი"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                />
              </FormControl>
            ) : null}
          </>
        ) : null}

        <b>{userBank?.leasing ? "ინფორმაცია განმცხადებლის შესახებ" : "ინფორმაცია მსესხებლის შესახებ"}</b>
        <FormControl variant="outlined" className={classes.formControl}>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel className={classes.inputLabel} htmlFor="legal_form">
              სამართლებრივი ფორმა
            </InputLabel>
            <Select
              className={classes.field}
              native
              onChange={formik.handleChange}
              inputProps={{
                name: "applicant_information.legal_form",
              }}
            >
              <option aria-label="None" value="" />
              {generalInfo?.legal_forms
                .filter((legal_form) => {
                  return legal_form.name !== "ი/მ" && legal_form.name !== "ფიზიკური პირი";
                })
                .map((legal_form) => (
                  <option key={legal_form._id} value={legal_form._id}>
                    {legal_form.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            className={classes.field}
            name="applicant_information.name"
            required
            label="დასახელება"
            onChange={formik.handleChange}
          />
          <TextField
            variant="outlined"
            className={classes.field}
            name="applicant_information.identification_code"
            required
            label="საიდენტიფიკაციო კოდი"
            onChange={formik.handleChange}
          />

          <TextField
            variant="outlined"
            className={classes.field}
            required
            name="applicant_information.legal_address"
            label="იურიდიული მისამართი"
            onChange={formik.handleChange}
          />
          <TextField
            variant="outlined"
            className={classes.field}
            name="applicant_information.foundation_year"
            type="number"
            label="რეგისტრაციის თარიღი (წელი)"
            onChange={formik.handleChange}
          />
          <TextField
            variant="outlined"
            className={classes.field}
            name="applicant_information.email"
            label="საჯარო ელ.ფოსტა (ამონაწერის მიხედვით)"
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            onChange={formik.handleChange}
          />
          {applicationType.name !== "რეფინანსირება" ? (
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.longerInputLabel} htmlFor="enterprise_category">
                  საწარმოს კატეგორია (შემოსავლების კომპონენტის მიხედვით)
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={(event) => {
                    setSelectedEnterpriseCategory(event.target.value);
                    formik.handleChange(event);
                  }}
                  inputProps={{
                    name: "applicant_information.enterprise_category",
                  }}
                >
                  <option aria-label="None" value="" />
                  {enterpriseCategories.map((enterpriseCategory) => (
                    <option key={enterpriseCategory} value={enterpriseCategory}>
                      {enterpriseCategory}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedEnterpriseCategory === "I კატეგორია" || selectedEnterpriseCategory === "II კატეგორია" ? (
                <TextField
                  variant="outlined"
                  className={classes.field}
                  name="applicant_information.enterprise_category_comment"
                  label="კატეგორიის კომენტარი"
                  onChange={formik.handleChange}
                />
              ) : null}
              <TextField
                variant="outlined"
                type="number"
                className={classes.field}
                name="applicant_information.employee_amount"
                label="დასაქმებულთა არსებული რაოდენობა"
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                onChange={formik.handleChange}
              />

              <FormControl variant="outlined" className={classes.formControl}>
                <FormControlLabel
                  variant="outlined"
                  Label
                  control={
                    <Checkbox
                      required
                      checked={checkboxes.tax_liabilities}
                      onChange={(event) => {
                        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        formik.setFieldValue("applicant_information.tax_liabilities", event.target.checked);
                      }}
                      name="tax_liabilities"
                    />
                  }
                  label="სახელმწიფოს მიმართ არ აქვს საგადასახადო დავალიანება, ან გადავადებული აქვს, ან ვალდებულება შეჩერებულია"
                />
                <FormControlLabel
                  variant="outlined"
                  Label
                  control={
                    <Checkbox
                      required
                      checked={checkboxes.registered_debtor}
                      onChange={(event) => {
                        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        formik.setFieldValue("applicant_information.registered_debtor", event.target.checked);
                      }}
                      name="registered_debtor"
                    />
                  }
                  label="არ არის რეგისტრირებული მოვალეთა რეესტრში"
                />
              </FormControl>
            </>
          ) : null}
        </FormControl>
        {applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>ინფორმაცია დამფუძნებელი იურიდიული პირ(ებ)ის შესახებ</b>
            <FormControl variant="outlined" className={classes.formControl}>
              {Array.from(Array(foundersCounter)).map((e, index) => (
                <FormControl variant="outlined" className={classes.formControl}>
                  <>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel className={classes.inputLabel} htmlFor="legal_form">
                          სამართლებრივი ფორმა
                        </InputLabel>
                        <Select
                          className={classes.shortField}
                          native
                          onChange={(event) => {
                            formik.handleChange(event);
                            let newFoundersLegalForms = founderLegalForms;
                            newFoundersLegalForms[index] = generalInfo?.legal_forms.find((form) => form._id === event.target.value);
                            setFounderLegalForms(newFoundersLegalForms);
                          }}
                          inputProps={{
                            name: `founders[${index}].legal_form`,
                          }}
                        >
                          <option aria-label="None" value="" />
                          {generalInfo?.legal_forms
                            .filter((legal_form) => {
                              return legal_form.name !== "ი/მ" && legal_form.name !== "ფიზიკური პირი";
                            })
                            .map((legal_form) => (
                              <option key={legal_form._id} value={legal_form._id}>
                                {legal_form.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="outlined"
                        className={classes.shortField}
                        name={`founders[${index}].name`}
                        label="დასახელება"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.shortField}
                        name={`founders[${index}].identification_code`}
                        label="საიდენტიფიკაციო კოდი"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name={`founders[${index}].ownership_percentage`}
                        type="number"
                        inputProps={{ step: "any", min: "0", max: "100" }}
                        label="წილობრივი მონაწილეობა (%)"
                        onChange={formik.handleChange}
                      />

                      {index === foundersCounter - 1 && index !== 0 ? (
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue(`founders[${index}]`, undefined);
                            founderRemoveHandler();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                      {index === foundersCounter - 1 ? (
                        <>
                          <IconButton
                            onClick={() => {
                              founderAddHandler();
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ) : null}
                    </FormControl>
                  </>
                </FormControl>
              ))}
            </FormControl>
          </>
        ) : null}

        <b>წარმომადგენელი (ხელმომწერი) პირ(ებ)ი</b>
        <FormControl variant="outlined" className={classes.formControl}>
          {Array.from(Array(signersCounter)).map((e, index) => (
            <Card className={classes.card} style={{ border: "1px solid #0000003b", boxShadow: "none" }}>
              <CardContent>
                <FormControl variant="outlined" className={classes.formControl}>
                  <>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        required
                        name={`signers[${index}].first_name`}
                        label="სახელი"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        required
                        name={`signers[${index}].last_name`}
                        label="გვარი"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        required
                        name={`signers[${index}].position`}
                        label="პოზიცია"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        required
                        name={`signers[${index}].private_number`}
                        label="პ/ნ"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        required
                        name={`signers[${index}].phone_number`}
                        label="საკონტაქტო ნომერი (5xxxxxxxx)"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        required
                        variant="outlined"
                        className={classes.field}
                        InputLabelProps={{
                          className: classes.textFieldLabel,
                        }}
                        name={`signers[${index}].email`}
                        label="წარმომადგენლის ელ-ფოსტა"
                        onChange={formik.handleChange}
                      />
                    </FormControl>

                    <FormControlLabel
                      variant="outlined"
                      Label
                      control={
                        <Checkbox
                          checked={localCheckboxes.poa_person[index]}
                          onChange={(event) => {
                            poaCheckboxHandler(event, index);
                          }}
                          name="poa_person"
                        />
                      }
                      label="მინდობილი პირი"
                    />
                    <FormControl variant="outlined" className={classes.formControl}>
                      {localCheckboxes.poa_person[index] ? (
                        <>
                          <TextField
                            variant="outlined"
                            className={classes.field}
                            name={`signers[${index}].poa_first_name`}
                            label="სახელი"
                            onChange={formik.handleChange}
                            onBlur={() =>
                              formik.setFieldValue(
                                `signers[${index}].poa_name`,
                                formik.values.signers[index]?.poa_first_name + " " + formik.values.signers[index]?.poa_last_name
                              )
                            }
                          />
                          <TextField
                            variant="outlined"
                            className={classes.field}
                            name={`signers[${index}].poa_last_name`}
                            label="გვარი"
                            onChange={formik.handleChange}
                            onBlur={() =>
                              formik.setFieldValue(
                                `signers[${index}].poa_name`,
                                formik.values.signers[index]?.poa_first_name + " " + formik.values.signers[index]?.poa_last_name
                              )
                            }
                          />
                          <TextField
                            variant="outlined"
                            className={classes.field}
                            name={`signers[${index}].poa_identification_code`}
                            label="პირადი ნომერი"
                            onChange={formik.handleChange}
                          />
                          <TextField
                            variant="outlined"
                            className={classes.field}
                            name={`signers[${index}].poa_requisites`}
                            label="Მინდობილობის ნომერი"
                            onChange={formik.handleChange}
                          />
                        </>
                      ) : null}
                    </FormControl>
                    <CardActions className={classes.cardActions}>
                      {index === signersCounter - 1 && index !== 0 ? (
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue(`children_information[${index}]`, undefined);
                            signerRemoveHandler();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                      {index === signersCounter - 1 ? (
                        <>
                          <IconButton
                            onClick={() => {
                              signerAddHandler();
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ) : null}
                    </CardActions>
                  </>
                </FormControl>
              </CardContent>
            </Card>
          ))}
        </FormControl>
        {applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>გენდერული სტატისტიკა</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="gender_statistics.director_women"
                label="დირექტორი ქალ(ებ)ის რაოდენობა"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.longField}
                name="gender_statistics.founder_women"
                label="დამფუძნებელი ქალ(ებ)ის რაოდენობა"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.longField}
                name="gender_statistics.stakes_women"
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                inputProps={{ step: "any", min: "0", max: "100" }}
                label="დამფუძნებელი ქალ(ებ)ის ჯამური წილობრივი მონაწილეობა (%)"
                type="number"
                onChange={formik.handleChange}
              />
            </FormControl>
            <b>ინფორმაცია შშმ პირთა პირობების შესახებ</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                className={classes.longField}
                name="disabilities.disabled_employees"
                label="არსებული, დასაქმებული შშმ პირების რაოდენობა"
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="number"
                onChange={formik.handleChange}
              />
              <FormControlLabel
                variant="outlined"
                Label
                control={
                  <Checkbox
                    required={!checkboxes.is_planned_to_adapt}
                    disabled={checkboxes.is_planned_to_adapt}
                    checked={checkboxes.is_adapted}
                    onChange={(event) => {
                      setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                      formik.setFieldValue("disabilities.is_adapted", event.target.checked);
                    }}
                    name="is_adapted"
                  />
                }
                label="ადმინისტრაციული შენობა ადაპტირებულია"
              />
              <FormControlLabel
                variant="outlined"
                Label
                control={
                  <Checkbox
                    required={!checkboxes.is_adapted}
                    disabled={checkboxes.is_adapted}
                    checked={checkboxes.is_planned_to_adapt}
                    onChange={(event) => {
                      setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                      formik.setFieldValue("disabilities.is_planned_to_adapt", event.target.checked);
                    }}
                    name="is_planned_to_adapt"
                  />
                }
                label="იგეგმება ადაპტირება"
              />
            </FormControl>
          </>
        ) : null}
        {applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>{userBank?.leasing ? "დამტკიცებული სალიზინგო საგნის შესახებ ინფორმაცია" : "დამტკიცებული სესხის შესახებ ინფორმაცია"}</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.loan_id"
                label={userBank?.leasing ? "ლიზინგის ID" : "სესხის ID"}
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approval_date"
                label="დამტკიცების თარიღი"
                type="date"
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleChange}
              />
              <TextField
                required
                helperText={errorMessages.approved_amount}
                error={errorMessages.approved_amount}
                onBlur={() => checkApprovedAmount()}
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approved_amount"
                label={userBank?.leasing ? "ლიზინგის საგნის ღირებულება (GEL)" : "დამტკიცებული თანხა (GEL)"}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="number"
                onChange={(event) => {
                  formik.values.loan_information.conditions.approval_months < 36
                    ? formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        (event.target.value *
                          (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) *
                          formik.values.loan_information.conditions.approval_months) /
                          12
                      )
                    : formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        event.target.value * (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) * 3
                      );
                  formik.handleChange(event);
                }}
              />
              {userBank?.leasing ? (
                <>
                  <TextField
                    variant="outlined"
                    className={classes.field}
                    name="loan_information.conditions.upfront_amount"
                    label="თანამონაწილეობა/წინასწარი შენატანი (GEL)"
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    type="number"
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                  />
                </>
              ) : null}
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approval_months"
                label={userBank?.leasing ? "დამტკიცებული სალიზინგო პროექტის ვადა(თვე)" : "დამტკიცებული ვადა (თვე)"}
                InputLabelProps={
                  userBank?.leasing
                    ? {
                        className: classes.textFieldLabel,
                      }
                    : null
                }
                type="number"
                onChange={(event) => {
                  event.target.value < 36
                    ? formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        (formik.values.loan_information.conditions.approved_amount *
                          (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) *
                          event.target.value) /
                          12
                      )
                    : formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        formik.values.loan_information.conditions.approved_amount *
                          (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) *
                          3
                      );
                  formik.handleChange(event);
                }}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.longerInputLabel} htmlFor="purpose_type">
                  დამტკიცებული საპროცენტო განაკვეთის ტიპი
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={async (event) => {
                    setSelectorData({
                      ...selectorData,
                      interest_type: await generalInfo?.interest_types.find((type) => type._id === event.target.value)?.name,
                    });
                    formik.handleChange(event);
                  }}
                  inputProps={{
                    name: "loan_information.conditions.purpose.interest_rate_type",
                  }}
                >
                  <option aria-label="None" value="" />
                  {generalInfo?.interest_types.map((interest_type) => (
                    <option key={interest_type._id} value={interest_type._id}>
                      {interest_type.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.purpose.refinancing_rate"
                value={formik.values.loan_information.conditions.purpose.refinancing_rate}
                type="number"
                label="ე.ბ.რ.გ. %"
                inputProps={{ step: "any", min: "0", max: "100" }}
                onChange={(event) => {
                  formik.handleChange(event);
                  formik.setFieldValue(
                    "loan_information.conditions.purpose.interest_rate",
                    parseFloat((parseFloat(event.target.value) + parseFloat(formik.values.loan_information.conditions.purpose.variable_rate)).toFixed(2))
                  );

                  formik.values.loan_information.conditions.approval_months < 36
                    ? formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        (formik.values.loan_information.conditions.approved_amount *
                          (event.target.value / 100 + agencyPercentage) *
                          formik.values.loan_information.conditions.approval_months) /
                          12
                      )
                    : formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        formik.values.loan_information.conditions.approved_amount * (event.target.value / 100 + agencyPercentage) * 3
                      );
                }}
              />
              {selectorData?.interest_type === "ცვლადი" ? (
                <>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.field}
                    name="loan_information.conditions.purpose.variable_rate"
                    type="number"
                    label="ბანკის მარჟა %"
                    inputProps={{ step: "any", min: "0", max: "100" }}
                    onChange={(event) => {
                      formik.handleChange(event);
                      formik.setFieldValue(
                        "loan_information.conditions.purpose.interest_rate",
                        parseFloat((parseFloat(formik.values.loan_information.conditions.purpose.refinancing_rate) + parseFloat(event.target.value)).toFixed(2))
                      );
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={formik.values.loan_information.conditions.purpose.interest_rate}
                    InputLabelProps={{ shrink: true }}
                    className={classes.field}
                    name="loan_information.conditions.purpose.interest_rate"
                    disabled
                    type="number"
                    inputProps={{ step: "any" }}
                    label="საპროცენტო განაკვეთი"
                    onChange={formik.handleChange}
                  />
                </>
              ) : null}
              {selectorData?.interest_type === "ფიქსირებული" || selectorData?.interest_type === "შერეული" ? (
                <TextField
                  required
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  variant="outlined"
                  className={classes.field}
                  name="loan_information.conditions.purpose.interest_rate"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  type="number"
                  inputProps={{ step: "any" }}
                  label="დამტკიცებული საპროცენტო განაკვეთი"
                  onChange={formik.handleChange}
                />
              ) : null}
              {selectorData?.interest_type === "შერეული" ? (
                <>
                  <TextField
                    variant="outlined"
                    multiline
                    className={classes.field}
                    name="loan_information.conditions.purpose.mixed_interest_rate"
                    label="შერეული (კომენტარი)"
                    onChange={formik.handleChange}
                  />
                </>
              ) : null}
              <TextField
                variant="outlined"
                className={classes.field}
                disabled
                value={(formik.values.loan_information.conditions.approval_months < 36
                  ? (formik.values.loan_information.conditions.approved_amount *
                      (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) *
                      formik.values.loan_information.conditions.approval_months) /
                    12
                  : formik.values.loan_information.conditions.approved_amount *
                    (formik.values.loan_information.conditions.purpose.refinancing_rate / 100 + agencyPercentage) *
                    3
                ).toFixed(2)}
                name="loan_information.conditions.purpose.co_financing_amount"
                InputLabelProps={{ shrink: true }}
                type="number"
                label="სააგენტოს თანადაფინანსების მაქსიმალური ჯამური თანხა (GEL)"
                onChange={formik.handleChange}
              />

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.inputLabel} htmlFor="purpose_type">
                  მიზნობრიობის ტიპი
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={(event) => {
                    setSelectedPurposeType(generalInfo?.purpose_types.find((type) => type._id === event.target.value));
                    formik.handleChange(event);
                  }}
                  inputProps={{
                    name: "loan_information.conditions.purpose.type",
                  }}
                >
                  <option aria-label="None" value="" />
                  {programType?.name === "რესტორნები" ? (
                    <>
                      {generalInfo?.purpose_types.map((purpose_type) => (
                        <option key={purpose_type._id} value={purpose_type._id}>
                          {purpose_type.name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      {generalInfo?.purpose_types
                        .filter((purpose_type) => {
                          return purpose_type?.name !== "მხოლოდ საბრუნავი საშუალებები" && purpose_type?.name !== "მხოლოდ საოპერაციო ხარჯები";
                        })
                        .map((purpose_type) => (
                          <option key={purpose_type._id} value={purpose_type._id}>
                            {purpose_type.name}
                          </option>
                        ))}
                    </>
                  )}
                </Select>
              </FormControl>
              {selectedPurposeType?.name === "შერეული (კაპიტალური ხარჯები/საბრუნავი)" ? (
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <b>კაპიტალური ხარჯების დასაფინანსებლად</b>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        required
                        variant="outlined"
                        className={classes.field}
                        name="loan_information.conditions.purpose.general.amount"
                        label="თანხა"
                        type="number"
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        helperText={
                          (
                            (formik.values?.loan_information?.conditions?.purpose?.general?.amount /
                              formik.values.loan_information.conditions?.approved_amount) *
                            100
                          ).toFixed(2) < 50
                            ? "კაპიტალური ხარჯები ვერ იქნება 50%ზე ნაკლები"
                            : null
                        }
                        error={
                          (
                            (formik.values?.loan_information?.conditions?.purpose?.general?.amount /
                              formik.values.loan_information.conditions?.approved_amount) *
                            100
                          ).toFixed(2) < 50
                        }
                      />
                      <TextField
                        variant="outlined"
                        disabled
                        className={classes.field}
                        value={(
                          (formik.values?.loan_information?.conditions?.purpose?.general?.amount / formik.values.loan_information.conditions?.approved_amount) *
                          100
                        ).toFixed(2)}
                        InputLabelProps={{ shrink: true }}
                        name="loan_information.conditions.purpose.general.percentage"
                        label="% წილი"
                        type="number"
                        onChange={formik.handleChange}
                        InputProps={{ inputProps: { max: 100, min: 50 } }}
                      />
                    </FormControl>
                    <b>საბრუნავი საშუალებების დასაფინანსებლად</b>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        variant="outlined"
                        disabled
                        className={classes.field}
                        value={formik.values.loan_information?.conditions?.purpose?.mixed?.amount}
                        name="loan_information.conditions.purpose.mixed.amount"
                        label="თანხა"
                        type="number"
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        disabled
                        value={formik.values.loan_information?.conditions?.purpose?.mixed?.percentage}
                        name="loan_information.conditions.purpose.mixed.percentage"
                        label="% წილი"
                        type="number"
                        onChange={formik.handleChange}
                        InputProps={{ inputProps: { max: 50, min: 0 } }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              ) : null}

              <TextField
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.purpose.purpose_detailed"
                label="მიზნობრიობის აღწერა"
                onChange={formik.handleChange}
              />

              <Card style={{ border: "1px solid #0000003b", boxShadow: "none", marginTop: "15px" }}>
                <CardContent className={classes.cardContent}>
                  <i className={classes.note}>
                    სახელმწიფო პროგრამის „აწარმოე საქართველოში“ ინდუსტრიული ნაწილის ან/და უნივერსალური ინდუსტრიული ნაწილის ფარგლებში კომპანიის, მისი მშობელი
                    კომპანიისთვის და შვილობილი კომპანიისთვის პროგრამის ფარგლებში საქმიანობის ერთსა და იმავე განყოფილებაში დამტკიცებული სესხ(ებ)ის, ლიზინგის
                    საგნ(ებ)ის ღირებულების ჯამური მოცულობა (აღნიშნული სესხის ჩათვლით) არ აღემატება 10 მილიონ ლარს.
                  </i>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        required
                        checked={formik.values.loan_information?.conditions?.purpose?.child_company_restriction}
                        onChange={(event) => {
                          formik.setFieldValue(`loan_information.conditions.purpose.child_company_restriction`, event.target.checked);
                        }}
                        name="loan_information.conditions.purpose.child_company_restriction"
                      />
                    }
                    label="შვილობილი/მშობელი კომპანიის შეზღუდვა"
                  />
                </CardContent>
              </Card>
            </FormControl>
          </>
        ) : (
          <>
            <b>{userBank?.leasing ? "დასარეფინანსირებელი სალიზინგო საგნის შესახებ ინფორმაცია:" : "დასარეფინანსირებელი სესხის შესახებ ინფორმაცია:"}</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel required className={classes.inputLabel} htmlFor="bank_initial">
                ბანკი (საწყისი)
              </InputLabel>
              <Select
                required
                className={classes.field}
                native
                onChange={formik.handleChange}
                inputProps={{
                  name: "refinancing_information.initial_bank",
                }}
              >
                <option aria-label="None" value="" />
                {generalInfo?.banks?.map((bank) => (
                  <option key={bank._id} value={bank._id}>
                    {bank.name}
                  </option>
                ))}
              </Select>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.loan_id"
                label={userBank?.leasing ? "დასარეფინანსირებელი ლიზინგის ID" : "დასარეფინანსირებელი სესხის ID "}
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.first_disbursment_date"
                label="სესხის პირველი ტრანშის გაცემის თარიღი"
                type="date"
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleChange}
              />
              {/* <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.annual_co_financing"
                label="მიზნობრივი თანადაფინანსება წლიური საპროცენტო განაკვეთიდან %"
                type="number"
                inputProps={{ step: "any", min: "0", max: "100" }}
                onChange={formik.handleChange}
              /> */}
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.approved_amount"
                label="დამტკიცებული თანხა (GEL)"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.remaining_amount"
                label="ნაშთი (დარჩენილი ძირითადი თანხა) GEL"
                inputProps={{ step: "0.01", min: "0" }}
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.outstanding_amount"
                label="დარჩენილი ასათვისებელი მოცულობა (GEL) (არსებობის შემთხვევაში)"
                inputProps={{ step: "0.01", min: "0" }}
                type="number"
                onChange={formik.handleChange}
              />
            </FormControl>
            <b>
              {userBank?.leasing
                ? "დამტკიცებული რეფინანსირებული სალიზინგო პროექტის შესახებ ინფორმაცია:"
                : "დამტკიცებული რეფინანსირებული სესხის შესახებ ინფორმაცია:"}
            </b>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.loan_id"
                label={userBank?.leasing ? "რეფინანსირებული ლიზინგის ID" : "რეფინანსირებული სესხის ID"}
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approval_date"
                label="დამტკიცების თარიღი"
                type="date"
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approved_amount"
                label="დამტკიცებული რეფინანსირებული მოცულობა (GEL)"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.approval_months"
                label="დამტკიცებული ვადა (თვე)"
                type="number"
                onChange={formik.handleChange}
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.longerInputLabel} htmlFor="purpose_type">
                  დამტკიცებული საპროცენტო განაკვეთის ტიპი
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={async (event) => {
                    setSelectorData({
                      ...selectorData,
                      interest_type: await generalInfo?.interest_types.find((type) => type._id === event.target.value)?.name,
                    });
                    formik.handleChange(event);
                  }}
                  inputProps={{
                    name: "loan_information.conditions.purpose.interest_rate_type",
                  }}
                >
                  <option aria-label="None" value="" />
                  {generalInfo?.interest_types.map((interest_type) => (
                    <option key={interest_type._id} value={interest_type._id}>
                      {interest_type.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="loan_information.conditions.purpose.refinancing_rate"
                value={formik.values.loan_information.conditions.purpose.refinancing_rate}
                type="number"
                label="ე.ბ.რ.გ. %"
                inputProps={{ step: "any", min: "0", max: "100" }}
                onChange={(event) => {
                  formik.handleChange(event);
                  formik.setFieldValue(
                    "loan_information.conditions.purpose.interest_rate",
                    parseFloat((parseFloat(event.target.value) + parseFloat(formik.values.loan_information.conditions.purpose.variable_rate)).toFixed(2))
                  );

                  formik.values.loan_information.conditions.approval_months < 36
                    ? formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        (formik.values.loan_information.conditions.approved_amount *
                          (event.target.value / 100 + agencyPercentage) *
                          formik.values.loan_information.conditions.approval_months) /
                          12
                      )
                    : formik.setFieldValue(
                        "loan_information.conditions.purpose.co_financing_amount",
                        formik.values.loan_information.conditions.approved_amount * (event.target.value / 100 + agencyPercentage) * 3
                      );
                }}
              />
              {selectorData?.interest_type === "ცვლადი" ? (
                <>
                  <TextField
                    required
                    variant="outlined"
                    className={classes.field}
                    name="loan_information.conditions.purpose.variable_rate"
                    type="number"
                    label="ბანკის მარჟა %"
                    inputProps={{ step: "any", min: "0", max: "100" }}
                    onChange={(event) => {
                      formik.handleChange(event);
                      formik.setFieldValue(
                        "loan_information.conditions.purpose.interest_rate",
                        parseFloat((parseFloat(formik.values.loan_information.conditions.purpose.refinancing_rate) + parseFloat(event.target.value)).toFixed(2))
                      );
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={formik.values.loan_information.conditions.purpose.interest_rate}
                    InputLabelProps={{ shrink: true }}
                    className={classes.field}
                    name="loan_information.conditions.purpose.interest_rate"
                    disabled
                    type="number"
                    inputProps={{ step: "any" }}
                    label="საპროცენტო განაკვეთი"
                    onChange={formik.handleChange}
                  />
                </>
              ) : null}
              {selectorData?.interest_type === "ფიქსირებული" || selectorData?.interest_type === "შერეული" ? (
                <TextField
                  required
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  variant="outlined"
                  className={classes.field}
                  name="loan_information.conditions.purpose.interest_rate"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  type="number"
                  inputProps={{ step: "any" }}
                  label="დამტკიცებული საპროცენტო განაკვეთი"
                  onChange={formik.handleChange}
                />
              ) : null}
              {selectorData?.interest_type === "შერეული" ? (
                <>
                  <TextField
                    variant="outlined"
                    multiline
                    className={classes.field}
                    name="loan_information.conditions.purpose.mixed_interest_rate"
                    label="შერეული (კომენტარი)"
                    onChange={formik.handleChange}
                  />
                </>
              ) : null}
            </FormControl>
          </>
        )}
        {applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>პროექტის შესახებ ზოგადი ინფორმაცია</b>
            <FormControl variant="outlined" className={classes.formControl}>
              {Array.from(Array(economicActivitiesCounter)).map((e, index) => (
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    id="nace_activities"
                    className={classes.autoComplete}
                    options={NACEActivities}
                    getOptionLabel={(option) => option.activity_code + " " + option.activity_description}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="outlined"
                        {...params}
                        label="ეკონომიკური საქმიანობა NACE-2 კლასიფიკატორის კოდების მიხედვით"
                        InputLabelProps={{
                          style: { marginTop: -7 },
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedEconomicActivity(newValue);
                      formik.setFieldValue(`project_information.economic_activity[${index}]`, newValue?._id);
                    }}
                  />

                  {index === economicActivitiesCounter - 1 && index !== 0 ? (
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue(`project_information.economic_activity[${index}]`, undefined);
                        activityRemoveHandler();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                  {index === economicActivitiesCounter - 1 ? (
                    <>
                      <IconButton
                        onClick={() => {
                          activityAddHandler();
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </>
                  ) : null}
                </FormControl>
              ))}
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.inputLabel} htmlFor="project_type">
                  პროექტის მიზანი
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={formik.handleChange}
                  inputProps={{
                    name: "project_information.project_type",
                  }}
                >
                  <option aria-label="None" value="" />
                  {projectTypes.map((projectType) => (
                    <option key={projectType} value={projectType}>
                      {projectType}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel required className={classes.longerInputLabel} htmlFor="usage_type">
                  ტერიტორიის სარგებლობის ტიპი
                </InputLabel>
                <Select
                  required
                  className={classes.field}
                  native
                  onChange={formik.handleChange}
                  inputProps={{
                    name: "project_information.territory_usage_type",
                  }}
                >
                  <option aria-label="None" value="" />
                  {usageTypes.map((usageType) => (
                    <option key={usageType} value={usageType}>
                      {usageType}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                inputProps={{ step: "any" }}
                required
                helperText={errorMessages.total_investment}
                error={errorMessages.total_investment}
                onBlur={() => checkTotalInvestment()}
                variant="outlined"
                className={classes.field}
                name="project_information.total_investment"
                type="number"
                label={
                  userBank?.leasing
                    ? "პროექტის ჯამური ინვესტიცია(GEL) (ლიზინგის საგნის ჯამური ღირებულება + პირადი ინვესტიცია)"
                    : "პროექტის ჯამური ინვესტიცია (სესხი + პირადი ინვესტიცია)"
                }
                onChange={formik.handleChange}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="project_information.jobs_created"
                type="number"
                label="ახალი სამუშაო ადგილები (ინვესტიციის ფარგლებში)"
                onChange={formik.handleChange}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
              <TextField
                variant="outlined"
                className={classes.longerField}
                name="project_information.completion_time"
                type="number"
                label="ფუნქციონირების დაწყების ან გაფართოება/გადაიარაღების დასრულების სავარაუდო ვადა (თვე)"
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { maxWidth: 600, marginTop: -7 },
                }}
              />
            </FormControl>
            <b>განსახორციელებელი პროექტის ადგილმდებარეობა - ფაქტობრივი მისამართი</b>
            <FormControl variant="outlined" className={classes.formControl}>
              {programType?.name !== "ბალნეოლოგიური კურორტები" ? (
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel required className={classes.inputLabel} htmlFor="region">
                      რეგიონი
                    </InputLabel>
                    <Select
                      required
                      className={classes.field}
                      native
                      onChange={
                        (formik.handleChange,
                        (event) => {
                          regionChangeHandler(event.target.value, "projectRegionSelected");
                        })
                      }
                      inputProps={{
                        name: "region",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {programType?.name === "სასტუმრო" ? (
                        <>
                          {generalInfo?.regions
                            .filter((region) => {
                              return region !== "თბილისი";
                            })
                            .map((region) => (
                              <option key={region}>{region}</option>
                            ))}
                        </>
                      ) : (
                        <>
                          {generalInfo?.regions.map((region) => (
                            <option key={region}>{region}</option>
                          ))}
                        </>
                      )}
                    </Select>
                    <FormControl variant="outlined" className={classes.formControl} disabled={!localCheckboxes.projectRegionSelected}>
                      <InputLabel required className={classes.longInputLabel} htmlFor="municipality">
                        მუნიციპალიტეტი/თვითმმართველი ქალაქი
                      </InputLabel>
                      <Select
                        required
                        className={classes.field}
                        native
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "project_information.actual_address.municipality",
                        }}
                      >
                        <option aria-label="None" value="" />
                        {programType?.name === "სასტუმრო" ? (
                          <>
                            {projectMunicipalities
                              .filter((municipality) => {
                                return municipality.municipality !== "ბათუმი" && municipality.municipality !== "თბილისი";
                              })
                              .map((municipality) => (
                                <option key={municipality._id} value={municipality._id}>
                                  {municipality.municipality}
                                </option>
                              ))}
                          </>
                        ) : (
                          <>
                            {projectMunicipalities.map((municipality) => (
                              <option key={municipality._id} value={municipality._id}>
                                {municipality.municipality}
                              </option>
                            ))}
                          </>
                        )}
                      </Select>
                    </FormControl>
                  </FormControl>
                </>
              ) : (
                <>
                  {/* 
          
          
          
          */}
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel required className={classes.inputLabel}>
                      მუნიციპალიტეტი
                    </InputLabel>
                    <Select
                      required
                      className={classes.field}
                      native
                      onChange={(event) => {
                        balneologicalMunicipalityChangeHandler(event.target.value);
                      }}
                    >
                      <option aria-label="None" value="" />
                      {generalInfo?.balneological_regions.map((region) => (
                        <option key={region} value={region}>
                          {region}
                        </option>
                      ))}
                    </Select>
                    <FormControl variant="outlined" className={classes.formControl} disabled={balneologicalVillages.length === 0}>
                      <InputLabel required className={classes.inputLabel} htmlFor="village">
                        სოფელი/დასახლება
                      </InputLabel>
                      <Select
                        required
                        className={classes.field}
                        native
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "project_information.actual_address.balneological_village",
                        }}
                      >
                        <option aria-label="None" value="" />
                        {balneologicalVillages.map((balneologicalVillage) => (
                          <option key={balneologicalVillage._id} value={balneologicalVillage._id}>
                            {balneologicalVillage.village}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                  {/* 
          
          
          
          
          
          */}
                </>
              )}

              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="project_information.actual_address.street"
                label="მისამართი (სოფელი/დაბა/ქუჩა/ნომერი)"
                onChange={formik.handleChange}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="project_information.actual_address.cadastral_code"
                label="საკადასტრო კოდი"
                onChange={formik.handleChange}
              />
            </FormControl>
            {programType?.name === "ბალნეოლოგიური კურორტები" || programType?.name === "სასტუმრო" ? (
              <>
                <b>სასტუმროს შესახებ ინფორმაცია</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    required
                    InputProps={{ inputProps: { min: 5 - formik.values.project_information?.hotel_information?.additional_rooms_amount } }}
                    className={classes.field}
                    name="project_information.hotel_information.existing_rooms_amount"
                    type="number"
                    label="არსებული ნომრების რაოდენობა"
                    onChange={formik.handleChange}
                    min={5}
                  />
                  <TextField
                    variant="outlined"
                    required
                    InputProps={{ inputProps: { min: 5 - formik.values.project_information?.hotel_information?.existing_rooms_amount } }}
                    className={classes.longerField}
                    name="project_information.hotel_information.additional_rooms_amount"
                    type="number"
                    label="პროექტის ფარგლებში დამატებული ახალი ნომრების რაოდენობა"
                    onChange={formik.handleChange}
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>არსებული ნომრების და ახალი ნომრების რაოდენობის ჯამი უნდა იყოს 5 ან 5-ზე მეტი.</i>
                    </CardContent>
                  </Card>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.permit_given}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("project_information.hotel_information.criteria.permit_given", event.target.checked);
                        }}
                        name="permit_given"
                      />
                    }
                    label="სამშენებლო ნებართვა/ბრძანება გაცემულია სასტუმროს/სასტუმრო კომპლექსის მშენებლობა/რეკონსტრუქციისთვის"
                  />
                  {checkboxes.permit_given ? (
                    <>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <TextField
                          variant="outlined"
                          className={classes.field}
                          name="project_information.hotel_information.criteria.permit_number"
                          label="ნებართვის/ბრძანების ნომერი"
                          onChange={formik.handleChange}
                        />
                        <TextField
                          variant="outlined"
                          className={classes.field}
                          name="project_information.hotel_information.criteria.permit_organization"
                          label="ნებართვის გამცემი ორგანო"
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        variant="outlined"
                        multiline
                        className={classes.longerField}
                        name="project_information.hotel_information.criteria.permit_absence_reason"
                        label="კომენტარი ნებართვის არარსებობის შესახებ"
                        onChange={formik.handleChange}
                      />
                    </>
                  )}
                </FormControl>
                <b>ნომრების შესაბამისობა პროგრამის პირობებთან</b>
                <FormControl variant="outlined">
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.satisfies_criteria}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.hotel_information.satisfies_criteria", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="satisfies_criteria"
                      />
                    }
                    label="სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>
                        სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს" და ამ ველის შემდეგ იყოს პატარა ზომის მკრთალი ტექსტი: სასტუმროს პროექტში
                        გათვალისწინებულია: 1 ან მრავალოთახიანი ნომრები: ა. 1 ოთახიანი ნომერი - 1 ან 2 ადგილიანი– არანაკლებ 18 კვ.მ, მათ შორის –
                        სააბაზანო/სანიტარიული კვანძის ფართობი არანაკლებ 4კვ.მ. ბ. მრავალოთახიანი ნომერი (აპარტამენტი) 2 ან 2+ ადგილიანი – თითოეულ სტუმარზე
                        არანაკლებ 8 კვ.მ, მათ შორის – სააბაზანო/სანიტარიული კვანძის ფართობი არანაკლებ 2კვ.მ. გ. ნომრებში გათვალისწინებული უნდა იყოს ბავშვის
                        საწოლის ჩამატების შესაძლებლობა.
                      </i>
                    </CardContent>
                  </Card>

                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.elevator}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("project_information.hotel_information.elevator", event.target.checked);
                        }}
                        name="elevator"
                      />
                    }
                    label="ლიფტი - თუ შენობა 2-ზე მეტსართულიანია"
                  />
                  {!checkboxes.elevator ? (
                    <TextField
                      variant="outlined"
                      multiline
                      className={classes.longerField}
                      name="project_information.hotel_information.elevator_absence_reason"
                      label="კომენტარი ლიფტის არარსებობის შემთხვევაში"
                      onChange={formik.handleChange}
                    />
                  ) : null}
                </FormControl>
                <b>სანიტარიული კეთილმოწყობა და ელექტრომომარაგება</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.utilities_criteria}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.utilities.utilities_criteria", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="utilities_criteria"
                      />
                    }
                    label="სანიტარული კეთილმოწყობა და ელექტრომომარაგება"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>
                        პროექტით გათვალისწინებულია: გარე განათება;შენობის შიდა ფართობის განათება;საშხაპე/აბაზანა, ხელსაბანი, უნიტაზი;სასტუმრო ოთახ(ებ)ის და
                        სანიტარიული კვანძის სათანადო განათება და ელექტრომომარაგება.
                      </i>
                    </CardContent>
                  </Card>
                </FormControl>
                <b>სანიტარიული კეთილმოწყობა და ელექტრომომარაგება</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.noise_conditioning}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.air_conditioning.noise_conditioning", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="noise_conditioning"
                      />
                    }
                    label="ხმაურის კონტროლი/ჰაერის კონდიცირება"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>
                        ნომერში არანაკლებ ერთი ფანჯარა ბუნებრივი განათებისათვის (ხმაურდამცავი მინაპაკეტით); ოთახების ცენტრალური ან/და ინდივიდუალური კონდიცირება
                        (გათბობა/გაგრილების სისტემა), აღჭურვილი ინდივიდუალური მართვის საშუალებებით; დამხმარე ოთახებისა (საჭიროების მიხედვით) და საზოგადოებრივი
                        ადგილების ცენტრალური ან/და ინდივიდუალური კონდიცირება (გათბობა/გაგრილების სისტემა).
                      </i>
                    </CardContent>
                  </Card>
                </FormControl>
                <b>უსაფრთხოების მინიმალური მოთხოვნები</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.fire_safety}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.security_measures.fire_safety", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="fire_safety"
                      />
                    }
                    label="უსაფრთხოების მინიმალური მოთხოვნები"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>ხანძარსაწინააღმდეგო ინსტრუქცია ქართულ და უცხო ენებზე; საევაკუაციო სქემა სტუმრისთვის თვალსაჩინო ადგილას;</i>
                    </CardContent>
                  </Card>
                </FormControl>
                <b>პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის:</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.ramp}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.disability_measures.disabled_criteria", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="ramp"
                      />
                    }
                    label="პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>
                        სასტუმროსთან ეტლით მისასვლელი პანდუსი; შენობის შიგნით შეუფერხებელი გადაადგილება; ლიფტით, ეტლით გადაადგილება; შეზღუდული შესაძლებლობის
                        მქონე პირების სასტუმროში განთავსების საშუალება.
                      </i>
                    </CardContent>
                  </Card>
                </FormControl>
                <b>სტუმართა რეგისტრაცია (რეცეფცია)</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    required
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.discrete_area}
                        onChange={(event) => {
                          formik.setFieldValue("project_information.reception.reception_criteria", event.target.checked);
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                        }}
                        name="reception_criteria"
                      />
                    }
                    label="სტუმართა რეგისტრაცია (რეცეფცია)"
                  />
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <i className={classes.note}>
                        განცალკევებული, დამოუკიდებელი რეგისტრაციის ადგილი; მინიმუმ სამ ადგილიანი მოსასვენებელი რეცეფციასთან; სანიტარიული კვანძი მისაღებ
                        სივრცეში.
                      </i>
                    </CardContent>
                  </Card>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    required
                    control={
                      <Checkbox
                        required
                        checked={checkboxes.catering}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("project_information.catering", event.target.checked);
                        }}
                        name="catering"
                      />
                    }
                    label="კვების ობიექტი (სასადილო/რესტორანი)"
                  />
                </FormControl>
              </>
            ) : null}
            {programType?.name === "ტურისტული სერვისები" ? (
              <>
                <b>სათავგადასავლო ტურიზმის მიმართულებით უნდა უზრუნველყოფდეს</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.guest_life_insurance}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.adventure_tourism_measures.guest_life_insurance", event.target.checked);
                        }}
                        name="guest_life_insurance"
                      />
                    }
                    label="ობიექტზე მე-3 პირის (სტუმრის) სიცოცხლისა და ჯანმრთელობის დაზღვევა"
                  />
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.group_service}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.adventure_tourism_measures.group_service", event.target.checked);
                        }}
                        name="group_service"
                      />
                    }
                    label="ჯგუფური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 20 ადამიანის მომსახურებას"
                  />
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.individual_service}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.adventure_tourism_measures.individual_service", event.target.checked);
                        }}
                        name="individual_service"
                      />
                    }
                    label="ინდივიდუალური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 5 ადამიანის მომსახურებას"
                  />
                </FormControl>
                <b>თემატური პარკების ეთნო/ეთნოგრაფიული პარკების მიმართულებით ობიექტი უზრუნველყოფს</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.historic_simulation}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.historic_simulation", event.target.checked);
                        }}
                        name="historic_simulation"
                      />
                    }
                    label="ძველი დროის პერიოდის სიმულაციას, რომელიც გამოხატავს ძველი ტრადიციების და კულტურის, ბუნებრივი და ისტორიული გარემოს იმიტაციას"
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.tourism_elevator}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.elevator", event.target.checked);
                        }}
                        name="tourism_elevator"
                      />
                    }
                    label="ლიფტი - თუ შენობა 2-ზე მეტსართულიანია (გამონაკლისის არსებობის შემთხვევაში მიუთითეთ მიზეზი)."
                  />
                  {!checkboxes.tourism_elevator ? (
                    <TextField
                      variant="outlined"
                      multiline
                      className={classes.longerField}
                      name="tourist_services_information.elevator_absence_reason"
                      label="კომენტარი ლიფტის არარსებობის შემთხვევაში"
                      onChange={formik.handleChange}
                    />
                  ) : null}
                </FormControl>
                <b>სანიტარიული კეთილმოწყობა და ელექტრომომარაგება</b>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.tourism_outside_light}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.utilities.outside_light", event.target.checked);
                        }}
                        name="tourism_outside_light"
                      />
                    }
                    label="გარე განათება"
                  />
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.tourism_inside_light}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.utilities.inside_light", event.target.checked);
                        }}
                        name="tourism_inside_light"
                      />
                    }
                    label="შიდა განათება"
                  />
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.tourism_water_utilities}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.utilities.water_utilities", event.target.checked);
                        }}
                        name="tourism_water_utilities"
                      />
                    }
                    label="სანიტარიული კვანძი მისაღებ სივრცეში (ხელსაბანი, უნიტაზი, ასევე საჭიროების შემთხვევაში (Wake Park) საშხაპე)"
                  />
                  <FormControlLabel
                    variant="outlined"
                    Label
                    control={
                      <Checkbox
                        checked={checkboxes.tourism_room_utilities}
                        onChange={(event) => {
                          setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
                          formik.setFieldValue("tourist_services_information.utilities.room_utilities", event.target.checked);
                        }}
                        name="tourism_room_utilities"
                      />
                    }
                    label="ოთახ(ებ)ის, პავილიონ(ებ)ის და სანიტარიული კვანძის სათანადო განათება და ელექტრომომარაგება."
                  />
                </FormControl>
              </>
            ) : null}
          </>
        ) : null}
        <b>ასატვირთი დოკუმენტები</b>
        {attachmentFields.map((attachment, index) => (
          <>
            <div className="mortgage_attachments">
              <FormControl variant="outlined" className={classes.formControl}>
                <p>{attachment.title}: </p>
                <input
                  type="file"
                  onChange={(event) => {
                    fileChangeHandler(event, index);
                  }}
                />

                <Button
                  style={{ marginLeft: "14.625rem" }}
                  color="secondary"
                  size="small"
                  variant={attachmentButtons[index] ? "contained" : "outlined"}
                  onClick={() => {
                    fileSubmitHandler(attachment.field, index);
                    setTimeout(() => formik.setFieldValue("attachments", uploadedAttachments));
                    const newAttachmentButtons = attachmentButtons;
                    newAttachmentButtons[index] = true;
                    setAttachmentButtons(newAttachmentButtons);
                  }}
                >
                  ატვირთვა
                </Button>
                <IconButton
                  onClick={() => {
                    // const newAttachmentFields = attachmentFields;
                    // newAttachmentFields.push();
                    setAttachmentFields(attachmentFields.concat({ title: attachment.title, field: attachment.field }));
                  }}
                >
                  <AddIcon />
                </IconButton>
              </FormControl>
            </div>
          </>
        ))}
        <FormControl variant="outlined" className={classes.formControl} style={{ textAlign: "center", display: "block", marginTop: "60px" }}>
          <Button
            type="submit"
            onClick={(event) => (window.confirm("გსურთ აპლიკაციის გაგზავნა?") ? console.log("ATTEMPTING SUBMISSION") : event.preventDefault())}
            color="primary"
            disabled={isUploading}
            variant="contained"
            startIcon={<SendIcon />}
            style={{ width: "40%" }}
          >
            გაგზავნა
          </Button>
        </FormControl>
      </form>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  field: {
    width: 320,
    marginRight: 30,
    marginTop: 15,
  },
  textFieldLabel: {
    marginTop: -8,
    maxWidth: 300,
  },
  inputLabel: {
    backgroundColor: "white",
    marginTop: 15,
    maxWidth: "270px",
  },
  longInputLabel: {
    backgroundColor: "white",
    marginTop: 7,
    maxWidth: "270px",
  },
  longerInputLabel: {
    backgroundColor: "white",
    maxWidth: "270px",
  },
  formLabel: {
    marginTop: 30,
  },
  shortField: {
    minWidth: 230,
    marginRight: 20,
    marginTop: 15,
  },
  longField: {
    marginTop: 15,
    minWidth: 350,
    marginRight: 30,
  },
  longerField: {
    marginTop: 15,
    minWidth: 600,
    marginRight: 30,
  },
  button: {
    marginTop: 80,
    width: "60%",
    margin: "0 auto",
  },
  formControl: {
    root: {
      flexDirection: "row",
    },
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  card: {
    width: "100%",
    margin: "15px auto 10px auto",
  },
  cardActions: {
    margin: "0 0 0 auto",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  note: {},
  checkbox: {
    margin: "15px 5px 0 5px",
  },
  autoComplete: {
    minWidth: 400,
    margin: "20px 30px 20px 0",
  },
}));

export default Industrial;
