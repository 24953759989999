import {
  COMPONENTS,
  CURRENCY,
  EcoTourismType,
  EnterpriseCategory,
  IArtisan,
  ICountry,
  // EcoTourismInformation,
  IFieldGroup,
  IInputFieldOption,
  IMonitoringAgreementModel,
  IMonitoringFields,
  IMonitoringHotelFields,
  INaceActivity,
  INature,
  IPlacement,
  Program,
  ProgramComponent,
  PROJECT_STATUS,
  TourismType,
} from "../../../../../types";
import { getNestedValue } from "../../utils";
import { dependencies, fieldValues, IFieldValues } from "../../monitoringDeps";
import { monitoring_initial_data } from "../../init";

const generateDependentFieldValue = (fieldName: keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields) => {
  return (data: IMonitoringFields & Partial<IMonitoringAgreementModel>, callback: (name: string, value: any) => void) => {
    if (fieldName in dependencies) {
      const fieldValue = getNestedValue(data, fieldName);
      const dependentFieldNames = dependencies[fieldName as keyof typeof dependencies];
      let fields: Partial<IFieldValues> = {};
      dependentFieldNames.forEach((dependentFieldName) => {
        let depName = dependentFieldName as string; // if I add callback needs change statement
        if (depName in fieldValues) {
          if (typeof fieldValues[depName as keyof IFieldValues] === "function") {
            let fn = fieldValues[depName as keyof IFieldValues] as (...args: any[]) => any;
            callback(depName, fn(fieldValue as boolean));
            fields[depName as keyof Partial<IFieldValues>] = fn(fieldValue as boolean) as any;
          } else {
            callback(depName, fieldValues[depName as keyof IFieldValues]);
            fields[depName as keyof Partial<IFieldValues>] = fieldValues[depName as keyof Partial<IFieldValues>] as any;
          }
        }
      });
      return fields;
    } else {
      return null;
    }
  };
};
const calculatecapitalExpenditures = (
  field: keyof IMonitoringFields & Partial<IMonitoringAgreementModel>,
  dependingFields: string[],
  totalField?: keyof IMonitoringFields & Partial<IMonitoringAgreementModel>,
  totalDependingFields?: string[]
) => {
  return (data: IMonitoringFields & Partial<IMonitoringAgreementModel>, callback: (name: string, value: any) => void) => {
    const target = dependingFields.reduce((acc, curr) => {
      return acc + Number(data[curr as keyof typeof data] || 0);
    }, 0);
    callback(field, target);
    if (totalDependingFields && totalField) {
      const total = totalDependingFields.reduce((acc, curr) => {
        return acc + Number(data[curr as keyof typeof data] || 0);
      }, 0);
      callback(totalField, total + target);
    }
  };
};
const monitoringGroupFieldsInitValues: IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[] = [
  {
    groupName: "company_name_company_identification_code",
    groupLabel: "ბენეფიციარის დასახელება და საიდენტიფიკაციო კოდი",
    fields: [
      {
        name: "company_name",
        label: "ბენეფიციარის დასახელება",
        type: "text",
        // disabled: true,
        value: "",
      },
      {
        name: "company_identification_code",
        label: "ბენეფიციარის საიდენტიფიკაციო ნომერი",
        // disabled: true,
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: "contract_number_contract_signed_date",
    groupLabel: "ბენეფიციართან გაფორმებული ხელშეკრულების ნომერი/სესხის/ლიზინგის ID და გაფორმების/დადასტურების თარიღი",
    fields: [
      {
        name: "contract_number",
        label: "ხელშეკრულების ნომერი/სესხის/ლიზინგის ID",
        // disabled: true,
        type: "text",
        value: "",
      },
      {
        name: "contract_signed_date",
        label: "ხელშეკრულების გაფორმების / სესხის/ლიზინგის დადასტურების თარიღი",
        // disabled: true,
        type: "date",
        value: null,
      },
    ],
  },
  {
    groupName: "project_status_program",
    groupLabel: "პროექტის ფარგლებში ბენეფიციარის სტატუსი, პროგრამის დასახელება და პროგრამის კომპონენტი",
    fields: [
      {
        name: "project_status",
        label: "პროექტის სტატუსი",
        // disabled: true,
        type: "select",
        value: "",
        options: [
          { value: PROJECT_STATUS.NEW, label: PROJECT_STATUS.NEW },
          { value: PROJECT_STATUS.EXTENDING, label: PROJECT_STATUS.EXTENDING },
        ],
      },
      {
        name: "program",
        label: "სახელმწიფო პროგრამის დასახელება",
        type: "select",
        // disabled: true,
        mapDependentValue: generateDependentFieldValue("program" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields),
        value: "",
        options: [
          { value: Program.CREDIT_GUARANTEE_PROGRAM, label: Program.CREDIT_GUARANTEE_PROGRAM },
          { value: Program.INDUSTRIAL, label: Program.INDUSTRIAL },
          { value: Program.UNIVERSAL, label: Program.UNIVERSAL },
        ],
      },
      {
        name: "program_component",
        label: "სახელმწიფო პროგრამის კომპონენტი",
        // disabled: true,
        type: "select",
        value: "",
        mapDependentValue: generateDependentFieldValue(
          "program_component" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        options: [
          { value: ProgramComponent.INDUSTRIAL_COMPONENT, label: ProgramComponent.INDUSTRIAL_COMPONENT },
          { value: ProgramComponent.HOTEL_INDUSTRY, label: ProgramComponent.HOTEL_INDUSTRY },
          { value: ProgramComponent.TOURISM_SERVICES_INDUSTRY, label: ProgramComponent.TOURISM_SERVICES_INDUSTRY },
          { value: ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY, label: ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY },
          { value: ProgramComponent.MANUFACTORING_INDUSTRY, label: ProgramComponent.MANUFACTORING_INDUSTRY },
          { value: ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY, label: ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY },
          { value: ProgramComponent.AGRI_TORUSIM_INDUSTRY, label: ProgramComponent.AGRI_TORUSIM_INDUSTRY },
          { value: ProgramComponent.ECO_TOURISM_INDUSTRY, label: ProgramComponent.ECO_TOURISM_INDUSTRY },
          { value: ProgramComponent.CREDIT_GUARANTEE_COMPONENT, label: ProgramComponent.CREDIT_GUARANTEE_COMPONENT },
        ],
      },
      {
        name: "component",
        label: "სესხი / ლიზინგი",
        // disabled: true,
        type: "select",
        value: "",
        options: [
          { value: COMPONENTS.LEASING_PROJECT, label: COMPONENTS.LEASING_PROJECT },
          { value: COMPONENTS.LOAN, label: COMPONENTS.LOAN },
          { value: COMPONENTS.PARALLEL_LOAN, label: COMPONENTS.PARALLEL_LOAN },
        ],
      },
    ],
  },
  {
    groupName: "bank_credentials",
    groupLabel: "პროექტის ფარგლებში მხარდამჭერი ფინანსური ინსტიტუტი",
    fields: [
      {
        name: "bank",
        // disabled: true,
        label: "ფინ. ინსტიტუტის დასახელება",
        type: "autocomplete",
        value: "",
      },
      {
        name: "approved_amount",
        label: "ფინ.ინსტიტუტის მიერ დამტკიცებული თანხა",
        // disabled: true,
        type: "number",
        value: 0,
      },
      {
        name: "currency",
        label: "ვალუტა",
        // disabled: true,
        type: "select",
        value: "",
        options: [
          { value: CURRENCY.GEL, label: CURRENCY.GEL },
          { value: CURRENCY.USD, label: CURRENCY.USD },
          { value: CURRENCY.EUR, label: CURRENCY.EUR },
        ],
      },
    ],
  },
  {
    groupName: `fixed_assets_capital_expenditures_funds_turnover`,
    groupLabel: `პროექტის ფარგლებში ბენეფიციარის მხრიდან შესრულებული აქტივობები`,
    fields: [
      {
        name: "used_amount",
        label: "ათვისებული თანხის მოცულობა ₾",
        // disabled: true,
        type: "number",
        value: 0,
      },

      {
        name: "fixed_assets_capital_expenditures",
        label: "ძირითადი საშუალებები/კაპიტალური დანახარჯები",
        callBackFn: calculatecapitalExpenditures("used_amount", ["funds_turnover", "fixed_assets_capital_expenditures"]),
        // disabled: true,
        type: "number",
        value: 0,
      },
      {
        name: "machine_equipment_equipment",
        label: "მანქანა - დანადგარები / აღჭურვილობა",
        callBackFn: calculatecapitalExpenditures(
          "fixed_assets_capital_expenditures",
          ["machine_equipment_equipment", "vehicles_special_technic", "purchase_building_land", "building_repairs"],
          "used_amount",
          ["funds_turnover"]
        ),
        type: "number",
        value: 0,
        // style: { width: "80%" },
        subItem: true,
      },
      {
        name: "vehicles_special_technic",
        label: "სატრანსპორტო საშუალებები / სპეც. ტექნიკა",
        callBackFn: calculatecapitalExpenditures(
          "fixed_assets_capital_expenditures",
          ["machine_equipment_equipment", "vehicles_special_technic", "purchase_building_land", "building_repairs"],
          "used_amount",
          ["funds_turnover"]
        ),
        type: "number",
        value: 0,
        subItem: true,
      },
      {
        name: "purchase_building_land",
        label: "შენობა ნაგებობის / მიწის შეძენა",
        callBackFn: calculatecapitalExpenditures(
          "fixed_assets_capital_expenditures",
          ["machine_equipment_equipment", "vehicles_special_technic", "purchase_building_land", "building_repairs"],
          "used_amount",
          ["funds_turnover"]
        ),
        type: "number",
        value: 0,
        subItem: true,
      },
      {
        name: "building_repairs",
        label: "მშენებლობა / რემონტი",
        callBackFn: calculatecapitalExpenditures(
          "fixed_assets_capital_expenditures",
          ["machine_equipment_equipment", "vehicles_special_technic", "purchase_building_land", "building_repairs"],
          "used_amount",
          ["funds_turnover"]
        ),
        type: "number",
        value: 0,
        subItem: true,
      },
      {
        name: "funds_turnover",
        label: "საბრუნავი საშუალებები",
        callBackFn: calculatecapitalExpenditures("used_amount", ["funds_turnover", "fixed_assets_capital_expenditures"]),
        type: "number",
        value: 0,
        note: {
          label: "",
          note: `აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.`,
        },
        warning: true,
      },
      {
        name: "worked_jobs_detail_description",
        label: "პროექტის ფარგლებში ბენეფიციარი კომპანიის მხრიდან შესრულებული სამუშაოების დეტალური აღწერა.",
        type: "textarea",
        value: "",
      },
      {
        name: "project_activities",
        value: "",
        type: "text",
        label: "",
        sub_fields: [],
        fieldTemplate: {
          project_activities: {
            name: "project_activities",
            label: "NACE კოდი",
            type: "autocomplete",
            value: "",
          },
        },
      },
    ],
  },
  {
    groupName: `company_assets_income_prev_year`,
    groupLabel: `ბენეფიციარის ფინანსური მდგომარეობა წინა საანგარიშგებო წლის მონაცემებით - ₾`,
    fields: [
      {
        name: "enterprise_category",
        label: "საწარმოს კატეგორია შემოსავლების კომპონენტში",
        type: "select",
        value: "",
        options: [
          { value: EnterpriseCategory.EnterpriseCategoryOne, label: EnterpriseCategory.EnterpriseCategoryOne },
          { value: EnterpriseCategory.EnterpriseCategoryTwo, label: EnterpriseCategory.EnterpriseCategoryTwo },
          { value: EnterpriseCategory.EnterpriseCategoryThree, label: EnterpriseCategory.EnterpriseCategoryThree },
          { value: EnterpriseCategory.EnterpriseCategoryFour, label: EnterpriseCategory.EnterpriseCategoryFour },
        ],
      },
      { name: "last_year_actives", label: "აქტივები", type: "number", value: 0 },
      { name: "last_year_income", label: "შემოსავლები", type: "number", value: 0 },
      { name: "last_year_paid_taxes", label: "გადასახადები", type: "number", value: 0 },
    ],
  },
  {
    groupName: `company_full_investment_volume`,
    groupLabel: `პროექტის ფარგლებში ბენეფიციარის მხრიდან განხორციელებული სრული ინვესტიციის (სესხი/ლიზინგი + სხვა ინვესტიციები) მოცულობა - ₾`,
    fields: [
      {
        name: "company_full_investment_volume",
        label: "ინვესტიციის მოცულობა",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `is_external_capital_external_capital_source`,
    groupLabel: `გარე კაპიტალი`,
    fields: [
      {
        name: "is_external_capital",
        label: "აქვს თუ არა კომპანია მოზიდული გარე კაპიტალი",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_external_capital"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      // 2 row
      {
        name: "external_capital_source",
        label: "გარე კაპიტალის დასახელება",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `social_industry_group`,
    groupLabel: `არის თუ არა კომპანია სოციალური საწარმო`,
    fields: [
      {
        name: "is_social_industry",
        label: "არის თუ არა კომპანია სოციალური საწარმო",
        type: "select",
        value: false,
        watchFields: ["is_social_industry"],
        mapDependentValue: generateDependentFieldValue("is_social_industry"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "social_industry_description",
        label: "სოციალური საწარმოს ტიპის აღწერა",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `exporter_company`,
    groupLabel: `ექსპორტი`,
    fields: [
      {
        name: "is_exporter",
        label: "ექსპორტი",
        type: "select",
        mapDependentValue: generateDependentFieldValue("is_exporter"),
        value: false,
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "country_export",
        value: "",
        type: "text",
        label: "",
        fieldTemplate: {
          country_export: {
            name: "country_export",
            label: "საექსპორტო ქვეყანა",
            type: "autocomplete",
            value: "",
          },
        },
        sub_fields: [],
      },
      {
        name: "product_export",
        label: "საექსპორტო პროდუქციის ჩამონათვალი",
        type: "textarea",
        value: "test",
      },
    ],
  },
  {
    groupName: `is_using_brand_used_brand`,
    groupLabel: `საქმიანობს თუ არა კომპანია ბრენდული სახელით, მათ შორის საერთაშორისო ბრენდის სახელით`,
    fields: [
      {
        name: "is_using_brand",
        label: "საქმიანობს თუ არა კომპანია ბრენდული სახელით",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_using_brand"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "used_brand",
        label: "ბრენდის სახელწოდება",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `is_functional_none_functional_reason`,
    groupLabel: `ბენეფიციარი კომპანიის ფუნქციონირების სტატუსი`,
    fields: [
      {
        name: "start_activity_date",
        label: "პროექტის ფარგლებში ფუნქციონირების დაწყების ბოლო ვადა",
        // disabled: true,
        type: "date",
        value: null,
      },
      {
        name: "is_functional",
        label: "ფუნქციონირების სტატუსი",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_functional"),
        options: [
          { value: false, label: "ფუნქციონირებს" },
          { value: true, label: "არ ფუნქციონირებს" },
        ],
      },
      {
        name: "stop_functional_activity_date",
        label: "პროექტის ფარგლებში ფუნქციონირების შეწყვეტის თარიღი",
        type: "date",
        value: null,
      },
      {
        name: "none_functional_reason",
        label: "პროექტის ფარგლებში ფუნქციონირების შეწყვეტის მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `is_started_operating_within_project_group`,
    groupLabel: `ბენეფიციარი კომპანიის საქმიანობის პროფილის შენარჩუნების სტატუსი `,
    fields: [
      {
        name: "is_started_operating_within_project",
        label: "პროექტის ფარგლებში ბენეფიციარ კომპანიას დაწყებული აქვს თუ არა  ფუნქციონირება",
        type: "select",
        value: true,
        mapDependentValue: generateDependentFieldValue("is_started_operating_within_project"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "start_beneficiar_activity_date",
        label: "პროექტის ფარგლებში საქმიანობის დაწყების თარიღი",
        type: "date",
        value: null,
      },
      {
        name: "is_same_company_profile_activity",
        label: "მონიტორინგის განხორციელების დღისთვის ბენეფიციარ კომპანიას შენარჩუნებული აქვს თუ არა საქმიანობის პროფილი",
        type: "select",
        value: true,
        mapDependentValue: generateDependentFieldValue("is_same_company_profile_activity"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "other_profile",
        label: "კომენტარის ველი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `employee_amount_enterprise_category`,
    groupLabel: `დასაქმებულთა რაოდენობა`,
    fields: [
      {
        name: "woman_employee_amount",
        label: "ქალი",
        type: "number",
        value: 0,
      },
      {
        name: "man_employee_amount",
        label: "კაცი",
        type: "number",
        value: 0,
      },
      {
        name: "employee_amount",
        label: "დასაქმებულთა არსებული რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "jobs_created",
        label: "პროექტის ფარგლებში შექმნილი სამუშაო ადგილების რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "is_lack_of_personnel",
        label: "განიცდის თუ არა კადრების დეფიციტს კომპანია",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_lack_of_personnel"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "is_lack_of_administration",
        label: "ადმინისტრაციული",
        mapDependentValue: generateDependentFieldValue("is_lack_of_administration"),
        type: "checkbox",
        value: false,
      },
      {
        name: "marketing",
        label: "მარკეტინგი",
        type: "checkbox",
        value: false,
      },
      {
        name: "accounting",
        label: "ბუღალტერია",
        type: "checkbox",
        value: false,
      },
      {
        name: "finances",
        label: "ფინანსები",
        type: "checkbox",
        value: false,
      },
      {
        name: "hr",
        label: "HR",
        type: "checkbox",
        value: false,
      },
      {
        name: "sales",
        label: "გაყიდვები",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_other_administration",
        label: "სხვა",
        mapDependentValue: generateDependentFieldValue("is_other_administration"),
        type: "checkbox",
        value: "",
      },
      {
        name: "other_administration",
        label: "სხვა",
        type: "textarea",
        value: "",
      },
      {
        name: "is_production_service_direction",
        label: "წარმოების/მომსახურების გაწევის მიმართულებით",
        mapDependentValue: generateDependentFieldValue("is_production_service_direction"),
        type: "checkbox",
        value: false,
      },
      {
        name: "lack_of_production_service_direction",
        label: "წარმოების/მომსახურების გაწევის მიმართულება",
        type: "text",
        value: "",
      },
      {
        name: "is_person_disability",
        label: "დასაქმებული შშმ პირი",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_person_disability"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "employeed_person_disability_amount",
        label: "დასაქმებული შშმ პირ(ებ)ის რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "is_building_adapted_for_person_disability",
        label: "ბენეფიციარი კომპანიის სამეურნეო ადმინისტრაციული შენობა ადაპტირებულია შშმ პირებზე",
        type: "select",
        value: false,
        mapDependentValue: generateDependentFieldValue("is_building_adapted_for_person_disability"),
        options: [
          { value: false, label: "არა" },
          { value: true, label: "კი" },
        ],
      },
      {
        name: "none_person_disability_reason_comment",
        label: "მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `employee_amount_gender_statistic`,
    groupLabel: `ბენეფიციარის გენდერული სტატისტიკა`,
    fields: [
      {
        name: "director_women_amount",
        label: "დირექტორი ქალ(ებ)ის რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "founder_women_amount",
        label: "დამფუძნებელი ქალ(ებ)ის რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "founder_women_amount_percentage",
        label: "დამფუძნებელი ქალ(ებ)ის ჯამური წილობრივი მონაწილეობა",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `actual_municipality_address_cadastral_code_is_building_rented`,
    groupLabel: `ბენეფიციარის საქმიანობის განხორციელების ფაქტობრივი მისამართი`,
    fields: [
      {
        name: "actual_municipality",
        label: "რეგიონი/მუნიციპალიტეტი *",
        type: "autocomplete",
        value: "",
      },
      {
        name: "is_sign_inscription_banner",
        label: `საქმიანობის განხორციელების ადგილას განთავსებულია აბრა წარწერით - პროექტი განხორციელდა პროგრამის "აწარმოე საქართველოში" მხარდაჭერით`,
        type: "checkbox",
        value: false,
      },
      {
        name: "actual_address",
        label: "მისამართი(სოფელი/დაბა/ქუჩა/ნომერი) *",
        type: "text",
        value: "",
      },
      {
        name: "cadastral_code",
        label: "საკადასტრო კოდი *",
        type: "text",
        value: "",
      },
      {
        name: "is_building_rented",
        label: "ბენეფიციარის საქმიანობის განხორციელების ფაქტობრივი მისამართის სტატუსი",
        type: "select",
        value: false,
        options: [
          { value: false, label: "საკუთრება" },
          { value: true, label: "დროებითი სარგებლობა (იჯარა)" },
        ],
      },
    ],
  },
  {
    groupName: `representative_name_phone_position_id_number_email`,
    groupLabel: `ბენეფიციარის წარმომადგენელი პირი`,
    fields: [
      {
        name: "representative_name",
        label: "სახელი, გვარი *",
        type: "text",
        value: "",
      },
      {
        name: "representative_phone",
        label: "ტელ. ნომერი *",
        type: "text",
        value: "",
      },
      {
        name: "representative_position",
        label: "პოზიცია *",
        type: "text",
        value: "",
      },
      {
        name: "representative_id_number",
        label: "პ/ნ *",
        type: "text",
        value: "",
      },
      {
        name: "representative_email",
        label: "წარმომადგენლის ელ. ფოსტა *",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `hotel_information`,
    groupLabel: `სასტუმრო/ბალნეოლოგიური კურორტები`,
    fields: [
      {
        name: "hotel_rooms",
        label: "სასტუმრო ნომრების სრული რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "new_hotel_rooms",
        label: "პროექტის ფარგლებში შექმნილი ახალი ნომრების რაოდენობა",
        type: "number",
        value: 0,
      },

      {
        name: "hotel_information.satisfies_criteria",
        label: "სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს",
        type: "checkbox",
        value: false,
        note: {
          label: "ნომრების შესაბამისობა პროგრამის პირობებთან",
          note: `სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს" და ამ ველის შემდეგ იყოს პატარა ზომის მკრთალი ტექსტი: სასტუმროს პროექტში
                              გათვალისწინებულია: 1 ან მრავალოთახიანი ნომრები: ა. 1 ოთახიანი ნომერი - 1 ან 2 ადგილიანი– არანაკლებ 18 კვ.მ, მათ შორის –
                              სააბაზანო/სანიტარიული კვანძის ფართობი არანაკლებ 4კვ.მ. ბ. მრავალოთახიანი ნომერი (აპარტამენტი) 2 ან 2+ ადგილიანი – თითოეულ სტუმარზე
                              არანაკლებ 8 კვ.მ, მათ შორის – სააბაზანო/სანიტარიული კვანძის ფართობი არანაკლებ 2კვ.მ. გ. ნომრებში გათვალისწინებული უნდა იყოს
                              ბავშვის საწოლის ჩამატების შესაძლებლობა.`,
        },
      },
      {
        name: "hotel_information.elevator",
        label: "ლიფტი - თუ შენობა 2-ზე მეტსართულიანია",
        mapDependentValue: generateDependentFieldValue(
          "hotel_information.elevator" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.elevator_absence_reason",
        label: "ლიფტის არარსებობის მიზეზი (ასეთის არსებობის შემთხვევაში)",
        type: "textarea",
        value: "",
      },
      {
        name: "hotel_information.utilities_criteria",
        label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `პროექტით გათვალისწინებულია: გარე განათება;შენობის შიდა ფართობის განათება;საშხაპე/აბაზანა, ხელსაბანი, უნიტაზი;სასტუმრო ოთახ(ებ)ის
                              და სანიტარიული კვანძის სათანადო განათება და ელექტრომომარაგება.`,
        },
      },
      {
        name: "hotel_information.noise_conditioning",
        label: "ხმაურის კონტროლი/ჰაერის კონდიცირება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `ნომერში არანაკლებ ერთი ფანჯარა ბუნებრივი განათებისათვის (ხმაურდამცავი მინაპაკეტით); ოთახების ცენტრალური ან/და ინდივიდუალური
                              კონდიცირება (გათბობა/გაგრილების სისტემა), აღჭურვილი ინდივიდუალური მართვის საშუალებებით; დამხმარე ოთახებისა (საჭიროების მიხედვით)
                              და საზოგადოებრივი ადგილების ცენტრალური ან/და ინდივიდუალური კონდიცირება (გათბობა/გაგრილების სისტემა).`,
        },
      },
      {
        name: "hotel_information.fire_safety",
        label: "უსაფრთხოების მინიმალური მოთხოვნები",
        type: "checkbox",
        value: false,
        note: {
          label: "უსაფრთხოების მინიმალური მოთხოვნები",
          note: `ხანძარსაწინააღმდეგო ინსტრუქცია ქართულ და უცხო ენებზე; საევაკუაციო სქემა სტუმრისთვის თვალსაჩინო ადგილას;`,
        },
      },
      {
        name: "hotel_information.disabled_criteria",
        label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
        type: "checkbox",
        value: false,
        note: {
          label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
          note: `სასტუმროსთან ეტლით მისასვლელი პანდუსი; შენობის შიგნით შეუფერხებელი გადაადგილება; ლიფტით, ეტლით გადაადგილება; შეზღუდული
                              შესაძლებლობის მქონე პირების სასტუმროში განთავსების საშუალება.`,
        },
      },
      {
        name: "hotel_information.catering",
        label: "კვების ობიექტი (სასადილო/რესტორანი)",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.reception_criteria",
        label: "სტუმართა რეგისტრაცია (რეცეფცია)",
        type: "checkbox",
        value: false,
        note: {
          label: "სტუმართა რეგისტრაცია (რეცეფცია)",
          note: `განცალკევებული, დამოუკიდებელი რეგისტრაციის ადგილი; 
                        მინიმუმ სამ ადგილიანი მოსასვენებელი რეცეფციასთან; 
                        სანიტარიული კვანძი მისაღებ სივრცეში.`,
        },
      },
      {
        name: "hotel_information.reason_comment",
        label: "კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `tourist_services_information`,
    groupLabel: `ტურისტული სერვისები (თემატური პარკები და სათავგადასავლო ტურიზმი)`,
    fields: [
      {
        name: "tourist_services_information.type",
        label: "ტიპი",
        type: "select",
        value: "",
        mapDependentValue: generateDependentFieldValue(
          "tourist_services_information.type" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        options: [
          { value: TourismType.AdventureTourismMeasures, label: TourismType.AdventureTourismMeasures },
          { value: TourismType.HistoricSimulation, label: TourismType.HistoricSimulation },
          // { value: TourismType.RESETVALUE, label: TourismType.RESETVALUE },
        ],
      },
      {
        name: "adventure_tourism_measures",
        label: "სათავგადასავლო ტურიზმის მიმართულებით უნდა უზრუნველყოფდეს",
        type: "text",
        value: "",
        subGroupFields: [
          {
            name: "tourist_services_information.adventure_tourism_measures.guest_life_insurance",
            label: "ობიექტზე მე-3 პირის (სტუმრის) სიცოცხლისა და ჯანმრთელობის დაზღვევა",
            type: "checkbox",
            value: false,
          },
          {
            name: "tourist_services_information.adventure_tourism_measures.group_service",
            label: "ჯგუფური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 20 ადამიანის მომსახურებას",
            type: "checkbox",
            value: false,
          },
          {
            name: "tourist_services_information.adventure_tourism_measures.individual_service",
            label: "ინდივიდუალური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 5 ადამიანის მომსახურება",
            type: "checkbox",
            value: false,
          },
        ],
      },
      {
        name: "historic_simulation",
        label: "თემატური პარკების ეთნო/ეთნოგრაფიული პარკების მიმართულებით ობიექტი უზრუნველყოფს",
        type: "text",
        value: "",
        subGroupFields: [
          {
            name: "tourist_services_information.historic_simulation",
            label: "ძველი დროის პერიოდის სიმულაციას, რომელიც გამოხატავს ძველი ტრადიციების და კულტურის, ბუნებრივი და ისტორიული გარემოს იმიტაციას",
            type: "checkbox",
            value: false,
          },
        ],
      },
      {
        name: "tourist_services_information.elevator",
        label: "ლიფტი - თუ შენობა 2-ზე მეტსართულიანია",
        type: "checkbox",
        value: false,
      },
      {
        name: "tourist_services_information.elevator_absence_reason",
        label: "ლიფტის არარსებობის მიზეზი (ასეთის არსებობის შემთხვევაში)",
        type: "text",
        value: "",
      },
      {
        name: "tourist_services_information.utilities_criteria",
        label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `პროექტით გათვალისწინებულია: გარე განათება;შენობის შიდა ფართობის განათება;საშხაპე/აბაზანა, ხელსაბანი, უნიტაზი;სასტუმრო ოთახ(ებ)ის
                              და სანიტარიული კვანძის სათანადო განათება და ელექტრომომარაგება.`,
        },
      },
      {
        name: "tourist_services_information.noise_conditioning",
        label: "ხმაურის კონტროლი/ჰაერის კონდიცირება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `ნომერში არანაკლებ ერთი ფანჯარა ბუნებრივი განათებისათვის (ხმაურდამცავი მინაპაკეტით); ოთახების ცენტრალური ან/და ინდივიდუალური
                              კონდიცირება (გათბობა/გაგრილების სისტემა), აღჭურვილი ინდივიდუალური მართვის საშუალებებით; დამხმარე ოთახებისა (საჭიროების მიხედვით)
                              და საზოგადოებრივი ადგილების ცენტრალური ან/და ინდივიდუალური კონდიცირება (გათბობა/გაგრილების სისტემა).`,
        },
      },
      {
        name: "tourist_services_information.fire_safety",
        label: "უსაფრთხოების მინიმალური მოთხოვნები",
        type: "checkbox",
        value: false,
        note: {
          label: "უსაფრთხოების მინიმალური მოთხოვნები",
          note: `ხანძარსაწინააღმდეგო ინსტრუქცია ქართულ და უცხო ენებზე; საევაკუაციო სქემა სტუმრისთვის თვალსაჩინო ადგილას;`,
        },
      },
      {
        name: "tourist_services_information.disabled_criteria",
        label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
        type: "checkbox",
        value: false,
        note: {
          label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
          note: `სასტუმროსთან ეტლით მისასვლელი პანდუსი; შენობის შიგნით შეუფერხებელი გადაადგილება; ლიფტით, ეტლით გადაადგილება; შეზღუდული
                              შესაძლებლობის მქონე პირების სასტუმროში განთავსების საშუალება.`,
        },
      },
      {
        name: "tourist_services_information.catering",
        label: "კვების ობიექტი (სასადილო/რესტორანი)",
        type: "checkbox",
        value: false,
        note: {
          label: "კვების ობიექტი (სასადილო/რესტორანი)",
          note: `სასადილო ან რესტორანი.`,
        },
      },
      {
        name: "tourist_services_information.reason_comment",
        label: "კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი.",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `agro_tourism_information`,
    groupLabel: `აგრო ტურიზმი`,
    fields: [
      {
        name: "agro_tourism_information.placement",
        label: "განთავსება",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.catering",
        label: "კვებითი მომსახურება",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.recreational",
        label: "გასართობი/რეკრეაციული",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.educational",
        label: "საგანმანათლებლო/შემეცნებითი",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.farming_property",
        label: "საკუთრების ან მართლზომიერი სარგებლობის უფლებით ფლობს ფერმერულ მეურნეობას*",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.food_safety",
        label: `სურსათის/ცხოველის საკვების უვნებლობის, ვეტერინარიისა და მცენარეთა დაცვის კოდექსით
                განსაზღვრული მოთხოვნების შესაბამისად, რეგისტრირებულია ბიზნესოპერატორად*`,
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.registered_farm",
        label: `რეგისტრირებულია   საქართველოს გარემოს დაცვისა და სოფლის მეურნეობის
            სამინისტროს ფერმათა/ფერმერთა რეესტრში*`,
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.is_other_activity",
        label: "სხვა აქტივობა",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.other_activity",
        label: "სხვა აქტივობის მითითება",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `eco_tourism_information`,
    groupLabel: `ეკო ტურიზმი`,
    fields: [
      {
        name: "eco_tourism_information.type",
        label: "ტიპი",
        type: "select",
        value: "",
        mapDependentValue: generateDependentFieldValue(
          "eco_tourism_information.type" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        options: [
          { value: EcoTourismType.PLACEMENT, label: EcoTourismType.PLACEMENT },
          { value: EcoTourismType.ARTISAN, label: EcoTourismType.ARTISAN },
          { value: EcoTourismType.NATURE, label: EcoTourismType.NATURE },
        ],
      },
      {
        name: "eco_tourism_information.placement.existing_building",
        label:
          "არსებული შენობა-ნაგებობა, რომელიც არის რეგიონისთვის დამახასიათებელი ავთენტური არქიტექტურის და განკუთვნილია განთავსების ობიექტად განვითარებისა და ფუნქციონირებისთვის",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.placement.mutliple_guests",
        label: "შეეძლება ერთდროულად არანაკლებ 8 სტუმრის გამასპინძლება",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.placement.multiple_rooms",
        label: "სანომრე ფონდი არაუმეტეს 10 ნომრისა",
        type: "checkbox",
        value: false,
      },

      {
        name: "eco_tourism_information.artisan.existing_workshop",
        label: "არსებული, მოქმედი სახელოსნო, საქმიანობისთვის გამორჩეული სახასიათო ელემენტებით",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.artisan.demonstrational_area",
        label: `გააჩნია არანაკლებ 10 ადამიანზე გათვლილი საჩვენებელი სივრცე`,
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.artisan.masterclass_area",
        label: `გააჩნია არანაკლებ 5 ადამიანზე გათვლილი მასტერკლასის ჩატარების შესაძლებლობა`,
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.bicycle",
        label: "ველოსიპედი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.horse",
        label: "ცხენი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.water",
        label: "საწყალოსნო",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.seasonal_sled",
        label: "სეზონური ჩასაბმელები",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.is_other",
        label: "სხვა",
        type: "checkbox",
        mapDependentValue: generateDependentFieldValue(
          "eco_tourism_information.nature.accessories.is_other" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.other",
        label: "დააკონკრეტეთ jjjj",
        type: "text",
        value: "",
      },
      {
        name: "eco_tourism_information.nature.accessories.safety",
        label: "უსაფრთხოების აქსესუარები",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.inventory",
        label: "აღჭურვილობა/ინვენტარი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.is_other",
        label: "სხვა აქსესუარები",
        type: "checkbox",
        mapDependentValue: generateDependentFieldValue(
          "eco_tourism_information.nature.accessories.is_other" as keyof IMonitoringFields & Partial<IMonitoringAgreementModel> & IMonitoringHotelFields
        ),
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.other",
        label: "დააკონკრეტეთ 111",
        type: "textarea",
        value: "",
      },
    ],
  },
];

export { monitoringGroupFieldsInitValues, MonitoringKeys, monitoring_initial_data };

const properties = {
  contract_number: "ხელშეკრულების ნომერი/ სესხის/ლიზინგის ID",
  company_identification_code: "ს/კ",
  company_name: "ბენეფიციარის დასახელება",
  program: "პროგრამა",
  enterprise_category: "ბენეფიციარის სამეწარმეო კატეგორია",
  contract_signed_date: "ხელშეკრულების გაფორმების /სესხის/ლიზინგის დადასტურების თარიღი",
  first_tranche_date: "პირველი ტრანშის თარიღი",
  start_activity_date: "საქმიანობის დაწყების ვადა",
  program_component: "პროგრამის კომპონენტი",
  component: "კომპონენტი",
  approved_amount: "დამტკიცებული თანხა",
  currency: "სესხის/ლიზინგის ვალუტა",
  bank: "მიმდინარე/ბოლო ფინანსო ინსტიტუტი",
  purpose: "სესხის/ლიზინგის მიზნობრიობა",
  jobs_created: "ინვესტიციით შექმნილი ახალი სამუშაო ადგილები",
  emploee_amount: "ჯამური დასაქმება",
  new_hotel_rooms: "სასტუმროს ახალი ნომრების რაოდენობა",
  hotel_rooms: "სასტუმროს ნომრების ჯამური რაოდენობა",
  project_status: "პროექტის სტატუსი - ახალი/გაფართოება",
  project_activity: "პროექტის ეკონომიკური აქტივობა",
  actual_region: "ფაქტობრივი მისამართი: რეგიონი",
  actual_municipality: "ფაქტობრივი მისამართი: თვითმმართველი ქალაქი/ მუნიციპალიტეტი",
  actual_address: "ფაქტობრივი მისამართი:  სოფელი/ქუჩა, ქუჩის ნომერი",
  representative_name_lastname: "წარმომადგენელი",
  representative_phone: "წარმომადგენლის ტელეფონი",
  representative_phone_secondary: "დამატებით საკონტაქტო",
  representative_person_email: "წარმომადგენლის ელ. ფოსტა",
  company_email: "ბენეფიციარის საჯარო ელ. ფოსტა",
  company_founder_phone: "დამფუძნებლის ნომერი",
};

const MonitoringKeys: string[] = [
  "country_export",
  "company_identification_code",
  "contract_number",
  "contract_signed_date",
  "company_name",
  "project_status",
  "program_component",
  "component",
  "approved_amount",
  "currency",
  "used_amount",
  "bank",
  "country",
  "enterprise_category",
  "funds_turnover",
  "fixed_assets_capital_expenditures",
  "last_year_actives",
  "last_year_income",
  "last_year_paid_taxes",
  "worked_jobs_detail_description",
  "is_external_capital",
  "external_capital_source",
  "is_social_industry",
  "social_industry_description",
  "is_exporter",
  "product_export",
  "is_using_brand",
  "used_brand",
  "is_functional",
  "none_functional_reason",
  "start_date",
  "project_activities",
  "employee_amount",
  "contract_based_employee_amount",
  "woman_employee_amount",
  "man_employee_amount",
  "is_lack_of_personnel",
  "lack_of_personnel_profession_area",
  "lack_of_administration_area",
  "lack_of_personnel",
  "lack_of_production_service_direction",
  "employeed_person_disability_amount",
  "is_building_adapted_for_person_disability",
  "director_women_amount",
  "founder_women_amount",
  "founder_women_amount_percentage",
  "cadastral_code",
  "actual_address",
  "actual_municipality",
];
