export enum Permissions {
  READ = "READ",
  WRITE = "WRITE",
  DELETE = "DELETE",
}
export enum Roles {
  ADMIN = "admin",
  USER = "user",
  GUIST = "guist",
}

export enum RequestReason {
  FullCover = "სრულად დაფარვა",
  FullCoverInAdvance = "წინსწრებით სრულად დაფარვა",
  Default = "დეფოლტი",
  Refinancing = "რეფინანსირება",
  ExitFromProgramAndReturnOfMoney = "პროგრამიდან გასვლა და თანხის დაბრუნება",
  Opening = "ჩახსნა",
  ExpirationOfLoanRepaymentPeriod = "თანადაფინანსების ვადის გასვლა",
  LoanNotGranted = "სესხი არ გაცემულა",
}
export enum RiskCategory {
  Moderate = "moderate",
  High = "high",
  Substantial = "substantial",
}
export enum EIAStatus {
  EIAExists = "eia_exists",
  EIAAbsenceWithLetter = "eia_abscence_with_letter",
  EIAAbsence = "eia_abscence",
}
export enum TourismType {
  AdventureTourismMeasures = "სათავგადასავლო ტურიზმის მიმართულება",
  HistoricSimulation = "თემატური პარკების ეთნო/ეთნოგრაფიული პარკების მიმართულება",
  RESETVALUE = "",
}

export enum EcoTourismType {
  PLACEMENT = "განთავსების საშუალება",
  ARTISAN = "ტრადიციული რეწვის სახელოსნო",
  NATURE = "ბუნებაში გადაადგილებისას და ბუნების აღქმისა და დაკვირვებისათვის აღჭურვილობის გაქირავება",
  RESETVALUE = "",
}

export enum Program {
  INDUSTRIAL = "ინდუსტრიული",
  CREDIT_GUARANTEE_PROGRAM = "საკრედიტო-საგარანტიო პროგრამა",
  UNIVERSAL = "უნივერსალური",
  RESETVALUE = "",
}

export enum PROJECT_STATUS {
  NEW = "ახალი",
  EXTENDING = "გაფართოება",
  NULL = "null",
  RESETVALUE = "",
}

export enum CURRENCY {
  GEL = "GEL",
  USD = "USD",
  EUR = "EUR",
  RESETVALUE = "",
}

export enum COMPONENTS {
  PARALLEL_LOAN = "პარალელური სესხი",
  LEASING_PROJECT = "სალიზინგო პროექტი",
  LOAN = "სესხი",
  RESETVALUE = "",
}

export enum EnterpriseCategory {
  EnterpriseCategoryOne = "I კატეგორიის საწარმო",
  EnterpriseCategoryTwo = "II კატეგორიის საწარმო",
  EnterpriseCategoryThree = "III კატეგორიის საწარმო",
  EnterpriseCategoryFour = "IV კატეგორიის საწარმო",
  RESETVALUE = "",
}
export enum ProgramComponent {
  INDUSTRIAL_COMPONENT = "ინდუსტრიული კომპონენტი", // ინდუსტრიული პროგრამა 13.1.1
  HOTEL_INDUSTRY = "სასტუმრო", // ინდუსტრიული პროგრამა 13.1.2
  TOURISM_SERVICES_INDUSTRY = "ტურისტული სერვისები", // ინდუსტრიული პროგრამა 13.1.3
  BALNEOLOGICAL_RESORT_INDUSTRY = "ბალნეოლოგიური საკურორტო ინდუსტრია", // ინდუსტრიული პროგრამა 13.1.4
  MANUFACTORING_INDUSTRY = "წარმოების ინდუსტრია", // უნივერსალური პროგრამა 13.2.1
  HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY = "სასტუმრო ინდუსტრია/ბალნეოლოგიური კურორტების ინდუსტრია", // უნივერსალური პროგრამა 13.2.2
  AGRI_TORUSIM_INDUSTRY = "აგრო ტურისტული ინდუსტრია", // უნივერსალური პროგრამა 13.2.3
  ECO_TOURISM_INDUSTRY = "ეკო ტურისტული ინდუსტრია", // უნივერსალური პროგრამა 13.2.4
  CREDIT_GUARANTEE_COMPONENT = "საგარანტიო კომპონენტი", // საკრედიტო-საგარანტიო / აგრო კრედიტი 13.3
  PREFERENTIAL_AGRO_CREDIT = "შეღავათიანი აგრო კრედიტი",
  RESETVALUE = "",
}

// export const programToProgramComponent = {
//   INDUSTRIAL: ["INDUSTRIAL_COMPONENT", "HOTEL_INDUSTRY", "TOURISM_SERVICES_INDUSTRY"],
//   UNIVERSAL: ["INDUSTRIAL_COMPONENT"],
//   CREDIT_GUARANTEE_PROGRAM: ["CREDIT_GUARANTEE_COMPONENT", "PREFERENTIAL_AGRO_CREDIT"],
// };

export enum CompanyContractStatus {
  NEW = "ახალი",
  EXTENTION = "გაფართოება",
}
