import { Pagination as MUIPagination } from "@material-ui/lab";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";

const Pagination = ({ filterData, setFilterData, pages }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" padding={2}>
      <MUIPagination
        count={pages}
        page={filterData.page}
        siblingCount={4}
        onChange={(event, value) => setFilterData({ ...filterData, page: value })}
        color="primary"
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        size="large"
      />
      <FormControl style={{ marginLeft: 20, minWidth: 120 }}>
        <InputLabel id="amount-label">ჩანაწერები</InputLabel>
        <Select
          labelId="amount-label"
          id="amount-select"
          value={filterData.amountOnPage}
          onChange={(event) => setFilterData({ ...filterData, amountOnPage: event.target.value, page: 1 })}
        >
          {[15, 30, 50].map((amount) => (
            <MenuItem key={amount} value={amount}>
              {amount}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Pagination;
