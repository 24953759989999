import { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, TextField, Button, IconButton } from "@material-ui/core";
import dateFormat from "dateformat";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ScheduleTolerancyDateSetter({ canModifyScheduling, applicationInformation, classes }) {
  const [newDate, setNewDate] = useState(null);
  const [newSubsidyFinishDate, setNewSubsidyFinishDate] = useState(null);
  const [newSubsidyFinishDateComment, setNewSubsidyFinishDateComment] = useState(null);
  const [editSubsidyFinishDateDialogOpen, setEditSubsidyFinishDateDialogOpen] = useState(false);

  useEffect(() => {
    //if applicationInformation?.schedule_tolerancy_date is not null, set the value of newDate to the value of applicationInformation?.schedule_tolerancy_date
    if (applicationInformation?.schedule_tolerancy_date) {
      setNewDate(applicationInformation?.schedule_tolerancy_date);
    }
  }, [applicationInformation]);

  function handleDateChange(e) {
    const component = window.location.pathname.split("/")[1];
    const reason = window.prompt("ტოლერანტობის შეყვანის მიზეზი");

    if (!window.confirm("მოთხოვნის გაგზავნა")) return;
    axios
      .post(`/agency/${component}/change_schedule_tolerancy_date`, {
        applicationID: applicationInformation._id,
        newDate,
        reason,
      })
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
      });
  }

  function deleteDisbursementDate(event, applicationID) {
    // "/agency/delete_disbursement_date/:component/:application_id",
    event.preventDefault();
    const component = window.location.pathname.split("/")[1];
    if (!window.confirm("მოთხოვნის გაგზავნა")) return;
    axios
      .delete(`/agency/delete_disbursement_date/${component}/${applicationID}`)
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
      });
  }

  function changeSubsidyFinishDate(event, applicationID) {
    event.preventDefault();
    const component = window.location.pathname.split("/")[1];
    if (!window.confirm("მოთხოვნის გაგზავნა")) return;
    axios
      .post(`/agency/${component}/change_subsidy_finish_date`, {
        applicationID,
        newSubsidyFinishDate,
      })
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
      });
  }

  return (
    <Card className={classes.component} variant="outlined">
      <CardContent>
        <>
          <Typography className={classes.cardSummary}>თარიღები</Typography>
          <table className={classes.table}>
            <tbody>
              <tr className={classes.row}>
                <td className={classes.title}>პირველი ტრანშის თარიღი:</td>
                <td className={classes.detail}>
                  {applicationInformation?.loan_information?.first_disbursement_date
                    ? dateFormat(applicationInformation?.loan_information?.first_disbursement_date, "yyyy-mm-dd", "T")
                    : "-"}
                  {applicationInformation?.loan_information?.first_disbursement_date && canModifyScheduling ? (
                    <IconButton
                      variant="contained"
                      size="small"
                      color="secondary"
                      href="/"
                      onClick={(event) => deleteDisbursementDate(event, applicationInformation._id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                </td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>სუბსიდირების დასრულების თარიღი:</td>
                <td className={classes.detail}>
                  {applicationInformation?.loan_information?.subsidy_finish_date
                    ? dateFormat(applicationInformation?.loan_information?.subsidy_finish_date, "yyyy-mm-dd", "T")
                    : "-"}
                  {applicationInformation?.loan_information?.subsidy_finish_date && window.location.pathname.split("/")[1] === "universal-application" && (
                    <IconButton variant="contained" size="small" color="secondary" onClick={() => setEditSubsidyFinishDateDialogOpen(true)}>
                      <EditOutlined />
                    </IconButton>
                  )}
                  <Dialog
                    open={editSubsidyFinishDateDialogOpen}
                    onClose={() => setEditSubsidyFinishDateDialogOpen(false)}
                    PaperProps={{
                      component: "form",
                      onSubmit: () => {},
                    }}
                  >
                    <DialogTitle>სუბსიდირების დასრულების თარიღი</DialogTitle>
                    <DialogContent>
                      <TextField
                        id="date"
                        label="სუბსიდირების დასრულების ახალი თარიღი"
                        type="date"
                        onChange={(e) => setNewSubsidyFinishDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "100%" }}
                      />
                      <TextField id="comment" label="კომენტარი" onChange={(e) => setNewSubsidyFinishDateComment(e.target.value)} style={{ width: "100%" }} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setEditSubsidyFinishDateDialogOpen(false)}>გაუქმება</Button>
                      <Button type="submit" onClick={(event) => changeSubsidyFinishDate(event, applicationInformation._id)}>
                        შეცვლა
                      </Button>
                    </DialogActions>
                  </Dialog>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography className={classes.cardSummary}>ვადაგადაცილებული დღეები</Typography>
          {typeof applicationInformation?.loan_information?.due_days === "number" ? (
            <table className={classes.table}>
              <tbody>
                <tr className={classes.row}>
                  <td className={classes.title}>ვადაგადაცილებული დღეების რაოდენობა:</td>
                  <td className={classes.detail}>{applicationInformation?.loan_information?.due_days}</td>
                </tr>
                <tr className={classes.row}>
                  <td className={classes.title}>თარიღი:</td>
                  <td className={classes.detail}>{dateFormat(applicationInformation?.loan_information?.due_date, "mm/dd/yyyy", "T")}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <Alert
              style={{
                width: "100%",
              }}
              severity="info"
            >
              ვადაგადაცილებული დღეები არ არის მითითებული
            </Alert>
          )}
        </>

        <Typography className={classes.cardSummary}>გრაფიკის ტოლერანტობის თარიღი</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.title}>გრაფიკის ტოლერანტობის თარიღი:</td>
              <td className={classes.detail}>
                {applicationInformation?.schedule_tolerancy_date ? dateFormat(applicationInformation?.schedule_tolerancy_date, "mm/dd/yyyy", "T") : null}
              </td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>მიზეზი:</td>
              <td className={classes.detail}>{applicationInformation?.schedule_tolerancy_reason}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>გრაფიკის ტოლერანტობის თარიღის შეცვლა:</td>
              <td className={classes.detail}>
                <TextField
                  id="date"
                  label="გრაფიკის ტოლერანტობის თარიღი"
                  type="date"
                  onChange={(e) => setNewDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      <Box
        //Fit both children in one row
        display="flex"
        flexDirection="row"
      >
        {applicationInformation?.schedule_tolerancy_date ? (
          <Alert
            style={{
              width: "100%",
            }}
            severity="warning"
          >
            გრაფიკის ტოლერანტობის თარიღი {dateFormat(applicationInformation?.schedule_tolerancy_date, "mm/dd/yyyy", "T")}
          </Alert>
        ) : (
          <Alert
            style={{
              width: "100%",
            }}
            severity="info"
          >
            გრაფიკის ტოლერანტობის თარიღი არ არის შეყვანილი
          </Alert>
        )}
        <Button variant="outlined" color="primary" onClick={handleDateChange}>
          დამატება
        </Button>
      </Box>
    </Card>
  );
}
