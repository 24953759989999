import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import MonitoringProvider from '../MonitoringProvider';
import MonitoringLayout from '../../components/Layouts/MonitoringLayout';

interface MonitoringRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const MonitoringRoute: React.FC<MonitoringRouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <MonitoringLayout>
        <MonitoringProvider>
          <Component {...props} />
        </MonitoringProvider>
      </MonitoringLayout>
    )} />
  );
};

export default MonitoringRoute;