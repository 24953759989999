import { PostData } from "./PostData";
import { Tooltip } from "@material-ui/core";
import WorldBankIcon from "../assets/icons/world_bank.png";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import ErrorIcon from "@material-ui/icons/Error";
import ForwardIcon from "@material-ui/icons/Forward";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BlockIcon from "@material-ui/icons/Block";
import dateFormat from "dateformat";
import axios from "axios";
import { Receipt } from "@material-ui/icons";

export default function SetTableData({
  setIsLoading,
  setTableData,
  filterData,
  isBank,
  setApprovedApplicationsCount,
  setReviewApplicationsCount,
  tabValue,
  setPages,
}) {
  const itemsOnPage = filterData.amountOnPage || 15;
  setIsLoading(true);
  setTableData([]);
  switch (tabValue) {
    case 0:
      filterData.application_type = filterData.legacy_mortgage ? "legacy_mortgage" : "mortgage";
      break;
    case 1:
      filterData.application_type = "industrial";
      break;
    case 2:
      filterData.application_type = "universal";
      break;
    default:
      break;
  }
  axios.post("/applications", filterData).then((res) => {
    let updatedTableData = [];
    let approvalStatus;
    setIsLoading(false);
    if (!isBank) {
      setApprovedApplicationsCount(res.data.approved_applications);
      setReviewApplicationsCount(res.data.review_applications);
    }
    res.data.applications.map((result, index) => {
      if (result.suspended) {
        approvalStatus = { name: "გაუქმებული", icon: <RemoveCircleIcon key={index} color="error" /> };
      } else {
        if (typeof result.approved === "undefined") {
          approvalStatus = {
            name: "განსახილველი",
            icon: <CachedIcon key={index} color="secondary" />,
          };
        } else {
          approvalStatus = result.approved
            ? {
                name: "დადასტურებული",
                icon: <CheckIcon key={index} color="primary" />,
              }
            : { name: "უარყოფილი", icon: <ErrorIcon key={index} color="error" /> };
        }
      }
      if (result.world_bank?.subsidy_mechanism) {
        approvalStatus.icon = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {approvalStatus.icon}
            <img src={WorldBankIcon} alt="World Bank Icon" style={{ marginLeft: "5px" }} />
          </div>
        );
      }
      if (result.credit_guarantee_scheme) {
        approvalStatus.icon = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {approvalStatus.icon}
            <Tooltip title="საკრედიტო საგარანტიო სქემით სარგებლობა">
              <Receipt />
            </Tooltip>
          </div>
        );
      }
      if (result.refinancing_information?.loan_id) {
        approvalStatus.icon = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {approvalStatus.icon}
            <Tooltip title={`რეფინანსირებული სესხი: ${result.refinancing_information.loan_id}`}>
              <ForwardIcon color="primary" />
            </Tooltip>
          </div>
        );
      }
      if (result.closure?.reason) {
        approvalStatus.icon = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {approvalStatus.icon}
            <Tooltip title={`დახურვის მიზეზი: ${result.closure.reason}`}>
              <BlockIcon color="error" />
            </Tooltip>
          </div>
        );
      }

      updatedTableData.push({
        numeration: (filterData.page - 1) * itemsOnPage + index + 1,
        _id: result?._id,
        purpose: result?.purpose?.name || result?.program_type?.name,
        loan_id: result?.loan_information.loan_id,
        applicant_name: result?.borrower_information?.borrower.name || result?.applicant_information?.name,
        applicant_pid: result?.borrower_information?.borrower?.private_number || result?.applicant_information?.identification_code,
        approved_amount: result?.loan_information?.amount?.toLocaleString() || result?.loan_information?.conditions?.approved_amount?.toLocaleString(),
        submit_date: dateFormat(result?.submit_date, "yyyy-mm-dd", "T"),
        bank: result?.bank?.name,
        status: approvalStatus?.name,
        icon: approvalStatus.icon,
        approval_history: result.approval_history,
        time_left:
          // if (result.approved), then return latest .date in approval_history
          // else go to logic below
          //Round down submit_date to 0 hours, add 7 days, subtract current date rounded to 0 hours, convert to days
          //If more than 7 days have passed, return 0
          //If less than 1 day left, return "დღეს"
          //Else return days left
          result.approved && result.approval_history.length > 0
            ? dateFormat(result.approval_history[result.approval_history.length - 1].date, "yyyy-mm-dd", "T")
            : Math.floor(
                (new Date(result.submit_date).setHours(0, 0, 0, 0) + 7 * 24 * 60 * 60 * 1000 - new Date().setHours(0, 0, 0, 0)) / (24 * 60 * 60 * 1000)
              ) > 7
            ? 0
            : Math.floor(
                (new Date(result.submit_date).setHours(0, 0, 0, 0) + 7 * 24 * 60 * 60 * 1000 - new Date().setHours(0, 0, 0, 0)) / (24 * 60 * 60 * 1000)
              ) < 1
            ? "დღეს"
            : Math.floor(
                (new Date(result.submit_date).setHours(0, 0, 0, 0) + 7 * 24 * 60 * 60 * 1000 - new Date().setHours(0, 0, 0, 0)) / (24 * 60 * 60 * 1000)
              ),
        latest_schedule: result?.schedules?.length > 0 ? result?.schedules[result?.schedules?.length - 1] : null,
      });
    });
    setPages(res.data.pages);
    setTableData(updatedTableData);
  });
}
