import React from "react";
import { FormControl, FormControlLabel, Checkbox, TextField, Modal, Box, Link, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";

export default function GreenTaxonomy({ formik, classes, errorMessages }) {
  // Checkbox 1 - სებ-ის მწვანე ტაქსონომიის მიხედვით კლასიფიცირდება მწვანე სესხად
  // Checkbox 2 - გაიარა სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი)
  // Checkbox 3 - არ გაუვლია სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი)
  const neitherAreSelectedCondition =
    !formik.values.green_taxonomy.classified_as_green_loan &&
    !formik.values.green_taxonomy.passed_screening &&
    !formik.values.green_taxonomy.did_not_pass_screening;
  return (
    <>
      <b>
        <a
          href="https://nbg.gov.ge/page/%E1%83%9B%E1%83%93%E1%83%92%E1%83%A0%E1%83%90%E1%83%93%E1%83%98-%E1%83%93%E1%83%90%E1%83%A4%E1%83%98%E1%83%9C%E1%83%90%E1%83%9C%E1%83%A1%E1%83%94%E1%83%91%E1%83%98%E1%83%A1-%E1%83%A2%E1%83%90%E1%83%A5%E1%83%A1%E1%83%9D%E1%83%9C%E1%83%9D%E1%83%9B%E1%83%98%E1%83%90"
          target="_blank"
          rel="noopener noreferrer"
        >
          მწვანე ტაქსონომია
        </a>
      </b>
      <FormControl variant="outlined" className={classes.formControl} required>
        <FormControlLabel
          control={
            <Checkbox
              required={neitherAreSelectedCondition}
              checked={formik.values.green_taxonomy.classified_as_green_loan}
              onChange={formik.handleChange}
              name="green_taxonomy.classified_as_green_loan"
              color="primary"
            />
          }
          label="სებ-ის მწვანე ტაქსონომიის მიხედვით კლასიფიცირდება მწვანე სესხად"
        />

        <FormControlLabel
          control={
            <Checkbox
              required={neitherAreSelectedCondition}
              checked={formik.values.green_taxonomy.passed_screening}
              onChange={formik.handleChange}
              name="green_taxonomy.passed_screening"
              color="primary"
            />
          }
          label="გაიარა სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი) და არ კვალიფიცირდება მწვანე სესხად"
        />

        <FormControlLabel
          control={
            <Checkbox
              required={neitherAreSelectedCondition}
              checked={formik.values.green_taxonomy.did_not_pass_screening}
              onChange={formik.handleChange}
              name="green_taxonomy.did_not_pass_screening"
              color="primary"
            />
          }
          label="არ გაუვლია სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი)"
        />
      </FormControl>
    </>
  );
}
