import { useState } from "react";
import { Box, Modal, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InfoIcon from "@material-ui/icons/Info";
import dateFormat from "dateformat";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function LoanIDHistoryModal({ history }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <InfoIcon onClick={handleOpen} color="primary" title="სესხის ID" fontSize="small" />
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="h6" id="modal-modal-title" component="div">
            სესხის ID-ს ისტორია
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>თარიღი</TableCell>
                  <TableCell>
                    სესხის ID <br />
                    (განახლებამდე)
                  </TableCell>
                  <TableCell>მომხმარებელი</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{dateFormat(row.date, "yyyy-mm-dd", "T")}</TableCell>
                    <TableCell>{row.loan_id}</TableCell>
                    <TableCell>{row.user?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
}
