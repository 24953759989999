/**
 * @React
 */
import React, { FC, ReactNode, useEffect, useState } from "react";
import axios from "axios";
/**
 * @Router
 */
import { useHistory, useLocation, useParams } from "react-router-dom";
/**
 * @Router
 */
import { FormProvider, set, useForm, useFormContext, UseFormReturn } from "react-hook-form";
/**
 * @Yup
 */
import * as yup from "yup";
/**
 * @MUI
 */
import { Box, Skeleton, CardContent, Card, Button, Alert } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
/**
 * @Context
 */
import { useGeneralInfoContext, useMonitoringFormContext } from "../../../context";

/**
 * @Components
 */
import { MonitoringForm } from "../../../components/FormComponent";
import AutoComplete from "../../../components/CustomComponents/AutoComplete";
import SelectCategory from "../../../components/Selection";
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
  IMonitoringFields,
  IInputField,
  IMonitoringAgreementModel,
  IFieldGroup,
  IGroup,
  INaceActivity,
  ICountry,
  MonitoringFieldsOptionType,
  MyType,
  TransformedData,
  MonitoringFieldsOptionsData,
  IDifferenceFromAgreement,
} from "../../../types";
/**
 * @Utilities
 */
import { addOptions, generateDetailsFields, updateMonitoringFormFields } from "../../../utilities/Fields/monitoringFields/utils";
import { monitoringGroupFieldsInitValues } from "../../../utilities/Fields/monitoringFields/fields/forms";
import { dependencies, fieldValues, optionsValueDependencies, valueDependencies } from "../../../utilities/Fields/monitoringFields/monitoringDeps";
import CardFieldSet from "../../../components/CustomComponents/CardFieldSet";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../../hooks";
import MonitoringLayout from "../../../components/MonitoringLayout";
import { moitoringDetailsFields } from "../../../utilities/Fields/monitoringFields/fields/detailFields";
import {
  MonitoringDetails,
  MonitoringLogsDetails
} from "../../../components/detailedApplication/MonitoringApplication";
import { monitoringInitialDataSchema } from "../../../utilities/Fields/monitoringFields/fields/forms/validation";

interface ConnectFormProps {
  children: (methods: UseFormReturn) => ReactNode;
}

const MonitoringApplicationDetailPage: FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [monitoringGroupFieldsInit, setMonitoringGroupFieldsInit] = useState<any[] | null>(null);
  const [fields, setFields] = useState<{ updatedFields: any[]; dependencies: any | null } | null>(null);
  const [approvingLoader, setApprovingLoader] = useState<boolean>(false);
  const {
    data,
    load: loading,
    error: detailsError,
  } = useFetchData<(IMonitoringFields & Partial<IMonitoringAgreementModel>) | null>(generateUrl(config.client, "getApplicant", { id: id }, null));
  const [bool, setBool] = useState<boolean>(false);
  let {
    options: GeneralInfo,
    setOptions: setGeneralInfoOptions,
    generatedValues: GeneralInfoValues,
    setGeneratedValues: setGeneralInfoValues,
    data: GeneralInfoOptions,
    load: GeneralInfoLoad,
    error: GeneralInfoError,
  } = useGeneralInfoContext();

  useEffect(() => {
    if (data && moitoringDetailsFields && GeneralInfoValues) {
      setBool(true);
      let updated = generateDetailsFields<any, keyof IMonitoringFields & Partial<IMonitoringAgreementModel>>(
        moitoringDetailsFields as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[],
        data as IMonitoringFields & Partial<IMonitoringAgreementModel>,
        GeneralInfoValues,
        dependencies,
        fieldValues,
        valueDependencies
      );
      setFields(updated);
      setBool(false);
    }
  }, [data, moitoringDetailsFields, GeneralInfoValues]);
  const applicationApproval = async (approval_status: boolean) => {
    try {
      setApprovingLoader(true);
      if (!data?.monitoring_approval) {
        await monitoringInitialDataSchema.validate(data, {
          abortEarly: false,
        })
      }
      let comment = ""
      if (!approval_status) {
        comment = prompt("კომენტარი") as string;
        if (!comment) {
          return;
        }
      }
      if (!window.confirm("არჩევანის დადასტურება")) {
        return;
      }
      await axios
        .post("/monitoring/application_approval", { application_id: id, approval: approval_status, comment })
        .then((res) => {
          alert(res.data.message);
          window.location.reload();
        });
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const firstError = err.inner[0];
        const errorMessage = firstError.message;
        alert(`${errorMessage}`);
      }
      if (err?.response?.data?.message) {
        alert(err.response.data.message);
      } else {
        alert("დაფიქსირდა შეცდომა, გთხოვთ მიმართოთ ვებ-გვერდის ადმინისტრატორს")
      }
    } finally {
      setApprovingLoader(false);
    }
  }

  return (
    <>
      {
        bool || GeneralInfoLoad || loading || approvingLoader ? (
          <Loader />
        ) : (
          <Card sx={{ width: "100%", margin: "15px auto 10px auto" }}>
            <CardContent>
              <h4 style={{ textAlign: "center" }}>
                ჩატარებული მონიტორინგის დეტალური ინფორმაცია
              </h4>
              {
                loading && (
                  <>
                    <Loader />
                  </>
                )
              }
              {
                detailsError && <Alert severity="error">დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს</Alert>
              }
              {
                fields
                &&
                data
                &&
                <MonitoringDetails
                  details={fields.updatedFields}
                  dependencies={fields.dependencies}
                  data={data}
                />
              }
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                gap: "50px"
              }}>
                <Button
                  disabled={
                    approvingLoader || data?.monitoring_approval === true}
                  onClick={() => {
                    applicationApproval(true);
                  }}
                  variant="contained"
                  color="success"
                  startIcon={<ThumbUpAltIcon />}
                >
                  დადასტურება
                </Button>
                <Button
                  disabled={approvingLoader}
                  onClick={() => {
                    applicationApproval(false);
                  }}
                  variant="contained"
                  color="error"
                  startIcon={<ThumbDownAltIcon />}
                >
                  დახარვეზება
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
    </>
  );
};

export default MonitoringApplicationDetailPage;



// axios
//   .post("/monitoring/application_approval", { application_id: id, approval: approval_status })
//   .then((res) => {
//     alert(res.data.message);
//     window.location.reload();
//   })
//   .catch((err) => {
//     if (err.response.data.message) {
//       alert(err.response.data.message);
//     } else {
//       alert("შეცდომა");
//     }
//   });
