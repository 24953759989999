import axios from "axios";
import { useState } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import LoanIDHistoryModal from "../modals/LoanIDHistoryModal";

export default function LoanID({ isBankHead, application }) {
  const [open, setOpen] = useState(false);
  const [newLoanID, setNewLoanID] = useState(application?.loan_information?.loan_id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmission = () => {
    axios
      .post("/bank/application/change_id", {
        loanID: application?._id,
        component: window.location.pathname.split("/")[1],
        newID: newLoanID,
      })
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>სესხის ID-ს ცვლილება</DialogTitle>
        <DialogContent>
          <DialogContentText>სესხის ID-ს ცვლილება მოხდება მომენტალურად. ცვლილება შეინახება სესხის ისტორიაში.</DialogContentText>
          <TextField
            defaultValue={application?.loan_information?.loan_id}
            value={newLoanID}
            onChange={(e) => setNewLoanID(e.target.value)}
            autoFocus
            margin="dense"
            id="loan_id"
            label="ახალი სესხის ID"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>გაუქმება</Button>
          <Button onClick={handleSubmission}>შეცვლა</Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        {isBankHead && application?.approved && (
          <div style={{ margin: "0 2px 0 0" }}>
            <IconButton
              style={{ padding: "0", margin: "0" }}
              color="primary"
              aria-label="edit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        <p>{application?.loan_information?.loan_id}</p>
        {application?.loan_information?.loan_id_history?.length > 0 && <LoanIDHistoryModal history={application?.loan_information?.loan_id_history} />}
      </div>
    </>
  );
}
