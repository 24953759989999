export default function MonitoringLayout({ children }: { children: React.ReactNode }) {
    return (
        <body>
            <main
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // minHeight: "100vh",
                }}
            >
                {children}
            </main>
        </body>
    );
}
