import { DataGrid } from "@mui/x-data-grid";
import dateFormat from "dateformat";

//Create component showcasing all payments using only material ui.
export default function PaymentTable({ rows }) {
  const columns = [
    {
      field: "bank",
      headerName: "ბანკი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.bank.name;
      },
    },
    { field: "actual_amount", headerName: "რეალურად გადარიცხული თანხა", flex: 2, headerAlign: "center", align: "center" },
    { field: "calculated_amount", headerName: "სოფტის მიერ გამოთვლილი თანხა", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "payment_date",
      headerName: "გადახდის თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.payment_date, "yyyy-mm-dd", true);
      },
    },
    {
      field: "upload_date",
      headerName: "ატვირთვის თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.upload_date, "yyyy-mm-dd");
      },
    },
  ];

  return (
    <div style={{ marginTop: 30, height: 400, width: "100%" }}>
      <DataGrid getRowId={(row) => row._id} rows={rows} columns={columns} pageSize={100} rowsPerPageOptions={[5]} disableColumnMenu hideFooter />;
    </div>
  );
}
