import {
  COMPONENTS,
  CURRENCY,
  EcoTourismType,
  EnterpriseCategory,
  ICountry,
  IFieldGroup,
  IInputFieldOption,
  IMonitoringAgreementModel,
  IMonitoringFields,
  IMonitoringHotelFields,
  Program,
  ProgramComponent,
  PROJECT_STATUS,
  TourismType,
} from "../../../../types";

export const monitoring_initial_data: IMonitoringFields & Partial<IMonitoringAgreementModel> = {
  // created_by: null,
  _id: "",
  contract_number: "",
  company_identification_code: "",
  company_name: "",
  monitoring_approval: false,
  bank: "",
  program: Program.RESETVALUE,
  enterprise_category: EnterpriseCategory.RESETVALUE,
  contract_signed_date: null,
  first_tranche_date: null,
  start_activity_date: null,
  program_component: ProgramComponent.RESETVALUE,
  component: COMPONENTS.RESETVALUE,
  approved_amount: 0,
  currency: CURRENCY.RESETVALUE,
  purpose: "",
  jobs_created: 0,
  employee_amount: 0,
  new_hotel_rooms: 0,
  hotel_rooms: 0,
  project_status: PROJECT_STATUS.RESETVALUE,
  project_activities: [],
  actual_municipality: "",
  is_sign_inscription_banner: false,
  actual_address: "",
  representative_name: "",
  representative_phone: "",
  representative_phone_secondary: "",
  representative_email: "",
  representative_position: "",
  representative_id_number: "",
  company_email: "",
  company_founder_phone: "",
  used_amount: 0,
  fixed_assets_capital_expenditures: 0,
  machine_equipment_equipment: 0,
  vehicles_special_technic: 0,
  purchase_building_land: 0,
  building_repairs: 0,
  funds_turnover: 0,
  last_year_actives: 0,
  last_year_income: 0,
  last_year_paid_taxes: 0,
  worked_jobs_detail_description: "",
  company_full_investment_volume: 0,
  is_external_capital: false,
  external_capital_source: "",
  is_social_industry: false,
  social_industry_description: "",
  is_exporter: false,
  product_export: "",
  is_using_brand: false,
  used_brand: "",
  country_export: [],
  is_functional: false,
  none_functional_reason: "",
  stop_functional_activity_date: null,
  is_started_operating_within_project: true,
  start_beneficiar_activity_date:null,
  is_same_company_profile_activity:true,
  other_profile:'',
  contract_based_employee_amount: 0,
  woman_employee_amount: 0,
  man_employee_amount: 0,
  is_lack_of_personnel: false,
  is_lack_of_administration: false,
  administration: false,
  marketing: false,
  accounting: false,
  finances: false,
  hr: false,
  sales: false,
  is_other_administration: false,
  other_administration: "",
  is_production_service_direction: false,
  lack_of_production_service_direction: "",
  is_person_disability: false,
  employeed_person_disability_amount: 0,
  is_building_adapted_for_person_disability: false,
  none_person_disability_reason_comment: "",
  director_women_amount: 0,
  founder_women_amount: 0,
  founder_women_amount_percentage: 0,
  is_building_rented: true,
  cadastral_code: "",
  hotel_information: {
    satisfies_criteria: false,
    elevator: false,
    elevator_absence_reason: "",
    utilities_criteria: false,
    noise_conditioning: false,
    fire_safety: false,
    disabled_criteria: false,
    reception_criteria: false,
    catering: false,
    reason_comment: "",
  },
  agro_tourism_information: {
    placement: false,
    catering: false,
    educational: false,
    recreational: false,
    is_other_activity: false,
    other_activity: "",
    farming_property: false,
    food_safety: false,
    registered_farm: false,
  },
  eco_tourism_information: {
    type: EcoTourismType.RESETVALUE,
    placement: {
      existing_building: false,
      mutliple_guests: false,
      multiple_rooms: false,
    },
    artisan: {
      existing_workshop: false,
      demonstrational_area: false,
      masterclass_area: false,
    },
    nature: {
      non_motorized_transport: {
        bicycle: false,
        horse: false,
        water: false,
        seasonal_sled: false,
        is_other: false,
        other: "",
      },
      accessories: {
        safety: false,
        inventory: false,
        is_other: false,
        other: "",
      },
    },
  },
  tourist_services_information: {
    type: TourismType.RESETVALUE,
    adventure_tourism_measures: {
      guest_life_insurance: false,
      group_service: false,
      individual_service: false,
    },
    historic_simulation: false,
    elevator: false,
    elevator_absence_reason: "",
    utilities_criteria: false,
    noise_conditioning: false,
    fire_safety: false,
    disabled_criteria: false,
    catering: false,
    reason_comment: "",
  },
  difference_from_agreement: [],
};
