import { Typography, Card, CardContent } from "@material-ui/core";

export default function GreenTaxonomyTable({ classes, applicationInformation }) {
  return (
    <Card className={classes.component} variant="outlined">
      <CardContent>
        <Typography className={classes.cardSummary}>მწვანე ტაქსონომია</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.title}>სებ-ის მწვანე ტაქსონომიის მიხედვით კლასიფიცირდება მწვანე სესხად:</td>
              <td className={classes.detail}>{applicationInformation?.green_taxonomy?.classified_as_green_loan ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>გაიარა სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი) და არ კვალიფიცირდება მწვანე სესხად:</td>
              <td className={classes.detail}>{applicationInformation?.green_taxonomy?.passed_screening ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>არ გაუვლია სებ-ის მწვანე ტაქსონომიის მიხედვით შემოწმება (სკრინინგი):</td>
              <td className={classes.detail}>{applicationInformation?.green_taxonomy?.did_not_pass_screening ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
