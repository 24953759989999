import React from "react";
import { Grid, Typography, LinearProgress, Box, Card } from "@mui/material";

interface TransportActivityApplicationsSum {
  approved: number;
  rejected: number;
  review: number;
  suspended: number;
}

interface UniversalTransportActivitySumProps {
  data: TransportActivityApplicationsSum;
}

const formatNumber = (num: number): string => {
  return num.toLocaleString();
};

const UniversalTransportActivitySum: React.FC<UniversalTransportActivitySumProps> = ({ data }) => {
  const approvedAndReview = data.approved + data.review;
  const maxValue = 50000000;

  return (
    <Card
      elevation={0}
      sx={{
        padding: 2,
      }}
    >
      <Typography variant="h6" sx={{ margin: 2 }}>
        49.39.0 სატრანსპორტო საქმეების მონაცემები
      </Typography>
      <Grid container spacing={2}>
        {/* Approved Applications */}
        <Grid item xs={12} sm={4}>
          <Card
            elevation={0}
            sx={{
              padding: 2,
              backgroundColor: "#e8f5e9", // Light green background
            }}
          >
            <Typography variant="subtitle1" color="green">
              დადასტურებული
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatNumber(data.approved)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(data.approved / maxValue) * 100}
              sx={{
                marginTop: 1,
                backgroundColor: "#c8e6c9",
                "& .MuiLinearProgress-bar": { backgroundColor: "green" },
              }}
            />
            <Typography variant="caption" display="block">
              {((data.approved / maxValue) * 100).toFixed(2)}% of 50,000,000
            </Typography>
          </Card>
        </Grid>
        {/* Review Applications */}
        <Grid item xs={12} sm={4}>
          <Card
            elevation={0}
            sx={{
              padding: 2,
              backgroundColor: "#f5f5f5", // Light gray background
            }}
          >
            <Typography variant="subtitle1" color="gray">
              განსახილველი
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatNumber(data.review)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(data.review / maxValue) * 100}
              sx={{
                marginTop: 1,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": { backgroundColor: "gray" },
              }}
            />
            <Typography variant="caption" display="block">
              {((data.review / maxValue) * 100).toFixed(2)}% of 50,000,000
            </Typography>
          </Card>
        </Grid>
        {/* Suspended Applications */}
        <Grid item xs={12} sm={4}>
          <Card
            elevation={0}
            sx={{
              padding: 2,
              backgroundColor: "#ffebee", // Light red background
            }}
          >
            <Typography variant="subtitle1" color="red">
              გაუქმებული
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatNumber(data.suspended)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(data.suspended / maxValue) * 100}
              sx={{
                marginTop: 1,
                backgroundColor: "#ffcdd2",
                "& .MuiLinearProgress-bar": { backgroundColor: "red" },
              }}
            />
            <Typography variant="caption" display="block">
              {((data.suspended / maxValue) * 100).toFixed(2)}% of 50,000,000
            </Typography>
          </Card>
        </Grid>
        {/* Total Applications */}
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Card elevation={0} sx={{ padding: 2, backgroundColor: "#e3f2fd" }}>
            <Typography variant="subtitle1" color="primary">
              დადასტურებული და განსახილველი
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              {formatNumber(approvedAndReview)}
            </Typography>
            <LinearProgress variant="determinate" value={(approvedAndReview / maxValue) * 100} sx={{ marginTop: 1 }} />
            <Typography variant="caption" display="block">
              {((approvedAndReview / maxValue) * 100).toFixed(2)}% of 50,000,000
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UniversalTransportActivitySum;
