import {
  COMPONENTS,
  CURRENCY,
  EcoTourismType,
  EnterpriseCategory,
  ICountry,
  IFieldGroup,
  IInputFieldOption,
  IMonitoringAgreementModel,
  IMonitoringFields,
  IMonitoringHotelFields,
  Program,
  ProgramComponent,
  PROJECT_STATUS,
} from "../../../../../../types";
import * as yup from "yup";

export const monitoringInitialDataSchema: yup.ObjectSchema<any> = yup.object().shape({
  company_name: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის დასახელება."),
  company_identification_code: yup
    .string()
    .required("გთხოვთ, მიუთითოთ, კომპანიის კოდი.")
    .matches(/^\d{9}$|^\d{11}$/, "კომპანიის საიდენტიფიკაციოს ნომრის ფორმატი არ არის ვალიდური"),
  contract_number: yup.string().required("გთხოვთ, მიუთითოთ ბენეფიციარ კომპანიასთან გაფორმებული ხელშეკრულების ნომერი/დამტკიცებული სესხის ID."),
  contract_signed_date: yup.date().required("გთხოვთ, მიუთითოთ, ბენეფიციარ კომპანიასთან გაფორმებული ხელშეკრულების გაფორმების/დადასტურების თარიღი."),
  project_status: yup.string().required("გთხოვთ, აირჩიოთ პროექტის სტატუსი."),
  program: yup.string().required("გთხოვთ, აირჩიოთ სახელმწიფო პროგრამის დასახელება."),
  program_component: yup.string().required("გთხოვთ, აირჩიოთ სახელმწიფო პროგრამის კომპონენტი."),
  component: yup.string().required("გთხოვთ, აირჩიოთ სესხი / ლიზინგი."),
  bank: yup.string().required("გთხოვთ, აირჩიოთ ფინ. ინსტიტუტის დასახელება."),
  approved_amount: yup
    .number()
    .required("გთხოვთ, მიუთითოთ ფინ. ინსტიტუტის მიერ დამტკიცებული თანხა.")
    .min(1, "გთხოვთ, მიუთითოთ ფინ. ინსტიტუტის მიერ დამტკიცებული თანხა."),
  currency: yup.string().required("გთხოვთ, აირჩიოთ ვალუტა."),
  used_amount: yup.number().required("გთხოვთ, მიუთითოთ ათვისებული თანხის მოცულობა.").min(1, "გთხოვთ, მიუთითოთ ათვისებული თანხის მოცულობა."),
  fixed_assets_capital_expenditures: yup.number().when("program_component", {
    is: (program: string) =>
      ![
        ProgramComponent.CREDIT_GUARANTEE_COMPONENT,
      ].includes(program as ProgramComponent),
    then: yup
      .number()
      .required("გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები.")
      .min(1, "გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები."),
    otherwise: yup
      .number()
      .required("გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები.")
      .min(0, "გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები."),
  }),
  funds_turnover: yup.number().required("გთხოვთ, მიუთითოთ საბრუნავი საშუალებები.").min(0, "გთხოვთ, მიუთითოთ საბრუნავი საშუალებები."),
  enterprise_category: yup.string().required("გთხოვთ, მიუთითოთ ბენეფიციარი კომპანიის კატეგორია შემოსავლების კომპონენტში."),
  last_year_actives: yup
    .number()
    .required("გთხოვთ, მიუთითოთ აქტივები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით.")
    .min(1, "გთხოვთ, მიუთითოთ აქტივები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით."),
  last_year_income: yup
    .number()
    .required("გთხოვთ, მიუთითოთ შემოსავლები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით.")
    .min(1, "გთხოვთ, მიუთითოთ შემოსავლები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით."),
  last_year_paid_taxes: yup
    .number()
    .required("გთხოვთ, მიუთითოთ გადასახადები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით.")
    .min(0, "გთხოვთ, მიუთითოთ გადასახადები კომპანიის ფინანსური მონაცემები წინა საანგარიშგებო წლის მონაცემებით."),
  worked_jobs_detail_description: yup
    .string()
    .required(" გთხოვთ, მიუთითოთ პროექტის ფარგლებში ბენეფიციარი კომპანიის მხრიდან შესრულებული სამუშაოების დეტალური აღწერა"),
  company_full_investment_volume: yup
    .number()
    .required("გთხოვთ, მიუთითოთ ხელშეკრულების ფარგლებში კომპანიის მხრიდან განხორციელებული სრული ინვესტიციის მოცულობა - ₾.")
    .min(1, "გთხოვთ, მიუთითოთ ხელშეკრულების ფარგლებში კომპანიის მხრიდან განხორციელებული სრული ინვესტიციის მოცულობა - ₾."),
  external_capital_source: yup.string().when(["is_external_capital"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ გარე კაპიტალის წყარო.");
    }
    return field.notRequired();
  }),
  is_social_industry: yup.boolean(),
  social_industry_description: yup.string().when(["is_social_industry"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ სოციალური ტიპის საწარმოს აღწერა.");
    }
    return field.notRequired();
  }),
  is_exporter: yup.boolean(),
  country_export: yup.array().of(yup.string().required("გთხოვთ მიუთითოთ ქვეყანა.")),
  product_export: yup.string().when(["is_exporter"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ საექსპორტო პროდუქციის ჩამონათვალი.");
    }
    return field.notRequired();
  }),
  is_using_brand: yup.boolean(),
  used_brand: yup.string().when(["is_using_brand"], (a: boolean[], field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ ბრენდის სახელწოდება.");
    }
    return field.notRequired();
  }),
  is_functional: yup.boolean(),
  stop_functional_activity_date: yup.date().when("is_functional", {
    is: (is_functional: boolean) => is_functional,
    then: yup
      .date()
      .required("გთხოვთ, მიუთითოთ პროექტის ფარგლებში ფუნქციონირების შეწყვეტის თარიღი.")
      .typeError("გთხოვთ, მიუთითოთ პროექტის ფარგლებში ფუნციონირების შეწყვეტის თარიღი."),
    otherwise: yup.date().strip(),
  }),
  none_functional_reason: yup.string().when(["is_functional"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ პროექტის ფარგლებში ფუნცქიონირების შეწყვეტის მიზეზი.");
    }
    return field.notRequired();
  }),
  is_started_operating_within_project: yup.boolean(),
  start_beneficiar_activity_date: yup.date().when("is_started_operating_within_project", {
    is: (is_started_operating_within_project: boolean) => is_started_operating_within_project,
    then: yup
      .date()
      .required("გთხოვთ, მიუთითოთ პროექტის ფარგლებში საქმიანობის დაწყების თარიღი")
      .typeError("გთხოვთ, მიუთითოთ პროექტის ფარგლებში საქმიანობის დაწყების თარიღი"),
    otherwise: yup.date().strip(),
  }),
  is_same_company_profile_activity: yup.boolean(),
  other_profile: yup.string().when(["is_same_company_profile_activity"], (a: boolean, field) => {
    if (!a) {
      return field.required("კომპანიას შენარჩუნებული არ აქვს საქმიანობის პროფილი და გთხოვთ მიუთითოთ კომენტარი ამის თაობაზე");
    }
    return field.notRequired();
  }),
  start_activity_date: yup
    .date()
    .required("გთხოვთ, მიუთითოთ ხელშეკრულების ფარგლებში განხორციელებული საქმიანობის დაწყების თარიღი.")
    .typeError("გთხოვთ, მიუთითოთ ხელშეკრულების ფარგლებში განხორციელებული საქმიანობის დაწყების თარიღი."),
  project_activities: yup.array().of(yup.string().required("გთხოვთ მიუთითოთ NACE კოდი.")),
  woman_employee_amount: yup
    .number()
    .required("გთხოვთ, მიუთითოთ ქალი თანამშრომელების დასაქმებულთა რაოდენობა.")
    .min(0, "გთხოვთ, მიუთითოთ ქალი თანამშრომელების დასაქმებულთა რაოდენობა."), // ??
  man_employee_amount: yup
    .number()
    .required("გთხოვთ, მიუთითოთ კაცი თანამშრომელების დასაქმებულთა რაოდენობა.")
    .min(0, "გთხოვთ, მიუთითოთ კაცი თანამშრომელების დასაქმებულთა რაოდენობა."), // ??
  employee_amount: yup.number().required("გთხოვთ, მიუთითოთ დასაქმებულთა არსებული რაოდენობა."),
  jobs_created: yup
    .number()
    .required("ხელშეკრულების ფარგლებში შექმნილი სამუშაო ადგილების რაოდენობა")
    .min(0, "ხელშეკრულების ფარგლებში შექმნილი სამუშაო ადგილების რაოდენობა"),
  is_lack_of_personnel: yup.boolean(),
  is_lack_of_administration: yup.boolean(),
  // .when(["is_lack_of_personnel", "is_production_service_direction"], {
  //   is: (is_lack_of_personnel: boolean, is_production_service_direction: boolean) => {
  //     return !is_lack_of_personnel;
  //   },
  //   then: yup.boolean().required("გთხოვთ, მიუთითოთ პროექტის ფარგლებში ფუნქციონირების შეწყვეტის თარიღი."),
  //   otherwise: yup.boolean().strip(),
  // }),
  is_production_service_direction: yup.boolean(),
  is_other_administration: yup.boolean(),
  lack_of_production_service_direction: yup.string().when(["is_production_service_direction"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ პროფესიის/მიმართულების კადრები.");
    }
    return field.notRequired();
  }),
  other_administration: yup.string().when(["is_other_administration"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ სხვა ადმინისტრაციული კადრი.");
    }
    return field.notRequired();
  }),
  is_person_disability: yup.boolean(),
  employeed_person_disability_amount: yup.number().when(["is_person_disability"], (a: boolean, field) => {
    if (a) {
      return field.required("გთხოვთ, მიუთითოთ დასაქმებული შშმ პირების რაოდენობა.").min(1, "გთხოვთ, მიუთითოთ დასაქმებული შშმ პირების რაოდენობა");
    }
    return field.notRequired();
  }),
  is_building_adapted_for_person_disability: yup.boolean(),
  none_person_disability_reason_comment: yup.string().when(["is_building_adapted_for_person_disability"], (a: boolean, field) => {
    if (!a) {
      return field.required("გთხოვთ, მიუთითოთ ბენეფიციარის სამეურნეო/ადმინისტრაციული შენობის შშმ პირებზე ადაპტირების არ ქონის მიზეზი.");
    }
    return field.notRequired();
  }),
  director_women_amount: yup.number().required("გთხოვთ, მიუთითოთ დირექტორი ქალ(ებ)ის რაოდენობა").min(0, "გთხოვთ, მიუთითოთ დირექტორი ქალ(ებ)ის რაოდენობა"),
  founder_women_amount: yup.number().required("გთხოვთ, მიუთითოთ დამფუძნებელი ქალ(ებ)ის რაოდენობა").min(0, "გთხოვთ, მიუთითოთ დამფუძნებელი ქალ(ებ)ის რაოდენობა"),
  founder_women_amount_percentage: yup
    .number()
    .required("გთხოვთ, მიუთითოთ დამფუძნებელი ქალ(ებ)ის ჯამური წილობრივი მონაწილეობა")
    .min(0, "გთხოვთ, მიუთითოთ დამფუძნებელი ქალ(ებ)ის ჯამური წილობრივი მონაწილეობა"),
  actual_municipality: yup
    .string()
    .required("გთხოვთ, მიუთითოთ კომპანიის საქმიანობის განხორციელების რეგიონი/მუნიციპალიტეტი.")
    .typeError("გთხოვთ, მიუთითოთ კომპანიის საქმიანობის განხორციელების რეგიონი/მუნიციპალიტეტი."),
  actual_address: yup.string().required("გთხოვთ, მიუთითოთ მისამართი(სოფელი/დაბა/ქუჩა/ნომერი)"),
  cadastral_code: yup.string().required("გთხოვთ, მიუთითოთ საკადასტრო კოდი"),
  representative_name: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის სახელი და გვარი"),
  representative_phone: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის ტელ. ნომერი"),
  representative_position: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის პოზიცია"),
  representative_id_number: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის პირადი ნომერი"),
  representative_email: yup.string().email("ელ. ფოსტა არ არის ვალიდური").required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის ელ. ფოსტა"),
  hotel_rooms: yup.number().when("program_component", {
    is: (program: string) =>
      [
        ProgramComponent.HOTEL_INDUSTRY,
        ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY,
        ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY,
      ].includes(program as ProgramComponent),
    then: yup.number().required("გთხოვთ, მიუთითოთ სასტუმროს ნომრების სრული რაოდენობა").min(0, "გთხოვთ, მიუთითოთ სასტუმროს ოთახების სრული რაოდენობა"),
    otherwise: yup.number().strip(),
  }),
  new_hotel_rooms: yup.number().when("program_component", {
    is: (program: string) =>
      [
        ProgramComponent.HOTEL_INDUSTRY,
        ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY,
        ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY,
      ].includes(program as ProgramComponent),
    then: yup.number().required("გთხოვთ, მიუთითოთ სასტუმროს ოთახების სრული რაოდენობა").min(1, "გთხოვთ, მიუთითოთ სასტუმროს ოთახების სრული რაოდენობა"),
    otherwise: yup.number().strip(),
  }),
  hotel_information: yup.object().when("program_component", {
    is: (program: string) =>
      [
        ProgramComponent.HOTEL_INDUSTRY,
        ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY,
        ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY,
      ].includes(program as ProgramComponent),
    then: yup.object().shape({
      satisfies_criteria: yup.boolean(),
      utilities_criteria: yup.boolean(),
      noise_conditioning: yup.boolean(),
      fire_safety: yup.boolean(),
      disabled_criteria: yup.boolean(),
      reception_criteria: yup.boolean(),
      catering: yup.boolean(),
      elevator: yup.boolean(),
      elevator_absence_reason: yup.string().when(["elevator"], (a: boolean, field) => {
        if (!a) {
          return field.required("გთხოვთ, მიუთითოთ ლიფტის არ არსებობის მიზეზი");
        }
        return field.notRequired();
      }),
      reason_comment: yup
        .string()
        .when(["satisfies_criteria", "utilities_criteria", "noise_conditioning", "fire_safety", "disabled_criteria", "reception_criteria", "catering"], {
          is: (
            satisfies_criteria: boolean,
            utilities_criteria: boolean,
            noise_conditioning: boolean,
            fire_safety: boolean,
            disabled_criteria: boolean,
            reception_criteria: boolean,
            catering: boolean
          ) => !satisfies_criteria || !utilities_criteria || !noise_conditioning || !fire_safety || !disabled_criteria || !reception_criteria || !catering,
          then: yup.string().required(" გთხოვთ, მიუთითოთ კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი"),
          otherwise: yup.string().notRequired(),
        }),
    }),
    otherwise: yup.object().strip(), // Strip the field if condition is not met
  }),
  tourist_services_information: yup.object().when("program_component", {
    is: (program: string) => [ProgramComponent.TOURISM_SERVICES_INDUSTRY].includes(program as ProgramComponent),
    then: yup.object().shape({
      type: yup.string().required("გთხოვთ, აირჩიოთ ტურისტული სერვისის ტიპი"),
      utilities_criteria: yup.boolean(),
      noise_conditioning: yup.boolean(),
      fire_safety: yup.boolean(),
      disabled_criteria: yup.boolean(),
      catering: yup.boolean(),
      elevator: yup.boolean(),
      elevator_absence_reason: yup.string().when(["elevator"], (a: boolean, field) => {
        if (!a) {
          return field.required("გთხოვთ, მიუთითოთ ლიფტის არარსებობის მიზეზი");
        }
        return field.notRequired();
      }),
      reason_comment: yup.string().when(["utilities_criteria", "noise_conditioning", "fire_safety", "disabled_criteria", "catering"], {
        is: (utilities_criteria: boolean, noise_conditioning: boolean, fire_safety: boolean, disabled_criteria: boolean, catering: boolean) =>
          !utilities_criteria || !noise_conditioning || !fire_safety || !disabled_criteria || !catering,
        then: yup.string().required(" გთხოვთ, მიუთითოთ კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი"),
        otherwise: yup.string().notRequired(),
      }),
    }),
    otherwise: yup.object().strip(),
  }),
});