import React, { useEffect, useRef, useState } from 'react';
import {
    Stack,
    Box,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    TextareaAutosize,
    ThemeProvider,
    createTheme,
    OutlinedInput,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Autocomplete,
    FormHelperText,
} from '@mui/material';
import { IInputField, IInputFieldOption, IMonitoringAgreementModel, IMonitoringFields } from '../../../types';
import { Controller, useFormContext, Control } from 'react-hook-form';

interface CustomInputProps<T> {
    newField: T;
    control: Control<any>;
    disabled?: boolean;
    customChange?: (data: IMonitoringFields & Partial<IMonitoringAgreementModel>) => void;
    watchedValues?: Record<string, any>;
    errors?: any;
    style?: React.CSSProperties;
}




const CustomInput: React.FC<CustomInputProps<IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>>> = ({
    newField,
    customChange,
    control,
    disabled = false,
    watchedValues,
    errors,
    style,
}) => {
    const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        backgroundColor: "inherit",
                        borderWidth: "1px",
                        minWidth: "150px",
                        '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
                            color: "rgba(0, 0, 0, 0.70)",
                            ...(style && { ...style })
                        }
                    },
                },
            },
        },
    });
    const methods = useFormContext();
    const { setValue, getValues } = methods;
    const [focused, setFocused] = useState(false);
    let {
        name,
        label,
        type,
        readonly,
        mapDependentValue,
        callBackFn,
        mapOptions,
    } = newField;
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, field: any) => {
        const newValue = e.target.checked;
        field.onChange(newValue);
        const currentValues = getValues();
        if (mapDependentValue) {
            mapDependentValue(currentValues as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                (n, v) => setValue(n, v),
            );
        }
        const changedValues = getValues()
        if (customChange) {
            customChange(changedValues as any);
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: any) => {
        const newValue = e.target.value;
        field.onChange(newValue);
        let temp = getValues();
        if (callBackFn) {
            callBackFn(temp as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                (n: string, v: any) => setValue(n, v),
            );
        }
        const changedValues = getValues();
        if (customChange) {
            customChange(changedValues as any);
        }
    };
    const handleSelectChange = (event: SelectChangeEvent<string>, field: any) => {
        const selectedValue = event.target.value;
        field.onChange(selectedValue);
        const currentValues = getValues();
        if (mapDependentValue) {
            mapDependentValue(currentValues as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                (n, v) => setValue(n, v),
            );
        }
        const changedValues = getValues()
        if (customChange) {
            customChange(changedValues as any);
        }
    };

    const handleAutocompleteChange = (field: any, selectedValue: { value: string; label: string; }) => {
        if (selectedValue) {
            field.onChange(selectedValue.value);
        }
        const currentValues = getValues();
        if (mapDependentValue) {
            mapDependentValue(currentValues as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                (n, v) => setValue(n, v),
            );
        }
        const changedValues = getValues();
        if (customChange) {
            customChange(changedValues as any);
        }
    };


    const inputFields = () => {
        switch (type) {
            case 'autocomplete':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => {
                            return (
                                <Autocomplete
                                    {...field}
                                    {...newField}
                                    disabled={disabled}
                                    // defaultValue={newField.autocompleteOptions ? newField.autocompleteOptions[newField.value] : null}
                                    // isOptionEqualToValue={(option, value) => {
                                    //     return option.value === value.value
                                    // }}
                                    // disableClearable
                                    sx={{
                                        width: "100%",
                                        height: '45px',
                                        padding: '2px'
                                    }}
                                    options={newField.autocompleteOptions ? Object.values(newField.autocompleteOptions) as { value: string; label: string; }[] : []}
                                    autoHighlight
                                    getOptionLabel={(option) => {
                                        return option.label
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                key={option.value}
                                                component="li"
                                                sx={
                                                    {
                                                        '& > img': { mr: 2, flexShrink: 0 },
                                                        fontSize: '12px'
                                                    }
                                                }
                                                {...props}>
                                                {option.label}
                                            </Box>
                                        )
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                {...field}
                                                disabled={disabled}
                                                label={label}
                                                size='small'
                                                error={errors && errors.message ? true : false}
                                                helperText={errors && errors.message ? errors.message : ''}
                                                value={params.inputProps.value ?? 'tt'}
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "&:focus": {
                                                            outline: `2px solid 'red'`,
                                                        },
                                                        "&:hover fieldset": {
                                                            color: "#1976d2",
                                                            borderColor: "#1976d2",
                                                            borderWidth: "2px",
                                                        },
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: "1rem",
                                                        top: '-2px',
                                                    },
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    sx: {
                                                        height: '20px',
                                                        padding: '2px',
                                                        fontSize: 12,
                                                    }
                                                }}
                                            // InputProps={{
                                            //     ...params.InputProps,
                                            //     type: 'search',
                                            //   }}

                                            />
                                        )
                                    }}
                                    filterOptions={(options, state) => {
                                        if (state.inputValue.length > 2) {
                                            return options.filter((item) =>
                                                String(item.label).toLowerCase().includes(state.inputValue.toLowerCase())
                                            );
                                        }
                                        return options;
                                    }}
                                    value={newField.autocompleteOptions ? newField.autocompleteOptions[newField.value] : null}
                                    onChange={(_, data) => handleAutocompleteChange(field, data as { value: string; label: string; })}
                                    componentsProps={{
                                        clearIndicator: {
                                            sx: {
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: '0.8rem', // Change the size of the clear button here
                                                },
                                            },
                                        },
                                    }}
                                />
                            )
                        }}
                    />
                );
            case 'select':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={newField.value}
                        render={({ field }) => {
                            return (
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        minWidth: 120,
                                        fontSize: '12px',
                                        "& .MuiOutlinedInput-root": {
                                            "&:focus": {
                                                outline: `2px solid 'red'`,
                                            },
                                            "&:hover fieldset": {
                                                color: "#1976d2",
                                                borderColor: "#1976d2",
                                                borderWidth: "2px",
                                            },
                                        },
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '2px',
                                            fontSize: focused || newField.value === false || newField.value ? '1rem' : '0.8rem',
                                            top: '-5px',
                                        }}
                                    >
                                        {newField.label ?? ''}
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            '& .MuiSelect-select': {
                                                fontSize: '12px',
                                            },
                                            hover: {
                                                borderColor: 'red'
                                            }

                                        }}
                                        size='small'
                                        error={errors && errors.message ? true : false}
                                        input={
                                            <OutlinedInput
                                                label={label}
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "&:focus": {
                                                            outline: `2px solid 'red'`,
                                                        },
                                                        "&:hover fieldset": {
                                                            color: "#1976d2",
                                                            borderColor: "#1976d2",
                                                            borderWidth: "2px",
                                                        },
                                                    },
                                                }}
                                            />}
                                        {...field}
                                        {...newField}
                                        disabled={disabled}
                                        onChange={(e) => handleSelectChange(e, field)}

                                    >
                                        {
                                            newField.options && newField.options.map((option: IInputFieldOption) => {
                                                return (
                                                    <MenuItem
                                                        sx={{ fontSize: '12px' }}
                                                        key={option.label}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                    {errors && errors.message && (
                                        <FormHelperText
                                            sx={{
                                                fontSize: '12px',
                                                color: '#d32f2f',
                                            }}
                                        >{errors.message}</FormHelperText>
                                    )}
                                </FormControl>
                            )
                        }}
                    />
                )

            case 'checkbox':
                return (
                    <FormControlLabel
                        control={
                            <Controller
                                name={name}
                                control={control}
                                defaultValue={false}
                                render={({ field }) => {
                                    return (
                                        <Checkbox
                                            {...field}
                                            {...newField}
                                            size='small'
                                            sx={{
                                                fontSize: '12px',
                                            }}
                                            checked={newField.value ? newField.value : false}
                                            onChange={(e) => handleCheckboxChange(e, field)}
                                            color="primary"
                                        />
                                    )
                                }}
                            />
                        }
                        label={label}
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '12px', // Change this to your desired font size
                            },
                        }}
                    />
                );
            case 'textarea':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={newField.value ?? ''}
                        render={({ field }) => {
                            return (
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        {...field}
                                        {...newField}
                                        disabled={disabled}
                                        type={"text"}
                                        name={name}
                                        label={label}
                                        error={errors && errors.message ? true : false}
                                        helperText={errors && errors.message ? errors.message : ''}
                                        multiline
                                        rows={4}
                                        value={newField.value}
                                        onChange={(e) => handleChange(e, field)}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    color: "#1976d2",
                                                    borderColor: "#1976d2",
                                                    borderWidth: "2px",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "1rem",
                                                top: '-2px',
                                            },
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                fontSize: 12,
                                                padding: '15px',
                                                transition: "font-size 0.2s, color 0.2s",
                                                style: {
                                                    fontSize: 12,
                                                },
                                                onBlur: () => setFocused(false),
                                                onFocus: () => setFocused(true),
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }}
                    />
                );
            case 'date':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={''}
                        render={({ field }) => {
                            return (
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        {...field}
                                        {...newField}
                                        disabled={disabled}
                                        value={newField.value ? new Date(newField.value).toISOString().split('T')[0] : null}
                                        size="small"
                                        type='date'
                                        fullWidth
                                        onChange={(e) => handleChange(e, field)}
                                        error={errors && errors.message ? true : false}
                                        helperText={errors && errors.message ? errors.message : ''}
                                        sx={{
                                            // "&:hover fieldset": {
                                            //     borderColor: "red",
                                            // },
                                            "& .MuiOutlinedInput-root": {
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        // color: "red",
                                                        borderWidth: "2px",
                                                    },
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "1rem",
                                                top: '-2px',
                                            },
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            sx: {
                                                height: '40px',
                                                padding: '2px',
                                            },
                                            inputProps: {
                                                fontSize: 12,
                                                min: 0,
                                                style: {
                                                    fontSize: 12,
                                                },
                                                onBlur: () => setFocused(false),
                                                onFocus: () => setFocused(true),
                                                color: "rgba(0, 0, 0, 0.87)",
                                                step: "1",
                                                padding: "10px",
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }}
                    />
                );
            case 'number':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => {
                            return (
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        {...field}
                                        {...newField}
                                        size="small"
                                        type='number'
                                        disabled={disabled}
                                        error={errors && errors.message ? true : false}
                                        helperText={errors && errors.message ? errors.message : ''}
                                        fullWidth
                                        onChange={(e) => handleChange(e, field)}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:focus": {
                                                    outline: `2px solid 'red'`,
                                                },
                                                "&:hover fieldset": {
                                                    color: "#1976d2",
                                                    borderColor: "#1976d2",
                                                    borderWidth: "2px",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "1rem",
                                                top: '-2px',
                                            },
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            sx: {
                                                height: '40px',
                                                padding: '2px',
                                            },
                                            inputProps: {
                                                fontSize: 12,
                                                transition: "font-size 0.2s, color 0.2s",
                                                min: 0, // Set the minimum value to 0
                                                style: {
                                                    fontSize: 12,
                                                },
                                                onBlur: () => setFocused(false),
                                                onFocus: () => setFocused(true),
                                                color: "rgba(0, 0, 0, 0.87)",
                                                step: "1",
                                                padding: "10px",
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }}
                    />
                );
            case 'text':
                return (
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={newField.value ?? ''}
                        render={({ field }) => {
                            return (
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        {...field}
                                        {...newField}
                                        disabled={disabled}
                                        size="small"
                                        fullWidth
                                        type='text'
                                        error={errors && errors.message ? true : false}
                                        helperText={errors && errors.message ? errors.message : ''}
                                        onChange={(e) => handleChange(e, field)}
                                        sx={{
                                            // "&:hover fieldset": {
                                            //     borderColor: "rgba(0, 0, 0, 1)",
                                            // },
                                            // lineHeight: '1.5rem',
                                            // height: '50px',
                                            // "&:focus": {
                                            //     outline: `2px solid 'red'`,
                                            // },
                                            "& .MuiOutlinedInput-root": {
                                                // "&:focus": {
                                                //     outline: `2px solid 'red'`,
                                                // },
                                                // "& fieldset": {
                                                //     color: "#1976d2",
                                                //     border: "2px solid '#1976d2'",
                                                //     borderWidth: "1px",
                                                // },
                                                "&:hover fieldset": {
                                                    color: "#1976d2",
                                                    borderColor: "#1976d2",
                                                    borderWidth: "2px",
                                                },
                                                // "&.Mui-focused fieldset": {
                                                //     borderColor: "rgba(0, 0, 0, 0.23)",
                                                //     borderWidth: "2px",
                                                // },
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "1rem",
                                                // color: 'rgb(44, 91, 148, 0.5)',
                                                // lineHeight: '1.5rem',
                                                top: '-2px',
                                            },
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            sx: {
                                                // color: "rgba(0, 0, 0, 0.8)",
                                                height: '40px',
                                                padding: '2px',
                                            },
                                            inputProps: {
                                                fontSize: 12,
                                                transition: "font-size 0.2s, color 0.2s",
                                                min: 0, // Set the minimum value to 0
                                                style: {
                                                    fontSize: 12,
                                                },
                                                onBlur: () => setFocused(false),
                                                onFocus: () => setFocused(true),
                                                color: "rgba(0, 0, 0, 0.87)",
                                                step: "1",
                                                padding: "10px",
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }}
                    />
                );
        }
    }
    return inputFields();
};

export default CustomInput;

