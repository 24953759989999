/**
 * @React
 */
import React, {
    FC,
    ReactNode,
    useEffect,
    useState
} from "react";
/**
 * @Router
 */
import { useHistory, useParams } from 'react-router-dom';

/**
 * @MUI
 */
import {
    CardContent,
    Card
} from "@mui/material";
/**
 * @Hooks
 */
import { useFetchData } from "../../../hooks";
/**
 * @Components
 */
import Loader from "../../../components/Loader";
import MonitoringLayout from "../../../components/MonitoringLayout";
/**
 * @Types
 */
import {
    IMonitoringFields,
    IMonitoringAgreementModel,
} from "../../../types";
/**
 * @Utilities
 */
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";



const MonitoringUpdateForm: FC = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [bool, setBool] = useState<boolean>(false);
    const [submitReload, setSubmitReload] = useState<boolean>(false);
    const { data, load, error } = useFetchData<IMonitoringFields & Partial<IMonitoringAgreementModel> | null>(generateUrl(config.client, 'getApplicant', { id: id }, null));

    if (load || submitReload || bool) {
        return <Loader />;
    }
    if (error) {
        return <h1>დაფიქსირდა შეცდომა</h1>
    }
    if (!data) {
        // return null; // or some fallback UI
        alert('data')
        return <h1>Something went wrong...</h1>; // or some fallback UI
    }
    return (
        <>
            {
                (submitReload || bool || load)
                    ? <Loader /> : <Card sx={{ width: "100%", margin: "15px auto 10px auto" }}>
                        <CardContent>
                            {
                                data
                                &&
                                <MonitoringLayout
                                    data={data}
                                    load={load}
                                    setSubmitReload={setSubmitReload}
                                    bool={bool}
                                    setBool={setBool}
                                />
                            }
                        </CardContent>
                    </Card>
            }

        </>
    )
}

export default MonitoringUpdateForm;
