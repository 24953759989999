/**
 * @React
 */
import {
    useState,
    useEffect,
    FC
} from "react";
/**
 * @ThirdPartProgramms
 */
import dateFormat from "dateformat";
/**
 * @Mui
 */
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
/**
 * @Utilities
 */
import DetailedStyles from "../../../../utilities/DetailedStyles";
import { fields, generateValueFormat } from "../../../../utilities/Fields/monitoringFields/fields/changesFields";
/**
 * @Types
 */
import { IDifferenceFromAgreement } from "../../../../types";


interface IMonitoringDetailsProps {
    changedDataHistory: IDifferenceFromAgreement[];
}

const MonitoringLogsDetails: FC<IMonitoringDetailsProps> = ({
    changedDataHistory
}: IMonitoringDetailsProps) => {
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 640 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)"
                            }} align="left"><b>ველის დასახელება</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)"
                            }} align="left"><b>საწყისი მონაცემები</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)"
                            }} align="left"><b>კორექტირებული მონაცემები</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)"
                            }} align="left"><b>ცვლილების განხორციელების თარიღი</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            changedDataHistory.map((row, index) => (
                                <TableRow
                                    key={`${index}`}
                                >
                                    <TableCell align="left">{fields[row.field as keyof typeof fields] ? fields[row.field as keyof typeof fields].title : row.field}</TableCell>
                                    <TableCell align="left">{fields[row.field as keyof typeof fields] && fields[row.field as keyof typeof fields].type === "date" ? generateValueFormat[row.field as keyof typeof generateValueFormat](row.from as any) : row.from}</TableCell>
                                    <TableCell align="left">{fields[row.field as keyof typeof fields] && fields[row.field as keyof typeof fields].type === "date" ? generateValueFormat[row.field as keyof typeof generateValueFormat](row.to as any) : row.to}</TableCell>
                                    <TableCell align="left">
                                        {row.changedAt ? `${dateFormat(row.changedAt, "dd/mm/yyyy HH:MM:ss")}` : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default MonitoringLogsDetails;
/**
 *   const columns = [
    {
      field: "bank",
      headerName: "ბანკი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.bank.name;
      },
    },
    { field: "amount", headerName: "თანხა", flex: 2, headerAlign: "center", align: "center" },
    { field: "interest_amount", headerName: "პროცენტი", flex: 2, headerAlign: "center", align: "center" },
    { field: "principal_amount", headerName: "ძირი", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "date",
      headerName: "თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.date, "yyyy-mm-dd", true);
      },
    },
  ];

  return (
    <div style={{ marginTop: 30, height: 400, width: "100%" }}>
      <DataGrid getRowId={(row) => row._id} rows={rows} columns={columns} pageSize={100} rowsPerPageOptions={[5]} disableColumnMenu hideFooter />;
    </div>
  );
}
 */
