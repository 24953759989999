import React, { ChangeEvent } from "react";
import { useField } from "formik";
import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";

interface SelectFieldProps {
    name: string;
    value: string;
    label: string;
    options: string[];
    callback?: (value: number, name?: string) => void;
}

const SelectCategory: React.FC<SelectFieldProps> = ({
    name,
    label,
    value,
    options,
    callback,
}: SelectFieldProps) => {
    const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (callback) {
            callback && callback(event.target.value as unknown as number, name);
        }
    };
    return (
        <FormControl fullWidth>
            <TextField
                InputLabelProps={{
                    style: {
                        fontSize: "14px",
                    },
                    shrink: true,
                }}
                select
                size="small"
                label={label}
                value={value}
                onChange={handleSelectChange}
                variant="outlined"
                InputProps={{
                    sx: { fontSize: "14px" },
                }}
            >
                {options.map((option: string) => (
                    <MenuItem sx={{ fontSize: "12px" }} key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default SelectCategory;
