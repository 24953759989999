import { useState, useEffect } from 'react';
import axiosInstance from '../../utilities/axios';
 interface ApiResponse<T> {
  data: T | null;
  load: boolean;
  error: any;
}
const useFetchData = <T>(url: string): ApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get<T>(url);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoad(false);
      }
    };
    if(url){
      fetchData();
    }
    
    return () => {
    //  cancelTokenSource.cancel('Request canceled by cleanup'); // maybe it would be needed
    };
  }, [url]);

  return { data, load, error };
};

export default useFetchData;
