import {
  Modal as MuiModal,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { PostData } from "../../utilities/PostData";
import { useState, useEffect } from "react";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import axios from "axios";

function Modal({ modalOpen, setModalOpen, user, userId, userPermissions, isBank, generalData }) {
  const [tabValue, setTabValue] = useState(0);
  const [isAdmin, setIsAdmin] = useState();
  const [mortgagePermission, setMortgagePermission] = useState();
  const [addUserPermission, setAddUserPermission] = useState();
  const [businessPermission, setBusinessPermission] = useState();
  const [userActive, setUserActive] = useState(false);
  const [agencyDepartment, setAgencyDepartment] = useState();
  const [agencyLevel, setAgencyLevel] = useState();

  const agencyPositions = [
    { label: "სოციალური რისკების სპეციალისტი", value: -2 },
    { label: "გარემოსდაცვითი სპეციალისტი", value: -1 },
    { label: "მონიტორინგი", value: 0 },
    { label: "დეპ. თანამშრომელი", value: 1 },
    { label: "დეპ. უფროსი", value: 2 },
    { label: "სააგენტოს დირექტორი", value: 3 },
  ];
  const classes = useStyles();

  function handleUserDeactivation(deactivate) {
    axios
      .post("/auth/deactivation", { userId, deactivate })
      .then((res) => {
        user.deactivated = !user.deactivated;
        setUserActive((prevState) => !prevState);
        alert(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert("კავშირის შეცდომა");
        }
      });
  }

  const handleAgencyInfoChange = () => {
    axios
      .post("/auth/change_agency_details", { userId, newAgencyLevel: agencyLevel, newAgencyDepartment: agencyDepartment })
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert("შეცდომა");
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("isAdmin")) {
      setIsAdmin(true);
    }
    setUserActive(!user.deactivated);
  }, [user]);

  useEffect(() => {
    if (userPermissions?.includes("SUBMIT_MORTGAGE_APPLICATIONS")) {
      setMortgagePermission(true);
    } else {
      setMortgagePermission(false);
    }
    if (userPermissions?.includes("SUBMIT_BUSINESS_APPLICATIONS")) {
      setBusinessPermission(true);
    } else {
      setBusinessPermission(false);
    }
    if (userPermissions?.includes("ADD_USER")) {
      setAddUserPermission(true);
    } else {
      setAddUserPermission(false);
    }
  }, [userPermissions]);

  useEffect(() => {
    // set agency department and level (if user is agency)
    if (user.agencyDepartment) {
      setAgencyDepartment(user.agencyDepartment);
    }
    if (user.agencyLevel) {
      setAgencyLevel(user.agencyLevel);
    }
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const changePermissions = useFormik({
    initialValues: {},
    onSubmit: (values, { resetForm }) => {
      resetForm();
      values = { submit_mortgage: mortgagePermission, submit_business: businessPermission, add_user: addUserPermission, user_id: userId };
      PostData("auth/change_permissions", values)
        .then((res) => {
          alert(res.message);
        })
        .catch((err) => {
          alert("დაფიქსირდა შეცდომა");
        });
    },
  });

  const changePassword = useFormik({
    initialValues: { new_password: "" },
    onSubmit: (values, { resetForm }) => {
      resetForm();
      values = { ...values, user_id: userId };
      PostData("admin/change_user_password", values)
        .then((res) => {
          alert(res.message);
        })
        .catch((err) => {
          alert("დაფიქსირდა შეცდომა");
        });
    },
  });
  return (
    <MuiModal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      className={classes.modal}
    >
      <Card className={classes.card}>
        <Tabs value={tabValue < 2 ? tabValue : false} onChange={handleTabChange} variant="fullWidth" indicatorColor="secondary" textColor="primary">
          <Tab icon={<SubtitlesIcon />} label="უფლებები" />
          <Tab icon={<SubtitlesIcon />} label="სააგენტო" disabled={!user.agencyDepartment} />
          {isAdmin ? <Tab icon={<VpnKeyIcon />} label="პაროლი" /> : null}
        </Tabs>
        <CardContent className={classes.CardContent}>
          {tabValue === 0 ? (
            <>
              <form className={classes.form} onSubmit={changePermissions.handleSubmit}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addUserPermission}
                        onChange={(event) => {
                          setAddUserPermission(event.target.checked);
                        }}
                        name="ADD_USER"
                      />
                    }
                    label="ბანკჰედი"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mortgagePermission}
                        onChange={(event) => {
                          setMortgagePermission(event.target.checked);
                        }}
                        name="SUBMIT_MORTGAGE_APPLICATIONS"
                      />
                    }
                    label="იპოთეკური აპლიკაციების დამატება"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={businessPermission}
                        onChange={(event) => {
                          setBusinessPermission(event.target.checked);
                        }}
                        name="SUBMIT_BUSINESS_APPLICATIONS"
                      />
                    }
                    label="ინდუსტრიული და უნივერსალური აპლიკაციების დამატება"
                  />
                </FormControl>
                <Button style={{ marginTop: 25 }} color="primary" variant="contained" fullWidth type="submit">
                  შეცვლა
                </Button>
                {user.deactivated ? (
                  <Button style={{ marginTop: 25 }} color="primary" variant="contained" fullWidth onClick={() => handleUserDeactivation(false)}>
                    მომხმარებლის აქტივაცია
                  </Button>
                ) : (
                  <Button style={{ marginTop: 25 }} color="info" variant="contained" fullWidth onClick={() => handleUserDeactivation(true)}>
                    მომხმარებლის დეაქტივაცია
                  </Button>
                )}
              </form>
            </>
          ) : tabValue === 1 ? (
            <>
              <FormControl fullWidth>
                <InputLabel htmlFor="agency_department">დეპარტამენტი</InputLabel>
                <Select
                  native
                  value={agencyDepartment}
                  onChange={(event) => {
                    setAgencyDepartment(event.target.value);
                  }}
                  inputProps={{
                    name: "agency_department",
                    id: "agency_department",
                  }}
                >
                  {generalData.agency_departments.map((department) => {
                    return (
                      <option key={department._id} value={department._id}>
                        {department.title}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel htmlFor="agency_level">დონე</InputLabel>
                <Select
                  native
                  value={agencyLevel}
                  onChange={(event) => {
                    setAgencyLevel(event.target.value);
                  }}
                  inputProps={{
                    name: "agency_level",
                    id: "agency_level",
                  }}
                >
                  {agencyPositions.map((position) => {
                    return (
                      <option key={position.value} value={position.value}>
                        {position.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                style={{ marginTop: 25 }}
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  handleAgencyInfoChange();
                }}
              >
                შეცვლა
              </Button>
            </>
          ) : (
            <>
              <form onSubmit={changePassword.handleSubmit} className={classes.form}>
                <Typography>შეიყვანეთ ახალი პაროლი.</Typography>
                <TextField
                  fullWidth
                  id="new_password"
                  name="new_password"
                  label="ახალი პაროლი"
                  value={changePassword.values.new_password}
                  onChange={changePassword.handleChange}
                />
                <Button style={{ marginTop: 25 }} color="primary" variant="contained" fullWidth type="submit">
                  აღდგენა
                </Button>
                <p>მომხმარებლის ID: {userId}</p>
              </form>
            </>
          )}
        </CardContent>
      </Card>
    </MuiModal>
  );
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    margin: "8% 0 0 0",
    justifyContent: "center",
  },
  card: {
    width: "30%",
    height: "40vh",
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
  },
  form: {
    width: "90%",
  },
});

export default Modal;
