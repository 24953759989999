import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Input,
  Button,
} from "@material-ui/core";
import dateFormat from "dateformat";
import axios from "axios";

export default function LegacyGuaranteeInformation({ applicationInformation, classes }) {
  if (!applicationInformation?.guarantee_information) return null;
  const { guarantee_information } = applicationInformation;

  function handleGuaranteeAdd(newGuaranteeInformation) {
    if (!window.confirm("მოთხოვნის გაგზავნა")) return;
    axios
      .post("/agency/legacy_mortgage/change_guarantee", {
        applicationID: applicationInformation._id,
        guaranteeInformation: newGuaranteeInformation,
      })
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  }

  return (
    <Card className={classes.component} variant="outlined">
      <CardContent>
        <Typography className={classes.cardSummary}>გარანტია</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.title}>გარანტია (4%):</td>
              <td className={classes.detail}>{guarantee_information.guarantee}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>მობრუნებული გარანტიის რაოდენობა:</td>
              <td className={classes.detail}>{guarantee_information.returned_guarantee_amount}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>ჩამოჭრილი გარანტიის რაოდენობა:</td>
              <td className={classes.detail}>{guarantee_information.charged_guarantee_amount}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>ჩამოჭრილი გარანტიის მობრუნება:</td>
              <td className={classes.detail}>{guarantee_information.returned_charged_guarantee_amount}</td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>თარიღი</TableCell>
              <TableCell align="right">გარანტია (4%)</TableCell>
              <TableCell align="right">მობრუნებული გარანტიის რაოდენობა</TableCell>
              <TableCell align="right">ჩამოჭრილი გარანტიის რაოდენობა</TableCell>
              <TableCell align="right">ჩამოჭრილი გარანტიის მობრუნება</TableCell>
              <TableCell align="right">მომხმარებელი</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guarantee_information.history.map((row) => (
              <TableRow key={row.date}>
                <TableCell component="th" scope="row" style={{ maxWidth: 50 }}>
                  {dateFormat(row.date, "yyyy-mm-dd", "T")}
                </TableCell>
                <TableCell align="right">{row.guarantee}</TableCell>
                <TableCell align="right">{row.returned_guarantee_amount}</TableCell>
                <TableCell align="right">{row.charged_guarantee_amount}</TableCell>
                <TableCell align="right">{row.returned_charged_guarantee_amount}</TableCell>
                <TableCell align="right">{row.submitted_by?.email.toLowerCase() || "საწყისი"}</TableCell>
              </TableRow>
            ))}
            {/* Enable prompt to add values to table and submit to backend */}
            <TableRow>
              <TableCell component="th" scope="row">
                <TextField
                  id="guarantee_date"
                  //Make shorter input field
                  style={{ maxWidth: 50 }}
                  type="date"
                  defaultValue={dateFormat(new Date(), "yyyy-mm-dd", "T")}
                />
              </TableCell>
              <TableCell align="right">
                <Input id="guarantee" defaultValue={guarantee_information.guarantee} type="number" />
              </TableCell>
              <TableCell align="right">
                <Input id="returned_guarantee_amount" defaultValue={guarantee_information.returned_guarantee_amount} type="number" />
              </TableCell>
              <TableCell align="right">
                <Input id="charged_guarantee_amount" defaultValue={guarantee_information.charged_guarantee_amount} type="number" />
              </TableCell>
              <TableCell align="right">
                <Input id="returned_charged_guarantee_amount" defaultValue={guarantee_information.returned_charged_guarantee_amount} type="number" />
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    handleGuaranteeAdd({
                      date: document.getElementById("guarantee_date").value,
                      guarantee: document.getElementById("guarantee").value,
                      returned_guarantee_amount: document.getElementById("returned_guarantee_amount").value,
                      charged_guarantee_amount: document.getElementById("charged_guarantee_amount").value,
                      returned_charged_guarantee_amount: document.getElementById("returned_charged_guarantee_amount").value,
                    });
                  }}
                  variant="outlined"
                  color="primary"
                >
                  დამატება
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
