export interface IServer {
  protocol: string;
  host: string;
}

export interface IEndpoint {
  method: string;
  uri: IPath;
}

export interface IPath {
  pathname: string;
  params?: Record<string, string>;
}

export interface IClient {
  endpoint: {
    getCompanies: IEndpoint;
    getAgreement: IEndpoint;
    getGeneralInfo: IEndpoint;
    monitoringSubmit: IEndpoint;
    getApplicant: IEndpoint;
    updateApplication: IEndpoint;
    getAgreementById: IEndpoint;
  };
}

// /monitoring/update_application

export interface IConfig {
  client: IClient;
}

//  /monitoring/submit_application /monitoring/monitoring/
export const config: IConfig = {
  client: {
    endpoint: {
      getCompanies: {
        method: "GET",
        uri: {
          pathname: "/monitoring/company_info",
        },
      },
      getAgreement: {
        method: "GET",
        uri: {
          pathname: "/monitoring/agreement",
        },
      },
      getAgreementById: {
        method: "GET",
        uri: {
          pathname: "/monitoring/agreement/:id",
        },
      },
      getApplicant: {
        method: "GET",
        uri: {
          pathname: "/monitoring/application/:id",
        },
      },
      getGeneralInfo: {
        method: "GET",
        uri: {
          pathname: "/general_monitoring_options",
        },
      },
      monitoringSubmit: {
        method: "POST",
        uri: {
          pathname: "/monitoring/submit_application",
        },
      },
      updateApplication: {
        method: "POST",
        uri: {
          pathname: "/monitoring/update_application",
        },
      },
    },
  },
};

// /monitoring/application/:id

export default config;
