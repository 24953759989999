import { TableContainer, TableHead, Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RowStyle from "../../../utilities/FilterRowStyle";
import TableSkeleton from "../../../components/TableSkeleton";

function Universal({ tableData, isBank, filterData, setFilterData, loading, setIsLoading, setApprovedApplicationsCount, setReviewApplicationsCount, page }) {
  const columns = [
    { id: "numeration", label: "" },
    { id: "icon", label: "" },
    { id: "purpose", label: "პროგრამის ტიპი" },
    { id: "loan_id", label: "სესხის/ლიზინგის ID" },
    { id: "applicant_name", label: "დასახელება" },
    { id: "applicant_pid", label: "ს/კ" },
    { id: "approved_amount", label: "დამტკიცებული მოცულობა" },
    { id: "submit_date", label: "გამოგზავნის თარიღი" },
    { id: "bank", label: "ფინ. ინსტიტუტი" },
    { id: "status", label: "სტატუსი" },
    !isBank && { id: "time_left", label: "დადასტურების დრო" },
  ].filter(Boolean);

  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" classes={{ root: classes.tableRoot }}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableSkeleton amount={15} columns={columns} /> : null}
            {tableData.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row._id}
                  onDoubleClick={() => {
                    window.open(`/universal-application/${row._id}`);
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell style={RowStyle(row.approval_history, row.status, isBank)} key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  dateSelector: {
    marginRight: 30,
  },
  filter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "30px 15px",
  },
  filterSelector: {
    minWidth: 120,
  },
  field: {
    margin: "0 30px 0 30px",
  },
});

export default Universal;
