import "./Mortgage.css";
import { GetData } from "../../../utilities/GetData";
import { PostData } from "../../../utilities/PostData";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, InputLabel, Select, FormControl, IconButton, FormControlLabel, Checkbox, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";

function Mortgage() {
  const classes = useStyles();
  const [forceRender, setForceRender] = useState();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState();
  const [purposes, setPurposes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [regionSelected, setRegionSelected] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [legalForms, setLegalForms] = useState([]);
  const [interestTypes, setInterestTypes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [mortgageTypes, setMortgageTypes] = useState([]);
  const [marriageStatuses, setMarriageStatuses] = useState([]);
  const [minorChildrenCounter, setminorChildrenCounter] = useState(1);
  const [coBorrowerCounter, setCoBorrowerCounter] = useState(1);
  const [uploadedAttachments, setUploadedAttachments] = useState({});
  const [isDeveloper, setIsDeveloper] = useState(true);
  const [selectedInterestType, setSelectedInterestType] = useState({});
  const [attachmentButtons, setAttachmentButtons] = useState([]);
  const [isChildAdult, setIsChildAdult] = useState([]);
  const [existingBorrower, setExistingBorrower] = useState({ state: false, message: "" });
  const [existingBorrowerChild, setExistingBorrowerChild] = useState(false);
  const [existingSpouseBorrower, setExistingSpouseBorrower] = useState(false);
  const [currencies, setCurrencies] = useState(["GEL", "USD", "EUR"]);
  const [citizenships, setCitizenships] = useState(["საქართველოს მოქალაქე", "სხვა ქვეყნის მოქალაქე"]);
  const [otherCitizenship, setOtherCitizenship] = useState(false);
  const [spouseOtherCitizenship, setSpouseOtherCitizenship] = useState(false);
  const [selectedOtherPersonLegalForm, setSelectedOtherPersonLegalForm] = useState();
  const [selectedPurposeType, setSelectedPurposeType] = useState();
  const [hasAdoptedChild, setHasAdoptedChild] = useState(false);
  const [error, setError] = useState(false);
  const [attachmentFields, setAttachmentFields] = useState([{ title: "შვილის/გერის დაბადების მოწმობა", field: "children_birth_certificate", canAdd: true }]);
  const [isUploading, setIsUploading] = useState(false);
  const [applicationType, setApplicationType] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let single_mother_certificate = { title: "მარტოხელა მშობლის სტატუსის დამადასტურებელი დოკუმენტი", field: "single_mother_certificate" };
    let spouse_death_certificate = { title: "მეუღლის გარდაცვალების მოწმობა", field: "spouse_death_certificate" };
    let child_adoption_certificate = { title: "შვილად აყვანის მოწმობა", field: "child_adoption_certificate", canAdd: true };
    if (selectedMaritalStatus?.includes("მარტოხელა")) {
      if (attachmentFields.filter((field) => field.title === single_mother_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, single_mother_certificate]);
      }
    }
    if (selectedMaritalStatus?.includes("ქვრივი")) {
      if (attachmentFields.filter((field) => field.title === spouse_death_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, spouse_death_certificate]);
      }
    }
    if (hasAdoptedChild) {
      if (attachmentFields.filter((field) => field.title === child_adoption_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, child_adoption_certificate]);
      }
    }
  }, [selectedMaritalStatus, hasAdoptedChild]);

  async function checkExistingBorrower(private_number) {
    let response = await PostData("check_mortgage_private_number", { private_number: private_number });
    return response;
  }

  async function checkExistingBorrowerChild(private_number) {
    let response = await PostData("check_mortgage_child_private_number", { private_number: private_number });
    return response;
  }

  async function checkAdult(birth_date) {
    let response = await PostData("check_child_age", { birth_date: birth_date });
    return response;
  }

  useEffect(() => {
    setMarriageStatuses(["ოჯახი", "მარტოხელა მშობელი", "ქვრივი"]);
    GetData("general_info")
      .then(async (resp) => {
        setInterestTypes(resp.interest_types);
        setPurposes(resp.purposes);
        setRegions(resp.regions);
        setLegalForms(resp.legal_forms);
        setMortgageTypes(resp.mortgage_program_types);
        setBanks(resp.banks);
      })
      .catch((err) => {});
  }, []);

  function fileChangeHandler(event, index) {
    let newAttachmentFields = [...attachmentFields];
    let newAttachmentField = { ...newAttachmentFields[index] };
    newAttachmentField.file = event.target.files[0];
    newAttachmentFields[index] = newAttachmentField;
    setAttachmentFields(newAttachmentFields);
  }
  function fileSubmitHandler(fieldName, index) {
    if (!attachmentFields[index].file) {
      return;
    }
    if (parseFloat((Math.round(+attachmentFields[index].file.size / 1024) / 1000).toFixed(2)) > 20) {
      alert("ფაილის ზომა აღემატება 20MB-ს.");
      return;
    }
    setIsUploading(true);
    const fileData = new FormData();
    fileData.append(fieldName, attachmentFields[index].file, attachmentFields[index].file.name);
    const newAttachments = uploadedAttachments;
    PostData("bank/application/upload_attachments", fileData, true)
      .then((res) => {
        if (newAttachments[fieldName]) {
          newAttachments[fieldName] = [...newAttachments[fieldName], res[fieldName][0]];
        } else {
          newAttachments[fieldName] = res[fieldName];
        }
        setUploadedAttachments(newAttachments);
        setIsUploading(false);
      })
      .catch((err) => {
        alert("ფაილის ატვირთვისას დაფიქსირდა შეცდომა");
        setIsUploading(false);
      });
  }
  function regionChangeHandler(region) {
    if (region === "") {
      setRegionSelected(false);
      return;
    }
    PostData("fetch_areas", { region: region }).then(async (res) => {
      setMunicipalities(res);
    });
    setRegionSelected(true);
  }

  function childrenAddHandler() {
    setminorChildrenCounter(minorChildrenCounter + 1);
  }

  function childrenRemoveHandler() {
    formik.setFieldValue("children_information", formik.values.children_information.slice(0, -1));
    setminorChildrenCounter(minorChildrenCounter - 1);
  }

  function borrowerAddHandler() {
    setCoBorrowerCounter(coBorrowerCounter + 1);
  }

  function borrowerRemoveHandler() {
    setCoBorrowerCounter(coBorrowerCounter - 1);
  }

  function joinSellerName() {
    formik.setFieldValue(
      "home_information.other_person_information.name",
      formik.values.home_information?.other_person_information?.first_name + " " + formik.values.home_information?.other_person_information?.last_name
    );
  }

  const formik = useFormik({
    initialValues: {
      purpose: "",
      borrower_information: { borrower: {}, borrower_spouse: {}, loan_information: {}, attached_documents: {} },
      children_information: [],
    },
    onSubmit: async (values) => {
      if (existingBorrower.state || existingBorrowerChild || existingSpouseBorrower || isChildAdult[0] || formik.values.loan_information?.amount > 200000) {
        alert("დაფიქსირდა შეცდომა ფორმაში.");
      } else {
        setLoading(true);
        PostData("bank/application/submit_mortgage", values).then((res) => {
          setLoading(false);
          alert(res.message);
        });
      }
    },
  });

  return (
    <div className="mortgage_container">
      <form onSubmit={formik.handleSubmit} className="mortgage_form">
        <b>განაცხადის ტიპი</b>
        <FormControl variant="outlined" className={classes.formControl}>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel className={classes.inputLabel} htmlFor="purpose">
              სესხის მიზნობრიობა
            </InputLabel>
            <Select
              className={classes.field}
              native
              value={formik.values.purpose}
              onChange={(event) => {
                formik.handleChange(event);
                setSelectedPurposeType(purposes.find((type) => type._id === event.target.value));
              }}
              inputProps={{
                name: "purpose",
              }}
            >
              <option aria-label="None" value="" />
              {purposes.map((purpose) => (
                <option className={classes.field} key={purpose._id} value={purpose._id}>
                  {purpose.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.inputLabel} htmlFor="purpose" required>
              განაცხადის ტიპი
            </InputLabel>
            <Select
              className={classes.field}
              native
              value={formik.values.application_type}
              onChange={(e) => {
                if (e.target.value !== "") {
                  formik.handleChange(e);
                  setApplicationType(mortgageTypes.find((type) => type._id === e.target.value));
                }
              }}
              inputProps={{
                name: "application_type",
              }}
            >
              <option aria-label="None" value="" />
              {mortgageTypes.map((type) => (
                <option
                  className={classes.field}
                  key={type._id}
                  value={type._id}
                  disabled={["იპოთეკური სესხი", "რეფინანსირება", "მეორე იპოთეკური სესხი"].indexOf(type.name) === -1}
                >
                  {type.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </FormControl>
        {applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>ინფორმაცია ბინის/სახლის/ასაშენებელი ტერიტორიის შესახებ</b>
            <FormControl variant="outlined" className={classes.formControl} required>
              <InputLabel className={classes.inputLabel} htmlFor="region">
                რეგიონი
              </InputLabel>
              <Select
                className={classes.field}
                native
                value={formik.values.region}
                onChange={
                  (formik.handleChange,
                  (event) => {
                    regionChangeHandler(event.target.value);
                  })
                }
                inputProps={{
                  name: "region",
                }}
              >
                <option aria-label="None" value="" />
                {regions.map((region) => (
                  <option key={region}>{region}</option>
                ))}
              </Select>
              <FormControl variant="outlined" className={classes.formControl} disabled={!regionSelected} required>
                <InputLabel className={classes.inputLabel} htmlFor="municipality">
                  მუნიციპალიტეტი
                </InputLabel>
                <Select
                  className={classes.field}
                  native
                  value={formik.values.home_information?.municipality}
                  onChange={formik.handleChange}
                  inputProps={{
                    name: "home_information.municipality",
                  }}
                >
                  <option aria-label="None" value="" />
                  {municipalities.map((municipality) => (
                    <option key={municipality._id} value={municipality._id}>
                      {municipality.municipality}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="home_information.exact_address"
                label="მის. (სოფელი/დაბა/ქუჩა/ნომერი)"
                onChange={formik.handleChange}
              />
              <TextField
                variant="outlined"
                className={classes.field}
                name="home_information.cadastral_code"
                label="საკადასტრო კოდი"
                onChange={formik.handleChange}
              />
              {!formik.values.home_information?.cadastral_code ? (
                <TextField
                  variant="outlined"
                  multiline
                  className={classes.longerField}
                  name="home_information.cadastral_code_absence_reason"
                  label="საკადასტრო კოდის არარსებობის მიზეზი"
                  onChange={formik.handleChange}
                />
              ) : null}
              {!selectedPurposeType?.name?.includes("აშენება") ? (
                <>
                  <TextField
                    variant="outlined"
                    required
                    className={classes.field}
                    name="home_information.market_value"
                    label="საბაზრო ღირებულება"
                    onChange={formik.handleChange}
                    type="number"
                  />

                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.inputLabel} htmlFor="purpose" shrink>
                      ვალუტა
                    </InputLabel>
                    <Select
                      className={classes.field}
                      native
                      value={formik.values.home_information?.market_value_currency}
                      onChange={formik.handleChange}
                      inputProps={{
                        name: "home_information.market_value_currency",
                      }}
                    >
                      {currencies.map((currency) => (
                        <option className={classes.field} key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : null}
              {selectedPurposeType?.name?.includes("შეძენა") ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          formik.setFieldValue("home_information.with_storage", event.target.checked);
                        }}
                        name="home_information.with_storage"
                      />
                    }
                    label="შეძენილ იქნება დამატებით სამეურნეო სათავსო(ებ)ი, განთავსებული იმავე საცხოვრებელ სახლში/კომპლექსში"
                  />
                </>
              ) : null}
            </FormControl>
          </>
        ) : null}

        {selectedPurposeType?.name !== "სახლის აშენება" && applicationType.name !== "რეფინანსირება" ? (
          <>
            <b>უძრავი ქონების რეალიზატორი</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  className={classes.field}
                  native
                  onChange={(event) => {
                    formik.handleChange(event);
                    setIsDeveloper(event.target.value === "developer");
                  }}
                >
                  <option aria-label="დეველოპერული კომპანია" value="developer">
                    დეველოპერული კომპანია
                  </option>
                  <option aria-label="სხვა პირი" value="person">
                    სხვა პირი
                  </option>
                </Select>
              </FormControl>
              {isDeveloper ? (
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.inputLabel} htmlFor="legal_form">
                      სამართლებრივი ფორმა
                    </InputLabel>
                    <Select
                      className={classes.field}
                      native
                      value={formik.values.home_information?.legal_form}
                      onChange={formik.handleChange}
                      inputProps={{
                        name: "home_information.developer_information.legal_form",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {legalForms
                        .filter((legal_form) => {
                          return legal_form.name !== "ფიზიკური პირი" && legal_form.name !== "ი/მ";
                        })
                        .map((legalForm) => (
                          <option key={legalForm._id} value={legalForm._id}>
                            {legalForm.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    className={classes.field}
                    name="home_information.developer_information.name"
                    label="ორგანიზაციის დასახელება"
                    onChange={formik.handleChange}
                  />

                  <TextField
                    variant="outlined"
                    className={classes.field}
                    name="home_information.developer_information.identification_number"
                    label="საიდენტიფიკაციო კოდი"
                    onChange={formik.handleChange}
                  />
                </>
              ) : (
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.inputLabel} htmlFor="legal_form">
                      სამართლებრივი ფორმა
                    </InputLabel>
                    <Select
                      className={classes.field}
                      native
                      value={formik.values.home_information?.other_person_information?.legal_form}
                      onChange={(event) => {
                        formik.handleChange(event);
                        setSelectedOtherPersonLegalForm(legalForms.find((legalForm) => legalForm._id === event.target.value));
                      }}
                      inputProps={{
                        name: "home_information.other_person_information.legal_form",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {legalForms.map((legalForm) => (
                        <option key={legalForm._id} value={legalForm._id}>
                          {legalForm.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedOtherPersonLegalForm?.name === "ი/მ" || selectedOtherPersonLegalForm?.name === "ფიზიკური პირი" ? (
                    <>
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name="home_information.other_person_information.first_name"
                        label="სახელი"
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        onBlur={() => joinSellerName()}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name="home_information.other_person_information.last_name"
                        label="გვარი"
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        onBlur={() => joinSellerName()}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name="home_information.other_person_information.identification_number"
                        label="პ/ნ"
                        onChange={formik.handleChange}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name="home_information.other_person_information.name"
                        label="ორგანიზაციის დასახელება"
                        onChange={formik.handleChange}
                      />
                      <TextField
                        variant="outlined"
                        className={classes.field}
                        name="home_information.other_person_information.identification_number"
                        label="საიდენტიფიკაციო კოდი"
                        onChange={formik.handleChange}
                      />
                    </>
                  )}
                </>
              )}
            </FormControl>
          </>
        ) : null}

        <b>ინფორმაცია მსესხებლის შესახებ</b>
        <FormControl variant="outlined" className={classes.formControl} required>
          <InputLabel className={classes.inputLabel} htmlFor="marriage_status">
            მსესხებლის სტატუსი
          </InputLabel>
          <Select
            className={classes.field}
            native
            value={formik.values.borrower_information.borrower.marital_status}
            onChange={(event) => {
              formik.handleChange(event);
              setSelectedMaritalStatus(event.target.value);
              // if (event.target.value === "მარტოხელა მშობელი")
              // eliminate borrower spouse
              if (event.target.value === "მარტოხელა მშობელი") {
                formik.setFieldValue("borrower_information.borrower_spouse", {});
              }
            }}
            inputProps={{
              name: "borrower_information.borrower.marital_status",
            }}
          >
            <option aria-label="None" value="" />
            {marriageStatuses.map((marriageStatus, index) => (
              <option key={index} value={marriageStatus}>
                {marriageStatus}
              </option>
            ))}
          </Select>
          <TextField
            required
            variant="outlined"
            className={classes.field}
            name="borrower_information.borrower.first_name"
            label="სახელი"
            onChange={formik.handleChange}
            onBlur={() => {
              formik.setFieldValue(
                "borrower_information.borrower.name",
                formik.values.borrower_information?.borrower?.first_name + " " + formik.values.borrower_information?.borrower?.last_name
              );
            }}
          />
          <TextField
            onBlur={() => {
              formik.setFieldValue(
                "borrower_information.borrower.name",
                formik.values.borrower_information?.borrower?.first_name + " " + formik.values.borrower_information?.borrower?.last_name
              );
            }}
            required
            variant="outlined"
            className={classes.field}
            name="borrower_information.borrower.last_name"
            label="გვარი"
            onChange={formik.handleChange}
          />
          <TextField
            required
            variant="outlined"
            className={classes.field}
            error={existingBorrower.state}
            helperText={existingBorrower.state ? existingBorrower.message : null}
            name="borrower_information.borrower.private_number"
            label="პ/ნ"
            inputProps={!otherCitizenship ? { minLength: 10, maxLength: 12 } : {}}
            onChange={(event) => {
              formik.handleChange(event);
            }}
            onBlur={(event) => {
              if (!(applicationType.name === "რეფინანსირება" || applicationType.name === "მეორე იპოთეკური სესხი")) {
                checkExistingBorrower(event.target.value).then((response) => {
                  setExistingBorrower({ state: response.error, message: response.message });
                });
              } else {
                checkExistingBorrower(event.target.value).then((response) => {
                  setExistingBorrower({ state: !response.error, message: "აპლიკანტი არ სარგებლობს პროგრამით" });
                });
              }
            }}
          />
          <TextField
            variant="outlined"
            className={classes.field}
            name="borrower_information.borrower.phone_number"
            label=" საკონტაქტო ნომერი (5xxxxxxxx)"
            onChange={formik.handleChange}
            required
          />
          <TextField variant="outlined" className={classes.field} name="borrower_information.borrower.email" label="ელ-ფოსტა" onChange={formik.handleChange} />

          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel className={classes.inputLabel} htmlFor="purpose">
              მოქალაქეობა
            </InputLabel>
            <Select
              className={classes.field}
              native
              value={formik.values.citizenship}
              onChange={(event) => {
                formik.handleChange(event);
                if (event.target.value === "საქართველოს მოქალაქე") {
                  formik.setFieldValue("borrower_information.borrower.citizenship", "საქართველო");
                  setOtherCitizenship(false);
                } else {
                  setOtherCitizenship(true);
                }
              }}
              inputProps={{
                name: "citizenship",
              }}
            >
              <option aria-label="None" value="" />
              {citizenships.map((citizenship) => (
                <option className={classes.field} key={citizenship} value={citizenship}>
                  {citizenship}
                </option>
              ))}
            </Select>
          </FormControl>
          {otherCitizenship ? (
            <>
              <TextField
                variant="outlined"
                className={classes.field}
                name="borrower_information.borrower.citizenship"
                label="მოქალაქეობა"
                onChange={formik.handleChange}
              />
            </>
          ) : null}
        </FormControl>

        {selectedMaritalStatus !== "მარტოხელა მშობელი" ? (
          <>
            <b>მსესხებლის მეუღლე</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                required={selectedMaritalStatus?.includes("ოჯახი")}
                className={classes.field}
                name="borrower_information.borrower_spouse.first_name"
                label="სახელი"
                onChange={formik.handleChange}
                onBlur={() =>
                  formik.setFieldValue(
                    "borrower_information.borrower_spouse.name",
                    formik.values.borrower_information?.borrower_spouse?.first_name + " " + formik.values.borrower_information?.borrower_spouse?.last_name
                  )
                }
              />
              <TextField
                variant="outlined"
                required={selectedMaritalStatus?.includes("ოჯახი")}
                className={classes.field}
                name="borrower_information.borrower_spouse.last_name"
                label="გვარი"
                onChange={formik.handleChange}
                onBlur={() =>
                  formik.setFieldValue(
                    "borrower_information.borrower_spouse.name",
                    formik.values.borrower_information?.borrower_spouse?.first_name + " " + formik.values.borrower_information?.borrower_spouse?.last_name
                  )
                }
              />
              <TextField
                required={selectedMaritalStatus?.includes("ოჯახი")}
                variant="outlined"
                className={classes.field}
                error={existingSpouseBorrower}
                helperText={existingSpouseBorrower ? "აპლიკანტი სარგებლობს პროგრამით" : null}
                label="პ/ნ"
                inputProps={!spouseOtherCitizenship ? { minLength: 10, maxLength: 12 } : {}}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={(event) => {
                  if (!(applicationType.name === "რეფინანსირება" || applicationType.name === "მეორე იპოთეკური სესხი")) {
                    checkExistingBorrower(event.target.value).then((response) => {
                      setExistingSpouseBorrower(response.error);
                    });
                  } else {
                    checkExistingBorrower(event.target.value).then((response) => {
                      setExistingSpouseBorrower(!response.error);
                    });
                  }
                }}
                name="borrower_information.borrower_spouse.private_number"
              />
              <FormControl variant="outlined" className={classes.formControl} required={selectedMaritalStatus?.includes("ოჯახი")}>
                <InputLabel className={classes.inputLabel} htmlFor="spouse_citizenship">
                  მოქალაქეობა
                </InputLabel>
                <Select
                  className={classes.field}
                  native
                  value={formik.values.spouse_citizenship}
                  onChange={(event) => {
                    formik.handleChange(event);
                    if (event.target.value === "საქართველოს მოქალაქე") {
                      formik.setFieldValue("borrower_information.borrower_spouse.citizenship", "საქართველო");
                      setSpouseOtherCitizenship(false);
                    } else {
                      setSpouseOtherCitizenship(true);
                    }
                  }}
                  inputProps={{
                    name: "spouse_citizenship",
                  }}
                >
                  <option aria-label="None" value="" />
                  {citizenships.map((citizenship) => (
                    <option className={classes.field} key={citizenship} value={citizenship}>
                      {citizenship}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {spouseOtherCitizenship ? (
                <>
                  <TextField
                    required={selectedMaritalStatus?.includes("ოჯახი")}
                    variant="outlined"
                    className={classes.field}
                    name="borrower_information.borrower_spouse.citizenship"
                    label="მოქალაქეობა"
                    onChange={formik.handleChange}
                  />
                </>
              ) : null}
            </FormControl>

            {selectedMaritalStatus !== "ქვრივი" ? (
              <FormControlLabel
                control={
                  <Checkbox
                    required={selectedMaritalStatus?.includes("ოჯახი")}
                    onChange={(event) => {
                      formik.setFieldValue("borrower_information.borrower.is_married", event.target.checked);
                    }}
                    name="is_married"
                  />
                }
                label="იმყოფება რეგისტრირებულ ქორწინებაში "
              />
            ) : null}
          </>
        ) : null}

        <b>არასრულწლოვანი შვილ(ებ)ი/გერ(ებ)ი</b>
        {Array.from(Array(minorChildrenCounter)).map((e, index) => (
          <FormControl variant="outlined" className={classes.formControl}>
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  required
                  variant="outlined"
                  className={classes.field}
                  name={`children_information[${index}].first_name`}
                  label="სახელი"
                  onChange={formik.handleChange}
                  onBlur={() =>
                    formik.setFieldValue(
                      `children_information[${index}].name`,
                      formik.values.children_information[index]?.first_name + " " + formik.values.children_information[index]?.last_name
                    )
                  }
                />
                <TextField
                  required
                  variant="outlined"
                  className={classes.field}
                  name={`children_information[${index}].last_name`}
                  label="გვარი"
                  onChange={formik.handleChange}
                  onBlur={() =>
                    formik.setFieldValue(
                      `children_information[${index}].name`,
                      formik.values.children_information[index]?.first_name + " " + formik.values.children_information[index]?.last_name
                    )
                  }
                />
                <TextField
                  required
                  variant="outlined"
                  className={classes.field}
                  name={`children_information[${index}].private_number`}
                  label="პ/ნ"
                  onChange={formik.handleChange}
                  error={existingBorrowerChild}
                  helperText={existingBorrowerChild ? "აპლიკანტი სარგებლობს პროგრამით" : null}
                  onBlur={(event) => {
                    checkExistingBorrowerChild(event.target.value).then((response) => {
                      setExistingBorrowerChild(response.error);
                    });
                  }}
                />
                <TextField
                  required
                  variant="outlined"
                  className={classes.field}
                  name={`children_information[${index}].birth_date`}
                  error={isChildAdult[index]}
                  helperText={isChildAdult[index] ? "უნდა იყოს არასრულწლოვანი" : null}
                  label="დაბადების თარიღი"
                  type="date"
                  // defaultValue={dateFormat("2005-01-02", "yyyy-mm-dd")}
                  onChange={(event) => {}}
                  InputLabelProps={{ shrink: true }}
                  onBlur={(event) => {
                    formik.handleChange(event);
                    checkAdult(event.target.value).then((response) => {
                      let newIsChildAdult = isChildAdult;
                      newIsChildAdult[index] = response.error ? true : false;
                      setIsChildAdult([...isChildAdult, (isChildAdult[index] = response.error ? true : false)]);
                    });
                  }}
                  shrink
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        formik.setFieldValue(`children_information[${index}].is_adopted`, event.target.checked);
                        setHasAdoptedChild(event.target.checked);
                      }}
                      name="is_adopted"
                    />
                  }
                  label="შვილად აყვანილი"
                />
                {index === minorChildrenCounter - 1 && index !== 0 ? (
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue(`children_information[${index}]`, undefined);
                      childrenRemoveHandler();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
                {index === minorChildrenCounter - 1 ? (
                  <>
                    <IconButton
                      onClick={() => {
                        childrenAddHandler();
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                ) : null}
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl} style={{ margin: "0 0 0 2%" }}></FormControl>
            </>
          </FormControl>
        ))}

        <>
          {" "}
          <b>
            {applicationType.name !== "რეფინანსირება" ? "ინფორმაცია დამტკიცებული სესხის შესახებ" : "დამტკიცებული რეფინანსირებული სესხის შესახებ ინფორმაცია"}
          </b>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField variant="outlined" required className={classes.field} name="loan_information.loan_id" label="სესხის ID" onChange={formik.handleChange} />
            <TextField
              variant="outlined"
              required
              className={classes.field}
              name="loan_information.approval_date"
              InputLabelProps={{ shrink: true }}
              label="დამტკიცების თარიღი"
              type="date"
              onChange={formik.handleChange}
            />
            <TextField
              variant="outlined"
              required
              className={classes.field}
              name="loan_information.amount"
              label="სესხის მოცულობა (GEL)"
              type="number"
              inputProps={{ step: "0.01", min: "0" }}
              error={formik.values.loan_information?.amount > 200000}
              helperText={formik.values.loan_information?.amount > 200000 ? "სესხის მოცულობა არ უნდა იყოს 200,000 ლარზე მეტი" : null}
              onChange={formik.handleChange}
            />
            <TextField
              required
              variant="outlined"
              className={classes.field}
              name="loan_information.duration"
              label="სესხის ვადა (თვე)"
              type="number"
              onChange={(event) => {
                formik.handleChange(event);
              }}
              onBlur={(event) => {
                formik.setFieldValue(
                  "loan_information.subsidy_duration_months",
                  formik.values.loan_information?.duration > 60 ? 60 : formik.values.loan_information?.duration
                );
                setForceRender(true);
              }}
            />
            {applicationType.name !== "რეფინანსირება" ? (
              <>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  className={classes.field}
                  name="loan_information.subsidy_duration_months"
                  label="თანადაფინანსების ვადა"
                  type="number"
                  value={formik.values?.loan_information?.subsidy_duration_months}
                  onChange={formik.handleChange}
                />
                <TextField
                  variant="outlined"
                  className={classes.field}
                  name="loan_information.borrower_co_financing"
                  label="მსესხებლის თანამონაწილეობა"
                  type="number"
                  onChange={formik.handleChange}
                />
              </>
            ) : null}
            <TextField
              required
              variant="outlined"
              className={classes.field}
              name="loan_information.approved_interest_rate"
              label="დამტკიცებული % განაკვეთი"
              inputProps={{ step: "any", min: "0", max: "100" }}
              type="number"
              onChange={formik.handleChange}
            />
          </FormControl>
        </>
        {applicationType.name === "რეფინანსირება" ? (
          <>
            <b>დასარეფინანსირებელი სესხის შესახებ ინფორმაცია</b>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel required className={classes.inputLabel} htmlFor="bank_initial">
                ბანკი (საწყისი)
              </InputLabel>
              <Select
                required
                className={classes.field}
                native
                onChange={formik.handleChange}
                inputProps={{
                  name: "refinancing_information.initial_bank",
                }}
              >
                <option aria-label="None" value="" />
                {banks?.map((bank) => (
                  <option key={bank._id} value={bank._id}>
                    {bank.name}
                  </option>
                ))}
              </Select>
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.loan_id"
                label="სესხის ID"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.first_disbursment_date"
                label="სესხის პირველი ტრანშის გაცემის თარიღი"
                type="date"
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.approved_amount"
                inputProps={{ step: "0.01", min: "0" }}
                label="დამტკიცებული თანხა (GEL)"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.remaining_amount"
                inputProps={{ step: "0.01", min: "0" }}
                label="ნაშთი (დარჩენილი ძირითადი თანხა) GEL"
                type="number"
                onChange={formik.handleChange}
              />
              <TextField
                required
                variant="outlined"
                className={classes.field}
                name="refinancing_information.outstanding_amount"
                inputProps={{ step: "0.01", min: "0" }}
                label="დარჩენილი ასათვისებელი მოცულობა (GEL) (არსებობის შემთხვევაში)"
                type="number"
                onChange={formik.handleChange}
              />
            </FormControl>
          </>
        ) : null}

        <b>ასატვირთი დოკუმენტები</b>
        {attachmentFields.map((attachment, index) => (
          <>
            <div className="mortgage_attachments">
              <FormControl variant="outlined" className={classes.formControl} style={{ padding: "15px 0" }}>
                <p>{attachment.title}: </p>
                <input
                  type="file"
                  onChange={(event) => {
                    fileChangeHandler(event, index);
                  }}
                />

                <Button
                  style={{ marginLeft: "14.625rem" }}
                  color="secondary"
                  size="small"
                  variant={attachmentButtons[index] ? "contained" : "outlined"}
                  onClick={() => {
                    fileSubmitHandler(attachment.field, index);
                    setTimeout(() => formik.setFieldValue("attached_documents", uploadedAttachments));
                    const newAttachmentButtons = attachmentButtons;
                    newAttachmentButtons[index] = true;
                    setAttachmentButtons(newAttachmentButtons);
                  }}
                >
                  ატვირთვა
                </Button>
                {attachment.canAdd ? (
                  <>
                    <IconButton
                      style={{ position: "absolute", left: "100%" }}
                      onClick={() => {
                        setAttachmentFields(attachmentFields.concat({ title: attachment.title, field: attachment.field, canAdd: true }));
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                ) : null}
              </FormControl>
            </div>
          </>
        ))}

        <FormControl variant="outlined" className={classes.formControl} style={{ textAlign: "center", display: "block", marginTop: "60px" }}>
          <Button
            type="submit"
            onClick={(event) => (window.confirm("გსურთ აპლიკაციის გაგზავნა?") ? console.log("ATTEMPTING SUBMISSION") : event.preventDefault())}
            color="primary"
            variant="contained"
            disabled={isUploading || loading}
            startIcon={<SendIcon />}
            style={{
              width: "40%",
              //give wavy animation to button
            }}
          >
            გაგზავნა
          </Button>
        </FormControl>
      </form>
      <LinearProgress style={{ display: loading ? "block" : "none" }} color="secondary" />
    </div>
  );
}

const validationSchema = yup.object({
  //   email: yup.string("შეიყვანეთ ფოსტა").email("შეიყვანეთ ვალიდური ფოსტა").required("ფოსტის შეყვანა აუცილებელია"),
  //   password: yup.string("შეიყვანეთ პაროლი").required("პაროლის შეყვანა აუცილებელია"),
});

const useStyles = makeStyles((theme) => ({
  field: {
    width: 320,
    marginRight: 30,
    marginTop: 15,
  },
  inputLabel: {
    backgroundColor: "white",
    marginTop: 15,
  },
  formLabel: {
    marginTop: 30,
  },
  longField: {
    minWidth: 300,
    marginRight: 30,
  },
  longerField: {
    marginTop: 15,
    minWidth: 380,
    marginRight: 30,
  },
  button: {
    marginTop: 80,
    width: "60%",
    margin: "0 auto",
  },
  formControl: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

export default Mortgage;
