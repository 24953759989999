import React, { ReactNode, FC } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { GeneralInformationContextProvider, MonitoringFormContextProvider, MonitoringFormDataProvider } from "../../context";
import { MonitoringApplicationContextProvider } from "../../context/MonitoringApplicationContext";

interface MonitoringFormProviderProps {
  children: ReactNode;
}

const MonitoringFormProvider: FC<MonitoringFormProviderProps> = ({ children }) => {
  // const methods = useForm();

  return (
    // <FormProvider {...methods}>
    <GeneralInformationContextProvider>
      <MonitoringApplicationContextProvider>
        <MonitoringFormContextProvider>
          <MonitoringFormDataProvider>{children}</MonitoringFormDataProvider>
        </MonitoringFormContextProvider>
      </MonitoringApplicationContextProvider>
    </GeneralInformationContextProvider>
    // </FormProvider >
  );
};

export default MonitoringFormProvider;
