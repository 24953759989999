import html2pdf from 'html2pdf.js';
const generatePDF = async (htmlPattern, fileName = "document") => {
  const { css, html } = htmlPattern;
  const element = document.createElement('div');
  element.innerHTML = html;
  const margin = 28;

  const pdf = html2pdf().from(element).set({
    margin: [margin, margin],
    filename: fileName,
    css,
    html2canvas: { scale: 3 },
    jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },

  });
  return pdf.output('arraybuffer');
};

export default generatePDF;