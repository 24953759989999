import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  DialogTitle,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AccountBalance, BusinessCenter, ScreenSearchDesktop } from "@mui/icons-material";
import axios from "axios";
import PermissionsCheckboxGroup, { Permission } from "./PermissionsCheckboxGroup";

type agencyPosition = {
  label: string;
  value: number;
};

type bank = {
  _id: string;
  name: string;
};

type agencyDepartment = {
  _id: string;
  title: string;
};

type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  bank?: string; // This will be the ID of the bank
  permissions?: string[]; // This will be an array of permission values
  agency_level?: number;
  agency_department?: string;
};

type UserCreateDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  banks: bank[];
  agencyDepartments: agencyDepartment[];
  isBank: boolean;
  isMonitoring: boolean;
};

export default function UserCreateDialog({ open, setOpen, banks = [], agencyDepartments = [], isBank, isMonitoring }: UserCreateDialogProps) {
  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    bank: "",
    permissions: [],
    agency_level: 0,
    agency_department: "",
  };

  const [user, setUser] = React.useState<UserForm>(initialUser);

  const agencyPositions: agencyPosition[] = [
    { label: "სოციალური რისკების სპეციალისტი", value: -2 },
    { label: "გარემოსდაცვითი სპეციალისტი", value: -1 },
    { label: "მონიტორინგი", value: 0 },
    { label: "დეპ. თანამშრომელი", value: 1 },
    { label: "დეპ. უფროსი", value: 2 },
    { label: "სააგენტოს დირექტორი", value: 3 },
  ];

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState("bank");

  useEffect(() => {
    setUser(initialUser);
  }, [tab]);
  useEffect(() => {
    if (isBank) setTab("bank");
    if (isMonitoring) setTab("monitoring");
  }, [isBank, isMonitoring]);

  const permissions: Permission[] = [
    { name: "ბანკჰედი", value: "ADD_USER", implies: ["SUBMIT_BUSINESS_APPLICATIONS", "SUBMIT_MORTGAGE_APPLICATIONS"] },
    { name: "ინდუსტრიული და უნივერსალური აპლიკაციების დამატება", value: "SUBMIT_BUSINESS_APPLICATIONS" },
    { name: "იპოთეკური აპლიკაციების დამატება", value: "SUBMIT_MORTGAGE_APPLICATIONS" },
  ];

  const monitoringPermissions: Permission[] = [
    { name: "მონიტორინგის მთავარი მომხმარებელი", value: "ADD_USER", implies: ["SUBMIT_MONITORING_FORMS"] },
    { name: "მონიტორინგის სპეციალისტი", value: "SUBMIT_MONITORING_FORMS" },
  ];

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUser({ ...user, [event.target.name]: event.target.value });
  }

  function createUser() {
    setLoading(true);
    axios
      .post("/auth/signup", {
        name: user.firstName + " " + user.lastName,
        email: user.email,
        bank: user.bank,
        role: tab,
        permissions: user.permissions,
        agency_level: user.agency_level,
        agency_department: user.agency_department,
      })
      .then((res) => {
        alert(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response.data.message || "შეცდომა");
        console.error(err.response.data);
        setLoading(false);
      });
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} variant="fullWidth">
          <Tab icon={<BusinessCenter />} disabled={isMonitoring} label="ბანკი" value="bank" />
          <Tab icon={<AccountBalance />} disabled={isBank || isMonitoring} label="სააგენტო" value="agency" />
          <Tab icon={<ScreenSearchDesktop />} disabled={isBank} label="მონიტორინგი" value="monitoring" />
        </Tabs>
        <DialogTitle>მომხმარებლის შექმნა</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth name="firstName" value={user.firstName} onChange={handleChange} placeholder="სახელი" label="სახელი" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth name="lastName" value={user.lastName} onChange={handleChange} placeholder="გვარი" label="გვარი" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth name="email" value={user.email} onChange={handleChange} placeholder="ელ-ფოსტა" label="ელ-ფოსტა" />
            </Grid>
            <Grid item xs={12} hidden={tab !== "bank"}>
              <TabPanel value="bank" sx={{ width: "100%", padding: 0 }}>
                <Grid item xs={12} hidden={isBank}>
                  {/* User will select bank name, value will be registered as ID */}
                  <InputLabel>ბანკი</InputLabel>
                  <Select fullWidth value={user.bank} onChange={(event) => setUser({ ...user, bank: event.target.value as string })}>
                    {banks.map((bank) => (
                      <MenuItem key={bank._id} value={bank._id}>
                        {bank.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <PermissionsCheckboxGroup
                    permissions={permissions}
                    userPermissions={user.permissions || []}
                    setUserPermissions={(newPermissions) => setUser({ ...user, permissions: newPermissions })}
                  />
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value="agency" sx={{ width: "100%", padding: 0 }}>
                <Grid item xs={12}>
                  <InputLabel>პოზიცია</InputLabel>
                  <Select fullWidth value={user.agency_level} onChange={(event) => setUser({ ...user, agency_level: event.target.value as number })}>
                    {agencyPositions.map((position) => (
                      <MenuItem key={position.value} value={position.value}>
                        {position.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>დეპარტამენტი</InputLabel>
                  <Select fullWidth value={user.agency_department} onChange={(event) => setUser({ ...user, agency_department: event.target.value as string })}>
                    {agencyDepartments.map((department) => (
                      <MenuItem key={department._id} value={department._id}>
                        {department.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </TabPanel>
            </Grid>
            {/* Monitoring tab */}
            <Grid item xs={12}>
              <TabPanel value="monitoring" sx={{ width: "100%", padding: 0 }}>
                <Grid item xs={12}>
                  <PermissionsCheckboxGroup
                    permissions={monitoringPermissions}
                    userPermissions={user.permissions || []}
                    setUserPermissions={(newPermissions) => setUser({ ...user, permissions: newPermissions })}
                  />
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            უკან
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              createUser();
            }}
            variant="contained"
            color="primary"
          >
            მომხმარებლის დამატება
          </Button>
        </DialogActions>
      </TabContext>
    </Dialog>
  );
}
