export function PostData(endpoint, requestBody, isFile) {
  var myHeaders = new Headers();
  if (localStorage.getItem("x-access-token")) {
    myHeaders.append("x-access-token", localStorage.getItem("x-access-token"));
  }
  if (!isFile) {
    requestBody = JSON.stringify(requestBody);
    myHeaders.append("Content-Type", "application/json");
  }
  var requestData = {
    method: "POST",
    headers: myHeaders,
    body: requestBody,
    redirect: "follow",
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, requestData).then((response) => {
    if (response.status === 403) {
      localStorage.clear();
      alert("დაფიქსირდა ავტორიზაციის შეცდომა");
      window.location.reload();
    }
    return response.json();
  });
}
