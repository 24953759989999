import axios from "axios";

export default function checkSanctionedCompany(formik, setErrorMessages) {
  if (formik.values.applicant_information?.identification_code) {
    axios
      .get(`/check_sanctioned_company/${formik.values.applicant_information.identification_code}`)
      .then((res) => {
        setErrorMessages((prevState) => {
          return { ...prevState, sanctioned_company: undefined };
        });
      })
      .catch((err) => {
        formik.setFieldValue("world_bank.government_subsidy_mechanism", true);
        formik.setFieldValue("world_bank.subsidy_mechanism", false);
        setErrorMessages((prevState) => {
          return { ...prevState, sanctioned_company: err.response.data.message };
        });
      });
  }
}
