import dateFormat from "dateformat";

export default function ClosureData({ classes, applicationInformation }) {
  return (
    <>
      {applicationInformation?.closure.reason && (
        <tr className={classes.row}>
          <td className={classes.title}>დახურვის მიზეზი:</td>
          <td className={classes.detail}>{applicationInformation?.closure.reason}</td>
        </tr>
      )}
      {applicationInformation?.closure?.submitted_by?.name ? (
        <tr className={classes.row}>
          <td className={classes.title}>დახურვის მომთხოვნი:</td>
          <td className={classes.detail}>{applicationInformation?.closure.submitted_by.name}</td>
        </tr>
      ) : null}
      {applicationInformation?.closure?.refinancing ? (
        <>
          <tr className={classes.row}>
            <td className={classes.title}>დარეფინანსირდა:</td>
            <td className={classes.detail}>{applicationInformation?.closure.refinancing ? "მონიშნულია" : "არ არის მონიშნული"}</td>
          </tr>
          <tr className={classes.row}>
            <td className={classes.title}>რეფინანსირების თარიღი:</td>
            <td className={classes.detail}>{dateFormat(applicationInformation?.closure.date, "yyyy-mm-dd", "T")}</td>
          </tr>
        </>
      ) : null}
      {applicationInformation?.closure?.comment ? (
        <tr className={classes.row}>
          <td className={classes.title}>კომენტარი:</td>
          <td className={classes.detail}>{applicationInformation?.closure.comment}</td>
        </tr>
      ) : null}
    </>
  );
}
