import axios from "axios";

export default function checkCadastralCode(formik, setErrorMessages) {
  if (formik.values.project_information?.actual_address?.cadastral_code) {
    axios
      .get(`/check_protected_territory/${formik.values.project_information.actual_address.cadastral_code}`)
      .then((res) => {
        setErrorMessages((prevState) => {
          return { ...prevState, cadastral_code: undefined };
        });
      })
      .catch((err) => {
        setErrorMessages((prevState) => {
          console.log(formik.values.world_bank?.government_subsidy_mechanism);
          // IF there is error, but government subsidy mechanism is checked (instead of world bank), neglect error and set cadastral code to undefined
          if (formik.values.world_bank?.government_subsidy_mechanism) {
            return { ...prevState, cadastral_code: undefined };
          }
          formik.setFieldValue("world_bank.subsidy_mechanism", false);
          return { ...prevState, cadastral_code: err.response.data.message };
        });
      });
  }
}
