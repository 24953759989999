import { IClient } from "./config";

export const generateUrl = (
  client: IClient,
  endpointKey: keyof IClient["endpoint"],
  params: Record<string, string> | null,
  queryParams: Record<string, string> | null
): string => {
  const endpoint = client.endpoint[endpointKey];
  let { pathname } = endpoint.uri;

  if (params) {
    Object.keys(params).forEach((key) => {
      pathname = pathname.replace(`:${key}`, params[key]);
    });
  }

  let queryString = "";
  if (queryParams) {
    queryString = Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join("&");
  }
  return `${pathname}${queryString ? `?${queryString}` : ""}`;
};
