import {
  Modal as MuiModal,
  Card,
  CardContent,
  Tabs,
  Tab,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useFormik, Form } from "formik";
import * as yup from "yup";
import { PostData } from "../../utilities/PostData";
import { GetData } from "../../utilities/GetData";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

function Modal({ modalOpen, setModalOpen }) {
  const [tabValue, setTabValue] = useState(0);
  const [initialRequestData, setInitialRequestData] = useState({ isBankHead: false, requestedPermissions: null });
  const [requestData, setRequestData] = useState();
  const [generalData, setGeneralData] = useState();
  const [permissions, setPermissions] = useState([]);
  const [isBank, setIsBank] = useState();
  const [canAddUser, setCanAddUser] = useState();
  const [successfullyAdded, setSuccessfullyAdded] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedBank, setSelectedBank] = useState();
  const [userBank, setUserBank] = useState();
  const classes = useStyles();
  const agencyPositions = [
    { label: "სოციალური რისკების სპეციალისტი", value: -2 },
    { label: "გარემოსდაცვითი სპეციალისტი", value: -1 },
    { label: "მონიტორინგი", value: 0 },
    { label: "დეპ. თანამშრომელი", value: 1 },
    { label: "დეპ. უფროსი", value: 2 },
    { label: "სააგენტოს დირექტორი", value: 3 },
  ];

  useEffect(() => {
    if (localStorage.getItem("role") === "ROLE_BANK") {
      setIsBank(true);
      setUserBank(JSON.parse(localStorage.getItem("bank")));
    }
    if (JSON.parse(localStorage.getItem("permissions")).includes("ADD_USER")) {
      setCanAddUser(true);
    }
    GetData("general_info")
      .then(async (resp) => {
        setGeneralData(resp);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setRequestData({ isBankHead: false, requestedPermissions: null });
    if (tabValue === 1) {
      setRequestData({ isBankHead: false, isAgency: true });
    }
    if (tabValue === 0) {
      setRequestData({ isBankHead: false, isAgency: false });
    }
  }, [tabValue]);

  useEffect(() => {
    if (requestData?.isBankHead) {
      setRequestData({ ...requestData, requestedPermissions: undefined });
      setPermissions([]);
    }
  }, [requestData?.isBankHead]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const permissionChangeHandler = (event) => {
    let newPermissions = permissions;
    let requestedPermission;
    if (newPermissions.includes(event.target.name)) {
      newPermissions = newPermissions.filter((val) => val !== event.target.name);
    } else {
      newPermissions.push(event.target.name);
    }
    if (newPermissions.length === 2) {
      requestedPermission = "ADD_BOTH";
    } else {
      requestedPermission = newPermissions.length === 0 ? undefined : newPermissions[0];
    }

    setRequestData({ ...requestData, requestedPermissions: requestedPermission });
    setPermissions(newPermissions);
  };
  const registerUser = (values) => {
    PostData("auth/signup", values)
      .then((res) => {
        setSuccessfullyAdded(true);
        setResponseMessage(res.message);
      })
      .catch((err) => {
        alert("დაფიქსირდა შეცდომა");
      });
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      for (var k in requestData) values[k] = requestData[k];
      registerUser(values);
      resetForm();
    },
  });
  return (
    <MuiModal
      open={modalOpen}
      onClose={() => {
        setSuccessfullyAdded(false);
        setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <Card className={classes.card}>
        <Tabs value={tabValue < 2 ? tabValue : false} onChange={handleTabChange} variant="fullWidth" indicatorColor="secondary" textColor="primary">
          <Tab icon={<BusinessCenterIcon />} label="ბანკი" />
          {!isBank ? <Tab icon={<AccountBalanceIcon />} label="სააგენტო" /> : null}
        </Tabs>
        <CardContent className={classes.CardContent}>
          {!successfullyAdded ? (
            <>
              <form onSubmit={formik.handleSubmit} className={classes.form}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="ელ-ფოსტა"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField fullWidth id="name" name="name" label="სახელი" value={formik.values.name} onChange={formik.handleChange} />
                {tabValue === 1 && canAddUser ? (
                  <>
                    <FormControl className={classes.filterSelector} required>
                      <InputLabel id="position-label">პოზიცია</InputLabel>
                      <Select
                        labelId="position-label"
                        id="agency_level"
                        value={requestData?.bank}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        inputProps={{
                          name: "agency_level",
                        }}
                      >
                        {agencyPositions.map((position) => (
                          <MenuItem value={position.value}>{position.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* Similar FormControl for departments */}
                    <FormControl className={classes.filterSelector} required>
                      <InputLabel id="department-label">დეპარტამენტი</InputLabel>
                      <Select
                        labelId="department-label"
                        id="department-select"
                        value={requestData.department}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        inputProps={{
                          name: "agency_department",
                        }}
                      >
                        {generalData?.agency_departments.map((department) => (
                          <MenuItem value={department._id}>{department.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
                {tabValue === 0 && canAddUser ? (
                  <>
                    {!isBank ? (
                      <FormControl className={classes.filterSelector} required>
                        <InputLabel id="bank-label">ბანკი</InputLabel>
                        <Select
                          labelId="bank-label"
                          id="bank-select"
                          value={requestData?.bank}
                          onChange={(event) => {
                            setRequestData({ ...setRequestData, bank: event.target.value });
                            setSelectedBank(generalData?.banks.find((bank) => bank._id === event.target.value));
                          }}
                        >
                          {generalData?.banks.map((bank) => (
                            <MenuItem value={bank._id}>{bank.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}

                    <FormControl>
                      <FormLabel component="legend">
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={requestData?.isBankHead}
                                onChange={(event) => {
                                  setRequestData({ ...requestData, [event.target.name]: event.target.checked });
                                }}
                                name="isBankHead"
                              />
                            }
                            label="ბანკჰედი"
                          />
                        </>
                        <FormControlLabel
                          disabled={requestData?.isBankHead}
                          control={
                            <Checkbox
                              checked={permissions.includes("ADD_BUSINESS")}
                              onChange={(event) => {
                                permissionChangeHandler(event);
                              }}
                              name="ADD_BUSINESS"
                            />
                          }
                          label="ინდუსტრიული და უნივერსალური აპლიკაციების დამატება"
                        />
                        {!selectedBank?.leasing && !userBank?.leasing ? (
                          <FormControlLabel
                            disabled={requestData?.isBankHead}
                            control={
                              <Checkbox
                                onChange={(event) => {
                                  permissionChangeHandler(event);
                                }}
                                checked={permissions.includes("ADD_MORTGAGE")}
                                name="ADD_MORTGAGE"
                              />
                            }
                            label="იპოთეკური აპლიკაციების დამატება"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                  </>
                ) : null}
                <Button
                  style={{ marginTop: 25 }}
                  disabled={!requestData?.requestedPermissions && !requestData?.isBankHead && !requestData?.isAgency}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  რეგისტრირება
                </Button>
              </form>
            </>
          ) : (
            <h5>{responseMessage}</h5>
          )}
        </CardContent>
      </Card>
    </MuiModal>
  );
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    margin: "5% 0 0 0",
    justifyContent: "center",
  },
  card: {
    width: "35%",
    height: "70vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
});

const validationSchema = yup.object({
  email: yup.string("შეიყვანეთ ფოსტა").email("შეიყვანეთ ვალიდური ფოსტა").required("ფოსტის შეყვანა აუცილებელია"),
});

export default Modal;
