import { useState, useEffect, useCallback } from "react";
import "./Report.css";
import { GetData } from "../../utilities/GetData";
import {
  Input,
  Card,
  CardHeader,
  CardContent,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import ListAltIcon from "@material-ui/icons/ListAlt";
import axios from "axios";

const Report = () => {
  const [isBank, setIsBank] = useState();
  const [isMonitoring, setIsMonitoring] = useState();
  const [banks, setBanks] = useState();
  const [filterData, setFilterData] = useState({ component: "all", status: "all", all_schedules: false });
  const [reportLoading, setReportLoading] = useState(false);
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    //Prompt user if they want to upload file
    if (window.confirm("გსურთ აიტვირთოთ ფაილი?")) {
      setPaymentDataLoading(true);
      const formData = new FormData();
      formData.append("schedule_payments", acceptedFiles[0]);
      axios
        .post("/agency/upload_payments", formData)
        .then((res) => {
          setPaymentDataLoading(false);
          alert(res.data.message);
        })
        .catch((err) => {
          setPaymentDataLoading(false);
          if (err.response.data.message) {
            alert(err.response.data.message);
          } else {
            alert("ფაილი ვერ აიტვირთა");
          }
        });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [approvalStatuses, setApprovalStatuses] = useState([
    { title: "ყველა", value: "all" },
    { title: "განიხილება", value: "review" },
    { title: "დამტკიცებული", value: "approved" },
    { title: "უარყოფილი", value: "rejected" },
    { title: "გაუქმებული", value: "suspended" },
  ]);
  const [components, setComponents] = useState([
    { title: "იპოთეკა", value: "mortgage" },
    { title: "ძველი იპოთეკა", value: "legacy_mortgage" },
    { title: "უნივერსალური", value: "universal" },
    { title: "ინდუსტრიული", value: "industrial" },
    { title: "უნივერსალური (რისკები)", value: "universal_risk", agency_only: true },
    { title: "მონიტორინგი", value: "monitoring", agency_only: true },
    { title: "ყველა", value: "all" },
  ]);

  useEffect(() => {
    if (localStorage.getItem("role") === "ROLE_BANK") {
      setIsBank(true);
    }
    if (localStorage.getItem("role") === "ROLE_MONITORING") {
      setIsMonitoring(true);
      setComponents((prev) => prev.filter((component) => component.value === "monitoring"));
    }

    GetData("general_info")
      .then(async (resp) => {
        setBanks([{ name: "ყველა", _id: undefined }, ...resp?.banks]);
      })
      .catch((err) => {});
  }, []);

  async function generateReport() {
    setReportLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}generate_report`,
      data: filterData,
      responseType: "blob", // This is important
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        let fileName = "ApplicationsReport.xlsx"; // Set your file name here
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();

        setReportLoading(false);
      })
      //Catch error, which will be an HTTP error, so convert blob to text, then convert to JSON
      .catch((error) => {
        //Convert blob to text
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        if (error.response.data.type === "application/json") {
          reader.onload = () => {
            const res = JSON.parse(reader.result);
            alert(res.message);
          };
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
        setReportLoading(false);
      });
  }

  async function generateSchedulingReport() {
    setReportLoading(true);
    axios
      .post(`agency/generate_report_scheduling`, filterData)
      .then((res) => {
        setReportLoading(false);
        window.open(`${process.env.REACT_APP_BASE_URL}public/schedule-reports/${res.data.file_name}`);
      })
      .catch((err) => {
        setReportLoading(false);
        alert("დაფიქსირდა შეცდომა");
      });
  }

  async function generatePaymentsReport() {
    setReportLoading(true);
    axios
      .post(`agency/generate_report_payments`, filterData)
      .then((res) => {
        setReportLoading(false);
        window.open(`${process.env.REACT_APP_BASE_URL}public/payment_reports/${res.data.file_name}`);
      })
      .catch((err) => {
        setReportLoading(false);
        alert(err.response.data.message || "დაფიქსირდა შეცდომა");
      });
  }

  async function generateAgencyShareReport() {
    setReportLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}agency/generate_report_agency_share`,
      data: filterData,
      responseType: "blob", // Requesting the data as a blob
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        let fileName = "AgencyShareReport.xlsx"; // Set your file name here
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();

        setReportLoading(false);
      })
      .catch((error) => {
        // Convert blob to text
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        if (error.response.data.type === "application/json") {
          reader.onload = () => {
            const res = JSON.parse(reader.result);
            alert(res.message);
          };
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
        setReportLoading(false);
      });
  }

  async function generateRefinancingRateReport() {
    // endpoint: /agency/generate_report_refinancing_rate
    setReportLoading(true);
    // note that response will be a blob
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}agency/generate_report_refinancing_rate`,
      data: filterData,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        let fileName = "RefinancingRateReport.xlsx";
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();
        setReportLoading(false);
      })
      .catch((error) => {
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        if (error.response.data.type === "application/json") {
          reader.onload = () => {
            const res = JSON.parse(reader.result);
            alert(res.message);
          };
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
        setReportLoading(false);
      });
  }

  async function generateLegacyMortgageReport() {
    setReportLoading(true);
    axios
      .post(`/generate_legacy_guarantee_report`, filterData)
      .then((res) => {
        setReportLoading(false);
        window.open(`${process.env.REACT_APP_BASE_URL}public/reports/${res.data.file_name}`);
      })
      .catch((err) => {
        setReportLoading(false);
        alert(err.response.data.message || "დაფიქსირდა შეცდომა");
      });
  }

  async function generateSchedulingLogReport() {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}agency/generate_report_scheduling_log`,
      data: filterData,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        let fileName = "SchedulingLogReport.xlsx";
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        // Convert blob to text
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        if (error.response.data.type === "application/json") {
          reader.onload = () => {
            const res = JSON.parse(reader.result);
            alert(res.message);
          };
        } else {
          alert("დაფიქსირდა შეცდომა");
        }
      });
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="რაპორტის გენერირება" style={{ borderBottom: "1px solid #f3f3f3" }} />
        <CardContent>
          <div className={classes.filter}>
            {!isBank ? (
              <FormControl className={classes.filterSelector}>
                <InputLabel id="bank-label" shrink>
                  ბანკი
                </InputLabel>
                <Select
                  labelId="bank-label"
                  id="bank-select"
                  value={filterData?.bank}
                  onChange={(event) => setFilterData({ ...filterData, bank_id: event.target.value })}
                >
                  {banks?.map((bank) => (
                    <MenuItem value={bank._id}>{bank.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            <FormControl className={classes.filterSelector} style={{ display: isMonitoring ? "none" : "block" }}>
              <InputLabel id="status-label">სტატუსი</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={filterData?.status}
                onChange={(event) => setFilterData({ ...filterData, status: event.target.value })}
              >
                {approvalStatuses?.map((approvalStatus) => (
                  <MenuItem value={approvalStatus.value}>{approvalStatus.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.dateSelector}>
              <InputLabel shrink>თარიღი (დან)</InputLabel>
              <Input type="date" onChange={(event) => setFilterData({ ...filterData, date_from: event.target.value })}></Input>
            </FormControl>
            <FormControl className={classes.dateSelector} onChange={(event) => setFilterData({ ...filterData, date_to: event.target.value })}>
              <InputLabel shrink>თარიღი (მდე)</InputLabel>
              <Input shrink type="date"></Input>
            </FormControl>
            {/* Add checkbox for displaying all schedules */}
            {/* Add a selector for component type */}
            <FormControl className={classes.filterSelector}>
              <InputLabel id="component-label" shrink>
                კომპონენტი
              </InputLabel>
              <Select
                labelId="component-label"
                id="component-select"
                value={filterData.component}
                onChange={(event) => setFilterData((prev) => ({ ...prev, component: event.target.value }))}
              >
                {/* Render options for all components, consider that if is_agency: true on component, then have !isBank check */}
                {components.map((component) => {
                  if (component.agency_only && isBank) {
                    return null;
                  }
                  return (
                    <MenuItem value={component.value} key={component.value}>
                      {component.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControlLabel
              style={{ display: isMonitoring ? "none" : "block" }}
              control={
                <Checkbox
                  checked={filterData.all_schedules}
                  onChange={(event) => setFilterData((prev) => ({ ...prev, all_schedules: event.target.checked }))}
                  value="all_schedules"
                />
              }
              label="ყველა მოთხოვნილი თვე"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Button
              style={{ marginRight: 30 }}
              disabled={reportLoading}
              color="primary"
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              onClick={() => generateReport()}
            >
              ჩამოტვირთვა
            </Button>
            {/* Hide for bank users */}
            {isBank || (!isBank && localStorage.getItem("agency-level") > 0) ? (
              <>
                <Button
                  disabled={reportLoading || filterData.component === "all" || filterData.component === "universal_risk"}
                  style={{ whiteSpace: "nowrap", marginRight: 30 }}
                  color="primary"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generateSchedulingReport()}
                >
                  გადახდების მოთხოვნის შემაჯამებელი რაპორტი
                </Button>
              </>
            ) : null}
            {!isBank && (
              <>
                <Button
                  disabled={reportLoading || filterData.component === "all" || filterData.component === "universal_risk"}
                  color="primary"
                  style={{ whiteSpace: "nowrap", marginRight: 30, display: isMonitoring ? "none" : "flex" }}
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generateAgencyShareReport()}
                >
                  სააგენტოს წილის შემაჯამებელი რაპორტი
                </Button>
                <Button
                  disabled={reportLoading || !filterData.bank_id || filterData.component === "all" || filterData.component === "universal_risk"}
                  style={{ display: isMonitoring ? "none" : "flex" }}
                  color="primary"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generateRefinancingRateReport()}
                >
                  რეფინანსირების განაკვეთის რაპორტი (2024)
                </Button>
              </>
            )}
          </div>
        </CardContent>
        <LinearProgress
          style={{
            margin: "0 auto",
            display: reportLoading ? "block" : "none",
          }}
        />
      </Card>

      {!isBank && localStorage.getItem("agency-level") > 0 ? (
        <Card className={classes.card} style={{ margin: "2rem 0" }}>
          <CardHeader
            title={
              <>
                გადარიცხვების ასახვა
                <>
                  {/* Create a button with excel icon on the right edge to download template */}
                  <Button
                    disabled={reportLoading}
                    style={{
                      float: "right",
                      marginLeft: "auto",
                      marginRight: "1rem",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      whiteSpace: "nowrap",
                    }}
                    color="primary"
                    variant="contained"
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => generatePaymentsReport()}
                  >
                    გადახდების შემაჯამებელი რაპორტი
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      marginRight: "1rem",
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                    }}
                    variant="outlined"
                    color="primary"
                    startIcon={<ListAltIcon />}
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_BASE_URL}public/payment_template.xlsx`);
                    }}
                  >
                    გადახდების შაბლონის ჩამოტვირთვა
                  </Button>
                </>
              </>
            }
            style={{ borderBottom: "1px solid #f3f3f3" }}
          />
          <CardContent>
            {paymentDataLoading ? (
              <LinearProgress />
            ) : (
              <div {...getRootProps({ className: "dropbox" })}>
                <input {...getInputProps()} />
                {isDragActive ? <p>ჩააგდეთ ფაილები...</p> : <p>ჩააგდეთ ფაილები ან დააჭირეთ ფაილების ასარჩევად.</p>}
              </div>
            )}
          </CardContent>
        </Card>
      ) : null}
      {!isBank && localStorage.getItem("agency-level") > 0 && (
        <Card className={classes.card} style={{ margin: "2rem 0" }}>
          <CardHeader
            title={
              <>
                ძველი იპოთეკის გარანტიები
                <Button
                  disabled={reportLoading}
                  style={{
                    float: "right",
                    marginLeft: "auto",
                    marginRight: "1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generateLegacyMortgageReport()}
                >
                  რაპორტის გადმოწერა
                </Button>
              </>
            }
            style={{ borderBottom: "1px solid #f3f3f3" }}
          />
        </Card>
      )}
      {!isBank && localStorage.getItem("agency-level") > 0 && (
        <Card className={classes.card} style={{ margin: "2rem 0" }}>
          <CardHeader
            title={
              <>
                გრაფიკების სერვისების ისტორია
                <Button
                  disabled={reportLoading}
                  style={{
                    float: "right",
                    marginLeft: "auto",
                    marginRight: "1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generateSchedulingLogReport()}
                >
                  რაპორტის გადმოწერა
                </Button>
              </>
            }
            style={{ borderBottom: "1px solid #f3f3f3" }}
          />
        </Card>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.between("md", "lg")]: {
      width: "90%",
    },
  },
  badgeApproved: {
    color: "white",
    backgroundColor: "#4E9F3D",
  },
  badgeReview: {
    color: "white",
    backgroundColor: "#C8C6C6",
  },
  filter: {
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "row",
    padding: "15px 5px",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "10px 0px",
    },
    border: "3px dotted #f3f3f3",
  },
  filterSelector: {
    minWidth: 120,
    marginRight: 20,
  },
  root: {
    width: "100%",
  },
  dateSelector: {
    marginRight: 20,
  },
  field: {
    margin: "0 30px 0 0",
  },
}));

export default Report;
