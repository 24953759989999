import "./MortgageDetailed.css";
import { PostData } from "../../../utilities/PostData";
import { useState, useEffect } from "react";
import DetailedStyles from "../../../utilities/DetailedStyles";
import { Card, CardContent, Button, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import dateFormat from "dateformat";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ScheduleTable from "../../../components/detailedApplication/scheduleTable/ScheduleTable";
import PaymentTable from "../../../components/detailedApplication/paymentTable/PaymentTable";
import ScheduleDiscrepancy from "../../../components/detailedApplication/scheduleDiscrepancy/ScheduleDiscrepancy";
import CloseLoan from "../../../components/detailedApplication/CloseLoan";
import ClosureData from "../../../components/detailedApplication/ClosureData";
import ReactDOM from "react-dom";
import SetDetailedApplicationData from "../../../utilities/SetDetailedApplicationData";
import BankPaymentTable from "../../../components/detailedApplication/bankPaymentTable/BankPaymentTable";
import MortgageChildren from "../../../components/detailedApplication/MortgageChildren";
import ApprovalHistoryTable from "../../../components/detailedApplication/ApprovalHistoryTable";
import LegacyGuaranteeInformation from "../../../components/detailedApplication/LegacyGuaranteeInformation";
import LoanID from "../../../components/detailedApplication/LoanID";
import ScheduleTolerancyDateSetter from "../../../components/detailedApplication/ScheduleTolerancyDateSetter";
import SameApplicantTable from "../../../components/detailedApplication/sameApplicantTable/SameApplicantTable";
import InternalAttachments from "../../../components/detailedApplication/internalAttachments/InternalAttachments";
import { downloadPDF, generatePDF, htmlTemplates } from "../../../utilities/PdfUtility";

function MortgageDetailed(props) {
  const [applicationInformation, setApplicationInformation] = useState();
  const [loading, setLoading] = useState(true);
  const [printLoad, setPrintLoad] = useState(false);
  const [isBank, setIsBank] = useState();
  const [isBankHead, setIsBankHead] = useState();
  const [agencyLevel, setAgencyLevel] = useState();
  const [canModifyScheduling, setCanModifyScheduling] = useState(false);
  const classes = DetailedStyles();

  useEffect(() => {
    SetDetailedApplicationData({
      setIsBank,
      setIsBankHead,
      setCanModifyScheduling,
      setAgencyLevel,
      setApplicationInformation,
      id: props.match.params.id,
      setLoading,
      applicationType: props.match.path.split("/")[1],
    });
  }, []);
  useEffect(() => {
    let details = document.getElementsByClassName(classes.detail);
    if (loading) {
      for (let i = 0; i < details.length; i++) {
        let skeleton = document.createElement("div");
        skeleton.className = "skeleton";
        ReactDOM.render(<Skeleton variant="text" />, skeleton);
        details[i].appendChild(skeleton);
      }
    } else {
      let skeletons = document.getElementsByClassName("skeleton");
      for (let i = 0; i < skeletons.length; i++) {
        skeletons[i].style.display = "none";
      }
    }
  }, [loading]);

  const approvalStatus = () => {
    if (applicationInformation?.suspended) {
      return { name: "გაუქმებული", icon: <RemoveCircleIcon color="primary" /> };
    }
    if (applicationInformation?.approved === true) {
      return { name: "დადასტურებული", icon: <CheckIcon color="primary" /> };
    } else if (applicationInformation?.approved === false) {
      return { name: "უარყოფილი", icon: <ErrorIcon color="primary" /> };
    } else {
      return { name: "განსახილველი", icon: <CachedIcon color="primary" /> };
    }
  };

  const submitApprovalHandler = (approvalStatus) => {
    let rejectionComment;
    if (!approvalStatus) {
      rejectionComment = prompt("უარყოფის მიზეზი: ");
    }
    //if applicationInformation.application_type.name === "დაიხურა", then prompt for closure comment
    let closureComment;
    if (applicationInformation?.application_type?.name === "დაიხურა") {
      closureComment = prompt("კომენტარი: ");
    }

    PostData("agency/approve_mortgage_application", {
      application_id: props.match.params.id,
      approval: approvalStatus,
      rejection_comment: rejectionComment,
      closure_comment: closureComment,
    }).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  const suspendApplicationHandler = () => {
    let suspensionReason = prompt("გაუქმების მიზეზი: ");
    if (!suspensionReason) return;
    if (!window.confirm("გაუქმების დადასტურება")) return;

    let request = { application_type: "mortgage", application_id: props.match.params.id, suspension_reason: suspensionReason };
    PostData("agency/suspend_application", request).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.innerCardContent}>
          <div
            className="left"
            // If view is mobile, set width to 100% but by default set width to 50%
            style={{
              width: window.innerWidth < 600 ? "100%" : "50%",
            }}
          >
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>სესხის შესახებ ინფორმაცია</Typography>

                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>სესხის ID:</td>
                      <td className={classes.detail}>
                        <LoanID application={applicationInformation} isBankHead={isBankHead} />
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სტატუსი:</td>
                      <td className={classes.detail} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ margin: "0 2px 0 0" }}>{approvalStatus()?.icon}</div>
                        <p>{approvalStatus()?.name}</p>
                      </td>
                    </tr>
                    {applicationInformation?.suspended ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>გაუქმების მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.suspension_reason}</td>
                      </tr>
                    ) : null}
                    {applicationInformation?.rejection_comment ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>უარყოფის მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.rejection_comment}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>მიზნობრიობა:</td>
                      <td className={classes.detail}>{applicationInformation?.purpose?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.application_type?.name}</td>
                    </tr>
                    <ClosureData classes={classes} applicationInformation={applicationInformation} />
                    <tr className={classes.row}>
                      <td className={classes.title}>ფინანსური ინსტიტუცია:</td>
                      <td className={classes.detail}>{applicationInformation?.bank?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომელი:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომლის ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ფინ. ინსტიტუტში განაცხადის დამტკიცების თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.loan_information?.approval_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                    {applicationInformation &&
                      applicationInformation.approved &&
                      applicationInformation.approval_history &&
                      applicationInformation.approval_history.length > 0 && (
                        <tr className={classes.row}>
                          <td className={classes.title}>სააგენტოში დამტკიცების თარიღი:</td>
                          <td className={classes.detail}>
                            {dateFormat(applicationInformation.approval_history[applicationInformation.approval_history.length - 1].date, "yyyy-mm-dd", "T")}
                          </td>
                        </tr>
                      )}
                    <tr className={classes.row}>
                      <td className={classes.title}>თანხა:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.amount?.toLocaleString()}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სესხის ვადა (თვე):</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.duration}</td>
                    </tr>
                    {applicationInformation?.loan_information?.borrower_co_financing ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>მსესხებლის თანამონაწილეობა:</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.borrower_co_financing?.toLocaleString()}</td>
                      </tr>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>დამტკიცებული საპროცენტო განაკვეთი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.approved_interest_rate}%</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>რეფინანსირების განაკვეთი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.refinancing_rate * 100 || null}%</td>
                    </tr>
                    {/* <tr className={classes.row}>
                    <td className={classes.title}>ეფექტური საპროცენტო განაკვეთი:</td>
                    <td className={classes.detail}>{applicationInformation?.loan_information?.effective_interest_rate}</td>
                  </tr> */}
                    <tr className={classes.row}>
                      <td className={classes.title}>თანადაფინანსების ვადა:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.subsidy_duration_months}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>აპლიკაციის შემოსვლის თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.submit_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>ინფორმაცია საკუთრების შესახებ</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>რეგიონი:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.municipality?.region}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>მუნიციპალიტეტი:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.municipality?.municipality}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ზუსტი მისამართი:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.exact_address}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საკადასტრო კოდი:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.cadastral_code}</td>
                    </tr>
                    {applicationInformation?.home_information?.cadastral_code_absence_reason ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>საკადასტრო კოდის არარსებობის მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.cadastral_code_absence_reason}</td>
                      </tr>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>საბაზრო ღირებულება:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.market_value}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ვალუტა:</td>
                      <td className={classes.detail}>{applicationInformation?.home_information?.market_value_currency}</td>
                    </tr>
                    {applicationInformation?.home_information?.with_storage ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>დამატებით სამეურნეო სათავსო(ებ)ი, განთავსებული იმავე საცხოვრებელ სახლში/კომპლექსში:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.with_storage ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.home_information?.developer_information?.name ? (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <Typography className={classes.cardSummary}>ინფორმაცია დეველოპერის შესახებ</Typography>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>სამართლებრივი ფორმა:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.developer_information?.legal_form?.name}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სახელი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.developer_information?.name}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.developer_information?.identification_number}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>იურიდიული მისამართი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.developer_information?.legal_address}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}

            {applicationInformation?.home_information?.other_person_information?.name ? (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <Typography className={classes.cardSummary}>ინფორმაცია სხვა პირის შესახებ</Typography>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>სამართლებრივი ფორმა:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.other_person_information?.legal_form?.name}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სახელი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.other_person_information?.name}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                        <td className={classes.detail}>{applicationInformation?.home_information?.other_person_information?.identification_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {applicationInformation?.same_applicant_applications?.length > 0 && !isBank && (
              <SameApplicantTable classes={classes} rows={applicationInformation?.same_applicant_applications} component="mortgage" />
            )}
            {!isBank && (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <InternalAttachments
                    externalClasses={classes}
                    applicationId={props.match.params.id}
                    component="mortgage"
                    initialFiles={applicationInformation?.internal_attachments?.map((attachment) => ({
                      fileName: attachment.path.split("/").pop(),
                      url: process.env.REACT_APP_BASE_URL + attachment.path,
                    }))}
                  />
                </CardContent>
              </Card>
            )}
          </div>
          <div className="right" style={{ width: window.innerWidth < 600 ? "100%" : "50%" }}>
            {applicationInformation?.approval_history?.length > 0 && !isBank ? (
              <ApprovalHistoryTable classes={classes} applicationInformation={applicationInformation} />
            ) : null}
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>მსესხებელი</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>ოჯახური მდგომარეობის სტატუსი:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.marital_status}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სახელი:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>პირადი ნომერი:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.private_number}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ტელეფონის ნომერი:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.phone_number}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>მოქალაქეობა:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.citizenship}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სქესი:</td>
                      <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower?.gender}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>იმყოფება რეგისტრირებულ ქორწინებაში:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.borrower_information?.borrower?.is_married ? "მონიშნულია" : "არ არის მონიშნული"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {applicationInformation?.borrower_information?.borrower_spouse?.name ? (
                  <>
                    <Typography className={classes.cardSummary}>მსესხებლის მეუღლის ინფორმაცია</Typography>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td className={classes.title}>სახელი:</td>
                          <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower_spouse?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პირადი ნომერი:</td>
                          <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower_spouse?.private_number}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>მოქალაქეობა:</td>
                          <td className={classes.detail}>{applicationInformation?.borrower_information?.borrower_spouse?.citizenship}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : null}
                {/* If application url contains legacy-mortgage-application, don't display mortgagechildren */}
                {props.match.url.includes("legacy-mortgage-application") ? null : (
                  <MortgageChildren classes={classes} applicationInformation={applicationInformation} />
                )}
                {applicationInformation?.borrower_information?.co_borrower?.name ? (
                  <>
                    <Typography className={classes.cardSummary}>თანამსესხებლის ინფორმაცია</Typography>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td className={classes.title}>სახელი:</td>
                          <td className={classes.detail}>{applicationInformation?.borrower_information?.co_borrower?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პირადი ნომერი:</td>
                          <td className={classes.detail}>{applicationInformation?.borrower_information?.co_borrower?.private_number}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : null}
              </CardContent>
            </Card>
            {applicationInformation?.refinancing_information?.loan_id ? (
              <Card className={classes.component}>
                <CardContent>
                  <Typography className={classes.cardSummary}>რეფინანსირება</Typography>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>ფინ. ინსტიტუტი:</td>
                        <td className={classes.detail}>{applicationInformation?.refinancing_information?.initial_bank?.name}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სესხის ID:</td>
                        <td className={classes.detail}>{applicationInformation?.refinancing_information?.loan_id}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სესხის პირველი ტრანშის გაცემის თარიღი:</td>
                        <td className={classes.detail}>
                          {dateFormat(applicationInformation?.refinancing_information?.first_disbursment_date, "yyyy-mm-dd", "T")}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სესხის თანხა - ₾:</td>
                        <td className={classes.detail}>{applicationInformation?.refinancing_information?.approved_amount}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ნაშთი:</td>
                        <td className={classes.detail}>{applicationInformation?.refinancing_information?.remaining_amount}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>დარჩენილი ასათვისებელი მოცულობა:</td>
                        <td className={classes.detail}>{applicationInformation?.refinancing_information?.outstanding_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {!isBank && <LegacyGuaranteeInformation classes={classes} applicationInformation={applicationInformation} />}
            {!isBank && (
              <ScheduleTolerancyDateSetter canModifyScheduling={canModifyScheduling} classes={classes} applicationInformation={applicationInformation} />
            )}
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>მიმაგრებული ფაილები</Typography>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.attached_documents?.children_birth_certificate.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>დაბადების მოწმობა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attached_documents?.child_adoption_certificate.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>ბავშვის აყვანის მოწმობა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attached_documents?.single_mother_certificate.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>მარტოხელა დედის მოწმობა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attached_documents?.spouse_death_certificate.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>მეუღლის გარდაცვალების მოწმობა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {/* Special card for changes, if updateHistory exists. */}
            {/* updateHistory schema from backend - 
              updateHistory: [
                {
                  field: String,
                  from: mongoose.Schema.Types.Mixed,
                  to: mongoose.Schema.Types.Mixed,
                  changedAt: { type: Date, default: Date.now },
                },
              ], */}
            {process.env.REACT_APP_ENVIRONMENT === "development" && applicationInformation?.updateHistory?.length > 0 && !isBank && (
              <Card
                className={classes.component}
                style={{
                  backgroundColor: "#fff9c4",
                }}
                variant="outlined"
              >
                <CardContent>
                  <Typography className={classes.cardSummary}>ცვლილებები</Typography>
                  <table className={classes.table}>
                    <tbody>
                      {applicationInformation?.updateHistory?.map((update) => (
                        <tr className={classes.row}>
                          <td className={classes.title}>{update.field}:</td>
                          <td className={classes.detail}>
                            {typeof update.from === "boolean"
                              ? update.from
                                ? "მონიშნულია"
                                : "არ არის მონიშნული"
                              : typeof update.from === "object"
                              ? "Object"
                              : update.from}
                            {" -> "}
                            {typeof update.to === "boolean"
                              ? update.to
                                ? "მონიშნულია"
                                : "არ არის მონიშნული"
                              : typeof update.to === "object"
                              ? `Field: ${update.to.field}, To: ${update.to.to}`
                              : update.to}{" "}
                            ({dateFormat(update.changedAt, "yyyy-mm-dd", "T")})
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            )}
          </div>
          {isBank && (
            // button for editing application. will take you to edit-application/mortgage/:id
            <Button
              disabled={applicationInformation?.approved || applicationInformation?.suspended}
              onClick={() => {
                props.history.push(`/edit-application/mortgage/${props.match.params.id}`);
              }}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<EditIcon />}
            >
              რედაქტირება
            </Button>
          )}
          {!loading && <ScheduleDiscrepancy isBank={isBank} loan_information={applicationInformation} />}
          {applicationInformation?.schedules?.length > 0 ? <ScheduleTable rows={applicationInformation.schedules} /> : null}
          {applicationInformation?.payments?.length > 0 ? <PaymentTable rows={applicationInformation.payments} /> : null}
          {applicationInformation?.bank_payment_history?.length > 0 ? <BankPaymentTable rows={applicationInformation.bank_payment_history} /> : null}
          <div className={classes.buttonContainer}>
            {!isBank && agencyLevel > 0 && (typeof applicationInformation?.approved === "undefined" || applicationInformation?.approved === null) ? (
              <>
                <Button onClick={() => submitApprovalHandler(true)} variant="contained" color="primary" startIcon={<CheckIcon />} disabled={loading}>
                  დადასტურება
                </Button>
                <Button onClick={() => submitApprovalHandler(false)} variant="contained" color="primary" startIcon={<ErrorIcon />} disabled={loading}>
                  უარყოფა
                </Button>
              </>
            ) : null}
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() =>
                  generatePDF(htmlTemplates.generateMortgageForBank(applicationInformation))
                    .then((pdfBlob) => downloadPDF(pdfBlob))
                    .then((a) => console.log("pdf downloaded"))
                    .catch((err) => {
                      console.error(err);
                      alert("დაფიქსირდა შეცდომა");
                    })
                }
              >
                PRINT
              </Button>
            </>
            <CloseLoan
              agencyLevel={agencyLevel}
              approved={applicationInformation?.approved}
              bankPayments={applicationInformation?.bank_payment_history}
              component="mortgage"
              loan_id={props.match.params.id}
              reloading={loading}
            />
          </div>

          {!isBank && agencyLevel >= 2 && !applicationInformation?.suspended ? (
            <>
              <Button
                onClick={() => suspendApplicationHandler()}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<RemoveCircleIcon />}
                disabled={loading}
              >
                სესხის გაუქმება
              </Button>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}

export default MortgageDetailed;
