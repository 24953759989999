/**
 * @React
 */
import React, {
    useEffect,
    useState,
    createContext,
    useContext
} from 'react';

/**
 * @Hooks
 */
import { useFetchData } from '../../hooks';
/**
 * @Utils
 */
import {
    generateOptions,
    transformArray,
    transformDataStructure
} from '../../utilities/Fields/monitoringFields/utils';
import { generateUrl } from '../../utilities/generateRequestUrl';
import config from '../../utilities/generateRequestUrl/config';
/**
 * @Types
 */
import {
    ICountry,
    INaceActivity,
    InputObject,
    MonitoringFieldsOptionsData,
    MonitoringFieldsOptionType,
    MonitoringFieldsOptionTypeTest,
    MonitoringValues,
    PropertyMappings,
    TransformedData
} from '../../types';
enum endpoints_enum {
    GETGENERALINFO = 'getGeneralInfo',
}
const propertyMappings: PropertyMappings = {
    countries: ['name'],
    nace_activities: ['activity_code', 'activity_description'],
    municipalities: [`region`, `municipality`],
    banks: ['name']
};
export interface GeneralInfoContextType {
    options: TransformedData | null;
    setOptions: React.Dispatch<React.SetStateAction<TransformedData | null>>;
    generatedValues: MonitoringValues | null;
    setGeneratedValues: React.Dispatch<React.SetStateAction<MonitoringValues | null>>;
    data: MonitoringFieldsOptionsData | null;
    load: boolean;
    error: string | null;
}
const GeneralInformationContext = createContext<GeneralInfoContextType | undefined>(undefined);
const GeneralInformationContextProvider: React.FC = ({ children }) => {
    const [options, setOptions] = useState<TransformedData | null>(null);
    const [generatedValues, setGeneratedValues] = useState<MonitoringValues | null>(null);
    const { data, load, error } = useFetchData<MonitoringFieldsOptionsData | null>(generateUrl(config.client, endpoints_enum.GETGENERALINFO, null, null));

    useEffect(() => {
        if (data) {
            const transformedData3 = transformDataStructure(data as any, propertyMappings);
            const { options, values } = transformedData3
            setOptions(options);
            setGeneratedValues(values);
        }
    }, [data]);

    return (
        <GeneralInformationContext.Provider value={{
            options,
            setOptions,
            generatedValues,
            setGeneratedValues,
            data,
            load,
            error
        }}>
            {children}
        </GeneralInformationContext.Provider>
    );
};
export const useGeneralInfoContext = () => {
    const context = useContext(GeneralInformationContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};

export default GeneralInformationContextProvider;
