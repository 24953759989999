import "../addApplication/AddApplication.css";
import { Card, CardContent } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MortgageNew from "../../components/application/mortgage/MortgageNew";

function EditApplication() {
  return (
    <Router basename="/edit-application">
      <div className="add_application_container">
        <Card className="add_application_card" style={{ boxShadow: "none" }}>
          <CardContent className="add_application_card_content" style={{ padding: "0" }}>
            <Switch>
              <Route path="/mortgage/:id" exact render={({ match }) => <MortgageNew editing mortgageID={match.params.id} />} />
            </Switch>
          </CardContent>
        </Card>
      </div>
    </Router>
  );
}

export default EditApplication;
