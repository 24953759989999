import React, { useState, useEffect } from "react";
import { Button, Modal, Box, TextField, Select, FormControl, InputLabel, Typography, MenuItem } from "@mui/material";
import axios from "axios";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

interface BankPayment {
  date: string;
}

interface CloseLoanProps {
  agencyLevel: number;
  component: string;
  loan_id: number | string;
  approved: boolean;
  bankPayments: BankPayment[];
  reloading: boolean;
}

const ClosureModal: React.FC<{
  open: boolean;
  onClose: () => void;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
  loading: boolean;
  handleSubmit: () => void;
}> = ({ open, onClose, reason, setReason, date, setDate, options, loading, handleSubmit }) => (
  <Modal open={open} onClose={onClose} aria-labelledby="closure-modal" aria-describedby="closure-modal-description">
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
      }}
    >
      <Box>
        <Typography variant="h6" component="h2" id="closure-modal-description">
          სესხის დახურვა
        </Typography>
      </Box>
      <FormControl fullWidth margin="normal">
        <InputLabel id="loan_closure_reason-label">მიზეზი</InputLabel>
        <Select
          labelId="loan_closure_reason-label"
          id="loan_closure_reason"
          value={reason}
          label="მიზეზი"
          onChange={(e) => setReason(e.target.value as string)}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="loan_closure_date"
        label="თარიღი"
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mt: 2 }}
      />
      <Button variant="contained" color="primary" disabled={loading} sx={{ mt: 2 }} onClick={handleSubmit}>
        დახურვის მოთხოვნა
      </Button>
    </Box>
  </Modal>
);

const CloseLoan: React.FC<CloseLoanProps> = ({ agencyLevel, component, loan_id, approved, bankPayments, reloading }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const options = [
    "სრულად დაფარვა",
    "წინსწრებით სრულად დაფარვა",
    "დეფოლტი",
    "რეფინანსირება",
    "პროგრამიდან გასვლა და თანხის დაბრუნება",
    "ჩახსნა",
    "თანადაფინანსების ვადის გასვლა",
    "სესხი არ გაცემულა",
    "შეწყვეტილი",
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(options[0]);
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    if (bankPayments && bankPayments.length > 0) {
      const lastPaymentDate = bankPayments[bankPayments.length - 1].date.slice(0, 10);
      setDate(lastPaymentDate);
    }
  }, [bankPayments]);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post("/agency/application/close_loan", {
        loanID: loan_id,
        component: component,
        reason: reason,
        date: date,
      })
      .then((res) => {
        setLoading(false);
        setModalOpen(false);
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setModalOpen(false);
        alert(err.response?.data?.message || "An error occurred");
      });
  };

  return (
    <>
      <ClosureModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        reason={reason}
        setReason={setReason}
        date={date}
        setDate={setDate}
        options={options}
        loading={loading}
        handleSubmit={handleSubmit}
      />
      {agencyLevel >= 2 && (
        <Button
          startIcon={<CancelPresentationIcon />}
          sx={{
            display: approved ? "flex" : "none",
          }}
          disabled={reloading}
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          სესხის დახურვა
        </Button>
      )}
    </>
  );
};

export default CloseLoan;
