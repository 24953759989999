export function GetData(endpoint, requestBody, isFile) {
  requestBody = JSON.stringify(requestBody);

  var myHeaders = new Headers();
  if (localStorage.getItem("x-access-token")) {
    myHeaders.append("x-access-token", localStorage.getItem("x-access-token"));
  }

  var requestData = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, requestData).then((response) => response.json());
}
