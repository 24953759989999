/**
 * @React
 */
import {
    useState,
    useEffect,
    FC
} from "react";
/**
 * @ThirdPartProgramms
 */
import dateFormat from "dateformat";
/**
 * @Mui
 */
import {
    ImageList,
    ImageListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
/**
 * @Utilities
 */
import DetailedStyles from "../../../../utilities/DetailedStyles";
import { fields, generateValueFormat } from "../../../../utilities/Fields/monitoringFields/fields/changesFields";
/**
 * @Types
 */
import { IMonitoringApprovalHistory, UploadedAttachments } from "../../../../types";


interface IMonitoringImagesGalleryProps {
    imagesList: string[];
}

const MonitoringImagesGallery: FC<IMonitoringImagesGalleryProps> = ({
    imagesList
}: IMonitoringImagesGalleryProps) => {

    return (
        <ImageList sx={{ width: "100%" }} cols={3} rowHeight={230} gap={10}>
            {
                imagesList.map((item, index) => (
                    <a key={`${item}-${index++}`} href={process.env.REACT_APP_BASE_URL + item}>
                        <ImageListItem key={`${item}-${index}`}>
                            <img
                                srcSet={`${process.env.REACT_APP_BASE_URL + item}`}
                                src={`${process.env.REACT_APP_BASE_URL + item}`}
                                alt={item}
                                loading="lazy"
                            />
                        </ImageListItem>
                    </a>
                ))
            }
        </ImageList>
    );
}

export default MonitoringImagesGallery;
/**
 *   const columns = [
    {
      field: "bank",
      headerName: "ბანკი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.bank.name;
      },
    },
    { field: "amount", headerName: "თანხა", flex: 2, headerAlign: "center", align: "center" },
    { field: "interest_amount", headerName: "პროცენტი", flex: 2, headerAlign: "center", align: "center" },
    { field: "principal_amount", headerName: "ძირი", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "date",
      headerName: "თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.date, "yyyy-mm-dd", true);
      },
    },
  ];

  return (
    <div style={{ marginTop: 30, height: 400, width: "100%" }}>
      <DataGrid getRowId={(row) => row._id} rows={rows} columns={columns} pageSize={100} rowsPerPageOptions={[5]} disableColumnMenu hideFooter />;
    </div>
  );
}
 */
