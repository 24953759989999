/**
 * @React
 */
import { useState, useEffect, FC } from "react";
/**
 * @MUI
 */
import {
    Card,
    CardContent,
    Typography,
    Box,
    Divider
} from "@material-ui/core";
import { Alert } from "@mui/material";
/**
 * @Types
 */
import {
    IDifferenceFromAgreement,
    IEditField,
    IEditFieldGroup,
    IMonitoringAgreementModel,
    IMonitoringFields,
    Program,
    ProgramComponent,
    TourismType
} from "../../../../types";
/**
 * @Utilities
 */
import DetailedStyles from "../../../../utilities/DetailedStyles";
import { generateValueFormat } from "../../../../utilities/Fields/monitoringFields/monitoringDeps";
import MonitoringLogsDetails from "../MonitoringLogsDetails";
import MonitoringApprovalHistory from "../MonitoringApprovalHistory";
import MonitoringImagesGallery from "../MonitoringImagesGallery";


interface MonitoringDetailsProps {
    details: IEditFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[];
    dependencies: any;
    data: IMonitoringFields & Partial<IMonitoringAgreementModel>;
}

const MonitoringDetails: FC<MonitoringDetailsProps> = ({ details, dependencies, data }: MonitoringDetailsProps) => {
    const classes = DetailedStyles();
    const [note, setNote] = useState<{
        funds_turnover: boolean;
        "tourist_services_information.adventure_tourism_measures.group_service": boolean;
        "tourist_services_information.adventure_tourism_measures.guest_life_insurance": boolean;
        "tourist_services_information.adventure_tourism_measures.individual_service": boolean;
        "tourist_services_information.historic_simulation": boolean;
    }>({
        funds_turnover: false,
        "tourist_services_information.adventure_tourism_measures.guest_life_insurance": false,
        "tourist_services_information.adventure_tourism_measures.group_service": false,
        "tourist_services_information.adventure_tourism_measures.individual_service": false,
        "tourist_services_information.historic_simulation": false
    });
    useEffect(() => {
        const used_amount = Number(data.used_amount) || 0;
        const funds_turnover = Number(data.funds_turnover) || 0;
        const fixed_assets_capital_expenditures = Number(data.fixed_assets_capital_expenditures) || 0;
        const program = data.program;
        const programComponent = data.program_component;
        const group_service = data?.tourist_services_information?.adventure_tourism_measures?.group_service || false;
        const guest_life_insurance = data?.tourist_services_information?.adventure_tourism_measures?.guest_life_insurance || false;
        const individual_service = data?.tourist_services_information?.adventure_tourism_measures?.individual_service || false;
        const tourist_services_information_type = data?.tourist_services_information?.type || "";
        const historic_simulation = data?.tourist_services_information?.historic_simulation || false;
        if (program === Program.INDUSTRIAL || program === Program.UNIVERSAL) {
            setNote((prev) => ({ ...prev, funds_turnover: funds_turnover > (0.5 * used_amount) }));
        }
        if (program === Program.CREDIT_GUARANTEE_PROGRAM) {
            setNote((prev) => ({ ...prev, funds_turnover: funds_turnover > (0.2 * used_amount) }));
            setNote((prev) => ({ ...prev, fixed_assets_capital_expenditures: fixed_assets_capital_expenditures == 0 }));
        }
        if (programComponent === ProgramComponent.TOURISM_SERVICES_INDUSTRY) {
            if (tourist_services_information_type === TourismType.AdventureTourismMeasures) {
                setNote((prev) => ({
                    ...prev,
                    "tourist_services_information.adventure_tourism_measures.group_service": !group_service,
                    "tourist_services_information.adventure_tourism_measures.guest_life_insurance": !guest_life_insurance,
                    "tourist_services_information.adventure_tourism_measures.individual_service": !individual_service
                }))
            }
            if (tourist_services_information_type === TourismType.HistoricSimulation) {
                setNote((prev) => ({
                    ...prev,
                    "tourist_services_information.historic_simulation": !historic_simulation
                }))
            }
        }
    }, [data]);
    return (
        <>
            <Box style={{ width: "100%" }}>
                {
                    details.map((item: IEditFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                        let isGroupVisible = true;
                        if (item.groupName in dependencies) {
                            if (!dependencies[item.groupName]) {
                                isGroupVisible = false;
                            }
                        }
                        return (
                            <>
                                {
                                    isGroupVisible
                                        ?
                                        <Card className={classes.component} variant="outlined">
                                            <CardContent>
                                                <Typography className={classes.cardSummary}>{item.groupLabel}</Typography>
                                                {
                                                    item.fields.map((field: IEditField<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                                                        let isVisible = true;
                                                        if (field.name in dependencies) {
                                                            if (!dependencies[field.name]) {
                                                                isVisible = false;
                                                            }
                                                        }
                                                        if (field.subGroupFields && field.subGroupFields.length > 0) {
                                                            return field.subGroupFields.map((subField: IEditField<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                                                                let isVisibleSubField = true;
                                                                if (subField.name in dependencies) {
                                                                    if (!dependencies[subField.name]) {
                                                                        isVisibleSubField = false;
                                                                    }
                                                                }
                                                                return (
                                                                    <>
                                                                        {
                                                                            isVisibleSubField
                                                                                ?
                                                                                <table className={classes.table}>
                                                                                    <tbody>
                                                                                        <tr className={classes.row}>
                                                                                            <td className={classes.title}>{subField.label}</td>
                                                                                            <td className={classes.detail}>
                                                                                                {generateValueFormat[subField.name as keyof typeof generateValueFormat] ? generateValueFormat[subField.name as keyof typeof generateValueFormat](subField.value as any) : subField.value}
                                                                                                {
                                                                                                    note[subField.name as keyof typeof note] ? (
                                                                                                        <>
                                                                                                            <Alert
                                                                                                                severity="warning"
                                                                                                                color="warning"
                                                                                                            >
                                                                                                                <i
                                                                                                                    style={{
                                                                                                                        color: "#ed6c02",
                                                                                                                        fontSize: "1em"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                                                                </i>
                                                                                                            </Alert>
                                                                                                        </>
                                                                                                    ) : null
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table> : null
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        } else {
                                                            const val = generateValueFormat[field.name as keyof typeof generateValueFormat]
                                                                ?
                                                                generateValueFormat[field.name as keyof typeof generateValueFormat](field.value as any)
                                                                :
                                                                field.value
                                                            let x = null;
                                                            if (field.sub_fields && field.sub_fields.length > 0) {
                                                                x = field.sub_fields.map((item: IEditField<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                <li style={{ listStyleType: "none", padding: "5px" }}>
                                                                                    {item.value}
                                                                                </li>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                            return (
                                                                <>
                                                                    {
                                                                        isVisible ? <table className={classes.table}>
                                                                            <tbody>
                                                                                <tr className={classes.row}>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingRight: "30px",
                                                                                        }}
                                                                                        className={classes.title}
                                                                                    >
                                                                                        {field.label}
                                                                                    </td>
                                                                                    <td
                                                                                        style={{
                                                                                            paddingLeft: "30px",
                                                                                        }}
                                                                                        className={classes.detail}
                                                                                    >
                                                                                        {
                                                                                            x
                                                                                                ?
                                                                                                <ul>
                                                                                                    {x}
                                                                                                </ul>
                                                                                                :
                                                                                                val
                                                                                        }
                                                                                        {
                                                                                            note[field.name as keyof typeof note] ? (
                                                                                                <>
                                                                                                    <Alert
                                                                                                        severity="warning"
                                                                                                        color="warning"
                                                                                                    >
                                                                                                        <i
                                                                                                            style={{
                                                                                                                color: "#ed6c02",
                                                                                                                fontSize: "1em"
                                                                                                            }}
                                                                                                        >
                                                                                                            აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                                                        </i>
                                                                                                    </Alert>
                                                                                                </>
                                                                                            ) : null
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table> : null
                                                                    }
                                                                </>

                                                            )
                                                        }

                                                    })

                                                }

                                            </CardContent>
                                        </Card>
                                        :
                                        null
                                }
                            </>
                        )
                    })
                }
                {
                    data?.attached_documents?.monitoring_attachments.map((attachment, index) => (
                        <tr className={classes.row}>
                            <td className={classes.title}>დოკუმენტი:</td>
                            <td className={classes.detail}>
                                <a href={process.env.REACT_APP_BASE_URL + attachment}>`ფაილი-{index++}`</a>
                            </td>
                        </tr>
                    ))
                }
                {
                    data
                    &&
                    data?.attached_documents
                    &&
                    data?.attached_documents?.monitoring_attachments.length > 0
                    &&
                    <Card className={classes.component} variant="outlined">
                        <CardContent>
                            <Typography className={classes.cardSummary}>{"ფოტოები"}</Typography>
                            <>

                                <MonitoringImagesGallery
                                    imagesList={data?.attached_documents.monitoring_attachments}
                                />

                            </>

                        </CardContent>
                    </Card>
                }
                {
                    data
                    &&
                    data?.difference_from_agreement
                    &&
                    <Card className={classes.component} variant="outlined">
                        <CardContent>
                            <Typography className={classes.cardSummary}>{"განაცხადის ცვლილების ისტორია"}</Typography>
                            <>
                                <MonitoringLogsDetails
                                    changedDataHistory={data?.difference_from_agreement ?? [] as IDifferenceFromAgreement[]}
                                />
                                <>
                                    <br />
                                    <br />
                                    <br />
                                    <Divider />
                                    <br />
                                    {
                                        data.monitoring_approval_history
                                        &&
                                        data.monitoring_approval_history.length > 0
                                        &&
                                        <MonitoringApprovalHistory
                                            approvalHistory={data.monitoring_approval_history}
                                        />
                                    }
                                </>

                            </>

                        </CardContent>
                    </Card>
                }
            </Box>
        </>
    );
}

export default MonitoringDetails;
