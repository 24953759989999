import { PostData } from "../../../utilities/PostData";
import { useState, useEffect } from "react";
import DetailedStyles from "../../../utilities/DetailedStyles";
import { Card, CardContent, Button, Typography } from "@material-ui/core";
import dateFormat from "dateformat";
import CheckIcon from "@material-ui/icons/Check";
// import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CachedIcon from "@material-ui/icons/Cached";
import ErrorIcon from "@material-ui/icons/Error";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import WorldBankTable from "../../../components/detailedApplication/WorldBankTable";
import CloseLoan from "../../../components/detailedApplication/CloseLoan";
import ClosureData from "../../../components/detailedApplication/ClosureData";
import { Skeleton } from "@material-ui/lab";
import ReactDOM from "react-dom";
import SetDetailedApplicationData from "../../../utilities/SetDetailedApplicationData";
import GetAgencyPosition from "../../../utilities/GetAgencyPosition";
import ApprovalHistoryTable from "../../../components/detailedApplication/ApprovalHistoryTable";
import ScheduleTable from "../../../components/detailedApplication/scheduleTable/ScheduleTable";
import PaymentTable from "../../../components/detailedApplication/paymentTable/PaymentTable";
import ScheduleDiscrepancy from "../../../components/detailedApplication/scheduleDiscrepancy/ScheduleDiscrepancy";
import BankPaymentTable from "../../../components/detailedApplication/bankPaymentTable/BankPaymentTable";
import LoanID from "../../../components/detailedApplication/LoanID";
import GreenTaxonomyTable from "../../../components/detailedApplication/GreenTaxonomyTable";
import ScheduleTolerancyDateSetter from "../../../components/detailedApplication/ScheduleTolerancyDateSetter";
import SameApplicantTable from "../../../components/detailedApplication/sameApplicantTable/SameApplicantTable";
import ApprovalStatus from "../../../components/detailedApplication/ApprovalStatus";
import { downloadPDF, generatePDF, htmlTemplates } from "../../../utilities/PdfUtility";
import InternalAttachments from "../../../components/detailedApplication/internalAttachments/InternalAttachments";
import UniversalGrantFields from "../../../components/detailedApplication/universalGrantFields/UniversalGrantFields";
import UniversalTransportActivitySum from "../../../components/detailedApplication/UniversalTransportActivitySum";

function UniversalDetailed(props) {
  const [applicationInformation, setApplicationInformation] = useState();
  const [isBank, setIsBank] = useState();
  const [isBankHead, setIsBankHead] = useState();
  const [status, setStatus] = useState();
  const [agencyLevel, setAgencyLevel] = useState();
  const [canModifyScheduling, setCanModifyScheduling] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = DetailedStyles();

  useEffect(() => {
    SetDetailedApplicationData({
      setIsBank,
      setIsBankHead,
      setCanModifyScheduling,
      setAgencyLevel,
      setApplicationInformation,
      id: props.match.params.id,
      setLoading,
      applicationType: "universal",
    });
  }, []);

  useEffect(() => {
    let details = document.getElementsByClassName(classes.detail);
    if (loading) {
      //Create new material-ui <Skeleton /> for each detail
      for (let i = 0; i < details.length; i++) {
        let skeleton = document.createElement("div");
        skeleton.className = "skeleton";
        ReactDOM.render(<Skeleton variant="text" />, skeleton);
        details[i].appendChild(skeleton);
      }
    } else {
      let skeletons = document.getElementsByClassName("skeleton");
      for (let i = 0; i < skeletons.length; i++) {
        skeletons[i].style.display = "none";
      }
    }
  }, [loading]);

  const submitApprovalHandler = (approvalStatus) => {
    let comment;
    let rejectionComment;
    if (!approvalStatus) {
      rejectionComment = prompt("უარყოფის მიზეზი: ");
    }
    if (approvalStatus && agencyLevel < 1) {
      comment = prompt("კომენტარი: ");
    }
    PostData("agency/approve_universal_application", {
      application_id: props.match.params.id,
      approval: approvalStatus,
      rejection_comment: rejectionComment,
      comment: comment,
    }).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  const suspendApplicationHandler = () => {
    let suspensionReason = prompt("გაუქმების მიზეზი: ");
    if (!suspensionReason) return;
    if (!window.confirm("გაუქმების დადასტურება")) return;

    let request = { application_type: "universal", application_id: props.match.params.id, suspension_reason: suspensionReason };
    PostData("agency/suspend_application", request).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.innerCardContent}>
          <div className="left" style={{ width: window.innerWidth < 600 ? "100%" : "50%" }}>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>სესხის შესახებ ინფორმაცია</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>სესხის/ლიზინგის ID:</td>
                      <td className={classes.detail}>
                        <LoanID application={applicationInformation} isBankHead={isBankHead} />
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სააგენტოს დადასტურების სტატუსი:</td>
                      <td className={classes.detail} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ margin: "0 2px 0 0" }}>{ApprovalStatus({ applicationInformation })?.icon}</div>
                        <p>{ApprovalStatus({ applicationInformation })?.name}</p>
                      </td>
                    </tr>
                    {applicationInformation?.suspended ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>გაუქმების მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.suspension_reason}</td>
                      </tr>
                    ) : null}
                    {applicationInformation?.rejection_comment ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>უარყოფის მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.rejection_comment}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.application_type?.name}</td>
                    </tr>
                    <ClosureData classes={classes} applicationInformation={applicationInformation} />
                    <tr className={classes.row}>
                      <td className={classes.title}>კომპონენტი:</td>
                      <td className={classes.detail}>{applicationInformation?.program_type?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საკრედიტო საგარანტიო სქემით სარგებლობა:</td>
                      <td className={classes.detail}>{applicationInformation?.credit_guarantee_scheme ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ფინანსური ინსტიტუცია:</td>
                      <td className={classes.detail}>{applicationInformation?.bank?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომელი:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომლის ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დამტკიცებული ვადა (თვე):</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.approval_months}</td>
                    </tr>
                    {applicationInformation?.loan_information?.subsidy_finish_date ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>სუბსიდირების დასრულების თარიღი:</td>
                        <td className={classes.detail}>{dateFormat(applicationInformation?.loan_information?.subsidy_finish_date, "yyyy-mm-dd", "T")}</td>
                      </tr>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>ფინ. ინსტიტუტში დამტკიცების თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.loan_information?.conditions?.approval_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                    {applicationInformation &&
                      applicationInformation.approved &&
                      applicationInformation.approval_history &&
                      applicationInformation.approval_history.length > 0 && (
                        <tr className={classes.row}>
                          <td className={classes.title}>სააგენტოში დამტკიცების თარიღი:</td>
                          <td className={classes.detail}>
                            {dateFormat(applicationInformation.approval_history[applicationInformation.approval_history.length - 1].date, "yyyy-mm-dd", "T")}
                          </td>
                        </tr>
                      )}

                    <tr className={classes.row}>
                      <td className={classes.title}>თანხა:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.approved_amount?.toLocaleString()}</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.upfront_amount ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>თანამონაწილეობა/წინასწარი შენატანი (GEL):</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.upfront_amount}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>მიზნობრიობის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.type?.name}</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.general?.amount ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>კაპიტალური ხარჯების თანხა:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.general?.amount?.toLocaleString()}</td>
                        </tr>
                        {applicationInformation?.loan_information?.conditions?.purpose?.general?.percentage ? (
                          <tr className={classes.row}>
                            <td className={classes.title}>კაპიტალური ხარჯების %:</td>
                            <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.general?.percentage}%</td>
                          </tr>
                        ) : null}
                      </>
                    ) : null}
                    {applicationInformation?.loan_information?.conditions?.purpose?.mixed?.amount ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>საბრუნავი საშუალების თანხა:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed?.amount?.toLocaleString()}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>საბრუნავი საშუალების %:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed?.percentage}%</td>
                        </tr>
                      </>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>მიზნობრიობა დეტალურად:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.purpose_detailed}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროცენტის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.interest_rate_type?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საპროცენტო განაკვეთი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.interest_rate}%</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.variable_rate ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>ბანკის მარჟა %:</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.variable_rate}%</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>ე.ბ.რ.გ.:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.refinancing_rate}%</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.mixed_interest_rate ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>შერეული საპროცენტო განაკვეთი:</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed_interest_rate}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>შვილობილი/მშობელი კომპანიის შეზღუდვა:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.loan_information?.conditions?.purpose?.child_company_restriction ? "მონიშნულია" : "არ არის მონიშნული"}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>აპლიკაციის შემოსვლის თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.submit_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>დამფუძნებლები</Typography>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.founders?.map((founder) => (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>დამფუძნებლის სამართლებრივი ფორმა:</td>
                          <td className={classes.detail}>{founder?.legal_form?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დამფუძნებლის დასახელება:</td>
                          <td className={classes.detail}>{founder?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                          <td className={classes.detail}>{founder?.identification_code}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>წილი:</td>
                          <td className={classes.detail}>{founder?.ownership_percentage}%</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>გენდერული სტატისტიკა</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>დირექტორი ქალები:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.director_women}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დამფუძნებელი ქალები:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.founder_women}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ქალების ჯამური მფლობელობის წილი:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.stakes_women}%</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>პროექტის ინფორმაცია</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროექტის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.project_type}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ეკონომიკური აქტივობა:</td>
                      <td>
                        {applicationInformation?.project_information?.economic_activity.map((activity) => (
                          <td className={classes.detail} style={{ fontSize: 10, maxWidth: 130 }}>
                            {activity.activity_code} {activity.activity_description},
                          </td>
                        ))}
                      </td>
                    </tr>
                    {/* If either of economic activity activity_code is 49.39.0, display */}
                    {!isBank && applicationInformation?.project_information?.economic_activity.some((activity) => activity.activity_code === "49.39.0") && (
                      <tr style={{ textAlign: "center", borderBottom: "1px #e3e3e3 solid", paddingBottom: "10px" }}>
                        <td colSpan={"2"}>
                          <UniversalTransportActivitySum data={applicationInformation.transport_activity_applications_sum} />
                        </td>
                      </tr>
                    )}
                    {/* TD for საქმიანობა ჯდება 15%-იან გრანტში */}
                    <tr className={classes.row}>
                      <td className={classes.title}>საქმიანობა ჯდება 15%-იან გრანტში:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.project_information?.economic_activity.some((activity) => activity.appendix_9 === true)
                          ? "აკმაყოფილებს"
                          : "არ აკმაყოფილებს"}
                      </td>
                    </tr>

                    {applicationInformation?.project_information?.actual_address?.municipality?.region ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>რეგიონი:</td>
                          <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.municipality?.region}</td>
                        </tr>
                      </>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>მუნიციპალიტეტი:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.project_information?.actual_address?.municipality?.municipality
                          ? applicationInformation?.project_information?.actual_address?.municipality?.municipality
                          : applicationInformation?.project_information?.actual_address?.balneological_village?.municipality}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროექტის განხორციელების ფაქტობრივი მისამართი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.street}</td>
                    </tr>
                    {applicationInformation?.project_information?.actual_address?.balneological_village?.municipality ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>სოფელი/დასახლება:</td>
                          <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.balneological_village?.village}</td>
                        </tr>
                      </>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>საკადასტრო კოდი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.cadastral_code}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ტერიტორიის სარგებლობის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.territory_usage_type}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ჯამური ინვესტიცია:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.total_investment}</td>
                    </tr>

                    <tr className={classes.row}>
                      <td className={classes.title}>დასრულების ვადა:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.completion_time}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.project_information?.economic_activity.some((activity) => activity.appendix_9 === true) && (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <Typography className={classes.cardSummary}>გრანტის ანაზღაურების სტატუსი</Typography>
                  <UniversalGrantFields applicationInformation={applicationInformation} applicationId={props.match.params.id} />
                </CardContent>
              </Card>
            )}
            {applicationInformation?.same_applicant_applications?.length > 0 && !isBank && (
              <SameApplicantTable classes={classes} rows={applicationInformation?.same_applicant_applications} component="universal" />
            )}
            {applicationInformation?.refinancing_information?.loan_id ? (
              <>
                <Card className={classes.component} variant="outlined">
                  <CardContent>
                    <Typography className={classes.cardSummary}>დარეფინანსირებული სესხის ინფორმაცია</Typography>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td className={classes.title}>ბანკი (საწყისი):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.initial_bank?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დარეფინანსირებელი სესხის ID:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.loan_id}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>სესხის პირველი ტრანშის გაცემის თარიღი:</td>
                          <td className={classes.detail}>
                            {dateFormat(applicationInformation?.refinancing_information?.first_disbursment_date, "yyyy-mm-dd", "T")}
                          </td>
                        </tr>
                        {/* <tr className={classes.row}>
                          <td className={classes.title}>მიზნობრივი თანადაფინანსება წლიური საპროცენტო განაკვეთიდან %:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.annual_co_financing}</td>
                        </tr> */}
                        <tr className={classes.row}>
                          <td className={classes.title}>დამტკიცებული თანხა (GEL):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.approved_amount}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ნაშთი (დარჩენილი ძირითადი თანხა) GEL:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.remaining_amount}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დარჩენილი ასათვისებელი მოცულობა (GEL) (არსებობის შემთხვევაში):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.outstanding_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </>
            ) : null}
            {!isBank && (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <InternalAttachments
                    externalClasses={classes}
                    applicationId={props.match.params.id}
                    component="universal"
                    initialFiles={applicationInformation?.internal_attachments?.map((attachment) => ({
                      fileName: attachment.path.split("/").pop(),
                      url: process.env.REACT_APP_BASE_URL + attachment.path,
                    }))}
                  />
                </CardContent>
              </Card>
            )}
          </div>

          <div className="right" style={{ width: window.innerWidth < 600 ? "100%" : "50%" }}>
            {applicationInformation?.approval_history?.length && !isBank > 0 ? (
              <ApprovalHistoryTable classes={classes} applicationInformation={applicationInformation} />
            ) : null}
            <WorldBankTable classes={classes} applicationInformation={applicationInformation} agencyLevel={agencyLevel} />
            <GreenTaxonomyTable classes={classes} applicationInformation={applicationInformation} />
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>აპლიკანტი</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>ბენეფიციარის სამართლებრივი ფორმა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_form?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სახელი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.name}</td>
                    </tr>
                    {applicationInformation?.applicant_information?.brand_name ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>ბრენდული სახელი:</td>
                        <td className={classes.detail}>{applicationInformation?.applicant_information?.brand_name}</td>
                      </tr>
                    ) : null}
                    {applicationInformation?.applicant_information?.social_media ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>სოციალური მედიის გვერდი:</td>
                        <td className={classes.detail}>{applicationInformation?.applicant_information?.social_media}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.identification_code}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>იურიდიული მისამართი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_address}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დაარსების თარიღი (წელი):</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.foundation_year}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სამეწარმეო კატეგორია:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.enterprise_category}</td>
                    </tr>
                    {applicationInformation?.applicant_information?.enterprise_category_comment ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>კატეგორიის კომენტარი:</td>
                        <td className={classes.detail}>{applicationInformation?.applicant_information?.enterprise_category_comment}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>საგადასახადო ვალდებულებები:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.tax_liabilities ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>რეგისტრირებული მევალე:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.applicant_information?.registered_debtor ? "მონიშნულია" : "არ არის მონიშნული"}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომელთა რაოდენობა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.employee_amount}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>შექმნილი სამუშაო ადგილები:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.jobs_created}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>ხელმომწერი პირები</Typography>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.signers?.map((signer) => (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>სახელი:</td>
                          <td className={classes.detail}>
                            {signer.first_name} {signer.last_name}
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პოზიცია:</td>
                          <td className={classes.detail}>{signer.position}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პირადი ნომერი:</td>
                          <td className={classes.detail}>{signer.private_number}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ტელ. ნომერი:</td>
                          <td className={classes.detail}>{signer.phone_number}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ელ-ფოსტა:</td>
                          <td className={classes.detail}>{signer.email}</td>
                        </tr>
                        {signer.poa_name ? (
                          <>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის სახელი:</td>
                              <td className={classes.detail}>{signer.poa_name}</td>
                            </tr>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის საიდენტიფიკაციო კოდი:</td>
                              <td className={classes.detail}>{signer.poa_identification_code}</td>
                            </tr>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის რეკვიზიტები:</td>
                              <td className={classes.detail}>{signer.poa_requisites}</td>
                            </tr>
                          </>
                        ) : null}
                      </>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>შშმ ადაპტაცია</Typography>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>ადაპტირებულია შშმ პირთათვის:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_adapted ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>იგეგმება ადაპტაცია:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_planned_to_adapt ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>შშმ თანამშრომელთა რაოდენობა:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.disabled_employees}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.eco_tourism_information?.type ? (
              <Card className={classes.component} variant="outlined">
                <Typography className={classes.cardSummary}>ეკო ტურიზმი</Typography>
                <CardContent>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>ტიპი:</td>
                        <td className={classes.detail}>{applicationInformation?.eco_tourism_information?.type}</td>
                      </tr>
                      {applicationInformation?.eco_tourism_information?.placement ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>შენობა დამახასიათებელია რეგიონის არქიტექტურის:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.placement?.existing_building ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>შეეძლება ერთდროულად არანაკლებ 8 სტუმრის გამასპინძლება:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.placement?.mutliple_guests ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>სანომრე ფონდი არაუმეტეს 10 ნომრისა:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.placement?.multiple_rooms ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {applicationInformation?.eco_tourism_information?.artisan ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>არსებული, მოქმედი სახელოსნო:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.artisan?.existing_workshop ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>გააჩნია არანაკლებ 10 ადამიანზე გათვლილი საჩვენებელი სივრცე:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.artisan?.demonstrational_area ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>გააჩნია არანაკლებ 5 ადამიანზე გათვლილი მასტერკლასის ჩატარების შესაძლებლობა:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.artisan?.masterclass_area ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      {applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>ველოსიპედი:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport?.bicycle ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>ცხენი:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport?.horse ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>საწყალოსნო:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport?.water ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>სეზონური ჩასაბმელები:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport?.seasonal_sled
                                ? "მონიშნულია"
                                : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>სხვა ტრანსპორტი:</td>
                            <td className={classes.detail}>{applicationInformation?.eco_tourism_information?.nature?.non_motorized_transport?.other}</td>
                          </tr>
                        </>
                      ) : null}
                      {applicationInformation?.eco_tourism_information?.nature?.accessories ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>უსაფრთხოების აქსესუარები:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.accessories?.safety ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>აღჭურვილობა/ინვენტარი:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.eco_tourism_information?.nature?.accessories?.inventory ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>სხვა აქსესუარები:</td>
                            <td className={classes.detail}>{applicationInformation?.eco_tourism_information?.nature?.accessories?.other}</td>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {applicationInformation?.agro_tourism_information ? (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <Typography className={classes.cardSummary}>აგრო ტურიზმი</Typography>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>განთავსება:</td>
                        <td className={classes.detail}>{applicationInformation?.agro_tourism_information?.placement ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>კვებითი მომსახურება:</td>
                        <td className={classes.detail}>{applicationInformation?.agro_tourism_information?.catering ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>საგანმანათლებლო/შემეცნებითი:</td>
                        <td className={classes.detail}>{applicationInformation?.agro_tourism_information?.educational ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>გასართობი/რეკრეაციული:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.agro_tourism_information?.recreational ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სხვა აქტივობა:</td>
                        <td className={classes.detail}>{applicationInformation?.agro_tourism_information?.other_activity}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>საკუთრების ან მართლზომიერი სარგებლობის უფლებით ფლობს ფერმერულ მეურნეობას:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.agro_tourism_information?.farming_property ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          სურსათის/ცხოველის საკვების უვნებლობის, ვეტერინარიისა და მცენარეთა დაცვის კოდექსით განსაზღვრული მოთხოვნების შესაბამისად,
                          რეგისტრირებულია ბიზნესოპერატორად:
                        </td>
                        <td className={classes.detail}>{applicationInformation?.agro_tourism_information?.food_safety ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          რეგისტრირებულია საქართველოს გარემოს დაცვისა და სოფლის მეურნეობის სამინისტროს ფერმათა/ფერმერთა რეესტრში:
                        </td>
                        <td className={classes.detail}>
                          {applicationInformation?.agro_tourism_information?.registered_farm ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {!isBank && (
              <ScheduleTolerancyDateSetter canModifyScheduling={canModifyScheduling} classes={classes} applicationInformation={applicationInformation} />
            )}
            <Card className={classes.component} variant="outlined">
              <CardContent>
                <Typography className={classes.cardSummary}>მიმაგრებული ფაილები</Typography>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.attachments?.poa_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>დანართი:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.elevator_absence_reason.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.building_permit.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>სასტუმროს/ბალნეოლოგიური კურორტის მშენებლობის ნებართვა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.agro_property_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>საკუთრების ან მართლზომიერი სარგებლობის უფლების დამადასტურებელი დოკუმენტი:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.agro_animal_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          სურსათის/ცხოველის საკვების უვნებლობის, ვეტერინარიისა და მცენარეთა დაცვის კოდექსით განსაზღვრული მოთხოვნების შესაბამისად,
                          ბიზნესოპერატორად რეგისტრაციის დოკუმენტი:
                        </td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.agro_farm_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          საქართველოს გარემოს დაცვისა და სოფლის მეურნეობის სამინისტროს ფერმათა/ფერმერთა რეესტრში რეგისტრაციის დოკუმენტი:
                        </td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.eco_authentic_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>დასკვნა რომ არსებული შენობა არის რეგიონისთვის დამახასიათებელი ავთენტური არქიტექტურის:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.tourist_services_information ? (
              <Card className={classes.component} variant="outlined">
                <Typography className={classes.cardSummary}>ტურისტული სერვისები</Typography>
                <CardContent>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>სტუმრის სიცოცხლისა და ჯანმრთელობის დაზღვევა:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.guest_life_insurance
                            ? "მონიშნულია"
                            : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          ჯგუფური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 20 ადამიანის მომსახურებას:
                        </td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.group_service ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          ინდივიდუალური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 5 ადამიანის მომსახურებას:
                        </td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.individual_service
                            ? "მონიშნულია"
                            : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ძველი დროის პერიოდის სიმულაცია:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.historic_simulation ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ლიფტი:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.elevator ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      {applicationInformation?.tourist_services_information?.elevator_absence_reason ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                            <td className={classes.detail}>{applicationInformation?.tourist_services_information?.elevator_absence_reason}</td>
                          </tr>
                        </>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>გარე განათება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.outside_light ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>შიდა განათება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.inside_light ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სანიტარული კეთილმოყობა:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.water_utilities ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ოთახ(ებ)ის, პავილიონ(ებ)ის განათება და ელექტრომომარაგება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.room_utilities ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {applicationInformation?.project_information?.hotel_information ? (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <Typography className={classes.cardSummary}>
                    {applicationInformation?.project_information?.economic_activity?.some((activity) => activity.activity_code === "55.20.0")
                      ? "ნომრ(ებ)ის/ერთეულის რაოდენობა"
                      : "სასტუმროს ინფორმაცია"}
                  </Typography>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>ნომრ(ებ)ის/ერთეულის რაოდენობა:</td>
                        <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.existing_rooms_amount}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>პროექტის ფარგლებში დამატებული ნომრ(ებ)ის/ერთეულის რაოდენობა:</td>
                        <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.additional_rooms_amount}</td>
                      </tr>

                      {!applicationInformation?.project_information?.economic_activity?.some((activity) => activity.activity_code === "55.20.0") && (
                        <>
                          {applicationInformation?.project_information?.hotel_information?.criteria?.permit_absence_reason ? (
                            <tr className={classes.row}>
                              <td className={classes.title}>ნებართვის არარსებობის მიზეზი:</td>
                              <td className={classes.detail}>
                                {applicationInformation?.project_information?.hotel_information?.criteria?.permit_absence_reason}
                              </td>
                            </tr>
                          ) : null}
                          <tr className={classes.row}>
                            <td className={classes.title}>ნებართვა გაცემულია:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.hotel_information?.criteria?.permit_given ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          {applicationInformation?.project_information?.hotel_information?.criteria?.permit_given ? (
                            <>
                              <tr className={classes.row}>
                                <td className={classes.title}>ნებართვის ნომერი:</td>
                                <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.criteria?.permit_number}</td>
                              </tr>
                              <tr className={classes.row}>
                                <td className={classes.title}>ნებართვის გამცემი ორგანო:</td>
                                <td className={classes.detail}>
                                  {applicationInformation?.project_information?.hotel_information?.criteria?.permit_organization}
                                </td>
                              </tr>
                            </>
                          ) : null}
                          <tr className={classes.row}>
                            <td className={classes.title}>სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.hotel_information?.satisfies_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>ლიფტი:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.hotel_information?.elevator ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          {applicationInformation?.project_information?.hotel_information?.elevator_absence_reason ? (
                            <>
                              <tr className={classes.row}>
                                <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                                <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.elevator_absence_reason}</td>
                              </tr>
                            </>
                          ) : null}
                          <tr className={classes.row}>
                            <td className={classes.title}>სანიტარული კეთილმოწყობა და ელექტრომომარაგება:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.utilities?.utilities_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>ხმაურის კონტროლი/ჰაერის კონდიცირება:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.air_conditioning?.noise_conditioning ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>უსაფრთხოების მინიმალური მოთხოვნები:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.security_measures?.fire_safety ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის:</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.disability_measures?.disabled_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>სტუმართა რეგისტრაცია (რეცეფცია):</td>
                            <td className={classes.detail}>
                              {applicationInformation?.project_information?.reception?.reception_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                            </td>
                          </tr>

                          <tr className={classes.row}>
                            <td className={classes.title}>სასადილო ან რესტორანი:</td>
                            <td className={classes.detail}>{applicationInformation?.project_information?.catering ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
          </div>
          {!loading && <ScheduleDiscrepancy isBank={isBank} loan_information={applicationInformation} />}
          {applicationInformation?.schedules?.length > 0 ? <ScheduleTable rows={applicationInformation.schedules} /> : null}
          {applicationInformation?.payments?.length > 0 ? <PaymentTable rows={applicationInformation.payments} /> : null}
          {applicationInformation?.bank_payment_history?.length > 0 ? <BankPaymentTable rows={applicationInformation.bank_payment_history} /> : null}
          <div className={classes.buttonContainer}>
            {!isBank && agencyLevel !== 0 && typeof applicationInformation?.approved === "undefined" ? (
              <>
                <Button onClick={() => submitApprovalHandler(true)} variant="contained" color="primary" startIcon={<CheckIcon />} disabled={loading}>
                  დადასტურება
                </Button>
                <Button onClick={() => submitApprovalHandler(false)} variant="contained" color="primary" startIcon={<ErrorIcon />} disabled={loading}>
                  უარყოფა
                </Button>
              </>
            ) : null}
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() =>
                  generatePDF(htmlTemplates.generateUniversalForBank(applicationInformation))
                    .then((pdfBlob) => downloadPDF(pdfBlob))
                    .then((a) => console.log("pdf downloaded"))
                    .catch((err) => {
                      console.error(err);
                      alert("დაფიქსირდა შეცდომა");
                    })
                }
              >
                PRINT
              </Button>
            </>
            <CloseLoan
              agencyLevel={agencyLevel}
              approved={applicationInformation?.approved}
              bankPayments={applicationInformation?.bank_payment_history}
              component="universal"
              loan_id={props.match.params.id}
              reloading={loading}
            />
          </div>

          {!isBank && agencyLevel >= 2 && !applicationInformation?.suspended ? (
            <>
              <Button
                onClick={() => suspendApplicationHandler()}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<RemoveCircleIcon />}
                disabled={loading}
              >
                სესხის გაუქმება
              </Button>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}

export default UniversalDetailed;
