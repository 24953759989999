import { Skeleton } from "@material-ui/lab";
import { TableRow, TableCell } from "@material-ui/core";

export default function TableSkeleton({ columns, amount }) {
  return (
    <>
      {Array.from({ length: amount }).map((_, i) => (
        <TableRow key={i}>
          {columns.map((column) => (
            <TableCell key={column.id} align={column.align}>
              <Skeleton variant="text" width={column.maxWidth} height={25} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
