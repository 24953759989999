import "./Login.css";
import { PostData } from "../../utilities/PostData";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CardActions } from "@material-ui/core";
import Modal from "../../components/modals/PasswordResetModal";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Footer from "../../components/landing/Footer";

function Login() {
  const [modalOpen, setModalOpen] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);

  useEffect(() => {
    if (captcha) {
      formik.handleSubmit();
    }
  }, [captcha]);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      values.captcha = captcha;
      axios
        .post("/auth/signin", values)
        .then(async (resp) => {
          if (resp.data.accessToken) {
            await localStorage.setItem("x-access-token", resp.data.accessToken);
            await localStorage.setItem("name", resp.data.name);
            await localStorage.setItem("role", resp.data.roles[0]);
            if (resp.data.roles.includes("ROLE_ADMIN")) {
              await localStorage.setItem("isAdmin", true);
            }
            if (resp.data.bank) {
              await localStorage.setItem("bank", JSON.stringify(resp.data.bank));
            }
            if (typeof resp.data.agencyLevel !== "undefined") {
              await localStorage.setItem("agency-level", resp.data.agencyLevel);
            }
            await localStorage.setItem("permissions", JSON.stringify(resp.data.permissions));
            window.location.reload();
          } else {
            alert(resp?.data?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
          } else {
            alert("დაფიქსირდა შეცდომა");
          }
          setLoading(false);
        })
        .finally(() => {
          setCaptcha(""); // reset the captcha after every attempt
        });
    },
  });

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={(cb) => {
          setModalOpen(cb);
        }}
      />
      <div className="login_container">
        <img src="/logo.png" alt="logo" />
        <Card className="login_card">
          <CardContent style={{ width: "90%" }}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await captchaRef.current.execute({ async: true });
              }}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                fullWidth
                id="email"
                name="email"
                label="ელ-ფოსტა"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                style={{ marginTop: 5, marginBottom: 25 }}
                fullWidth
                id="password"
                name="password"
                label="პაროლი"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                size="invisible"
                onVerify={(token) => {
                  setCaptcha(token);
                }}
                onError={(err) => {
                  console.error(err);
                  captchaRef.current.resetCaptcha();
                  setLoading(false);
                }}
                ref={captchaRef}
              />

              {/* Refactor button to include loading animation */}
              <Button color="primary" variant="contained" fullWidth type="submit" startIcon={<ExitToAppIcon />} disabled={loading}>
                შესვლა
              </Button>

              <Button
                style={{ margin: "0 auto 0 auto" }}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                პაროლის აღდგენა
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </>
  );
}

const validationSchema = yup.object({
  email: yup.string("შეიყვანეთ ფოსტა").email("შეიყვანეთ ვალიდური ფოსტა").required("ფოსტის შეყვანა აუცილებელია"),
  password: yup.string("შეიყვანეთ პაროლი").required("პაროლის შეყვანა აუცილებელია"),
});

export default Login;
