import dateFormat from "dateformat";
import {
  IAgroTourismInformation,
  ITouristServicesInformation,
  EcoTourismType,
  IArtisan,
  IEcoTourismInformation,
  IInputFieldOption,
  IMonitoringHotelFields,
  INature,
  IPlacement,
  Program,
  ProgramComponent,
  IMonitoringAgreementModel,
  IMonitoringFields,
  TourismType,
} from "../../../../types";
import { IMonitoringData } from "../../../../types/models/universal_schedule.interface";
import { assignValueToField } from "../utils";

export type Dependencies = {
  is_using_brand: string[];
  is_social_industry: string[];
  is_external_capital: string[];
  is_exporter: string[];
  is_functional: string[];
  is_started_operating_within_project: string[],
  is_same_company_profile_activity:string[],
  is_lack_of_personnel: string[];
  is_production_service_direction: string[];
  is_other_administration: string[];
  is_lack_of_administration: string[];
  is_person_disability: string[];
  is_building_adapted_for_person_disability: string[];
  program: string[];
  program_component: string[];
  "hotel_information.elevator": string[];
  "tourist_services_information.type": string[];
  "tourist_services_information.elevator": string[];
  "eco_tourism_information.type": string[];
  "eco_tourism_information.nature.accessories.is_other": string[];
  "eco_tourism_information.nature.non_motorized_transport.is_other": string[];
  "agro_tourism_information.is_other_activity": string[];
};

export interface IDependeciesCallbacks {
  "eco_tourism_information.type": (a: any) => () => boolean;
}

export type WatchedInitFieldsType = keyof Dependencies;
export const dependencies: Dependencies = {
  is_using_brand: ["used_brand"],
  is_social_industry: ["social_industry_description"],
  is_external_capital: ["external_capital_source"],
  is_exporter: ["product_export", "country_export"],
  is_functional: ["none_functional_reason", "stop_functional_activity_date"],
  is_started_operating_within_project: ["start_beneficiar_activity_date","is_same_company_profile_activity","other_profile"],
  is_same_company_profile_activity:["other_profile"],
  is_lack_of_personnel: ["is_lack_of_administration", "is_production_service_direction"],
  is_production_service_direction: ["lack_of_production_service_direction"],
  is_lack_of_administration: ["administration", "marketing", "accounting", "finances", "hr", "sales", "is_other_administration", "other_administration"],
  is_other_administration: ["other_administration"],
  is_person_disability: ["employeed_person_disability_amount"],
  is_building_adapted_for_person_disability: ["none_person_disability_reason_comment"],
  program: ["new_hotel_rooms", "hotel_rooms", "program_component"],
  program_component: [
    "new_hotel_rooms",
    "hotel_rooms",
    "hotel_information",
    "agro_tourism_information",
    "eco_tourism_information",
    "tourist_services_information",
  ],
  "hotel_information.elevator": ["hotel_information.elevator_absence_reason"],
  "tourist_services_information.type": [
    "adventure_tourism_measures",
    "historic_simulation",
    "tourist_services_information.adventure_tourism_measures.group_service",
    "tourist_services_information.adventure_tourism_measures.individual_service",
    "tourist_services_information.adventure_tourism_measures.guest_life_insurance",
    "tourist_services_information.historic_simulation",
  ],
  "tourist_services_information.elevator": ["tourist_services_information.elevator_absence_reason"],
  "eco_tourism_information.type": [
    "eco_tourism_information.placement",
    "eco_tourism_information.artisan",
    "eco_tourism_information.nature",
    "eco_tourism_information.placement.existing_building",
    "eco_tourism_information.placement.mutliple_guests",
    "eco_tourism_information.placement.multiple_rooms",
    "eco_tourism_information.artisan.existing_workshop",
    "eco_tourism_information.artisan.demonstrational_area",
    "eco_tourism_information.artisan.masterclass_area",
    "eco_tourism_information.nature.non_motorized_transport.bicycle",
    "eco_tourism_information.nature.non_motorized_transport.horse",
    "eco_tourism_information.nature.non_motorized_transport.water",
    "eco_tourism_information.nature.non_motorized_transport.seasonal_sled",
    "eco_tourism_information.nature.non_motorized_transport.is_other",
    "eco_tourism_information.nature.non_motorized_transport.other",
    "eco_tourism_information.nature.accessories.safety",
    "eco_tourism_information.nature.accessories.inventory",
    "eco_tourism_information.nature.accessories.is_other",
    "eco_tourism_information.nature.accessories.other",
  ],
  "eco_tourism_information.nature.accessories.is_other": ["eco_tourism_information.nature.accessories.other"],
  "eco_tourism_information.nature.non_motorized_transport.is_other": ["eco_tourism_information.nature.non_motorized_transport.other"],
  "agro_tourism_information.is_other_activity": ["agro_tourism_information.other_activity"],
};

export const dependenciesCallbacks: IDependeciesCallbacks = {
  "eco_tourism_information.type": (value: string) => () => value === EcoTourismType.PLACEMENT,
};
export const valueDependencies: { [key: string]: (...args: any[]) => boolean } = {
  new_hotel_rooms: (value: string) => value === ProgramComponent.HOTEL_INDUSTRY || value === ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY,
  hotel_rooms: (value: string) => value === ProgramComponent.HOTEL_INDUSTRY || value === ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY,
  hotel_information: (value: string) => {
    return (
      value === ProgramComponent.HOTEL_INDUSTRY ||
      value === ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY ||
      value === ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY
    );
  },
  agro_tourism_information: (value: string) => {
    // return value === ProgramComponent.AGRI_TORUSIM_INDUSTRY || value === ProgramComponent.PREFERENTIAL_AGRO_CREDIT;
    return value === ProgramComponent.AGRI_TORUSIM_INDUSTRY;
  },
  tourist_services_information: (value: string) => value === ProgramComponent.TOURISM_SERVICES_INDUSTRY,
  eco_tourism_information: (value: string) => value === ProgramComponent.ECO_TOURISM_INDUSTRY,
  adventure_tourism_measures: (value: string) => value === TourismType.AdventureTourismMeasures,
  // "tourist_services_information.elevator": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType) ? true : false,
  "tourist_services_information.adventure_tourism_measures.group_service": (value: string) => value === TourismType.AdventureTourismMeasures,
  "tourist_services_information.adventure_tourism_measures.individual_service": (value: string) => value === TourismType.AdventureTourismMeasures,
  "tourist_services_information.adventure_tourism_measures.guest_life_insurance": (value: string) => value === TourismType.AdventureTourismMeasures,
  historic_simulation: (value: string) => value === TourismType.HistoricSimulation,
  "tourist_services_information.historic_simulation": (value: string) => value === TourismType.HistoricSimulation,
  // "tourist_services_information.utilities_criteria": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  // "tourist_services_information.noise_conditioning": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  // "tourist_services_information.fire_safety": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  // "tourist_services_information.disabled_criteria": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  // "tourist_services_information.catering": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  // "tourist_services_information.reason_comment": (value: string) =>
  //   [TourismType.HistoricSimulation, TourismType.AdventureTourismMeasures].includes(value as TourismType),
  "eco_tourism_information.placement.existing_building": (value: string) => value === EcoTourismType.PLACEMENT,
  "eco_tourism_information.placement.mutliple_guests": (value: string) => value === EcoTourismType.PLACEMENT,
  "eco_tourism_information.placement.multiple_rooms": (value: string) => value === EcoTourismType.PLACEMENT,
  "eco_tourism_information.artisan.existing_workshop": (value: string) => value === EcoTourismType.ARTISAN,
  "eco_tourism_information.artisan.demonstrational_area": (value: string) => value === EcoTourismType.ARTISAN,
  "eco_tourism_information.artisan.masterclass_area": (value: string) => value === EcoTourismType.ARTISAN,
  "eco_tourism_information.nature.non_motorized_transport.bicycle": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.non_motorized_transport.horse": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.non_motorized_transport.water": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.non_motorized_transport.seasonal_sled": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.accessories.safety": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.accessories.inventory": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.non_motorized_transport.is_other": (value: string) => value === EcoTourismType.NATURE,
  "eco_tourism_information.nature.accessories.is_other": (value: string) => value === EcoTourismType.NATURE,

  code: (isTruthy: boolean) => (isTruthy ? false : true),
  "hotel_information.elevator_absence_reason": (isTruthy: boolean) => (isTruthy ? false : true),
  "tourist_services_information.elevator_absence_reason": (isTruthy: boolean) => (isTruthy ? false : true),
  none_person_disability_reason_comment: (isTruthy: boolean) => (isTruthy ? false : true),
  is_started_operating_within_project: (isTruthy: boolean) => (isTruthy ? false : true),
  other_profile:(isTruthy: boolean) => (isTruthy ? false : true),
};
export interface IFieldValues {
  product_export: string;
  country_export: (isTruthy: boolean) => string[];
  social_industry_description: string;
  used_brand: string;
  external_capital_source: string;
  none_functional_reason: string;
  stop_functional_activity_date: Date | null;
  start_beneficiar_activity_date:Date | null;
  is_same_company_profile_activity:boolean;
  other_profile:string;
  employeed_person_disability_amount: number;
  none_person_disability_reason_comment: string;
  is_lack_of_administration: boolean;
  is_production_service_direction: boolean;
  lack_of_production_service_direction: string;
  administration: boolean;
  marketing: boolean;
  accounting: boolean;
  finances: boolean;
  hr: boolean;
  sales: boolean;
  is_other_administration: boolean;
  other_administration: string;
  "hotel_information.elevator_absence_reason": string;
  "tourist_services_information.elevator": boolean;
  "tourist_services_information.elevator_absence_reason": string;
  "agro_tourism_information.other_activity": string;
  "eco_tourism_information.nature.accessories.is_other": boolean;
  "eco_tourism_information.nature.accessories.other": string;
  program_component: string;
  eco_tourism_information: IEcoTourismInformation;
  "eco_tourism_information.placement": IPlacement;
  "eco_tourism_information.artisan": IArtisan;
  "eco_tourism_information.nature": INature;
  new_hotel_rooms: number;
  hotel_rooms: number;
  agro_tourism_information: IAgroTourismInformation;
  hotel_information: IMonitoringHotelFields;
  tourist_services_information: ITouristServicesInformation;
  "tourist_services_information.adventure_tourism_measures.group_service": boolean;
  "tourist_services_information.adventure_tourism_measures.individual_service": boolean;
  "tourist_services_information.adventure_tourism_measures.guest_life_insurance": boolean;
  "tourist_services_information.historic_simulation": boolean;
  "tourist_services_information.utilities_criteria": boolean;
  "tourist_services_information.noise_conditioning": boolean;
  "tourist_services_information.fire_safety": boolean;
  "tourist_services_information.disabled_criteria": boolean;
  "tourist_services_information.catering": boolean;
  "tourist_services_information.reason_comment": string;
}

export const fieldValues: IFieldValues = {
  program_component: ProgramComponent.RESETVALUE,
  product_export: "",
  country_export: (isTruthy: boolean) => (isTruthy ? [""] : []),
  social_industry_description: "",
  used_brand: "",
  external_capital_source: "",
  none_functional_reason: "",
  stop_functional_activity_date: null,
  start_beneficiar_activity_date:null,
  is_same_company_profile_activity:true,
  other_profile:'',
  is_lack_of_administration: false,
  administration: false,
  marketing: false,
  accounting: false,
  finances: false,
  hr: false,
  sales: false,
  is_other_administration: false,
  other_administration: "",
  is_production_service_direction: false,
  lack_of_production_service_direction: "",
  employeed_person_disability_amount: 0,
  none_person_disability_reason_comment: "",
  tourist_services_information: {
    type: TourismType.RESETVALUE,
    adventure_tourism_measures: {
      guest_life_insurance: false,
      group_service: false,
      individual_service: false,
    },
    historic_simulation: false,
    elevator: false,
    elevator_absence_reason: "",
    utilities_criteria: false,
    noise_conditioning: false,
    fire_safety: false,
    disabled_criteria: false,
    catering: false,
    reason_comment: "",
  },
  "tourist_services_information.elevator_absence_reason": "",
  "tourist_services_information.elevator": false,
  "tourist_services_information.adventure_tourism_measures.group_service": false,
  "tourist_services_information.adventure_tourism_measures.individual_service": false,
  "tourist_services_information.adventure_tourism_measures.guest_life_insurance": false,
  "tourist_services_information.historic_simulation": false,
  "tourist_services_information.utilities_criteria": false,
  "tourist_services_information.noise_conditioning": false,
  "tourist_services_information.fire_safety": false,
  "tourist_services_information.disabled_criteria": false,
  "tourist_services_information.catering": false,
  "tourist_services_information.reason_comment": "",
  eco_tourism_information: {
    type: EcoTourismType.RESETVALUE,
    placement: {
      existing_building: false,
      mutliple_guests: false,
      multiple_rooms: false,
    },
    artisan: {
      existing_workshop: false,
      demonstrational_area: false,
      masterclass_area: false,
    },
    nature: {
      non_motorized_transport: {
        bicycle: false,
        horse: false,
        water: false,
        seasonal_sled: false,
        is_other: false,
        other: "",
      },
      accessories: {
        safety: false,
        inventory: false,
        is_other: false,
        other: "",
      },
    },
  },
  "eco_tourism_information.placement": {
    existing_building: false,
    mutliple_guests: false,
    multiple_rooms: false,
  },
  "eco_tourism_information.artisan": {
    existing_workshop: false,
    demonstrational_area: false,
    masterclass_area: false,
  },
  "eco_tourism_information.nature": {
    non_motorized_transport: {
      bicycle: false,
      horse: false,
      water: false,
      seasonal_sled: false,
      is_other: false,
      other: "",
    },
    accessories: {
      safety: false,
      inventory: false,
      is_other: false,
      other: "",
    },
  },
  "eco_tourism_information.nature.accessories.is_other": false,
  "eco_tourism_information.nature.accessories.other": "",
  agro_tourism_information: {
    placement: false,
    catering: false,
    educational: false,
    recreational: false,
    is_other_activity: false,
    other_activity: "",
    farming_property: false,
    food_safety: false,
    registered_farm: false,
  },
  hotel_information: {
    satisfies_criteria: false,
    elevator: false,
    elevator_absence_reason: "",
    utilities_criteria: false,
    noise_conditioning: false,
    fire_safety: false,
    disabled_criteria: false,
    reception_criteria: false,
    catering: false,
  },
  new_hotel_rooms: 0,
  hotel_rooms: 0,
  "hotel_information.elevator_absence_reason": "",
  "agro_tourism_information.other_activity": "",
};
export interface IFieldOptions {
  program_component: (value: string) => IInputFieldOption[];
}
// export const programToProgramComponent = {
//   INDUSTRIAL: ["INDUSTRIAL_COMPONENT", "HOTEL_INDUSTRY", "TOURISM_SERVICES_INDUSTRY"],
//   UNIVERSAL: ["INDUSTRIAL_COMPONENT"],
//   CREDIT_GUARANTEE_PROGRAM: ["CREDIT_GUARANTEE_COMPONENT", "PREFERENTIAL_AGRO_CREDIT"],
// };
export const optionsValueDependencies: IFieldOptions = {
  program_component: (value: string) => {
    switch (value) {
      case Program.INDUSTRIAL:
        return [
          { value: ProgramComponent.INDUSTRIAL_COMPONENT, label: ProgramComponent.INDUSTRIAL_COMPONENT },
          { value: ProgramComponent.TOURISM_SERVICES_INDUSTRY, label: ProgramComponent.TOURISM_SERVICES_INDUSTRY },
          { value: ProgramComponent.HOTEL_INDUSTRY, label: ProgramComponent.HOTEL_INDUSTRY },
          { value: ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY, label: ProgramComponent.BALNEOLOGICAL_RESORT_INDUSTRY },
        ];
      case Program.UNIVERSAL:
        return [
          { value: ProgramComponent.INDUSTRIAL_COMPONENT, label: ProgramComponent.INDUSTRIAL_COMPONENT },
          { value: ProgramComponent.MANUFACTORING_INDUSTRY, label: ProgramComponent.MANUFACTORING_INDUSTRY },
          { value: ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY, label: ProgramComponent.HOTEL_INDUSTRY_BALNEOLOGICAL_RESORTS_INDUSTRY },
          { value: ProgramComponent.AGRI_TORUSIM_INDUSTRY, label: ProgramComponent.AGRI_TORUSIM_INDUSTRY },
          { value: ProgramComponent.ECO_TOURISM_INDUSTRY, label: ProgramComponent.ECO_TOURISM_INDUSTRY },
        ];
      case Program.CREDIT_GUARANTEE_PROGRAM:
        return [
          {
            value: ProgramComponent.PREFERENTIAL_AGRO_CREDIT,
            label: ProgramComponent.PREFERENTIAL_AGRO_CREDIT,
          },
          {
            value: ProgramComponent.CREDIT_GUARANTEE_COMPONENT,
            label: ProgramComponent.CREDIT_GUARANTEE_COMPONENT,
          },
        ];
      default:
        return [];
    }
  },
};
export const generateValueFormat = {
  contract_signed_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  is_social_industry: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_exporter: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_using_brand: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_functional: (isTruthy: string) => (isTruthy ? "არა" : "კი"),
  is_started_operating_within_project:(isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_same_company_profile_activity: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_external_capital: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_lack_of_personnel: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_person_disability: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  start_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  stop_functional_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  start_beneficiar_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  is_building_rented: (isTruthy: string) => (isTruthy ? "იჯარა" : "საკუთრებაში"),
  is_building_adapted_for_person_disability: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_lack_of_administration: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_production_service_direction: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_sign_inscription_banner: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  administration: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  marketing: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  accounting: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  finances: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  hr: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  sales: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  is_other_administration: (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.satisfies_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.elevator": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.utilities_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.noise_conditioning": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.fire_safety": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.disabled_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.reception_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "hotel_information.catering": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.placement.existing_building": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.placement.mutliple_guests": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.placement.multiple_rooms": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.artisan.existing_workshop": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.artisan.demonstrational_area": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.artisan.masterclass_area": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.non_motorized_transport.bicycle": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.non_motorized_transport.horse": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.non_motorized_transport.water": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.non_motorized_transport.seasonal_sled": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.accessories.safety": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.accessories.inventory": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.placement": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.catering": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.recreational": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.educational": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.farming_property": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.food_safety": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.registered_farm": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "agro_tourism_information.is_other_activity": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.non_motorized_transport.is_other": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "eco_tourism_information.nature.accessories.is_other": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.utilities_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.noise_conditioning": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.fire_safety": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.disabled_criteria": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.catering": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.adventure_tourism_measures.guest_life_insurance": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.adventure_tourism_measures.group_service": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.adventure_tourism_measures.individual_service": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.historic_simulation": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.elevator": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.utilities.outside_light": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.utilities.water_utilities": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
  "tourist_services_information.utilities.room_utilities": (isTruthy: string) => (isTruthy ? "კი" : "არა"),
};
