/**
 * @React
 */
import React, {
  useEffect,
  useState,
  createContext,
  useContext
} from 'react';
/**
 * @abstract
 */

import { useFetchData } from '../../hooks';
/**
 * @Utils
 */
import { generateUrl } from '../../utilities/generateRequestUrl';
import config from '../../utilities/generateRequestUrl/config';
import { selectKeys, getCompaniesData } from '../../utilities/Fields/monitoringFields/utils';
import { monitoring_initial_data } from '../../utilities/Fields/monitoringFields/init';
/**
 * @types
 */
import {
  IMonitoringFields,
  IMonitoringAgreementModel,
  MyType
} from '../../types';
enum endpoints_enum {
  GETCOMPANIES = "getCompanies",
  GETAGREEMENT = "getAgreement",
}
// const data: IMonitoringAgreementModel[] = [...];
// const keys: (keyof IMonitoringAgreementModel)[] = [...];
export interface MonitoringFormContextType {
  endpoints: endpoints_enum | null;
  setEndpoints: React.Dispatch<React.SetStateAction<endpoints_enum | null>>;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  companiesData: IMonitoringAgreementModel[] | null;
  setCompaniesData: React.Dispatch<React.SetStateAction<IMonitoringAgreementModel[] | null>>;
  companies: MyType<{ value: string; label: string }> | null;
  setCompanies: React.Dispatch<React.SetStateAction<MyType<{ value: string; label: string }> | null>>;
  selectedCompany: string;
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>;
  selectedContract: string;
  setSelectedContract: React.Dispatch<React.SetStateAction<string>>;
  contracts: string[] | null;
  setContracts: React.Dispatch<React.SetStateAction<string[] | null>>;
  agreement: (IMonitoringFields & Partial<IMonitoringAgreementModel>) | null;
  setAgreement: React.Dispatch<React.SetStateAction<(IMonitoringFields & Partial<IMonitoringAgreementModel>) | null>>;
  agreementLoad: boolean;
  setAgreementLoad: React.Dispatch<React.SetStateAction<boolean>>;
  companiesLoad: boolean;
  setCompaniesLoad: React.Dispatch<React.SetStateAction<boolean>>;
  contractLoad: boolean;
  setContractLoad: React.Dispatch<React.SetStateAction<boolean>>;
  reset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  // formFields: Field[];
  // setFormFields: React.Dispatch<React.SetStateAction<Field[]>>;
  data: IMonitoringAgreementModel | IMonitoringAgreementModel[] | null;
  load: boolean;
  error: string | null;
}

const MonitoringFormContext = createContext<MonitoringFormContextType | undefined>(undefined);
const MonitoringFormContextProvider: React.FC = ({ children }) => {
  const [reset, setReset] = useState<boolean>(false);
  const [endpoints, setEndpoints] = useState<endpoints_enum | null>(null);
  const [url, setUrl] = useState<string>(() => generateUrl(config.client, endpoints_enum.GETCOMPANIES, null, null));
  const [companiesData, setCompaniesData] = useState<IMonitoringAgreementModel[] | null>(null);
  const [contracts, setContracts] = useState<string[] | null>(null);
  const [contractLoad, setContractLoad] = useState<boolean>(false);
  const [companiesLoad, setCompaniesLoad] = useState<boolean>(false);
  const [companies, setCompanies] = useState<MyType<{ value: string; label: string }> | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedContract, setSelectedContract] = useState<string>("");
  const [agreementLoad, setAgreementLoad] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<(IMonitoringFields & Partial<IMonitoringAgreementModel>) | null>(null);
  // const [formFields, setFormFields] = useState<Field[]>([])
  const { data, load, error } = useFetchData<IMonitoringAgreementModel | IMonitoringAgreementModel[] | null>(url);

  useEffect(() => {
    setEndpoints(endpoints_enum.GETCOMPANIES);
  }, []);

  useEffect(() => {
    if (reset) {
      setEndpoints(endpoints_enum.GETCOMPANIES);
      setUrl(generateUrl(config.client, endpoints_enum.GETCOMPANIES, null, null));
      setReset(false);
    }
  }, [reset]);
  useEffect(() => {
    if (endpoints === endpoints_enum.GETCOMPANIES) {
      getCompaniesData<IMonitoringAgreementModel, { value: string; label: string }>(
        data as IMonitoringAgreementModel[],
        setCompaniesData,
        (item: IMonitoringAgreementModel) =>
        ({ value: item.company_identification_code.toString(), label: `${item.company_name} ${item.company_identification_code}` } as {
          value: string;
          label: string;
        }),
        setCompanies,
        setCompaniesLoad
      );
    }
    if (endpoints === endpoints_enum.GETAGREEMENT) {
      if (data) {
        const response = data as Partial<IMonitoringAgreementModel>;
        const dt: Partial<IMonitoringAgreementModel> = {
          ...response,
        };
        let generateData = selectKeys(
          dt as IMonitoringFields & Partial<IMonitoringAgreementModel>,
          monitoring_initial_data as IMonitoringFields & Partial<IMonitoringAgreementModel>
        )
        setAgreement(
          generateData
        );
        setAgreementLoad(false);
      } else {
        setAgreement(null);
      }
      setAgreementLoad(false);
    }
  }, [data]);
  useEffect(() => {
    if (selectedContract) {
      setAgreementLoad(true);
      setEndpoints(endpoints_enum.GETAGREEMENT);
      setUrl(generateUrl(config.client, endpoints_enum.GETAGREEMENT, null, { contract_number: selectedContract }));
    }
  }, [selectedContract]);
  useEffect(() => {
    if (selectedCompany) {
      setContractLoad(true);
      setContracts(companiesData?.filter((a) => a.company_identification_code === selectedCompany).map((b) => b.contract_number) ?? null);
      setContractLoad(false);
    }
  }, [selectedCompany]);

  return (
    <MonitoringFormContext.Provider
      value={{
        reset,
        setReset,
        endpoints,
        setEndpoints,
        url,
        setUrl,
        companiesData,
        setCompaniesData,
        companies,
        setCompanies,
        selectedCompany,
        setSelectedCompany,
        contracts,
        setContracts,
        selectedContract,
        setSelectedContract,
        agreement,
        setAgreement,
        agreementLoad,
        setAgreementLoad,
        companiesLoad,
        setCompaniesLoad,
        contractLoad,
        setContractLoad,
        data,
        load,
        error,
      }}
    >
      {children}
    </MonitoringFormContext.Provider>
  );
};
export const useMonitoringFormContext = () => {
  const context = useContext(MonitoringFormContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export default MonitoringFormContextProvider;
