/**
 * @React
 */
import React, {
    FC,
    ReactNode,
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from "react";
/**
 * @Router
 */
import { useHistory, useLocation, useParams } from 'react-router-dom';
/**
 * @Router
 */
import {
    FormProvider,
    set,
    useForm,
    useFormContext,
    UseFormReturn
} from "react-hook-form";
/**
 * @MUI
 */
import {
    Box,
    Skeleton,
} from "@mui/material";
/**
 * @Context
 */
import {
    useGeneralInfoContext,
    useMonitoringFormContext,
} from "../../context";

/**
 * @Components
 */
import CardFieldSet from "../../components/CustomComponents/CardFieldSet";
import { MonitoringForm } from "../FormComponent";
import AutoComplete from "../CustomComponents/AutoComplete";
import SelectCategory from "../Selection";
import Loader from "../Loader";
/**
 * @Types
 */
import {
    IMonitoringFields,
    IInputField,
    IMonitoringAgreementModel,
    IFieldGroup,
    IGroup,
    INaceActivity,
    ICountry,
    MonitoringFieldsOptionType,
    MyType,
    TransformedData,
    MonitoringFieldsOptionsData
} from "../../types";
/**
 * @Utilities
 */
import {
    addOptions,
    updateMonitoringFormFields
} from "../../utilities/Fields/monitoringFields/utils";
import {
    monitoringGroupFieldsInitValues,
} from "../../utilities/Fields/monitoringFields/fields/forms";
import {
    dependencies,
    fieldValues,
    optionsValueDependencies,
    valueDependencies
} from "../../utilities/Fields/monitoringFields/monitoringDeps";
import { generateUrl } from "../../utilities/generateRequestUrl";
import config from "../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../hooks";
import axiosInstance from "../../utilities/axios";

interface ConnectFormProps {
    children: (methods: UseFormReturn) => ReactNode;
}

interface MonitoringLayoutProps {
    data: IMonitoringFields & Partial<IMonitoringAgreementModel>;
    load: boolean;
    setSubmitReload: Dispatch<SetStateAction<boolean>>;
    bool: SetStateAction<boolean>;
    setBool: Dispatch<SetStateAction<boolean>>;
}



const MonitoringLayout: FC<MonitoringLayoutProps> = ({
    data,
    load,
    setSubmitReload,
    bool,
    setBool,
}) => {
    // const [bool, setBool] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const [agreementLoad, setAgreementLoad] = useState<boolean>(load);
    const [agreement, setAgreement] = useState<(IMonitoringFields & Partial<IMonitoringAgreementModel>) | null>(() => data);
    const [monitoringGroupFieldsInit, setMonitoringGroupFieldsInit] = useState<IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[] | null>(null);
    const [fields, setFields] = useState<{ updatedFields: IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[]; dependencies: any | null } | null>(null);

    const history = useHistory();
    let {
        options: GeneralInfo,
        setOptions: setGeneralInfoOptions,
        data: GeneralInfoOptions,
        load: GeneralInfoLoad,
        error: GeneralInfoError,
    } = useGeneralInfoContext();

    useEffect(() => {
        if (GeneralInfo) {
            setBool(true);
            const { countries, nace_activities, banks, municipalities } = GeneralInfo;
            let updateed = addOptions(
                { countries, nace_activities, banks, municipalities } as TransformedData,
                monitoringGroupFieldsInitValues as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[],
            );
            setMonitoringGroupFieldsInit(updateed);
            setBool(false);
        }
    }, [GeneralInfo]);

    useEffect(() => {
        if (agreement && monitoringGroupFieldsInit && GeneralInfo) {
            setBool(true);
            let updated = updateMonitoringFormFields<IMonitoringFields & Partial<IMonitoringAgreementModel>, keyof IMonitoringFields & Partial<IMonitoringAgreementModel>>(
                monitoringGroupFieldsInit,
                // monitoringGroupFieldsInitValues,
                agreement as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                dependencies,
                fieldValues,
                valueDependencies,
                optionsValueDependencies
            );
            setFields(updated);
            setAgreementLoad(false);
            setBool(false);
        }
    }, [agreement, monitoringGroupFieldsInit, GeneralInfo]);
    const submitFn = async (data: IMonitoringFields & Partial<IMonitoringAgreementModel>) => {

        try {
            setSubmitReload(true);
            const resp = await axiosInstance.post(generateUrl(config.client, "updateApplication", null, null), {
                application: data,
            });
            if (resp && resp.data && resp.statusText == "OK") {
                history.push("/monitoring");
                setAgreement(null);
                setReset(true);
            }
        } catch (e) {
            if (e) {
                alert("დაფიქსირდა შეცდომა");
            }
            console.error(e);
        } finally {
            setSubmitReload(false)
        }
    };
    return (
        <>
            {
                bool
                    ? <Loader />
                    : data
                        &&
                        agreement
                        &&
                        fields
                        ?
                        <MonitoringForm
                            inpuFields={fields?.updatedFields as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[]}
                            agreement={agreement}
                            setAgreement={setAgreement}
                            setReset={setReset}
                            agreementLoad={agreementLoad}
                            dependencies={fields?.dependencies}
                            submitFn={submitFn}
                        /> : <p style={{ textAlign: 'center', fontSize:'11px' }}> იტვირთება... </p>
            }
        </>
    )
}

export default MonitoringLayout;
