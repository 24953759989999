import "./FilterApplications.css";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Paper,
  Tabs,
  Tab,
  FormControl,
  FormControlLabel,
  Badge,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
  TextField,
  Switch,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Mortgage from "./mortgage/Mortgage";
import Industrial from "./industrial/Industrial";
import Universal from "./universal/Universal";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import SetTableData from "../../utilities/SetTableData";
import { GetData } from "../../utilities/GetData";
import axios from "axios";
import Pagination from "../../components/filterApplication/Pagination";

function FilterApplications() {
  const [isBank, setIsBank] = useState();
  const [generalFilterData, setGeneralFilterData] = useState();
  const [localFilterData, setLocalFilterData] = useState();
  const [filterData, setFilterData] = useState({
    status: "review",
    bank_id: 0,
    page: 1,
    amountOnPage: 15,
    application_type: "mortgage",
    legacy_mortgage: false,
  });
  const [pages, setPages] = useState(0);
  const [downloadingSchedulesLoading, setDownloadingSchedulesLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [userBank, setUserBank] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [approvedApplicationsCount, setApprovedApplicationsCount] = useState(0);
  const [reviewApplicationsCount, setReviewApplicationsCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [approvalStatuses, setApprovalStatuses] = useState([
    { title: "განიხილება", value: "review" },
    { title: "გაუქმებული", value: "suspended" },
    { title: "დამტკიცებული", value: "approved" },
    { title: "უარყოფილი", value: "rejected" },
  ]);
  const [userPermissions, setUserPermissions] = useState([]);
  const classes = useStyles();

  const handleTempStateChange = (event, name) => {
    setLocalFilterData({ ...localFilterData, [name]: event.target.value });
  };

  const handleTempStateSubmit = () => {
    setFilterData({ ...filterData, ...localFilterData, page: 1 });
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "ROLE_BANK") {
      setIsBank(true);
    }
    if (localStorage.getItem("role") === "ROLE_MONITORING") {
      window.location.href = "/monitoring";
    }

    setUserPermissions(localStorage.getItem("permissions"));

    GetData("general_info")
      .then(async (resp) => {
        setGeneralFilterData(resp);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    SetTableData({ setIsLoading, setTableData, filterData, isBank, setApprovedApplicationsCount, setReviewApplicationsCount, tabValue, setPages });
  }, [filterData, tabValue]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setFilterData({ ...filterData, page: 1 });
    if (localStorage.getItem("bank")) {
      setUserBank(JSON.parse(localStorage.getItem("bank")));
    }
  };

  return (
    <div className="filter_applications_container">
      <Paper className={classes.root}>
        <Card className="add_application_card" style={{ borderRadius: "0", boxShadow: "none" }}>
          <Tabs value={tabValue < 3 ? tabValue : false} onChange={handleChange} variant="fullWidth" indicatorColor="secondary" textColor="primary">
            <Tab
              disabled={userBank?.leasing || (!userPermissions.includes("SUBMIT_MORTGAGE_APPLICATIONS") && isBank)}
              icon={<HomeIcon />}
              label={
                <Badge
                  max={9999}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  badgeContent={tabValue === 0 ? reviewApplicationsCount : 0}
                  classes={{ badge: classes.badgeReview }}
                >
                  <Badge
                    max={9999}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={tabValue === 0 ? approvedApplicationsCount : 0}
                    classes={{ badge: classes.badgeApproved }}
                  >
                    იპოთეკური სესხი
                  </Badge>
                </Badge>
              }
            />
            <Tab
              disabled={!userPermissions.includes("SUBMIT_BUSINESS_APPLICATIONS") && isBank}
              icon={<BusinessCenterIcon />}
              label={
                <Badge
                  max={9999}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  badgeContent={tabValue === 1 ? reviewApplicationsCount : 0}
                  classes={{ badge: classes.badgeReview }}
                >
                  <Badge
                    max={9999}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={tabValue === 1 ? approvedApplicationsCount : 0}
                    classes={{ badge: classes.badgeApproved }}
                  >
                    ინდუსტრიული სესხი{" "}
                  </Badge>
                </Badge>
              }
            />
            <Tab
              disabled={!userPermissions.includes("SUBMIT_BUSINESS_APPLICATIONS") && isBank}
              icon={<DomainDisabledIcon />}
              label={
                <Badge
                  max={9999}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  badgeContent={tabValue === 2 ? reviewApplicationsCount : 0}
                  classes={{ badge: classes.badgeReview }}
                >
                  <Badge
                    max={9999}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={tabValue === 2 ? approvedApplicationsCount : 0}
                    classes={{ badge: classes.badgeApproved }}
                  >
                    უნივერსალური სესხი{" "}
                  </Badge>
                </Badge>
              }
            />
          </Tabs>
          <div className={classes.filter}>
            <FormControl className={classes.filterSelector}>
              <InputLabel id="status-label">სტატუსი</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={filterData.status}
                onChange={(event) => setFilterData({ ...filterData, status: event.target.value })}
              >
                {approvalStatuses.map((approvalStatus) => (
                  <MenuItem key={approvalStatus.title} value={approvalStatus.value}>
                    {approvalStatus.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!isBank ? (
              <FormControl className={classes.filterSelector}>
                <InputLabel id="bank-label">ბანკი</InputLabel>
                <Select
                  labelId="bank-label"
                  id="bank-select"
                  value={filterData.bank}
                  onChange={(event) => setFilterData({ ...filterData, bank_id: event.target.value })}
                >
                  <MenuItem key={0} value={0}>
                    ყველა
                  </MenuItem>
                  {generalFilterData?.banks?.map((bank) => (
                    <MenuItem key={bank._id} value={bank._id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            <TextField className={classes.field} label="სესხის ID" onChange={(event) => handleTempStateChange(event, "loan_id")} />
            <TextField className={classes.field} label="სახელი" onChange={(event) => handleTempStateChange(event, "applicant_name")} />
            <TextField className={classes.field} label="აპლიკანტის ს/ნ" onChange={(event) => handleTempStateChange(event, "applicant_pid")} />
            <FormControl className={classes.dateSelector}>
              <InputLabel shrink>თარიღი (დან)</InputLabel>
              <Input type="date" defaultValue={filterData.date_from} onChange={(event) => handleTempStateChange(event, "date_from")}></Input>
            </FormControl>
            <FormControl className={classes.dateSelector} onChange={(event) => handleTempStateChange(event, "date_to")}>
              <InputLabel shrink>თარიღი (მდე)</InputLabel>
              <Input type="date"></Input>
            </FormControl>

            <Button
              style={{
                margin: "0 1rem",
              }}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              onClick={() => handleTempStateSubmit()}
            >
              <b>ძებნა</b>
            </Button>
            {/* If tabvalue is 0, add a <Switch /> to switch between new and legacy mortgage */}
            {tabValue === 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!filterData.legacy_mortgage}
                    onChange={(event) => {
                      setFilterData((prevState) => ({
                        ...prevState,
                        status: !event.target.checked ? "approved" : prevState.status,
                        legacy_mortgage: !prevState.legacy_mortgage,
                      }));
                    }}
                    value={!filterData.legacy_mortgage}
                  />
                }
                label={filterData.legacy_mortgage ? "ძველი იპოთეკა" : "ახალი იპოთეკა"}
              />
            )}
            {/* Receipt Icon button for downloading payment scheduling */}
            <Button
              style={{
                margin: "0 1rem",
              }}
              size="small"
              disabled={downloadingSchedulesLoading}
              variant="outlined"
              onClick={() => {
                const schedule_id = tableData.map((row) => row.latest_schedule);
                const application_type = filterData.application_type;
                setDownloadingSchedulesLoading(true);
                axios
                  .post("/view_schedule", { schedule_id, application_type })
                  .then((res) => {
                    setDownloadingSchedulesLoading(false);
                    window.location.assign(`${process.env.REACT_APP_BASE_URL}public/schedules/${res.data.scheduleFilePath}`);
                  })
                  .catch((err) => {
                    setDownloadingSchedulesLoading(false);
                    if (err.response) {
                      return alert(err.response.data.message);
                    }
                    alert("შეცდომა");
                  });
              }}
            >
              გრაფიკების გადმოტვირთვა
            </Button>
          </div>
        </Card>
        {tabValue === 1 ? (
          <Industrial
            tableData={tableData}
            page={filterData.page}
            isBank={isBank}
            loading={loading}
            setIsLoading={(cb) => setIsLoading(cb)}
            filterData={filterData}
            setFilterData={(cb) => setFilterData(cb)}
            setApprovedApplicationsCount={(cb) => setApprovedApplicationsCount(cb)}
            setReviewApplicationsCount={(cb) => setReviewApplicationsCount(cb)}
          />
        ) : tabValue === 2 ? (
          <Universal
            tableData={tableData}
            page={filterData.page}
            isBank={isBank}
            loading={loading}
            setIsLoading={(cb) => setIsLoading(cb)}
            filterData={filterData}
            setFilterData={(cb) => setFilterData(cb)}
            setApprovedApplicationsCount={(cb) => setApprovedApplicationsCount(cb)}
            setReviewApplicationsCount={(cb) => setReviewApplicationsCount(cb)}
          />
        ) : tabValue === 0 ? (
          <Mortgage tableData={tableData} isBank={isBank} loading={loading} filterData={filterData} setFilterData={(cb) => setFilterData(cb)} />
        ) : null}
        <Pagination {...{ filterData, setFilterData, tableData, pages }} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  badgeApproved: {
    color: "white",
    backgroundColor: "#4E9F3D",
  },
  badgeReview: {
    color: "white",
    backgroundColor: "#C8C6C6",
  },
  filter: {
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "row",
    padding: "15px 30px",
  },
  filterSelector: {
    minWidth: 120,
    marginRight: 30,
  },
  root: {
    width: "100%",
  },
  dateSelector: {
    marginRight: 30,
  },
  field: {
    [theme.breakpoints.between("md", "lg")]: {
      paddingTop: 10,
    },
    margin: "0 30px 0 0",
  },
}));

export default FilterApplications;
