import GetAgencyPosition from "../../utilities/GetAgencyPosition";
import dateFormat from "dateformat";
import { Card, CardContent, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";

export default function ApprovalHistoryTable({ classes, applicationInformation }) {
  return (
    <>
      <Card className={classes.component} variant="outlined">
        <CardContent>
          <Typography className={classes.cardSummary}>დადასტურების ისტორია</Typography>
          {applicationInformation?.approval_history?.map((approval_information) => (
            <table className={classes.table}>
              <tbody>
                <tr className={classes.row}>
                  <td className={classes.title}>მომხმარებელი:</td>
                  <td className={classes.detail}>{approval_information?.user_id?.name}</td>
                </tr>
                <tr className={classes.row}>
                  <td className={classes.title}>ელ-ფოსტა:</td>
                  <td className={classes.detail}>{approval_information?.user_id?.email}</td>
                </tr>
                {approval_information?.agency_level < 3 && (
                  <tr className={classes.row}>
                    <td className={classes.title}>დეპარტამენტი:</td>
                    <td className={classes.detail}>{approval_information?.user_id?.agencyDepartment?.title}</td>
                  </tr>
                )}
                <tr className={classes.row}>
                  <td className={classes.title}>თანამდებობა:</td>
                  <td className={classes.detail}>{GetAgencyPosition(approval_information?.agency_level)}</td>
                </tr>
                <tr className={classes.row}>
                  <td className={classes.title}>თარიღი:</td>
                  <td className={classes.detail}>{dateFormat(approval_information?.date, "yyyy-mm-dd", "T")}</td>
                </tr>
                {approval_information?.comment && (
                  <tr className={classes.row}>
                    <td className={classes.title}>კომენტარი:</td>
                    <td className={classes.detail}>{approval_information?.comment}</td>
                  </tr>
                )}
                <tr className={classes.row}>
                  <td className={classes.title}>სტატუსი:</td>
                  <td className={classes.detail}>
                    {approval_information?.approved ? (
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <CheckIcon color="primary" />
                        დადასტურება
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <ErrorIcon color="error" />
                        უარყოფა
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </CardContent>
      </Card>
    </>
  );
}
