import { useState, useEffect } from "react";
import { FormControl, FormControlLabel, Checkbox, TextField, Modal, Box, Link, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import checkCadastralCode from "../../utilities/CheckCadastralCode";

export default function WorldBank({ formik, classes, errorMessages, setErrorMessages }) {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    checkCadastralCode(formik, setErrorMessages);
  }, [formik.values.world_bank.government_subsidy_mechanism, setErrorMessages]);

  useEffect(() => {
    //When legal_activity_documents or banned_activities_disclaimer is set to unchecked, set world_bank.subsidy_mechanism to false
    if (!formik.values.world_bank.legal_activity_documents || !formik.values.world_bank.banned_activities_disclaimer) {
      formik.setFieldValue("world_bank.subsidy_mechanism", false);
    }
  }, [formik.values.world_bank.legal_activity_documents, formik.values.world_bank.banned_activities_disclaimer]);

  const ConditionsModal = () => {
    const conditions = [
      `მეწარმე სუბიექტის საქმიანობა არ არის მოცემული <a target="_blank" rel="noopener noreferrer" href="${process.env.REACT_APP_BASE_URL}public/world_bank_exceptions.pdf">მსოფლიო ბანკის აკრძალულ საქმიანობათა ნუსხაში.</a>`,
      "მეწარმე სუბიექტის საქმიანობა არ იწვევს იძულებით გადასახლებას/გასახლებას, ასევე, მეწარმე სუბიექტი არ ეწევა იმგვარ საქმიანობას, რომელიც მოიცავს მუდმივ ან დროებით შეზღუდვებს დაზარებული ადამიანის (მესაკუთრე ან მფლობელი) მიწაზე, აქტივებზე/საარსებო საშუალებაზე და წარმოებისათვის საჭირო ყველა მიწის და საკუთრების, სადაც ბიზნეს აქტივობები უნდა განხორციელდეს, კანონიერი მესაკუთრე/მფლობელი არის აღნიშნული მეწარმე სუბიექტი",
      "მეწარმე სუბიექტი არ არის სანქცირებული ეროვნულ გარემოსდაცვით და სოციალურ კანონმდებლობასთან შეუსაბამო ქმედების გამო",
      "მეწარმე სუბიექტის მიერ საქმიანობის განხორციელების ადგილი არ არის დაცული ტერიტორიები ან კულტურული მემკვიდრეობის ტერიტორია",
      "მეწარმე სუბიექტის საქმიანობა არ უქმნის მნიშვნელოვან რისკს მუშაკთა და საზოგადოების ჯანმრთელობასა და უსაფრთხოებას",
    ];

    return (
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            backgroundColor: "white",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "5px",
          }}
        >
          <ul>
            {conditions.map((condition, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <p dangerouslySetInnerHTML={{ __html: condition }}></p>
              </li>
            ))}
          </ul>
        </Box>
      </Modal>
    );
  };
  return (
    <>
      <b>რისკის კატეგორია</b>
      <FormControl variant="outlined" className={classes.formControl} required>
        <ConditionsModal />
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.world_bank.banned_activities_disclaimer}
              onChange={(event) => {
                formik.setFieldValue("world_bank.banned_activities_disclaimer", event.target.checked);
              }}
            />
          }
          label={
            <>
              არ ეწევა პროგრამით გათვალისწინებულ&nbsp;
              <Link
                style={{
                  //make LIGHT RED
                  color: "red",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                აკრძალულ საქმიანობებს
              </Link>
            </>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.world_bank.permits}
              onChange={(event) => {
                formik.setFieldValue("world_bank.permits", event.target.checked);
              }}
            />
          }
          label={
            <>
              <Link href="https://matsne.gov.ge/ka/document/view/26824?publication=91" target="_blank" rel="noopener">
                ლიცენზიებისა და ნებართვების შესახებ საქართველოს კანონის
              </Link>
              &nbsp; შესაბამისად, საჭიროების შემთხვევაში, ფლობს საქმიანობის განხორციელების ან/და მშენებლობისთვის საჭირო ყველა ლიცენზიას და ნებართვას (არსებობის
              შემთხვევაში ატვირთოს შესაბამისი დოკუმენტები)
            </>
          }
        />
        <p style={{ margin: "5px 0" }}>
          *გთხოვთ, რისკების კატეგორიის არჩევისას იხელმძღვანელოთ{" "}
          <a href="https://matsne.gov.ge/ka/document/view/3691981?publication=10" target="_blank" rel="noopener noreferrer">
            საქართველოს გარემოსდაცვითი შეფასების კოდექსის პირველი და მეორე დანართებით.
          </a>
        </p>
        <FormControl variant="outlined" className={classes.formControl} fullWidth>
          <InputLabel className={classes.inputLabel} id="demo-simple-select-label">
            მსოფლიო ბანკის რისკების კატეგორია
          </InputLabel>
          <Select
            className={classes.field}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            value={formik.values.world_bank.risk_category}
            name="world_bank.risk_category"
            onChange={formik.handleChange}
          >
            <MenuItem value="moderate">მცირე ან ზომიერი (Low or moderate)</MenuItem>
            <MenuItem value="substantial">მნიშვნელოვანი (Substantial) </MenuItem>
            <MenuItem value="high">მაღალი (High) </MenuItem>
          </Select>
          <>
            {formik.values.world_bank.risk_category === "substantial" || formik.values.world_bank.risk_category === "moderate" ? (
              <FormControlLabel
                disabled={errorMessages?.sanctioned_company}
                control={
                  <Checkbox
                    disabled={!formik.values.world_bank.banned_activities_disclaimer || !formik.values.world_bank.permits || errorMessages?.cadastral_code}
                    checked={formik.values.world_bank.subsidy_mechanism}
                    required={!formik.values.world_bank.government_subsidy_mechanism}
                    onChange={(event) => {
                      formik.setFieldValue("world_bank.subsidy_mechanism", event.target.checked);
                      if (event.target.checked) {
                        formik.setFieldValue("world_bank.government_subsidy_mechanism", false);
                      }
                    }}
                  />
                }
                label="მსოფლიო ბანკიდან დაფინანსებული პროექტი"
              />
            ) : null}

            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.world_bank.government_subsidy_mechanism}
                  required={!formik.values.world_bank.subsidy_mechanism}
                  onChange={(event) => {
                    formik.setFieldValue("world_bank.government_subsidy_mechanism", event.target.checked);
                    if (event.target.checked) {
                      formik.setFieldValue("world_bank.subsidy_mechanism", false);
                    }
                  }}
                />
              }
              label="სახელმწიფო სუბსიდირების მექანიზმი"
            />
            {/* If risk category is moderate and subsidy mechanism is not ticked, create a textfield for why applicant is not using subsidy */}
          </>
        </FormControl>

        <Typography>
          {formik.values.world_bank.risk_category === "substantial" ? (
            <>*მეწარმე სუბიექტის საქმიანობა, რომელიც მოცემულია საქართველოს გარემოსდაცვითი შეფასების კოდექსის მე-2 დანართში</>
          ) : null}
          {formik.values.world_bank.risk_category === "high" ? (
            <>*მიეკუთვნება საქმიანობებს, რომლებიც მოცემულია საქართველოს გარემოსდაცვითი შეფასების კოდექსის პირველ დანართში</>
          ) : null}
          {formik.values.world_bank.risk_category === "moderate" ? (
            <>
              *მეწარმე სუბიექტის საქმიანობა არ კვალიფიცირდება მნიშვნელოვანი და მაღალი რისკის მქონე საქმიანობებად, არ არის საქართველოს გარემოსდაცვითი შეფასების
              კოდექსის პირველი და მეორე დანართებით განსაზღვრული საქმიანობების ჩამონათვალში და არ გააჩნია ბუნებრივ და სოციალურ გარემოზე უარყოფითი ზემოქმედების
              პოტენციალი
            </>
          ) : null}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl} fullWidth>
          {(formik.values.world_bank.risk_category === "moderate" || formik.values.world_bank.risk_category === "substantial") &&
            formik.values.world_bank.government_subsidy_mechanism && (
              <TextField
                variant="outlined"
                className={classes.longerField}
                multiline
                rows={4}
                label="მიზეზი, რატომ არ იყენებს მსოფლიო ბანკის დაფინანსებას"
                name="world_bank.subsidy_mechanism_absence_comment"
                onChange={formik.handleChange}
              />
            )}
        </FormControl>
        {formik.values.world_bank.subsidy_mechanism && formik.values.world_bank.risk_category === "substantial" ? (
          <>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel className={classes.inputLabel} id="world_bank_subsidy">
                მსოფლიო ბანკიდან დაფინანსებული პროექტი
              </InputLabel>
              <Select
                className={classes.longerField}
                labelId="world_bank_subsidy"
                id="demo-simple-select"
                label="Age"
                value={formik.values.world_bank.eia_status}
                name="world_bank.eia_status"
                onChange={formik.handleChange}
              >
                <MenuItem value="eia_exists">აქვს გზშ </MenuItem>
                <MenuItem value="eia_abscence_with_letter">აქვს სკრინინგის გადაწყვეტილება გეს-იდან</MenuItem>
              </Select>
            </FormControl>
            <Typography>
              {formik.values.world_bank.eia_status === "eia_exists" ? (
                <>წარმოდგენილია სსიპ „გარემოს ეროვნული სააგენტოს“ მიერ დადასტურებული გარემოზე ზემოქმედების შეფასება (გზშ)</>
              ) : null}
              {formik.values.world_bank.eia_status === "eia_abscence_with_letter" ? (
                <>
                  სსიპ „გარემოს ეროვნული სააგენტოს“ მიერ გაცემულია წერილი გზშ-ს საჭიროების არ არსებობის თაობაზე, რომლის ატვირთვაც უნდა მოხდეს დანართებში. ან/და
                  არ არის წარმოდგენილი ხსენებული წერილი. ჩატარებულია ფინანსური ინსტიტუტის მხრიდან გარემოს დაცვითი და სოციალური სქრინინგი და საჭიროების
                  შემთხვევაში შემუშავებულია გარემოსდაცვითი და სოციალური რისკების შესამცირებელი გეგმა.
                </>
              ) : null}
            </Typography>
          </>
        ) : null}
      </FormControl>
    </>
  );
}
