import React, { useState, useEffect } from "react";
import { Box, Typography, FormControlLabel, Checkbox, TextField, Button, Divider } from "@mui/material";
import axios from "axios";

interface UniversalGrantFieldsProps {
  applicationInformation: any;
  applicationId: string;
}

interface ReimbursementData {
  status: boolean;
  report_number: string;
  report_date: string; // ISO date string (YYYY-MM-DD)
  reimbursed_amount: number;
}

interface GrantInformation {
  partially_reimbursed?: ReimbursementData;
  fully_reimbursed?: ReimbursementData;
}

const UniversalGrantFields: React.FC<UniversalGrantFieldsProps> = ({ applicationInformation, applicationId }) => {
  const [grantInformation, setGrantInformation] = useState<GrantInformation>({});
  const [showPartial, setShowPartial] = useState<boolean>(false);
  const [showFull, setShowFull] = useState<boolean>(false);
  const [totalReimbursedAmount, setTotalReimbursedAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch existing data when component mounts
  useEffect(() => {
    const fetchGrantData = async () => {
      try {
        const data = applicationInformation.grant_information || {};

        setGrantInformation(data);

        setShowPartial(!!data.partially_reimbursed?.status);
        setShowFull(!!data.fully_reimbursed?.status);

        // Calculate total reimbursed amount
        let totalAmount = 0;
        if (data.partially_reimbursed?.status) {
          totalAmount += data.partially_reimbursed.reimbursed_amount || 0;
        }
        if (data.fully_reimbursed?.status) {
          totalAmount += data.fully_reimbursed.reimbursed_amount || 0;
        }
        setTotalReimbursedAmount(totalAmount);
      } catch (error) {
        console.error("Error fetching grant data:", error);
      }
    };

    fetchGrantData();
  }, [applicationInformation]);

  // Handle checkbox change
  const handleCheckboxChange = (type: "partial" | "full") => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (type === "partial") {
      setShowPartial(checked);
      if (!checked) {
        // Remove partially_reimbursed data
        setGrantInformation((prev) => {
          const updated = { ...prev };
          delete updated.partially_reimbursed;
          return updated;
        });
      } else {
        // Initialize partially_reimbursed data if not present
        setGrantInformation((prev) => ({
          ...prev,
          partially_reimbursed: {
            status: true,
            report_number: "",
            report_date: "",
            reimbursed_amount: 0,
          },
        }));
      }
    } else if (type === "full") {
      setShowFull(checked);
      if (!checked) {
        // Remove fully_reimbursed data
        setGrantInformation((prev) => {
          const updated = { ...prev };
          delete updated.fully_reimbursed;
          return updated;
        });
      } else {
        // Initialize fully_reimbursed data if not present
        setGrantInformation((prev) => ({
          ...prev,
          fully_reimbursed: {
            status: true,
            report_number: "",
            report_date: "",
            reimbursed_amount: 0,
          },
        }));
      }
    }
  };

  // Handle changes in form fields
  const handleChange = (type: "partially_reimbursed" | "fully_reimbursed", field: keyof ReimbursementData, value: any) => {
    setGrantInformation((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [field]: value,
      },
    }));
  };

  // Recalculate total reimbursed amount when grantInformation changes
  useEffect(() => {
    let totalAmount = 0;
    if (grantInformation.partially_reimbursed?.status) {
      totalAmount += grantInformation.partially_reimbursed.reimbursed_amount || 0;
    }
    if (grantInformation.fully_reimbursed?.status) {
      totalAmount += grantInformation.fully_reimbursed.reimbursed_amount || 0;
    }
    setTotalReimbursedAmount(totalAmount);
  }, [grantInformation]);

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(`/agency/universal/update_grant/${applicationId}`, { grant_information: grantInformation });
      alert("Grant data updated successfully.");
    } catch (error) {
      console.error("Error updating grant data:", error);
      alert("Error updating grant data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
      {/* Checkboxes */}
      <FormControlLabel control={<Checkbox checked={showPartial} onChange={handleCheckboxChange("partial")} />} label="ნაწილობრივ ანაზღაურებული" />
      <FormControlLabel control={<Checkbox checked={showFull} onChange={handleCheckboxChange("full")} />} label="სრულად ანაზღაურებული" />

      <Divider sx={{ my: 2 }} />

      {/* Partially Reimbursed Fields */}
      {showPartial && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            ნაწილობრივ ანაზღაურებული
          </Typography>

          {/* Field I: Report Number */}
          <TextField
            label="მოხსენებითი ბარათის ნომერი"
            variant="outlined"
            fullWidth
            value={grantInformation.partially_reimbursed?.report_number || ""}
            onChange={(e) => handleChange("partially_reimbursed", "report_number", e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Field II: Report Date */}
          <TextField
            label="მოხსენებითი ბარათის გაშვების თარიღი"
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={grantInformation.partially_reimbursed?.report_date || ""}
            onChange={(e) => handleChange("partially_reimbursed", "report_date", e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Field III: Reimbursed Amount */}
          <TextField
            label="ანაზღაურებული გრანტის თანხა - ₾"
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            value={grantInformation.partially_reimbursed?.reimbursed_amount || ""}
            onChange={(e) => handleChange("partially_reimbursed", "reimbursed_amount", parseFloat(e.target.value) || 0)}
            sx={{ mb: 2 }}
          />
        </Box>
      )}

      {/* Fully Reimbursed Fields */}
      {showFull && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            სრულად ანაზღაურებული
          </Typography>

          {/* Field I: Report Number */}
          <TextField
            label="მოხსენებითი ბარათის ნომერი"
            variant="outlined"
            fullWidth
            value={grantInformation.fully_reimbursed?.report_number || ""}
            onChange={(e) => handleChange("fully_reimbursed", "report_number", e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Field II: Report Date */}
          <TextField
            label="მოხსენებითი ბარათის გაშვების თარიღი"
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={grantInformation.fully_reimbursed?.report_date || ""}
            onChange={(e) => handleChange("fully_reimbursed", "report_date", e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Field III: Reimbursed Amount */}
          <TextField
            label="ანაზღაურებული გრანტის თანხა - ₾"
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            value={grantInformation.fully_reimbursed?.reimbursed_amount || ""}
            onChange={(e) => handleChange("fully_reimbursed", "reimbursed_amount", parseFloat(e.target.value) || 0)}
            sx={{ mb: 2 }}
          />
        </Box>
      )}

      {/* Total Reimbursed Amount */}
      <Typography variant="body1" sx={{ mb: 2 }}>
        სულ ანაზღაურებული თანხა: ₾ {totalReimbursedAmount.toFixed(2)}
      </Typography>

      {/* Submit Button */}
      <Button disabled={loading} variant="contained" color="primary" onClick={handleSubmit} fullWidth>
        {loading ? "იტვირთება..." : "განახლება"}
      </Button>
    </Box>
  );
};

export default UniversalGrantFields;
