import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import CachedIcon from "@material-ui/icons/Cached";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

export default function ApprovalStatus({ applicationInformation }) {
  if (applicationInformation?.suspended) {
    return { name: "გაუქმებული", icon: <RemoveCircleIcon color="primary" /> };
  }
  if (applicationInformation?.approved === true) {
    return { name: "დადასტურებული", icon: <CheckIcon color="primary" /> };
  } else if (applicationInformation?.approved === false) {
    return { name: "უარყოფილი", icon: <ErrorIcon color="primary" /> };
  } else {
    return { name: "განსახილველი", icon: <CachedIcon color="primary" /> };
  }
}
