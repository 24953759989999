import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div>
        This site is protected by hCaptcha and its{" "}
        <a href="https://www.hcaptcha.com/privacy" target="_blank">
          Privacy Policy
        </a>
        <span> and </span>
        <a href="https://www.hcaptcha.com/terms" target="_blank">
          Terms of Service
        </a>
        <span> apply. </span>
      </div>
    </footer>
  );
}
