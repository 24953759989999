import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table } from "@material-ui/core";
import RowStyle from "../../../utilities/FilterRowStyle";
import TableSkeleton from "../../../components/TableSkeleton";

function Mortgage({ tableData, isBank, filterData, setFilterData, loading }) {
  const columns = [
    { id: "numeration", label: "" },
    { id: "icon", label: "" },
    { id: "purpose", label: "მიზნობრიობა" },
    { id: "loan_id", label: "სესხის ID" },
    { id: "applicant_name", label: "აპლიკანტის სახელი" },
    { id: "applicant_pid", label: "პ/ნ" },
    { id: "approved_amount", label: "სესხის თანხა - ₾" },
    { id: "submit_date", label: "გამოგზავნის თარიღი" },
    { id: "bank", label: "ფინ. ინსტიტუტი" },
    { id: "status", label: "სტატუსი" },
  ].filter(Boolean);

  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" classes={{ root: classes.tableRoot }}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} className={classes.tableCell}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableSkeleton amount={15} columns={columns} /> : null}
            {tableData.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row._id}
                  onDoubleClick={() => {
                    if (filterData.legacy_mortgage) {
                      window.open(`/legacy-mortgage-application/${row._id}`);
                    } else {
                      window.open(`/mortgage-application/${row._id}`);
                    }
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell className={classes.tableCell} style={RowStyle(row.approval_history, row.status, isBank)} key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableCell: {
    [theme.breakpoints.between("md", "lg")]: {
      padding: "10px",
    },
  },
  dateSelector: {
    marginRight: 30,
  },
  field: {
    margin: "0 30px 0 30px",
  },
}));

export default Mortgage;
