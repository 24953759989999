import "./Users.css";
import { useState, useEffect } from "react";
import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Modal from "../../components/modals/UserModal";
import ChangeModal from "../../components/modals/PasswordChangeModal";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import TableSkeleton from "../../components/TableSkeleton";
import UserCreateDialog from "../../components/dialogs/UserCreateDialog";

function Users() {
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [filterData, setFilterData] = useState({ page: 1 });
  const [generalFilterData, setGeneralFilterData] = useState();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [isBank, setIsBank] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminSelectedUser, setAdminSelectedUser] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem("role") === "ROLE_BANK") {
      setIsBank(true);
    }
    if (localStorage.getItem("role") === "ROLE_MONITORING") {
      setIsMonitoring(true);
    }
    if (localStorage.getItem("isAdmin")) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    setUserPermissions(localStorage.getItem("permissions"));

    axios
      .get("/general_info")
      .then(async (resp) => {
        setGeneralFilterData(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });

    axios
      .post("/fetch_users", filterData)
      .then((res) => {
        setLoading(false);
        let updatedTableData = [];
        res.data.map((result) => {
          if (result.bank) {
            result = { ...result, bank: result.bank.name };
          } else if (result.roles.some((role) => role.name.includes("monitoring"))) {
            result = { ...result, bank: "მონიტორინგი" };
          } else {
            // console.log(result);
            result = { ...result, bank: "სააგენტო" };
          }
          if (result.permissions.includes("ADD_USER")) {
            result = { ...result, icon: <Tooltip title="ადმინისტრატორი">{<SupervisorAccountIcon color="primary" />}</Tooltip> };
          }
          updatedTableData.push(result);
        });
        setTableData(updatedTableData);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [filterData, modalOpen]);

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={(cb) => {
          setModalOpen(cb);
        }}
      />
      <ChangeModal
        isBank={isBank}
        modalOpen={changeModalOpen}
        setModalOpen={(cb) => {
          setChangeModalOpen(cb);
        }}
        user={adminSelectedUser}
        userId={adminSelectedUser._id}
        userPermissions={adminSelectedUser.permissions}
        generalData={generalFilterData}
      />
      <UserCreateDialog
        open={createUserDialogOpen}
        setOpen={setCreateUserDialogOpen}
        banks={generalFilterData?.banks}
        isBank={isBank}
        isMonitoring={isMonitoring}
        agencyDepartments={generalFilterData?.agency_departments}
      />
      <Paper>
        <Card variant="outlined">
          <CardContent>
            <CardHeader title="მომხმარებლები" style={{ textAlign: "center" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              {userPermissions.includes("ADD_USER") ? (
                <>
                  {
                    <Button
                      onClick={() => {
                        setCreateUserDialogOpen(true);
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                    >
                      მომხმარებლის დამატება
                    </Button>
                  }
                  <FormControl>
                    <TextField
                      id="outlined-basic"
                      label="მომხმარებელი"
                      onChange={(e) => {
                        //Only if length is more than 3, if not, set to empty
                        if (e.target.value.length > 3) {
                          setFilterData({ ...filterData, email: e.target.value });
                        } else {
                          setFilterData({ ...filterData, email: "" });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="outlined-basic"
                      label="სახელი"
                      onChange={(e) => {
                        if (e.target.value.length > 3) {
                          setFilterData({ ...filterData, name: e.target.value });
                        } else {
                          setFilterData({ ...filterData, name: "" });
                        }
                      }}
                    />
                  </FormControl>
                </>
              ) : null}
              {!isBank && !isMonitoring ? (
                <FormControl className={classes.filterSelector}>
                  <InputLabel id="bank-label">ინსტიტუცია</InputLabel>
                  <Select
                    labelId="bank-label"
                    id="bank-select"
                    value={filterData?.bank}
                    onChange={(event) => setFilterData({ ...filterData, bankId: event.target.value })}
                  >
                    <MenuItem value="agency">სააგენტო</MenuItem>
                    <MenuItem value="monitoring">მონიტორინგი</MenuItem>
                    {generalFilterData?.banks?.map((bank) => (
                      <MenuItem value={bank._id}>{bank.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </div>
          </CardContent>
        </Card>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" classes={{ root: classes.tableRoot }}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  //Give small width
                  align="center"
                  style={{ minWidth: "10px" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableSkeleton amount={30} columns={columns} />
              ) : (
                <>
                  {tableData.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number" ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">
                          {isAdmin ||
                            (userPermissions.includes("ADD_USER") && !isBank) ||
                            (userPermissions.includes("ADD_USER") && isBank && !row.permissions.includes("ADD_USER")) ? (
                            <IconButton
                              onClick={() => {
                                setAdminSelectedUser(row);
                                setChangeModalOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.tableFooter}>
          {filterData.page === 1 ? (
            <IconButton aria-label="delete" disabled color="primary">
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="delete"
              onClick={() => {
                setFilterData({ ...filterData, page: filterData.page - 1 });
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {tableData.length < 10 ? (
            <IconButton aria-label="delete" disabled style={{ marginLeft: "auto" }}>
              <ArrowForwardIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="delete"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setFilterData({ ...filterData, page: filterData.page + 1 });
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          )}
        </div>
      </Paper>
    </>
  );
}

const columns = [
  { id: "icon", label: "", align: "center" },
  { id: "bank", label: "ფინანსური ინსტიტუცია", minWidth: 100, align: "center" },
  { id: "name", label: "სახელი", minWidth: 150, align: "center" },
  { id: "email", label: "ელ-ფოსტა", minWidth: 100, align: "center" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  filter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "30px 15px",
  },
  filterSelector: {
    minWidth: 120,
  },
  tableFooter: {
    display: "flex",
  },
  field: {
    margin: "0 30px 0 30px",
  },
});

export default Users;
