"use client";
import { createContext, useContext, Dispatch, SetStateAction, useState, PropsWithChildren } from "react";
import { useEffect } from "react";
import axios from "axios";
import { IUser } from "../../types/models/user.interface";
import { IMonitoringApprovalHistory } from "../../types";

export enum Type {
  monitoring = "monitoring",
  agreement = "agreement",
  approved = "approved",
}

type Filter = {
  company_name: string;
  company_identification_code: string;
  page: number;
  amountOnPage: number;
  type: Type;
  municipality: string;
  component: string;
};

export type ApplicationPreview = {
  _id: string;
  company_name: string;
  company_identification_code: string;
  contract_number: string;
  approved_amount: number;
  contract_signed_date: string;
  program_component: string;
  component: string;
  monitoring_approval?: boolean;
  actual_municipality?: string;
  contract?: string;
  created_by?: IUser;
  monitoring_approval_history?: IMonitoringApprovalHistory[];
};

interface FilterContextProps {
  setFilter: Dispatch<SetStateAction<Filter>>;
  filter: Filter;
  applications: ApplicationPreview[];
  setApplications: Dispatch<SetStateAction<ApplicationPreview[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  totalPages: number;
  setTotalPages: Dispatch<SetStateAction<number>>;
  fetchApplications: () => void;
}

const MonitoringApplicationContext = createContext<FilterContextProps>({
  setFilter: () => { },
  filter: { company_name: "", company_identification_code: "", page: 1, amountOnPage: 15, type: Type.monitoring, municipality: "", component: "" },
  applications: [],
  setApplications: () => { },
  loading: true,
  setLoading: () => { },
  totalPages: 1,
  setTotalPages: () => { },
  fetchApplications: () => { },
});

export const MonitoringApplicationContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [applications, setApplications] = useState<ApplicationPreview[]>([]);
  const [filter, setFilter] = useState<Filter>({ company_name: "", company_identification_code: "", page: 1, amountOnPage: 15, type: Type.monitoring, municipality: "", component: "" });
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchApplications();
  }, [filter.page, filter.amountOnPage, filter.type]);

  function fetchApplications() {
    setApplications([]);
    setLoading(true);
    axios
      .get(`/monitoring/applications`, { params: { ...filter } })
      .then((res) => {
        const mappedApplications = res.data.results.map((application: any) => {
          return {
            ...application,
            contract_signed_date: new Date(application.contract_signed_date).toLocaleDateString(),
          };
        });
        setApplications(mappedApplications);
        setLoading(false);
        setTotalPages(res.data.pages);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  return (
    <MonitoringApplicationContext.Provider
      value={{
        setFilter,
        filter,
        applications,
        setApplications,
        loading,
        setLoading,
        totalPages,
        setTotalPages,
        fetchApplications,
      }}
    >
      {children}
    </MonitoringApplicationContext.Provider>
  );
};

export const useMonitoringApplicationContext = () => useContext(MonitoringApplicationContext);
