/**
 * @React
 */
import React, { FC, ReactNode, useEffect, useState } from "react";
/**
 * @Router
 */
import { FormProvider, set, useForm, useFormContext, UseFormReturn } from "react-hook-form";
/**
 * @MUI
 */
import { Box, Skeleton, Card, CardContent } from "@mui/material";
/**
 * @Context
 */
import { useGeneralInfoContext, useMonitoringFormContext } from ".././../../context";

/**
 * @Components
 */
import { MonitoringForm } from "../../../components/FormComponent";
import AutoComplete from "../../../components/CustomComponents/AutoComplete";
import SelectCategory from "../../../components/Selection";
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
  IMonitoringFields,
  IInputField,
  IMonitoringAgreementModel,
  IFieldGroup,
  IGroup,
  INaceActivity,
  ICountry,
  MonitoringFieldsOptionType,
  MyType,
  TransformedData,
} from "../../../types";
/**
 * @Utilities
 */
import {
  addOptions,
  updateMonitoringFormFields
} from "../../../utilities/Fields/monitoringFields/utils";
import {
  monitoringGroupFieldsInitValues,
} from "../../../utilities/Fields/monitoringFields/fields/forms";
import {
  dependencies,
  fieldValues,
  optionsValueDependencies,
  valueDependencies
} from "../../../utilities/Fields/monitoringFields/monitoringDeps";
import axiosInstance from "../../../utilities/axios";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useHistory } from "react-router-dom";

interface ConnectFormProps {
  children: (methods: UseFormReturn) => ReactNode;
}
const ConnectForm = ({ children }: ConnectFormProps) => {
  const methods = useFormContext();

  return children({ ...methods });
};

const MonitoringFormPage: FC = () => {
  const [bool, setBool] = useState<boolean>(false);
  const [monitoringGroupFieldsInit, setMonitoringGroupFieldsInit] = useState<IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[] | null>(null);
  const [fields, setFields] = useState<{ updatedFields: IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[]; dependencies: any | null } | null>(null);
  const [submitReload, setSubmitReload] = useState<boolean>(false);
  const history = useHistory();
  let {
    options: GeneralInfo,
    setOptions: setGeneralInfoOptions,
    data: GeneralInfoOptions,
    load: GeneralInfoLoad,
    error: GeneralInfoError,
  } = useGeneralInfoContext();
  const {
    setReset,
    setCompaniesData,
    companiesLoad,
    contracts,
    contractLoad,
    companies,
    setCompanies,
    selectedCompany,
    selectedContract,
    agreement,
    agreementLoad,
    setAgreement,
    setSelectedCompany,
    setSelectedContract,
    error,
    load
  } = useMonitoringFormContext();

  useEffect(() => {
    if (GeneralInfo) {
      setBool(true);
      const { countries, nace_activities, banks, municipalities } = GeneralInfo;
      let updateed = addOptions(
        { countries, nace_activities, banks, municipalities } as TransformedData,
        monitoringGroupFieldsInitValues as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[],
      )
      setMonitoringGroupFieldsInit(updateed);
      setBool(false);
    }
  }, [GeneralInfo]);
  useEffect(() => {
    if (agreement && monitoringGroupFieldsInit && GeneralInfo) {
      setBool(true);
      let updated = updateMonitoringFormFields<IMonitoringFields & Partial<IMonitoringAgreementModel>, keyof IMonitoringFields & Partial<IMonitoringAgreementModel>>(
        monitoringGroupFieldsInit,
        agreement as IMonitoringFields & Partial<IMonitoringAgreementModel>,
        dependencies,
        fieldValues,
        valueDependencies,
        optionsValueDependencies
      );
      setFields(updated);
      setBool(false);
    }
  }, [agreement, monitoringGroupFieldsInit, GeneralInfo]);

  const submitFn = async (data: IMonitoringFields & Partial<IMonitoringAgreementModel>) => {
    setSubmitReload(true)
    try {
      const resp = await axiosInstance.post(generateUrl(config.client, "monitoringSubmit", null, null), {
        application: data,
      });
      if (resp && resp.data && resp.statusText == "OK") {
        history.push("/monitoring");
        setAgreement(null);
        setReset(true);
      }
    } catch (e) {
      if (e) {
        alert("დაფიქსირდა შეცდომა");
      }
      console.error(e);
    } finally {
      setSubmitReload(false);
    }
  };

  return (
    <>
      {!submitReload ? <Card sx={{ width: "100%", margin: "15px auto 10px auto" }}>
        <CardContent>
          {load || agreementLoad || GeneralInfoLoad || bool ? (
            <Loader />
          ) : error ? (
            <>
              <p>ERROR</p>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {
                  bool ? (
                    <Skeleton variant="rectangular" width={`100%`} height={35} sx={{ borderRadius: "5px" }} />
                  ) : (
                    <AutoComplete
                      name={"company"}
                      value={
                        selectedCompany && companies
                          ? (companies[selectedCompany as keyof MyType<{ value: string; label: string }>] as unknown as { value: string; label: string })
                          : null
                      }
                      label={"ბენეფიციარი"}
                      options={companies ?? {}}
                      callback={(val) => {
                        if (val) {
                          setSelectedCompany(val.value.toString());
                          setAgreement(null);
                        }
                      }}
                    />
                  )}
                {contracts && (
                  <Box
                    style={{
                      paddingTop: "20px",
                    }}
                  >
                    {
                      contractLoad ? (
                        <Skeleton variant="rectangular" width={`100%`} height={40} sx={{ borderRadius: "5px" }} />
                      ) : (
                        <SelectCategory
                          name={"contracts"}
                          value={selectedContract}
                          label={"ხელშეკრულება/სესხის/ლიზინგის ID"}
                          options={contracts ?? []}
                          callback={(val) => {
                            setSelectedContract(val.toString());
                          }}
                        />
                      )}
                  </Box>
                )}
              </Box>
            </>
          )}
          {
            agreement
              &&
              fields
              ? (
                <MonitoringForm
                  inpuFields={fields?.updatedFields as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[]}
                  agreement={agreement}
                  setAgreement={setAgreement}
                  setReset={setReset}
                  agreementLoad={agreementLoad}
                  dependencies={fields?.dependencies}
                  submitFn={submitFn}
                />
              )
              :
              null
          }
        </CardContent>
      </Card>
        :
        <Loader />}
    </>
  );
};

export default MonitoringFormPage;

// import { useDependentFields } from "../../../hooks";
// import { GetData } from "../../../utilities/GetData";
// import useFetchOptions from "../../../hooks/useGenerateMonitoringFields";
// import useGenerateMonitoringFieldsData from "../../../hooks/useGenerateMonitoringFields";