import axios from "axios";

const token = localStorage.getItem("x-access-token");
const cancelTokenSource = axios.CancelToken.source();
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    ...(token && { "x-access-token": token }),
  },
  cancelToken: cancelTokenSource.token,
});

export default axiosInstance;
