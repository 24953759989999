import "./AddApplication.css";
import { Card, CardContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import Mortgage from "../../components/application/mortgage/Mortgage";
import Industrial from "../../components/application/industrial/Industrial";
import Universal from "../../components/application/universal/Universal";
import MortgageNew from "../../components/application/mortgage/MortgageNew";

function AddApplication() {
  const [tabValue, setTabValue] = useState(3);
  const [userPermissions, setUserPermissions] = useState([]);
  const [userBank, setUserBank] = useState({});
  useEffect(() => {
    setUserPermissions(localStorage.getItem("permissions"));
    setUserBank(JSON.parse(localStorage.getItem("bank")));
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Router basename="/add-application">
      <div className="add_application_container">
        <Card className="add_application_card" style={{ boxShadow: "none" }}>
          <Tabs
            value={tabValue < 3 ? tabValue : false}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="primary"
            aria-label="icon label tabs example"
          >
            <Tab
              disabled={!userPermissions.includes("SUBMIT_MORTGAGE_APPLICATIONS") || userBank?.leasing}
              icon={<HomeIcon />}
              label="იპოთეკური სესხი"
              component={Link}
              // to={"mortgage"}
              to={"mortgage-new"}
            />
            <Tab
              disabled={!userPermissions.includes("SUBMIT_BUSINESS_APPLICATIONS")}
              icon={<BusinessCenterIcon />}
              label="ინდუსტრიული სესხი"
              component={Link}
              to={"industrial"}
            />
            <Tab
              disabled={!userPermissions.includes("SUBMIT_BUSINESS_APPLICATIONS")}
              icon={<DomainDisabledIcon />}
              label="უნივერსალური სესხი"
              component={Link}
              to={"universal"}
            />
          </Tabs>
          <CardContent className="add_application_card_content" style={{ padding: "0" }}>
            <Switch>
              <Route path="/mortgage" exact component={Mortgage} />
              <Route path="/mortgage-new" exact component={MortgageNew} />
              <Route path="/industrial" exact component={Industrial} />
              <Route path="/universal" exact component={Universal} />
            </Switch>
          </CardContent>
        </Card>
      </div>
    </Router>
  );
}

export default AddApplication;
