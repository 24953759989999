import React from "react";
import { FormControl, FormLabel, FormControlLabel, Checkbox } from "@mui/material";

export type Permission = {
  name: string;
  value: string;
  implies?: string[];
};

interface PermissionsCheckboxGroupProps {
  permissions: Permission[];
  userPermissions: string[];
  setUserPermissions: (newPermissions: string[]) => void;
}

const PermissionsCheckboxGroup: React.FC<PermissionsCheckboxGroupProps> = ({ permissions, userPermissions, setUserPermissions }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, permission: Permission) => {
    const isChecked = event.target.checked;
    let newPermissions = [...userPermissions];

    if (isChecked) {
      newPermissions.push(permission.value);
      permission.implies?.forEach((implied) => {
        if (!newPermissions.includes(implied)) {
          newPermissions.push(implied);
        }
      });
    } else {
      newPermissions = newPermissions.filter((p) => p !== permission.value);
      permission.implies?.forEach((implied) => {
        newPermissions = newPermissions.filter((p) => p !== implied);
      });
    }

    setUserPermissions(newPermissions);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">უფლებები</FormLabel>
      {permissions.map((permission) => (
        <FormControlLabel
          key={permission.value}
          control={
            <Checkbox
              checked={userPermissions.includes(permission.value)}
              onChange={(event) => handleCheckboxChange(event, permission)}
              disabled={permissions.some((p) => p.implies?.includes(permission.value) && userPermissions.includes(p.value))}
              name={permission.value}
            />
          }
          label={permission.name}
        />
      ))}
    </FormControl>
  );
};

export default PermissionsCheckboxGroup;
