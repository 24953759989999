import { Typography, Card, CardContent, FormControlLabel, Checkbox, TextField, Button } from "@material-ui/core";
import { useState } from "react";

export default function WorldBankTable({ classes, applicationInformation, agencyLevel }) {
  const [monitoringData, setMonitoringData] = useState({
    environmental_check: false,
    social_check: false,
    comment: "",
  });
  return (
    <Card
      className={classes.component}
      variant="outlined"
      style={{
        display: applicationInformation?.world_bank?.risk_category && applicationInformation?.world_bank.eia_status ? "block" : "none",
      }}
    >
      <CardContent>
        <Typography className={classes.cardSummary}>რისკის კატეგორია</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.title}>არ ეწევა პროგრამით გათვალისწინებულ აკრძალულ საქმიანობებს:</td>
              <td className={classes.detail}>{applicationInformation?.world_bank?.banned_activities_disclaimer ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>რისკების კატეგორია:</td>
              <td className={classes.detail}>
                {applicationInformation?.world_bank?.risk_category === "substantial" ? "მნიშვნელოვანი (Substantial)" : null}
                {applicationInformation?.world_bank?.risk_category === "high" ? "მაღალი (High)" : null}
                {applicationInformation?.world_bank?.risk_category === "moderate" ? "მცირე ან ზომიერი (Low or moderate)" : null}
              </td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>მსოფლიო ბანკიდან დაფინანსებული პროექტი:</td>
              <td className={classes.detail}>{applicationInformation?.world_bank?.subsidy_mechanism ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>სახელმწიფო სუბსიდირების მექანიზმი:</td>
              <td className={classes.detail}>{applicationInformation?.world_bank?.government_subsidy_mechanism ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.title}>ფლობს საქმიანობის განხორციელების ან/და მშენებლობისთვის საჭირო ყველა ლიცენზიას და ნებართვას:</td>
              <td className={classes.detail}>{applicationInformation?.world_bank?.permits ? "მონიშნულია" : "არ არის მონიშნული"}</td>
            </tr>
            {/* Make row for world_bank.subsidy_mechanism_absence_comment if such exists */}
            {applicationInformation?.world_bank?.subsidy_mechanism_absence_comment ? (
              <tr className={classes.row}>
                <td className={classes.title}>მიზეზი, რატომ არ იყენებს მსოფლიო ბანკის დაფინანსებას:</td>
                <td className={classes.detail}>{applicationInformation?.world_bank?.subsidy_mechanism_absence_comment}</td>
              </tr>
            ) : null}
            {/* Display only if risk category is substantial */}
            {applicationInformation?.world_bank?.risk_category === "substantial" ? (
              <tr className={classes.row}>
                <td className={classes.title}>გზშ სტატუსი:</td>
                <td className={classes.detail}>
                  {applicationInformation?.world_bank?.eia_status === "eia_exists" ? "აქვს გზშ" : null}
                  {applicationInformation?.world_bank?.eia_status === "eia_abscence_with_letter" ? "აქვს სკრინინგის გადაწყვეტილება გეს-იდან" : null}
                  {applicationInformation?.world_bank?.eia_status === "eia_abscence" ? "არ აქვს გზშ (არ არის წერილი გეს-იდან)" : null}
                </td>
              </tr>
            ) : null}
          </tbody>
          {/* 
        If application approval history is not empty and agencyLevel is 0, 
        create 2 checkboxes with comment field if not checked
        */}
          {applicationInformation?.approval_history?.length > 0 && agencyLevel === 0 ? (
            <tbody>
              <tr className={classes.row}>
                <td className={classes.title}>გავლილია სააგენტოს გარემოს დაცვის სპეციალისტის შემოწმება:</td>
                <td className={classes.detail}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={monitoringData.environmental_check}
                        name="environmental_check"
                        color="primary"
                        onChange={(e) => {
                          setMonitoringData((prevState) => ({
                            ...prevState,
                            environmental_check: e.target.checked,
                          }));
                        }}
                      />
                    }
                    label="დადასტურება"
                  />
                </td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>გავლილია სააგენტოს სოციალური რისკების სპეციალისტის შემოწმება:</td>
                <td className={classes.detail}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={monitoringData.social_check}
                        name="social_check"
                        color="primary"
                        onChange={(e) => {
                          setMonitoringData((prevState) => ({
                            ...prevState,
                            social_check: e.target.checked,
                          }));
                        }}
                      />
                    }
                    label="დადასტურება"
                  />
                </td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>კომენტარი:</td>
                <td className={classes.detail}>
                  <TextField
                    id="outlined-multiline-static"
                    label="კომენტარი"
                    multiline
                    rows={2}
                    variant="outlined"
                    value={monitoringData.comment}
                    onChange={(e) => {
                      setMonitoringData((prevState) => ({
                        ...prevState,
                        comment: e.target.value,
                      }));
                    }}
                    name="comment"
                  />
                </td>
              </tr>
              <tr className={classes.row}>
                <td className={classes.title}>დადასტურება:</td>
                <td className={classes.detail}>
                  <Button variant="contained" color="primary" style={{ display: "block", margin: "auto" }} size="small" fullWidth>
                    შენახვა
                  </Button>
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </CardContent>
    </Card>
  );
}
