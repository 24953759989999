import React, { useState, useEffect } from "react";
import { Box, Button, Typography, List, ListItem, ListItemIcon, ListItemText, makeStyles, createStyles, Theme } from "@material-ui/core";
import { InsertDriveFile as InsertDriveFileIcon, CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import axios, { AxiosResponse, AxiosError } from "axios";
import FileUploadDropbox from "../../dialogs/FileUploadDropbox";

interface FileData {
  fileName: string;
  url: string;
}

interface InternalAttachmentsProps {
  applicationId: string;
  component: string;
  initialFiles?: FileData[];
  externalClasses?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropbox: {
      border: "2px dashed #cccccc",
      padding: theme.spacing(4),
      textAlign: "center",
      cursor: "pointer",
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    listItemPending: {
      color: theme.palette.text.disabled,
    },
    listItemIcon: {
      minWidth: "40px",
    },
    newUploadedItem: {
      backgroundColor: theme.palette.action.hover,
    },
  })
);

const InternalAttachments: React.FC<InternalAttachmentsProps> = ({ applicationId, component, externalClasses, initialFiles = [] }) => {
  const classes = useStyles();
  const [initialFilesState, setInitialFilesState] = useState<FileData[]>(initialFiles); // Files already uploaded
  const [uploadedFiles, setUploadedFiles] = useState<FileData[]>([]); // New files uploaded via FileUploadDropbox
  const [pendingFiles, setPendingFiles] = useState<FileData[]>([]); // Files selected but not yet uploaded
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setInitialFilesState(initialFiles);
  }, [initialFiles]);

  // Handle successful upload
  const handleUploadSuccess = (data: any) => {
    const uploadedFilePaths: FileData[] = data.internal_attachments.map((filePath: string) => ({
      fileName: filePath.split("/").pop() || "Unknown File",
      url: filePath,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...uploadedFilePaths]);

    setPendingFiles([]);
  };

  const handleUploadError = (err: any) => {
    console.error("Error uploading files:", err);
    if (err.response && err.response.data && err.response.data.message) {
      alert(err.response.data.message);
    } else {
      alert("File upload failed");
    }
  };

  const handleFilesSelected = (files: File[]) => {
    const newPendingFiles: FileData[] = files.map((file) => ({
      fileName: file.name,
      url: "",
    }));
    setPendingFiles((prevFiles) => [...prevFiles, ...newPendingFiles]);
  };

  // Handle submit
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/agency/submit_attachments/${component}/${applicationId}`, {
        attachments: uploadedFiles,
      });
      if (res.data.message) {
        alert(res.data.message);
      }
      setInitialFilesState((prevFiles) => [...prevFiles, ...uploadedFiles]);
      setUploadedFiles([]);
    } catch (error) {
      const err = error as AxiosError<any>;
      console.error("Error submitting files:", err);
      if (err.response && err.response.data && err.response.data.message) {
        alert(err.response.data.message);
      } else {
        alert("დაფიქსირდა შეცდომა");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography className={externalClasses.cardSummary}>შიდა დოკუმენტები</Typography>

      {/* Previously Uploaded Files */}
      {initialFilesState.length > 0 && (
        <Box>
          <List>
            {initialFilesState.map((file, index) => (
              <ListItem key={`initial-${index}`}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InsertDriveFileIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={file.fileName}
                  secondary={
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                      {file.url}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {uploadedFiles.length > 0 && (
        <Box>
          <Typography variant="h6">ახლა ატვირთული ფაილები</Typography>
          <List>
            {uploadedFiles.map((file, index) => (
              <ListItem key={`uploaded-${index}`} className={classes.newUploadedItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CloudUploadIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={file.fileName}
                  secondary={
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                      {file.url}
                    </a>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {/* Pending Files */}
      {pendingFiles.length > 0 && (
        <Box>
          <Typography variant="h6">ასატვირთი ფაილები</Typography>
          <List>
            {pendingFiles.map((file, index) => (
              <ListItem key={`pending-${index}`} className={classes.listItemPending}>
                <ListItemIcon className={classes.listItemIcon}>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary={file.fileName} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {/* Dropbox */}
      <FileUploadDropbox
        uploadUrl="/agency/upload_attachments"
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        fieldName="internal_attachments"
        promptMessage="Do you want to upload these files?"
        multiple={true}
        // Pass a callback to handleFilesSelected
        onFilesSelected={handleFilesSelected}
      />

      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading || uploadedFiles.length === 0} style={{ marginTop: "20px" }}>
        {loading ? "იტვირთება..." : "ატვირთვა"}
      </Button>
    </Box>
  );
};

export default InternalAttachments;
